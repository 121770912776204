import React from "react";

import { useBeneficiary } from "hooks/beneficiary";

import {
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorMode,
	useColorModeValue,
} from "@chakra-ui/react";
import dateFormat from "functions/dateFormat";
import { useBeneficiaries } from "hooks/beneficiaries";

const BeneficiaryTeleOrientation = () => {
	const { beneficiary } = useBeneficiaries();
	const events = beneficiary?.events;

	const textColor = useColorModeValue('black', 'white');
	const bgColor = useColorModeValue('gray.200', 'gray.500');

	const getTeleTurn = (tele) => {
		try {
			const timestamp = new Date(tele);
			const turn = timestamp.getHours() < 12 ? "Manhã" : "Tarde";
			return dateFormat(timestamp) + ' - ' + turn;
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<Flex w="100%">
			{
				!events ?
					<Flex mb="24px">
						<Text size="md">
							Não há informações para serem exibidas
						</Text>
					</Flex>
					:
					<TableContainer width="100%">
						<Table variant='simple' size='md'>
							<Thead>
								<Tr bg={bgColor}>
									<Th color={textColor}>Data / Hora</Th>
								</Tr>
							</Thead>
							<Tbody>
								{
									events.filter(ev => ev.type == 'tele').length == 0 ?
										<Tr>
											<Td>Não há registros de tele-atendimento</Td>
										</Tr>
										:
										events.filter(ev => ev.type == 'tele').map(ev => (
											<Tr>
												<Td>{getTeleTurn(ev.scheduled_to)}</Td>
											</Tr>
										))
								}
							</Tbody>
						</Table>
					</TableContainer>
			}
		</Flex>
	)
}

export default BeneficiaryTeleOrientation