
const ACTION_TYPES = {
    LIST: "LIST_EVENTS",
    NEW_EVENT: "NEW_EVENT",
    STATISTICS: "STATISTICS",
    PAGINATE_DAY_EVENTS: "PAGINATE_DAY_EVENTS",
    EVENT_CANCELED: "EVENT_CANCELED",
}
const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.LIST: {
            return {
                ...state,
                events: action.data
            }
        }
        case ACTION_TYPES.STATISTICS: {
            return {
                ...state,
                statistics: action.statistics
            }
        }
        case ACTION_TYPES.PAGINATE_DAY_EVENTS: {
            return {
                ...state,
                rows: action.data.eventsPagination.data,
                page: action.data.eventsPagination.paginatorInfo.currentPage,
                perPage: action.data.eventsPagination.paginatorInfo.perPage,
                total: action.data.eventsPagination.paginatorInfo.total,
                currentPage: action.data.eventsPagination.paginatorInfo.currentPage,
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export {
    reducers,
    ACTION_TYPES
}
