import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetProfessionals } from "hooks/calendar";

import { useUpdateCommitment } from "hooks/commitments";

import {
  Button,
  useToast,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
} from "@chakra-ui/react";

const EditCommitment = ({
  commitment: { id, status, contact_attempts, professional },
  getStatusLabel,
  onClose,
}) => {
  const [
    getProfessionals,
    { data: professionals, loading: loadingProfessionals },
  ] = useGetProfessionals();
  const methods = useForm();
  const toast = useToast();

  React.useEffect(() => {
    getProfessionals();
  }, []);

  const schema = yup.object().shape({
    status: yup
      .string()
      .required("O campo Status é obrigatório")
      .oneOf(
        ["pending", "contact_attempt", "scheduled", "refused"],
        "Valor de status inválido"
      ),
    contact_attempts: yup
      .number("O valor do campo é numérico")
      .positive("O valor desse campo deve ser um número positivo"),
    professional_id: yup.string().when("status", {
      is: (status) => status === "refused" || status === "scheduled",
      then: yup.string().required("O campo Profissional é obrigatório"),
    }),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [
    updateCommitment,
    { loading: loadingUpdate, error },
  ] = useUpdateCommitment({
    onSuccess: (data) => {
      toast({
        title: "Consulta atualizada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Ocorreu um erro.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onSubmit = () => {
    const variables = {
      input: {
        id: id,
        status: newStatus,
        professional_id: getValues("professional_id"),
        contact_attempts: parseInt(getValues("contact_attempts")) || 0,
        refusal_motive: getValues("refusal_motive"),
      },
    };

    updateCommitment({ variables: variables });
  };

  const newStatus = watch("status");

  return (
    <>
      <ModalOverlay />
      <ModalContent padding="16px">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Editar consulta</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={5} isInvalid={errors?.status}>
                <FormLabel fontWeight="bold" fontSize="sm">
                  Status
                </FormLabel>
                <Select
                  {...register("status")}
                  borderRadius="8px"
                  fontSize="md"
                  defaultValue={status}
                >
                  {status === "pending" && (
                    <option value="pending">Pendente</option>
                  )}
                  <option value="contact_attempt">Tentativa de contato</option>
                  <option value="scheduled">Agendado</option>
                  <option value="refused">Recusado</option>
                </Select>
                {errors?.status && (
                  <FormErrorMessage>{errors.status.message}</FormErrorMessage>
                )}
              </FormControl>

              {newStatus == "contact_attempt" && (
                <FormControl mb={5} isInvalid={errors?.contact_attempts}>
                  <FormLabel fontWeight="bold" fontSize="xs">
                    Número de tentativas
                  </FormLabel>
                  <NumberInput
                    defaultValue={contact_attempts || 1}
                    min={1}
                    max={99}
                    size="sm"
                  >
                    <NumberInputField {...register("contact_attempts")} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  {errors?.contact_attempts && (
                    <FormErrorMessage>
                      {errors.contact_attempts.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )}

              {newStatus == "refused" && (
                <FormControl mb={5} isInvalid={errors?.refusal_motive}>
                  <FormLabel fontWeight="bold" fontSize="xs">
                    Motivo de recusa
                  </FormLabel>
                  <Select
                    {...register("refusal_motive")}
                    borderRadius="8px"
                    fontSize="md"
                  >
                    <option value="not_interested">Não deseja agendar</option>
                    <option value="private_doctor">
                      Possui médico particular
                    </option>
                    <option value="specialist_pass">
                      Passa com especialista
                    </option>
                  </Select>
                  {errors?.refusal_motive && (
                    <FormErrorMessage>
                      {errors.refusal_motive.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )}

              {(newStatus === "scheduled" || newStatus === "refused") && (
                <FormControl mb={5} isInvalid={errors?.professional_id}>
                  <FormLabel fontWeight="bold" fontSize="sm">
                    Profissional Responsável
                  </FormLabel>
                  <Select
                    {...register("professional_id")}
                    defaultValue={professional?.user?.id}
                    borderRadius="8px"
                    fontSize="md"
                    placeholder="Selecione uma opção..."
                  >
                    {professionals?.professionals.map((professional) => {
                      return (
                        <option id={professional.id} value={professional.id}>
                          {professional?.user?.name || null}
                        </option>
                      );
                    })}
                  </Select>
                  {errors?.professional_id && (
                    <FormErrorMessage>
                      {errors.professional_id.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )}
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                bg="notrelife.900"
                color="white"
                onClick={onClose}
                variant="nohover"
                disabled={!isValid}
              >
                Atualizar
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </>
  );
};

export default EditCommitment;
