import { ResponsiveBar } from '@nivo/bar'
import { useColorModeValue } from "@chakra-ui/react";

import { padding } from "variables/nivo/bar";
import { axisLeft, themeDark, themeLight, tooltip } from "variables/nivo/default";
import { axisBottom } from 'variables/nivo/default';

const getName = (value) => {
    if (value == 'awaiting') return 'Aguardando Agendamento';
    if (value == 'scheduled') return 'Agendados';
    else return value;
}

const getPercentage = (value, data) => {
    if (!data.awaiting || data.awaiting === 0 || !data.scheduled || data.scheduled === 0) return '100';
    const total = data.awaiting + data.scheduled;
    return ((value / total) * 100).toFixed(0);
}

const SchedulesPA = ({ data = [] }) => {
    const colorMode = useColorModeValue(themeLight, themeDark);

    return (
        <ResponsiveBar
            data={data}
            keys={["awaiting", "scheduled"]}
            indexBy="name"
            layout="vertical"
            groupMode='grouped'
            margin={{ top: 20, right: 60, bottom: 70, left: 50 }}
            padding={padding}
            colors={["#F15A22", "#2B6CB0"]}
            labelSkipWidth={11}
            labelSkipHeight={5}
            theme={colorMode}
            axisBottom={axisBottom}
            axisTop={null}
            axisLeft={axisLeft}
            labelTextColor="#FFF"
            tooltip={(data) => { return tooltip(getName(data.id), `${data.value} (${getPercentage(data.value, data.data)}%)`) }}
        />
    )
}

export default SchedulesPA