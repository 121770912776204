import { createContext, useContext, useReducer } from "react";
import { useQuery } from "@apollo/client";
import { getMonth, getYear } from "date-fns";
import { HEALTH, LIVES, OPERATIONAL, WHATS_APP, REGIONAL, CPT } from "graphql/dashboards";
import { ACCUMULATED } from "graphql/dashboards";

const ACTION_TYPES = {
    SET_VALUES_DEFAULT: "SET_VALUES_DEFAULT",
    UPDATE_FILTERS: "UPDATE_FILTERS"
};

const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_VALUES_DEFAULT: {
            return {
                ...state,
                dashboards: action.data
            }
        }
        default: {
            return state
        }
    }
};

const DashboardsStateContext = createContext();
const DashboardsDispatchContext = createContext();

const currentDate = new Date();
const currentYear = String(getYear(currentDate));
const currentMonth = String(getMonth(currentDate) + 1);

const DashboardsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, { dashboards: {} });

    // const {loading} = useQuery(LIVES, {
    //     variables: {
    //         filters
    //     },
    //     onCompleted: (data) => dispatch({type: ACTION_TYPES.SET_VALUES_DEFAULT, data: data.lives})
    // })

    return (
        <DashboardsStateContext.Provider value={state}>
            <DashboardsDispatchContext.Provider value={dispatch}>{children}</DashboardsDispatchContext.Provider>
        </DashboardsStateContext.Provider>
    )
};

const useDashboards = () => {
    const context = useContext(DashboardsStateContext);

    if (!context)
        throw new Error("useDashboards must be used within an DashboardsProvider");

    return context
};

const useRegional = (id) => {
    const dashboards = useDashboards()

    return useQuery(REGIONAL, {
        fetchPolicy: "no-cache",
        variables: {
            id: id
        }
    },
        {
            onCompleted: (data) => {
                dashboards({ type: ACTION_TYPES.SELECT, data: data.regional })
            },
        }
    )
}

const useLivesDashboard = (filters = { year: currentYear, month: currentMonth }) => {
    const dashboards = useDashboards();

    return useQuery(LIVES, {
        fetchPolicy: "no-cache",
        variables: {
            filters: filters
        }
    },
        {
            onCompleted: (data) => {
                dashboards({ type: ACTION_TYPES.SELECT, data: data })
            },
        }
    )
};

const useHealthDashboard = (filters = { year: currentYear, month: currentMonth }) => {
    const dashboards = useDashboards();

    return useQuery(HEALTH, {
        fetchPolicy: "no-cache",
        variables: {
            filters: filters
        }
    },
        {
            onCompleted: (data) => {
                dashboards({ type: ACTION_TYPES.SELECT, data: data })
            },
        }
    )
};

const useCptDashboard = (filters = { year: currentYear, month: currentMonth }) => {
    const dashboards = useDashboards();

    return useQuery(CPT, {
        fetchPolicy: "no-cache",
        variables: {
            filters: filters
        }
    },
        {
            onCompleted: (data) => {
                dashboards({ type: ACTION_TYPES.SELECT, data: data })
            },
        }
    )
};

const useOperationalDashboard = (filters = { year: currentYear, month: currentMonth }) => {
    const dashboards = useDashboards();

    return useQuery(OPERATIONAL, {
        fetchPolicy: "no-cache",
        variables: {
            filters: filters
        }
    },
        {
            onCompleted: (data) => {
                dashboards({ type: ACTION_TYPES.SELECT, data: data })
            },
        }
    )
};

const useWhatsAppDashboard = (filters = { year: currentYear, month: currentMonth }) => {
    const dashboards = useDashboards();

    return useQuery(WHATS_APP, {
        fetchPolicy: "no-cache",
        variables: {
            filters: filters
        }
    },
        {
            onCompleted: (data) => {
                dashboards({ type: ACTION_TYPES.SELECT, data: data })
            },
        }
    )
};

const useAccumulatedDashboard = (filters = { year: currentYear, month: currentMonth }) => {
    const dashboards = useDashboards();

    return useQuery(ACCUMULATED, {
        fetchPolicy: "no-cache",
        variables: {
            filters: filters
        }
    },
        {
            onCompleted: (data) => {
                dashboards({ type: ACTION_TYPES.SELECT, data: data })
            },
        }
    )
};

export {
    DashboardsProvider,
    useDashboards,
    useRegional,
    useLivesDashboard,
    useHealthDashboard,
    useOperationalDashboard,
    useWhatsAppDashboard,
    useCptDashboard,
    useAccumulatedDashboard
}
