import React, { useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

const renderDayTemplate = (event) => {
  return (
    <>{event.event.title}</>
  )
}

const EventCalendar = ({ calendarData, initialDate, handleEventClick, handleDateClick, weekView, changeDate = false, event = false, loading= false }) => {
  const calendarRef = useRef(null)

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}
      locale="pt-br"
      lazyFetching={false}
      loading={loading}
      customButtons={{
        custToday: {
            text: 'HOJE',
            click: function() {
              let calendarApi = calendarRef.current.getApi()
              changeDate('today');
              calendarApi.today();
            },
        },
        custPrev: {
            text: 'Ante.',
            click: function() {
              let calendarApi = calendarRef.current.getApi()
              changeDate('prev');
              calendarApi.prev();
            },
        },
        custNext: {
            text: 'Próx.',
            click: function() {
              let calendarApi = calendarRef.current.getApi()
              changeDate('next');
              calendarApi.next();
            },
        },
    }}
      headerToolbar={
        {
        start: 'title', // will normally be on the left. if RTL, will be on the right
        center: '',
        end: !event ? 'today prev,next' : 'custPrev custNext' // will normally be on the right. if RTL, will be on the left
      }}
      buttonText={{today: 'HOJE'}}
      initialView={weekView ? "dayGridWeek" : "dayGridMonth"}
      initialDate={initialDate}
      contentHeight="600"
      dateClick={(date) => handleDateClick(date.dateStr)}
      eventClick={({event}) => handleEventClick(event.startStr, event.title)}
      events={calendarData}
      editable={true}
      minHeight="40px"
      eventContent={renderDayTemplate}
      height="100%"
      slotWidth={12}
      ref={calendarRef}
    />
  );
}

export default EventCalendar;
