/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
  Box,
  Flex,
  Icon as CIcon,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

import Icon from "components/FontAwesomeIcons/Icon";
import dateFormat from "functions/dateFormat";
import { risks } from "variables/colors";

function TimelineRow(props) {
  const {
    logo,
    title,
    titleColor,
    date,
    isTele,
    color,
    index,
    tags,
    description,
    arrLength,
    haveTemplate,
    children,
  } = props;

  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  const getColor = (risk) => {
    const keywords = [
      "baixo",
      "moderado",
      "alto",
      "alta",
      "medio",
      "leve",
      "severa",
      "sugere",
      "declinio",
      "cognitivo",
      "desempenho",
      "abaixo",
      "do",
      "esperado",
    ].join("|");

    const keepOnlyKeywords = new RegExp(`(?!(${keywords}))\\b\\w+\\b`, "g");

    risk = risk
      .replace(/[áàãâä]/g, "a")
      .replace(/[éèêë]/g, "e")
      .replace(/[íìîï]/g, "i")
      .replace(/[óòõôö]/g, "o")
      .replace(/[úùûü]/g, "u")
      .toLowerCase()
      .split(":")
      .pop()
      .replace(keepOnlyKeywords, "")
      .replace(/ /g, "");

    switch (risk) {
      case "mediobaixo":
      case "moderado":
      case "medio":
      case "leve":
      case "moderadodereadmissão":
        return risks.medium;
      case "medioalto":
        return risks.high;
      case "alto":
      case "alta":
      case "severa":
      case "sugeredecliniocognitivo":
      case "desempenhoabaixodoesperado":
      case "altodereadmissao":
        return risks.veryHigh;
      default:
        return risks.veryLow;
    }
  };

  return (
    <Flex alignItems="center" minH="78px" justifyContent="start" mb="5px">
      <Flex direction="column" h="100%">
        <CIcon
          as={logo}
          bg={bgIconColor}
          color="#ff2020"
          h={"30px"}
          w={"26px"}
          pe="6px"
          zIndex="1"
          position="relative"
          right={document.documentElement.dir === "rtl" ? "-8px" : ""}
          left={document.documentElement.dir === "rtl" ? "" : "-8px"}
        />
        <Box
          w="2px"
          bg="gray.200"
          h={index === arrLength - 1 ? "15px" : "100%"}
        ></Box>
      </Flex>
      <Flex direction="column" justifyContent="flex-start" h="100%">
        <Text
          fontSize="sm"
          color={titleColor !== undefined ? titleColor : textColor}
          fontWeight="bold"
        >
          {title}
        </Text>
        <Flex alignItems="start" direction="column">
          {/* <Text
            fontSize="sm"
            color="gray.400"
            fontWeight="normal"
            mb="8px"
            mr="10px"
          >
            Preenchido por: 
          </Text> */}
          {date && (
            <Text
              fontSize="sm"
              color="gray.400"
              fontWeight="normal"
              mb="8px"
              mr="10px"
            >
              {dateFormat(`${date} 00:00:01`)}
            </Text>
          )}
          {isTele && (
            <Tooltip
              label="Tele-orientação"
              placement="top"
              closeOnClick={true}
            >
              <Text fontSize="sm" color="gray.400" fontWeight="normal" mb="8px">
                <Icon icon="tele" />
              </Text>
            </Tooltip>
          )}
        </Flex>
        {haveTemplate && children}
        {description !== undefined && tags?.length > 0 ? (
          <Text
            fontSize="sm"
            color="gray.400"
            fontWeight="normal"
            mb="6px"
            maxW="70%"
          >
            {description}
          </Text>
        ) : null}
        {tags !== undefined ? (
          <Stack direction="row" spacing="6px">
            {tags.map((tag, index) => {
              return (
                <Tag
                  bg={getColor(tag.titleTag)}
                  fontSize="xs"
                  size="lg"
                  color="#fff"
                  mb="16px"
                  padding="1em"
                  borderRadius="15px"
                  alignSelf="flex-start"
                  textAlign="center"
                  key={index}
                >
                  {tag.titleTag}
                </Tag>
              );
            })}
            
          </Stack>
        ) : null}
      </Flex>
    </Flex>
  );
}

export default TimelineRow;
