import { API } from '../api'

export async function GET_CAMPAIGN(key) {
	const { data } = await API.get(`api/communication/campaign?key=${key}`);

	return data;
}

export async function POST_CAMPAIGN(form, key) {
	const { data } = await API.post(`api/communication/campaign?key=${key}`, form);

	return data;
}