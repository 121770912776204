import { gql } from "@apollo/client";

export const PAGINATE_DISCLOSURE_CAMPAIGN_RECEIVERS = gql`
  query campaignReceivers($whatsapp_disclosure_id: String!, $status: String, $first: Int! = 15, $page: Int! = 1) {
    campaignReceiversPaginate(
      whatsapp_disclosure_id: $whatsapp_disclosure_id
      status: $status
      first: $first
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        status
        receiver {
          phone
          beneficiary {
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_RECEIVER = gql`
  mutation updateCampaignReceiver($input: UpdateCampaignReceiverInput) {
    updateCampaignReceiver(input: $input) {
      id
      status
    }
  }
`;
