import { createElement, useEffect, useState } from "react";
import BeneficiaryCharts from "./BeneficiaryCharts";
import BeneficiaryTimeline from "./BeneficiaryTimeline";
import BeneficiaryHealthStatment from "./BeneficiaryHealthStatment";
import BeneficiaryFirstAssessment from "./BeneficiaryFirstAssessment";
import BeneficiaryLifePlan from "./BeneficiaryLifePlan";
import BeneficiaryCarePlan from "./BeneficiaryCarePlan";
import BeneficiaryAssessment from "./BeneficiaryAssessment";
import BeneficiaryPhoneRegister from "./BeneficiaryPhoneRegister";
import BeneficiaryNotes from "./BeneficiaryNotes";
import BeneficiaryTeleOrientation from "./BeneficiaryTeleOrientation";
import BeneficiaryFiles from "./BeneficiaryFiles";
import BeneficiaryUnscheduledEvent from "./BeneficiaryUnscheduledEvent";
import { Box, Button, Divider, Flex, useColorModeValue } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { NotesProvider } from "hooks/notes";
import { LifePlansProvider } from "hooks/lifePlans";
import { DocumentsProvider } from "hooks/documents";
import { UnscheduledEventsProvider } from "hooks/unscheduledEvents";
import { useAuth } from "hooks/useAuth";
const menu = [
    { title: "Indicadores", component: BeneficiaryCharts, exclude: ['Recepcionista'] },
    { title: "Timeline", component: BeneficiaryTimeline },
    { title: "Declaração de Saúde", component: BeneficiaryHealthStatment, exclude: ['Recepcionista'] },
    { title: "Primeiro Acolhimento", component: BeneficiaryFirstAssessment, exclude: ['Recepcionista'] },
    { title: "Acompanhamento", component: BeneficiaryAssessment, exclude: ['Recepcionista'] },
    { title: "Metas Plano de Vida", component: BeneficiaryLifePlan, provider: LifePlansProvider, exclude: ['Recepcionista'] },
    //{title: "Plano de Cuidado", component: BeneficiaryCarePlan},
    { title: "Contato Telefônico", component: BeneficiaryPhoneRegister, provider: LifePlansProvider },
    { title: "Registro de Notas", component: BeneficiaryNotes, provider: NotesProvider },
    { title: "Tele Atendimento", component: BeneficiaryTeleOrientation },
    { title: "Arquivos", component: BeneficiaryFiles, provider: DocumentsProvider },
    { title: "Atendimentos Não Previstos", component: BeneficiaryUnscheduledEvent, provider: UnscheduledEventsProvider },
]

const FolderControl = ({ beneficiary }) => {
    const { user } = useAuth();
    const userRole = user?.role || null;
    const [pannel, setPannel] = useState(0);
    const textColor = useColorModeValue('black', 'white');
    const hoverBg = useColorModeValue('gray.200', 'gray.500');

    useEffect(() => {
        if (['Recepcionista'].includes(userRole))
            setPannel(1)
    }, [userRole])

    return (
        <Card px="0px" pb="0px" minHeight="100px" overflowX={{ sm: "auto", lg: "unset" }}>
            <CardHeader px="22px" mb="0px">
                <Flex flexDirection="column">
                    <Flex
                        justifyContent="space-between"
                        alignItems="top"
                        flexWrap="wrap"
                    >
                        {menu.map((m, index) => (
                            <Button
                                hidden={m.exclude && m.exclude.includes(userRole)}
                                key={index}
                                bgColor={index === pannel ? 'notrelife.800' : 'transparent'}
                                _hover={index === pannel ? { bg: "notrelife.800" } : { bg: hoverBg }}
                                color={index === pannel ? 'white' : textColor}
                                mr="12px"
                                mb="12px"
                                onClick={() => setPannel(index)}
                            >
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {m.title}
                                </Flex>
                            </Button>
                        ))}
                    </Flex>
                    <Divider orientation='horizontal' mb="30px" />
                </Flex>
            </CardHeader>
            <CardBody h="100%" width="100%">
                <Box w="100%" px="22px" pb="22px">
                    {
                        menu[pannel].provider ?
                            createElement(menu[pannel].provider, { beneficiary: beneficiary }, createElement(menu[pannel].component))
                            :
                            createElement(menu[pannel].component)
                    }
                </Box>
            </CardBody>
        </Card>

    )
}

export default FolderControl;
