import React from "react";
import { padding } from "variables/nivo/bar";
import { useColorMode } from "@chakra-ui/react";
import {
  axisLeft,
  themeDark,
  themeLight,
  tooltip,
} from "variables/nivo/default";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { risksColors, risks } from "variables/colors";

const RadialRisksChart = ({ data }) => {
  data = [
    {
      id: "Risco Baixo",
      data: [
        {
          x: "Risco Baixo",
          y: data?.find((i) => i.name === "Risco Baixo")?.total || 0,
        },
      ],
    },
    {
      id: "Risco Médio Baixo",
      data: [
        {
          x: "Risco Médio Baixo",
          y: data?.find((i) => i.name === "Risco Médio Baixo")?.total || 0,
        },
      ],
    },
    {
      id: "Risco Médio Alto",
      data: [
        {
          x: "Risco Médio Alto",
          y: data?.find((i) => i.name === "Risco Médio Alto")?.total || 0,
        },
      ],
    },
    {
      id: "Risco Alto",
      data: [
        {
          x: "Risco Alto",
          y: data?.find((i) => i.name === "Risco Alto")?.total || 0,
        },
      ],
    },
  ];

  const { colorMode } = useColorMode();

  return (
    <ResponsiveRadialBar
      data={data}
      keys={[
        "Risco Baixo",
        "Risco Médio Baixo",
        "Risco Médio Alto",
        "Risco Alto",
      ]}
      indexBy="id"
      layout="horizontal"
      margin={{ top: 30, right: 25, bottom: 30, left: 25 }}
      padding={padding}
      cornerRadius={0}
      colors={[risks.low, risks.medium, risks.high, risks.veryHigh]}
      radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
      theme={colorMode === "light" ? themeLight : themeDark}
      axisBottom={null}
      axisLeft={axisLeft}
      labelTextColor="#FFF"
      tooltip={({ bar }) => tooltip(bar.groupId, bar.value)}
    />
  );
};

export default RadialRisksChart;
