import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { padding } from "variables/nivo/bar";
import { useColorMode } from "@chakra-ui/react";
import {
  axisLeft,
  themeDark,
  themeLight,
  tooltip,
} from "variables/nivo/default";
import { risks } from "variables/colors";

const getColor = (bar) => {
  const schemes = {
    Fumante: {
      "Sim": risks.veryHigh,
      "Parei há menos de 1 ano": risks.medium,
      "Não": risks.veryLow,
    },
    "Consumo de álcool": {
      "Nunca": risks.veryLow,
      "Raramente": risks.low,
      "Menos de três doses por semana": risks.medium,
      "Três a seis doses por semana": risks.high,
      "Sete ou mais doses por semana": risks.veryHigh,
      "Sim": risks.veryHigh,
    },
    "Atividade física": {
      "Nenhuma vez": risks.veryHigh,
      "Nenhuma vez por semana": risks.veryHigh,
      "Raramente": risks.high,
      "Menos de três vezes por semana": risks.high,
      "Sete ou mais vezes por semana": risks.medium,
      "De uma a duas vezes por semana": risks.medium,
      "Três vezes por semana": risks.medium,
      "Quatro vezes por semana": risks.low,
      "De três ou mais vezes": risks.low,
      "Quase sempre (5 vezes ou mais por semana)": risks.veryLow,
    },
  };

  return schemes[bar.indexValue]?.[bar.id] || "#000";
};

const LifeHabitsChart = ({ tabaco = [], booze = [], physical = [] }) => {


  const data = [
    {
      label: "Fumante",
      "Sim": tabaco.find((i) => i.name === "Sim")?.total || 0,
      "Parei há menos de 1 ano":
        tabaco.find((i) => i.name === "Parei há menos de 1 ano")?.total || 0,
      "Não": tabaco.find((i) => i.name === "Não")?.total || 0,
    },
    {
      label: "Consumo de álcool",
      "Sim": booze.find((i) => i.name === "Sim")?.total || 0,
      "Nunca": booze.find((i) => i.name === "Nunca" || i.name === "Não" )?.total || 0,
      "Raramente": booze.find((i) => i.name === "Raramente")?.total || 0,
      "Menos de três doses por semana":
        booze.find((i) => i.name === "Menos de três doses por semana")?.total ||
        0,
      "Três a seis doses por semana":
        booze.find((i) => i.name === "Três a seis doses por semana")?.total ||
        0,
      "Sete ou mais doses por semana":
        booze.find((i) => i.name === "Sete ou mais doses por semana")?.total ||
        0,
    },
    {
      label: "Atividade física",
      "Nenhuma vez":
        physical.find((i) => i.name === "Nenhuma vez")
          ?.total || 0,
      "Raramente": physical.find((i) => i.name === "Raramente")?.total || 0,
      "De uma a duas vezes por semana":
        physical.find((i) => i.name === "De uma a duas vezes por semana")
          ?.total || 0,
      "De três ou mais vezes":
        physical.find((i) => i.name === "De três ou mais vezes")?.total || 0,
      "Nenhuma vez por semana":
        physical.find((i) => i.name === "Nenhuma vez por semana")?.total || 0,
      "Menos de três vezes por semana":
        physical.find((i) => i.name === "Menos de três vezes por semana")
          ?.total || 0,
      "Três vezes por semana":
        physical.find((i) => i.name === "Três vezes por semana")?.total || 0,
      "Quatro vezes por semana":
        physical.find((i) => i.name === "Quatro vezes por semana")?.total || 0,
      "Quase sempre (5 vezes ou mais por semana)":
        physical.find(
          (i) => i.name === "Quase sempre (5 vezes ou mais por semana)"
        )?.total || 0,
    },
  ];

  const { colorMode } = useColorMode();

  return (
    <ResponsiveBar
      data={data}
      keys={[
        "Nenhuma vez por semana",
        "Menos de três vezes por semana",
        "Três vezes por semana",
        "Quatro vezes por semana",
        "Quase sempre (5 vezes ou mais por semana)",

        "Sete ou mais vezes por semana",

        "Sim",
        "Sete ou mais doses por semana",
        "Três a seis doses por semana",
        "Menos de três doses por semana",
        "Raramente",
        "Nunca",

        "Não",
        "Parei há menos de 1 ano",

        "Nenhuma vez",
        "De uma a duas vezes por semana",
        "De três ou mais vezes",
      ]}
      indexBy="label"
      layout="horizontal"
      groupMode="stacked"
      margin={{ top: 20, right: 20, bottom: 10, left: 150 }}
      padding={padding}
      colors={getColor}
      labelSkipWidth={11}
      theme={colorMode === "light" ? themeLight : themeDark}
      axisBottom={null}
      axisLeft={axisLeft}
      labelTextColor="#FFF"
      tooltip={({ id, value }) => tooltip(id, value)}
    />
  );
};

export default LifeHabitsChart;
