import React, {useEffect, useState} from "react"
import {useCreateReport} from "hooks/reports";
import InputMask from "react-input-mask";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {isValid} from "date-fns";

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Skeleton,
    useColorModeValue,
    useToast
} from "@chakra-ui/react"

import {useForm} from "react-hook-form";
import {REPORTS} from "../../../functions/enums";
import {useRegionals} from "../../../hooks/globalRegionals";
import {roleConsu, roleEnfer} from "../../../variables/roles";

const schema = yup?.object()?.shape({
    label: yup
        ?.string()
        ?.required("O campo Nome é obrigatório"),
    report_tag: yup
        ?.string()
        ?.nullable()
        ?.required("O campo Tipo de Relatório é obrigatório"),
    regional_id: yup
        ?.string()
        ?.nullable(),
    start_date: yup
        ?.string()
        ?.nullable()
        ?.required("O campo Data de Início é obrigatório")
        ?.test("date-valid", "Informe uma data válida", function (field) {
            const date = new Date(`${field} 00:00:00`);
            return isValid(date);
        }),
    end_date: yup
        ?.string()
        ?.nullable()
        ?.required("O campo Data de Término é obrigatório")
        ?.test("date-valid", "Informe uma data válida", function (field) {
            const date = new Date(`${field} 00:00:00`);
            return isValid(date);
        }),
})

const ReportCreateModal = ({open, setModal}) => {
    const toast = useToast();
    const [noParseStart, setNoParseStart] = useState(null);
    const [noParseEnd, setNoParseEnd] = useState(null);
    const [created, setCreated] = useState(0);
    const {regionals, isLoading: isLoadingRegionals, isFetching: isFetchingRegionals} = useRegionals();

    const {register, handleSubmit, formState: {errors}, getValues, setValue} = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
    });

    const [createReport, {loading}] = useCreateReport({
        onSuccess: () => {
            toast({
                title: "Relatório solicitado com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
            }),
                setModal(false)
        },
        onError: () => {
            toast({
                title: "Ocorreu um erro.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        },
    });

    const handleChange = (value) => {
        const result = value?.replace(/[^a-z0-9]/gi, "");
        setValue("label", result);
    };

    useEffect(() => {
        if (noParseStart && noParseStart?.replace("_", "").length === 10) {
            const date = noParseStart?.split("/");
            setValue("start_date", `${date[2]}-${date[1]}-${date[0]}`)
        } else setValue("start_date", null);
    }, [noParseStart])

    useEffect(() => {
        if (noParseEnd && noParseEnd?.replace("_", "").length === 10) {
            const date = noParseEnd?.split("/");
            setValue("end_date", `${date[2]}-${date[1]}-${date[0]}`)
        } else setValue("end_date", null);
    }, [noParseEnd])

    const onSubmit = (data) => {
        const variables = {
            start_date: getValues("start_date"),
            end_date: getValues("end_date"),
            regional_id: getValues("regional_id"),
        };
        const filters = JSON.stringify(variables);

        createReport({
            variables: {
                input: {
                    label: data?.label,
                    report_tag: data?.report_tag,
                    filters: filters
                }
            }
        })
    };

    const handleRegionals = () => {
        return regionals
            ?.filter(r => r?.is_admin === false)
            ?.map(r => (<option value={r?.id}>{r?.name}</option>));
    }

    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <>
            <form>
                <Modal isOpen={open} onClose={() => setModal(false)}>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Nome do relatório</ModalHeader>
                        <ModalBody>
                            <Grid templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}} gap="24px" mb="24px">
                                <FormControl isInvalid={errors?.label}>
                                    <FormLabel fontSize="xs" fontWeight="bold">
                                        Nome
                                    </FormLabel>
                                    <Input
                                        {...register("label")}
                                        borderRadius="8px"
                                        focusBorderColor={inputHover}
                                        fontSize="md"
                                        color="gray.400"
                                        onChange={({target}) => handleChange(target?.value)}
                                    />
                                    {errors?.label && <FormErrorMessage>{errors?.label?.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={errors?.report_tag}>
                                    <FormLabel fontSize="xs" fontWeight="bold">
                                        Tipo de relatório
                                    </FormLabel>
                                    <Select
                                        {...register("report_tag")}
                                        focusBorderColor={inputHover}
                                        placeholder="Selecione uma opção..."
                                        color="gray.400"
                                        size="md"
                                        borderRadius="8px"
                                        cursor="pointer"
                                    >
                                        {REPORTS.map((r) => <option value={r?.tag}>{r?.label}</option>)}
                                    </Select>
                                    {errors?.report_tag && <FormErrorMessage>{errors?.report_tag?.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={errors?.regional_id}>
                                    <FormLabel fontSize="xs" fontWeight="bold">
                                        Regional
                                    </FormLabel>
                                    <Skeleton isLoaded={!isLoadingRegionals && !isFetchingRegionals}>
                                        <Select
                                            {...register("regional_id")}
                                            focusBorderColor={inputHover}
                                            placeholder="Selecione uma opção..."
                                            color="gray.400"
                                            size="md"
                                            borderRadius="8px"
                                            cursor="pointer"
                                        >
                                            {handleRegionals()}
                                        </Select>
                                    </Skeleton>
                                    {errors?.regional_id && <FormErrorMessage>{errors?.regional_id?.message}</FormErrorMessage>}
                                </FormControl>
                            </Grid>
                            <Grid templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}} gap="24px">
                                <FormControl isInvalid={errors?.start_date}>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Data de Início
                                    </FormLabel>
                                    <Input
                                        as={InputMask}
                                        mask="99/99/9999"
                                        focusBorderColor={inputHover}
                                        borderRadius="8px"
                                        fontSize="md"
                                        onChange={({target}) => setNoParseStart(target?.value)}
                                    />
                                    {errors?.start_date && <FormErrorMessage>{errors?.start_date?.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={errors?.end_date}>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Data de Término
                                    </FormLabel>
                                    <Input
                                        as={InputMask}
                                        mask="99/99/9999"
                                        focusBorderColor={inputHover}
                                        borderRadius="8px"
                                        fontSize="md"
                                        onChange={({target}) => setNoParseEnd(target?.value)}
                                    />
                                    {errors?.end_date && <FormErrorMessage>{errors?.end_date?.message}</FormErrorMessage>}
                                </FormControl>
                            </Grid>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                isLoading={loading}
                                type="submit"
                                variant="no-hover"
                                bg={buttonBg}
                                alignSelf="flex-end"
                                w="100px"
                                h="35px"
                                onClick={() => handleSubmit(onSubmit)()}
                            >
                                Gerar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </form>
        </>
    );
}

export default ReportCreateModal