import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from "@chakra-ui/react";
import {FormProvider, useForm} from "react-hook-form";
import PlanFormCreate from "./PlanFormCreate";


const ModalNewPlan = ({ onClose, isOpen, onSubmit, loadingSave }) => {
    const methods = useForm()
    const {  handleSubmit } = methods

    return (
        <Modal
            isCentered
            onClose={() => onClose(false)}
            isOpen={isOpen}
            motionPreset='slideInBottom'
            size={"xl"}
            autoFocus={false}
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Criar Plano Associado a regional</ModalHeader>
                        <ModalBody>
                            <PlanFormCreate />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                isLoading={loadingSave}
                                backgroundColor='notrelife.800'
                                color="white"
                                variant="no-hover"
                                type="submit"
                            >
                                Salvar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default ModalNewPlan;
