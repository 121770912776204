import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    Flex,
    Grid,
    Select,
    Skeleton,
    Spinner,
    Stack,
    Text,
    useColorModeValue,
    useToast
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import EventCalendar from "components/Calendars/EventCalendar";
import EventModal from "./EventModal/EventModal";
import { useEvents, useGetMonthEvents, useGetProfessionals } from "hooks/calendar";
import { useHistory } from "react-router-dom";
import NextEventsList from "./components/NextEventsList";
import dateFormat from "functions/dateFormat";
import { addMonths } from "date-fns";
import { useLazyQuery } from "@apollo/client";
import { REGIONAL } from "../../graphql/calendar";
import { roleConsu, roleEnfer } from "../../variables/roles";
import { useRegionals } from "../../hooks/globalRegionals";
import { storageFilter } from "functions/storageFilters";

const Calendar = () => {
    const defaultFilters = storageFilter('@calendar_filters')
    const history = useHistory();

    const { regional: regionalId } = useRegionals();
    const { loading, refetchMonth } = useEvents();
    const [getMonthEvents, { data, loading: loadingMonth }] = useGetMonthEvents();
    const [getProfessionals, { data: professionals, loading: loadingProfessionals }] = useGetProfessionals();

    const [open, setOpen] = React.useState(false);
    const [eventGamb, setEventGamb] = React.useState(false);
    const [currentDate, setCurrentDate] = useState(new Date);
    const [regional, setRegional] = useState(null);
    const [role, setRole] = useState(defaultFilters?.role || null);
    const [professional, setProfessional] = useState(defaultFilters?.professional || null);

    const eventCreated = () => {
        getMonthEvents({ variables: { month: dateFormat(currentDate, 'y-MM') } })
        setEventGamb(!eventGamb);
    }

    useEffect(() => {
        getProfessionals()
        getMonthEvents({ variables: { month: dateFormat(currentDate, 'y-MM') } })
    }, []);

    const [loadPlans, { data: qRegional, loading: loadingRegional }] = useLazyQuery(REGIONAL);

    useEffect(() => {
        getMonthEvents({
            variables: {
                month: dateFormat(currentDate, 'y-MM'),
                regional_id: regional,
                role: role,
                professional_id: professional
            }
        })
        const stringFilter = JSON.stringify({
            role: role,
            professional: professional
        })
        window.localStorage.setItem('@calendar_filters', stringFilter)
    }, [role, professional]);

    useEffect(() => {
        if (!!regionalId) loadPlans({ variables: { id: regionalId } });
    }, [regionalId]);

    const changeDate = async (event) => {
        setCurrentDate(addMonths(currentDate, event === 'next' ? 1 : -1))
        await getMonthEvents({
            variables: {
                month: dateFormat(addMonths(currentDate, event === 'next' ? 1 : -1), 'y-MM'),
                role: role,
                regional_id: regionalId,
                professional_id: professional
            }
        })
    }

    const handleDateClick = (item) => {
        history.push({ pathname: "/sistema/agenda/" + item });
    };

    const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
    const textColor = useColorModeValue("gray.700", "white");
    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const buttonColor = useColorModeValue("white", "white");

    const handleProfessionals = () => {
        let pro = qRegional?.regional?.professionals;
        if (!!role) {
            pro = pro?.filter(p => p?.user?.role === role);
        } else {
            pro = pro?.filter(p => p?.user?.role === roleConsu || p?.user?.role === roleEnfer);
        }
        return pro?.map(p => (<option value={p?.id}>{p?.user?.name}</option>))
    }

    return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{ sm: "120px", lg: "160px" }}
        >
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(6, 6fr)" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="12px"
                w={"100%"}
                pt={{ sm: "12px", lg: "12px" }}
            >
                <Card minH="800px" style={{ gridColumn: "1 / span 4" }}>
                    <CardHeader mb="20px">
                        <Flex direction="column" width="100%">
                            <Box w="100%">
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color="gray.400"
                                    mb="20px"
                                >
                                    <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
                                        Calendário
                                    </Text>
                                    <Button
                                        bg={buttonBg}
                                        variant="no-hover"
                                        color={buttonColor}
                                        onClick={() => setOpen(!open)}>
                                        Criar Agendamento
                                    </Button>
                                </Flex>
                            </Box>
                            <Flex mb="20px">
                                <Stack
                                    direction="column"
                                    spacing="12px"
                                    mr="12px"
                                >
                                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                        Filtrar por tipo de profissional
                                    </Text>
                                    <Skeleton isLoaded={!loadingRegional && !loadingProfessionals}>
                                        <Select
                                            value={role}
                                            focusBorderColor={inputHover}
                                            placeholder="Selecione uma opção..."
                                            onChange={({ target }) => setRole(target.value)}
                                            color="gray.500"
                                            size="sm"
                                            borderRadius="12px"
                                            cursor="pointer"
                                        >
                                            <option value={roleConsu}>Consultor de Saúde</option>
                                            <option value={roleEnfer}>Enfermeiro</option>
                                        </Select>
                                    </Skeleton>
                                </Stack>
                                <Stack
                                    direction="column"
                                    spacing="12px"
                                >
                                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                        Filtrar por profissional
                                    </Text>
                                    <Skeleton isLoaded={!loadingRegional && !loadingProfessionals}>
                                        <Select
                                            value={professional}
                                            focusBorderColor={inputHover}
                                            placeholder={"Selecione uma opção..."}
                                            onChange={({ target }) => setProfessional(target.value)}
                                            color="gray.500"
                                            size="sm"
                                            borderRadius="12px"
                                            cursor="pointer"
                                        >
                                            {handleProfessionals()}
                                        </Select>
                                    </Skeleton>
                                </Stack>
                            </Flex>
                        </Flex>
                    </CardHeader>
                    <CardBody position="relative" display="block" height="100%">
                        {
                            loading || loadingMonth ?
                                <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
                                    <Spinner />
                                </Flex>
                                :
                                <EventCalendar
                                    event
                                    changeDate={changeDate}
                                    calendarData={data?.eventsMonth || []}
                                    initialDate={currentDate}
                                    handleEventClick={handleDateClick}
                                    handleDateClick={handleDateClick}
                                />
                        }
                    </CardBody>
                </Card>
                <Card style={{ gridColumn: "5 / 7" }}>
                    <CardHeader mb="6px">
                        <Flex direction="column">
                            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
                                Próximos Eventos
                            </Text>
                        </Flex>
                    </CardHeader>
                    <CardBody position="relative" display="block" height="100%">
                        <NextEventsList
                            date={currentDate}
                            eventGamb={eventGamb}
                        />
                    </CardBody>
                </Card>
            </Grid>
            {
                open &&
                <EventModal
                    open={open}
                    close={() => setOpen(false)}
                    finished={() => eventCreated()}
                />
            }
        </Flex>
    );
}

export default Calendar;
