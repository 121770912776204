import { CalendarQueryProvider } from "../services/calendar"
import { StatisticHookProvider } from "../hooks/calendar/statistics"


export const CalendarStatisticProvider = ({children}) => {
    return (
        <CalendarQueryProvider>
            <StatisticHookProvider>{children}</StatisticHookProvider>
        </CalendarQueryProvider>
    )
}