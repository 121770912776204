
const ACTION_TYPES = {
    LIST: "LIST_CPT",
    SELECTED_CPT: "SELECTED_CPT",
    UPDATE_CPT: "UPDATE_CPT",
    DELETE_CPT: "DELETE_CPT",
    ASSIGN_CPT: "ASSIGN_CPT",
}
const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.LIST: {
            return {
                ...state,
                page: action.data
            }
        }
        case ACTION_TYPES.SELECTED_CPT: {
            return {
                ...state,
                selectedCpt: action.data
            }
        }
        case ACTION_TYPES.UPDATE_CPT: {
            return {
                ...state,
                selectedCpt: action.data
            }
        }
        case ACTION_TYPES.ASSIGN_CPT: {
            return {
                ...state,
                page: { data: state.page.data.filter(p => action.data[0].id !== p.id) }
            }
        }
        case ACTION_TYPES.DELETE_CPT:
        default: {
            return {
                ...state
            }
        }
    }
}

export {
    reducers,
    ACTION_TYPES
}
