import { CommitmentsProvider as Provider } from "hooks/commitmentsManagement"
import { CalendarQueryProvider } from "services/calendar"

const CommitmentsManagementProvider = ({children}) => {
    return (
        <CalendarQueryProvider>
            <Provider>
                {children}
            </Provider>
        </CalendarQueryProvider>
    )
}

export default CommitmentsManagementProvider
