import { gql } from "@apollo/client";

export const GET_AWAITING_COMMITMENTS = gql`
  query GetAwaitingCommitments(
    $first: Int! = 30
    $page: Int! = 1
    $input: CommitmentFilterInput
  ) {
    awaitingCommitments(first: $first, page: $page, input: $input) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
        phone
        birthdate
        manager_type
        days_awaiting
        is_awaiting_schedule
        awaiting_schedule_at
        awaiting_schedule
        plan {
          id
          name
        }
        notes {
          id
          title
          text
          created_at
        }
        manager {
          id
          user {
            name
            role
          }
        }
        assessments {
          id
          answered_in
          assessmentType {
            name
            builder_tag
          }
          metrics {
            tag
            result
          }
        }
        pending_awaiting_schedules {
          id
          is_awaiting_schedule
          awaiting_schedule_at
          awaiting_schedule
        }
      }
    }
  }
`;

export const GET_SCHEDULED_COMMITMENTS = gql`
  query GetScheduledCommitments(
    $first: Int! = 30
    $page: Int! = 1
    $input: CommitmentFilterInput
  ) {
    scheduledCommitments(first: $first, page: $page, input: $input) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        scheduled_to
        type
        awaiting_schedule {
          awaiting_schedule
        }
        assessment {
          id
          assessmentType {
            id
            name
            builder_tag
          }
        }
        patient {
          id
          name
          phone
          birthdate
          plan {
            id
            name
          }
          assessments {
            id
            assessmentType {
              name
              builder_tag
            }
            metrics {
              tag
              result
            }
          }
          notes {
            id
            title
            text
            created_at
          }
        }
        professional {
          id
          user {
            name
            role
          }
        }
      }
    }
  }
`;

export const GET_MISSED_COMMITMENTS = gql`
  query GetMissedCommitments(
    $first: Int! = 30
    $page: Int! = 1
    $input: CommitmentFilterInput
  ) {
    missedCommitments(first: $first, page: $page, input: $input) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
        phone
        birthdate
        eventLastExpired {
          id
          scheduled_to
          awaiting_schedule {
            awaiting_schedule
          }
          professional {
            id
            user {
              name
              role
            }
          }
          assessment {
            id
            assessmentType {
              name
              builder_tag
            }
            metrics {
              tag
              result
            }
          }
        }
        plan {
          id
          name
        }
        assessments {
          id
          assessmentType {
            name
            builder_tag
          }
          metrics {
            tag
            result
          }
        }
        notes {
          id
          title
          text
          created_at
        }
      }
    }
  }
`;

export const GET_AUTO_COMMITMENTS = gql`
  query GetAutoCommitments(
    $first: Int! = 30
    $page: Int! = 1
    $input: CommitmentFilterInput
  ) {
    autoCommitments(first: $first, page: $page, input: $input) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
        phone
        awaiting_schedule
        plan_id
        plan_name
        pending_awaiting_schedules {
          id
          is_awaiting_schedule
          awaiting_schedule_at
          awaiting_schedule
        }
        birthdate
        plan {
          id
          name
        }
        notes {
          id
          title
          text
          created_at
        }
        manager {
          id
          user {
            name
            role
          }
        }
        assessments {
          id
          assessmentType {
            name
            builder_tag
          }
          metrics {
            tag
            result
          }
        }
      }
    }
  }
`;

export const GET_REFUSAL_COMMITMENTS = gql`
  query GetRefusalCommitments(
    $first: Int! = 30
    $page: Int! = 1
    $input: CommitmentFilterInput
  ) {
    refusalCommitments(first: $first, page: $page, input: $input) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
        phone
        birthdate
        type_ineligibility
        assessments {
          id
          assessmentType {
            name
            builder_tag
          }
          metrics {
            tag
            result
          }
        }
        pending_awaiting_schedules {
          id
          is_awaiting_schedule
          awaiting_schedule_at
          awaiting_schedule
        }
        plan {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMMITMENTS_STATISTICS = gql`
  query GetCommitmentsStatistics($input: CommitmentFilterInput) {
    commitmentsStatistics(input: $input) {
      presencial_average
      tele_average
      total
      done
      reschedules
      missing
    }
  }
`;

export const RESCHEDULE_EVENT = gql`
  mutation RescheduleEvent($input: EventRescheduleInput) {
    rescheduleEvent(input: $input) {
      id
      scheduled_to
      parent_id
      professional {
        id
        user {
          id
          name
          role
        }
      }
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($input: CreateNoteInput) {
    createNote(input: $input) {
      id
      title
      text
      created_at
      beneficiary {
        id
      }
      user {
        name
      }
    }
  }
`;

export const SEND_WHATSAPP_MESSAGE = gql`
  mutation SendWhatsapp($patient_id: String!) {
    sendWhatsapp(patient_id: $patient_id) {
      id
    }
  }
`;

export const PA_REFUSAL = gql`
  mutation paRefusal($input: UpdatePatientInput) {
    paRefusal(input: $input) {
      id
    }
  }
`;
