import {
    Avatar,
    Box,
    Button,
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";



export default ({
    backgroundProfile,
    avatarImage,
    name,
    cpf,
    email,
    children
}) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const borderProfileColor = useColorModeValue(
        "white",
        "rgba(255, 255, 255, 0.31)"
    );
    const emailColor = useColorModeValue("gray.400", "gray.300");

    return (
        <Box
            borderRadius="15px"
            px="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            align="center"
        >
            <Box
                w="100%"
                borderRadius="25px"
                bgRepeat="no-repeat"
                display="flex"
                justifyContent="center"
            >
                <Flex
                    direction={{ sm: "column", md: "row" }}
                    mx="1.5rem"
                    maxH="330px"
                    w={{ sm: "90%", xl: "95%" }}
                    justifyContent={{ sm: "center", md: "space-between" }}
                    align="center"
                    backdropFilter="saturate(200%) blur(50px)"
                    boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
                    border="2px solid"
                    borderColor={borderProfileColor}
                    bg={backgroundProfile}
                    p="24px"
                    borderRadius="20px"

                >
                    <Flex
                        align="center"
                        direction={{ sm: "column", md: "row" }}
                        w={{ sm: "100%" }}
                        textAlign={{ sm: "center", md: "start" }}
                    >
                        <Avatar
                            me={{ md: "22px" }}
                            src={avatarImage}
                            w="80px"
                            h="80px"
                            borderRadius="15px"
                        />
                        <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
                            <Text
                                fontSize={{ sm: "lg", lg: "xl" }}
                                color={textColor}
                                fontWeight="bold"
                                ms={{ sm: "8px", md: "0px" }}
                            >
                                {name}
                            </Text>
                            {
                                cpf &&
                                <Text
                                    fontSize={{ sm: "sm", md: "md" }}
                                    color={emailColor}
                                    fontWeight="semibold"
                                >
                                    {cpf}
                                </Text>
                            }
                            <Text
                                fontSize={{ sm: "sm", md: "md" }}
                                color={emailColor}
                                fontWeight="semibold"
                            >
                                {email}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex
                        direction={{ sm: "column", lg: "row" }}
                        w={{ sm: "100%", md: "50%", lg: "auto" }}
                    >
                        {children}
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
};
