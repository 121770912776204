import React, {useState} from "react"
import AsyncSelect from "react-select/async";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {addWeeks, getWeek} from "date-fns";

import {
    useCreateEvent,
    useGetProfessionalsByRegional,
    useGetProfessionalSplitTime,
    useLazyBeneficiariesQuick
} from "hooks/calendar";

import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Text,
    useColorMode,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import {msDarkTheme, msLightTheme} from "variables/multiselectStyle";
import EventCalendar from "components/Calendars/EventCalendar";
import dateFormat from "functions/dateFormat";
import {useRegionals} from "hooks/globalRegionals";
import {roleConsu, roleEnfer, roleSuper} from "../../../variables/roles";

const schema = yup.object().shape({
    patient_id: yup
        .string()
        .required("O campo Beneficiário é obrigatório"),
    professional_id: yup
        .string()
        .required("O campo Profissional é obrigatório"),
    regional_id: yup
        .string()
        .required("O campo Regional é obrigatório"),
    type: yup
        .string()
        .oneOf(["presencial", "tele"]),
    scheduled_to: yup
        .string()
        .nullable()
        .required("O campo Date e Hora é obrigatório"),
})

const EventModal = ({open, close, finished}) => {
    const {regional: regionalId} = useRegionals();
    const {colorMode} = useColorMode();
    const toast = useToast();

    const {register, handleSubmit, formState: {errors}, reset, watch, getValues, setValue} = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
    });

    const [currentDate, setCurrentDate] = useState(new Date());
    const [eventDate, setEventDate] = useState(null);
    const [brDate, setBrDate] = useState(null);
    const [patient, setPacient] = useState(null);
    const [awaitingSchedule, setAwaitingSchedule] = useState(null);
    const [professionals, setProfessionals] = useState(null);
    const [professional, setProfessional] = useState(null);
    const type = watch("type");
    const [turn, setTurn] = useState(null);
    const [duration, setDuration] = useState('20min');
    const [options, setOptions] = useState([]);
    const [splitTime, setSplitTime] = useState([]);
    const [regional, setRegional] = useState("");

    const [mutateFunction, {loading: loadingCreate}] = useCreateEvent({
        onCompleted: () => {
            reset();
            setPacient(null)
            setProfessionals(null)
            setSplitTime([])
            setRegional("")
            setTurn(null);
            setEventDate(null)
            setBrDate(null)
            setValue("attendance_duration", null)
            setValue("scheduled_to", null);
            setProfessional(null)
            resetDate()
            toast({
                title: "Agendamento criado com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
            close();
            finished();
        },
        onError: (errors) => {
            const errorsArr = errors ? Object.keys(errors) : null;
            if (errorsArr)
                errorsArr.map(err => {
                    if (errors[err]) {
                        toast({
                            title: errors[err][0],
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                    }
                })
            else
                toast({
                    title: "Ocorreu um erro",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
        },
    });

    React.useEffect(() => {
        if (!!eventDate) {
            const date = dateFormat(eventDate, "yyyy-MM-dd HH:mm:ss")
            setValue("scheduled_to", date);
            if (type === "presencial") {
                const datee = dateFormat(eventDate, "dd/MM/yyyy HH:mm:ss")
                setBrDate(datee);
            } else {
                const datee = dateFormat(eventDate, "dd/MM/yyyy")
                setBrDate(`${datee} ${turn}`);
            }

        }
    }, [eventDate]);

    React.useEffect(() => {
        loadProfessionalTime(professional);
    }, [type, duration]);

    const resetDate = () => {
        setEventDate(null)
        setBrDate(null)
        setValue("scheduled_to", null);
    };

    const [loadCall, {data, error, loading}] = useLazyBeneficiariesQuick();
    const [loadProfessionals] = useGetProfessionalsByRegional();
    const [loadProfessionalSplitTime, {loading: loadingTime}] = useGetProfessionalSplitTime();

    const asyncValues = async (item) => {
        const {data} = await loadCall({
            variables: {
                name: `%${item}%`,
                active: true,
                regional_id: regionalId,
            }
        });
        return data.patientsQuick;
    };

    const selectBeneficiary = async (patient) => {
        setPacient(patient);
        setValue("patient_id", patient.id);

        const pendingAwaitingSchedules = patient?.pending_awaiting_schedules;
        if (!!pendingAwaitingSchedules && pendingAwaitingSchedules.length === 1) {
            setAwaitingSchedule(pendingAwaitingSchedules[0].id);
            setValue("awaiting_schedule_id", pendingAwaitingSchedules[0].id);
        }

        const checked = patient?.plan[0]?.regionals?.filter(r => r.name !== 'TODAS REGIONAIS')?.filter(r => r.id === regional || r.id === regionalId);
        if (!!checked && !!checked[0] && !!checked[0].id) loadProfessionalsByRegional(checked[0].id);
    }

    const loadProfessionalsByRegional = async (regional) => {
        setRegional(regional);
        setValue("regional_id", regional);
        const {data} = await loadProfessionals({variables: {regional_id: regional, accepted_roles: [roleEnfer, roleConsu]}});
        setProfessionals(data?.professionals);
    }

    const handleEventClick = (time, turn = null) => {
        setTurn(turn);
        setEventDate(time);
    }

    const loadProfessionalTime = async (value) => {
        setProfessional(value);
        setValue("professional_id", value);
        const {data} = await loadProfessionalSplitTime({
            variables: {
                input: {
                    week: getWeek(currentDate),
                    regional: regional,
                    professional: value || null,
                    type: type,
                    duration: duration,
                }
            }
        })
        let newSplitTime = [];
        if (!!data && !!data.splitTime) {
            data.splitTime.map(st => {
                newSplitTime.push({...st, className: "fc-event-primary"})
            })
        }
        setSplitTime(newSplitTime);
    }

    const changeDate = async (time) => {
        let week;
        if (time !== "today") {
            setCurrentDate(addWeeks(currentDate, time === "next" ? 1 : -1));
            week = getWeek(addWeeks(currentDate, time === "next" ? 1 : -1));
        } else {
            setCurrentDate(new Date());
            week = getWeek(currentDate);
        }

        const {data} = await loadProfessionalSplitTime({
            variables: {
                input: {
                    week: week,
                    regional: regional,
                    professional: professional,
                    type: type,
                    duration: duration,
                }
            }
        })
        let newSplitTime = [];
        if (!!data && !!data.splitTime) data.splitTime.map(st => {newSplitTime.push({...st, className: "fc-event-primary"})});
        setSplitTime(newSplitTime);
    }

    const handleSave = (data) => {
        mutateFunction({
            variables: {input: data}
        });
    }

    const handleRegionals = (patient) => {
        const planRegionals = patient?.plan[0]?.regionals?.filter(r => r.name !== 'TODAS REGIONAIS');
        if (!!planRegionals) return planRegionals.map(r => (<option value={r.id} key={r.id}>{r.name}</option>));
    }

    const handleEvents = (patient) => {
        const planEvents = patient?.pending_awaiting_schedules;
        if (!!planEvents) return planEvents.map(e => (<option value={e.id} key={e.id}>{e.awaiting_schedule}</option>));
    }

    const handleProfessionals = () => {
        if (!!professionals) return professionals.map(p => (<option value={p.id} key={p.id}>{p.user.name}</option>));
    }

    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Modal
            isCentered
            onClose={() => close()}
            isOpen={open}
            motionPreset="slideInBottom"
            size={"xl"}
            autoFocus={false}
        >
            <form onSubmit={handleSubmit(handleSave)}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Criar novo Agendamento</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Flex direction="column" w="100%">
                            <Grid
                                templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
                                gap="24px"
                            >
                                <FormControl isInvalid={errors.patient_id}>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Beneficiário
                                    </FormLabel>
                                    <AsyncSelect
                                        {...register("patient_id")}
                                        loadOptions={asyncValues}
                                        onChange={selectBeneficiary}
                                        isLoading={loading}
                                        getOptionLabel={e => e.name}
                                        getOptionValue={e => e.id}
                                        options={options}
                                        color="#FFF"
                                        placeholder="Selecione uma opção..."
                                        loadingMessage={() => "Procurando..."}
                                        noOptionsMessage={({inputValue}) => !inputValue ? "Digite para pesquisar" : "Não foram encontrados resultados"}
                                        styles={
                                            colorMode === "light" ? msLightTheme : msDarkTheme
                                        }
                                    />
                                    {errors.patient_id &&
                                        <FormErrorMessage>{errors.patient_id.message}</FormErrorMessage>}
                                </FormControl>
                                {
                                    patient &&
                                    <FormControl>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Compromisso
                                        </FormLabel>
                                        <Select
                                            {...register("awaiting_schedule_id")}
                                            value={awaitingSchedule}
                                            disabled={!patient}
                                            placeholder="Selecione uma opção..."
                                        >
                                            {handleEvents(patient)}
                                        </Select>
                                    </FormControl>
                                }
                                <FormControl>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Selecione a Regional
                                    </FormLabel>
                                    <Select
                                        {...register("regional_id")}
                                        value={regional}
                                        onChange={(item) => loadProfessionalsByRegional(item.currentTarget.value)}
                                        disabled={!patient || (!!patient && patient.plan[0].regionals.length < 1)}
                                        placeholder="Selecione uma opção..."
                                    >
                                        {handleRegionals(patient)}
                                    </Select>
                                </FormControl>
                                <FormControl isInvalid={errors.professional_id}>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Selecione o Profissional
                                    </FormLabel>
                                    <Select
                                        {...register("professional_id")}
                                        onChange={({target}) => {
                                            setProfessional(target.value);
                                            loadProfessionalTime(target.value)
                                        }}
                                        disabled={!professionals || (professionals && professionals.length < 1)}
                                        placeholder="Selecione uma opção..."
                                    >
                                        {handleProfessionals()}
                                    </Select>
                                    {errors.professional_id &&
                                        <FormErrorMessage>{errors.professional_id.message}</FormErrorMessage>}
                                </FormControl>
                                {
                                    professional &&
                                    <FormControl>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Selecione o tipo de agendamento
                                        </FormLabel>
                                        <Select
                                            {...register("type")}>
                                            <option value="presencial">Presencial</option>
                                            <option value="tele">Tele-atendimento</option>
                                        </Select>
                                    </FormControl>
                                }
                                {type === "presencial" &&
                                    <FormControl isInvalid={errors.professional_id}>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Selecione a duração do atendimento
                                        </FormLabel>
                                        <Select
                                            value={duration}
                                            onChange={({target}) => setDuration(target.value)}
                                            disabled={!professionals || (professionals && professionals.length < 1)}
                                        >
                                            <option value="20min">20min</option>
                                            <option value="10min">10min</option>
                                        </Select>
                                        {errors.attendance_duration &&
                                            <FormErrorMessage>{errors.attendance_duration.message}</FormErrorMessage>}
                                    </FormControl>
                                }
                                {
                                    !!professional &&
                                    <FormControl isInvalid={errors.scheduled_to} mb={eventDate == null ? "120px" : ""}
                                                 pb="30px" height={"100%"}>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Selecione a data e hora
                                        </FormLabel>
                                        {
                                            loadingTime ?
                                                <Flex width="100%" height="100%" justifyContent="center"
                                                      alignItems="center">
                                                    <Spinner/>
                                                </Flex>
                                                :
                                                splitTime && splitTime.length > 0 &&
                                                !getValues("scheduled_to") || getValues("scheduled_to") == null ?
                                                    <EventCalendar
                                                        calendarData={splitTime}
                                                        initialDate={currentDate}
                                                        handleEventClick={handleEventClick}
                                                        event
                                                        changeDate={changeDate}
                                                        weekView
                                                    />
                                                    :
                                                    <Flex alignItems="center">
                                                        <Text fontSize="20px" mr="15px">{brDate}</Text>
                                                        <Button
                                                            backgroundColor="notrelife.800"
                                                            color="white"
                                                            variant="no-hover"
                                                            onClick={() => resetDate()}
                                                        >
                                                            Alterar
                                                        </Button>
                                                    </Flex>
                                        }
                                        {errors.scheduled_to &&
                                            <FormErrorMessage>{errors.scheduled_to.message}</FormErrorMessage>}
                                    </FormControl>
                                }
                            </Grid>
                        </Flex>
                        {(patient && patient.plan[0].regionals.length < 1) ? "Plano do paciente nao tem regionais cadastradas" : ""}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={loadingCreate}
                            backgroundColor="notrelife.800"
                            color="white"
                            variant="no-hover"
                            type="submit"
                        >
                            Criar Evento
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}

export default EventModal
