import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import { useCancelEvent } from "../../../hooks/calendar/statistics";

const ModalCancel = ({
  open = false,
  close = () => console.log("close"),
  confirm = () => console.log("confirmado"),
  loading,
}) => (
  <Modal isOpen={open} onClose={close}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Cancelar Agendamento</ModalHeader>
      <ModalCloseButton />
      <ModalBody>Quer mesmo Cancelar este agendamento?</ModalBody>
      <ModalFooter>
        <Button variant="no-hover" onClick={() => close(true)}>
          Cancelar
        </Button>
        <Button
          isLoading={loading}
          variant="no-hover"
          onClick={() => confirm()}
        >
          Confirmar
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default ({ id, canceledEvent, open, close }) => {
  const [onCancelEvent, { loading }] = useCancelEvent(id, {
    onCompleted: () => {
      close();
      if (canceledEvent) canceledEvent();
    },
  });
  return (
    <>
      <ModalCancel
        open={open}
        close={() => close()}
        confirm={() => onCancelEvent()}
        loading={loading}
      />
    </>
  );
};
