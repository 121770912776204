

// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Icon,
  Progress,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import ReportCard from "./ReportCard";
import bgCardReports from "assets/img/background-card-reports.png";
import { FaUser, FaPhone } from "react-icons/fa";

const CardsGroup = ({loading = false, statistics = {}}) => {
  
  return (
    <Grid
      templateColumns={{ md: "repeat(2, 1fr)" }}
      templateRows={{ md: "repeat(2, 1fr)" }}
      gap="24px">
      <ReportCard
        loading={loading}
        backgroundImage={bgCardReports}
        title={"Média diária de agendamentos presenciais"}
        number={statistics?.presencial_average?.toFixed(2) || 0}
        icon={<Icon as={FaUser} w="25px" h="25px" color="blue.900" />}
      />
      <ReportCard
        loading={loading}
        backgroundImage={bgCardReports}
        title={"Média diária de agendamentos por tele"}
        number={statistics?.tele_average?.toFixed(2) || 0}
        icon={<Icon as={FaPhone} w="25px" h="25px" color="blue.900" />}
      />
    </Grid>
  );
};

export default CardsGroup