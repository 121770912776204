import React from 'react'
import { ResponsiveLine } from '@nivo/line'

import { useColorMode } from "@chakra-ui/react";

import { margin, padding, legendsLight, legendsDark, lineProps } from 'variables/nivo/line'
import { themeDark, themeLight, axisBottom, axisLeft, labelTextColor, tooltip } from 'variables/nivo/default'
import { defaultColors } from 'variables/colors';

const BodyMassGrowthChart = ({ data }) => {
	const { colorMode } = useColorMode();
    if (!data) return "carregando";
  return (
    <ResponsiveLine
      data={data}
      margin={margin}
      padding={padding}
      theme={colorMode === 'light' ? themeLight : themeDark}
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      colors={ defaultColors }
      labelTextColor="#FFF"
      tooltip={({point}) => tooltip(`${point.data.x} - ${point.data.y}`, point.data.yStacked, null, point.color)}
      legends={colorMode === 'light' ? legendsLight : legendsDark}
      {...lineProps}
    />
  )
}

export default BodyMassGrowthChart
