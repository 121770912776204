// Chakra imports
import {Flex, Progress, Stack, Text, useColorModeValue,} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";

const ItemLoad = ({label, percentage, color}) => (
    <Flex direction="column">
        <Flex justify="space-between" mb="8px">
            <Text fontSize="md" color="gray.400" fontWeight="500">
                {label}
            </Text>
            <Text fontSize="md" color="gray.400" fontWeight="500">
                {percentage || 0}%
            </Text>
        </Flex>
        <Progress
            colorScheme={color}
            size="sm"
            value={percentage || 0}
            borderRadius="15px"
        ></Progress>
    </Flex>
)


const percentageCalc = (mount, total) => total === 0 ? 0 : (mount * 100) / total

export default ({statistics}) => {
    const textColor = useColorModeValue("gray.700", "white");
    const accomplisheds = statistics?.status?.find(r => r.label === "accomplished") || {};
    const rescheduled = statistics?.status?.find(r => r.label === "rescheduled") || {};
    const canceled = statistics?.status?.find(r => r.label === "canceled") || {};
    const unrealized = statistics?.status?.find(r => r.label === "unrealized") || {};
    const pending = statistics?.status?.find(r => r.label === "pending") || {};

    const items = [
        {
            label: "Agendamentos Realizados",
            percentage: percentageCalc(accomplisheds?.total || 0, statistics.commitments).toFixed(2),
            color: accomplisheds?.color
        },
        {
            label: "Agendamentos Reagendados",
            percentage: percentageCalc(rescheduled?.total || 0, statistics.commitments).toFixed(2),
            color: rescheduled?.color
        },
        {
            label: "Agendamentos Cancelados",
            percentage: percentageCalc(canceled?.total || 0, statistics.commitments).toFixed(2),
            color: canceled?.color
        },
        {
            label: "Agendamentos Ausentes",
            percentage: percentageCalc(unrealized?.total || 0, statistics.commitments).toFixed(2),
            color: unrealized?.color
        },
        {
            label: "Agendamentos Aguardando",
            percentage: percentageCalc(pending?.total || 0, statistics.commitments).toFixed(2),
            color: pending?.color
        },
    ];

    return (
        <Card>
            <CardHeader mb="24px">
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                    Resumo
                </Text>
            </CardHeader>
            <CardBody>
                <Flex direction="column" w="100%">
                    <Stack direction="column" spacing="28px" w="100%" mb="40px">
                        {items.map(s => <ItemLoad color={s.color} percentage={s.percentage} label={s.label}/>)}
                    </Stack>
                </Flex>
            </CardBody>
        </Card>
    );
};
