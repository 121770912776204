import { createContext } from 'react'
import { JWT_TOKEN_COGNITO } from 'variables/enumLabels'

const TokenStateContext = createContext()
const TokenDispatchContext = createContext()

const TokenProvider = ({ children }) => {
    return (
        <TokenStateContext.Provider>
            <TokenDispatchContext.Provider>{children}</TokenDispatchContext.Provider>
        </TokenStateContext.Provider>
    )
}

const useToken = () => {
    return {
        bearer: window.localStorage.getItem(JWT_TOKEN_COGNITO)
    }
}

const useSetToken = () => {
    return (token) => {
        window.localStorage.setItem(JWT_TOKEN_COGNITO, token);
    }
}

const useRemoveToken = () => {
    window.localStorage.clear();
    return {
        bearer: window.localStorage.getItem(JWT_TOKEN_COGNITO)
    }
}

export {
    TokenProvider,
    useToken,
    useSetToken,
    useRemoveToken
}