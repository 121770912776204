import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import { cpf as cpfValidator } from "cpf-cnpj-validator";

import { useCreateBeneficiary } from "hooks/beneficiaries";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Skeleton,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import { GET_PLANS } from "graphql/plans";

import states from "variables/states";
import cities from "variables/cities";
import getCEP from "../../../functions/getCEP";
import { HEALTH_STATEMENT } from "../../../functions/enums";
import moment from "moment";

const schema = yup.object().shape({
  proposal: yup.string().required("O campo Número da Proposta é obrigatório"),
  assign_at: yup
    .string()
    .nullable()
    .required("O campo Número da Proposta é obrigatório"),
  plan: yup.object().shape({
    plan_id: yup.string().required("O campo Plano é obrigatório"),
  }),
  beneficiary: yup.object().shape({
    name: yup.string().required("O campo Nome é obrigatório"),
    mother_name: yup.string(),
    email: yup.string().email("Insira um e-mail válido").nullable(),
    cpf: yup
      .string()
      .required("O campo CPF é obrigatório")
      .test("cpf-valid", "O CPF informado não é válido", function (field) {
        return cpfValidator.isValid(field);
      }),
    rg: yup.string(),
    phone: yup
      .string()
      .required("O campo Celular é obrigatório")
      .matches(/\(\d{2}\) (\d{5})-(\d{4})/, "Insira um telefone válido"),
    second_phone: yup.string(),
    second_phone_contact: yup.string().when("second_phone", {
      is: (second_phone) => second_phone.length > 0,
      then: yup.string().required("Esse campo é obrigatório"),
    }),
    second_phone_type: yup
      .string()
      .oneOf(["primary_beneficiary", "beneficiary", "kinsman", "external"])
      .when("second_phone", {
        is: (second_phone) => second_phone.length > 0,
        then: yup.string().required("Esse campo é obrigatório"),
      }),
    gender: yup.string().required("O campo Gênero é obrigatório"),
    birthdate: yup
      .string()
      .required("O campo Data de Nascimento é obrigatório"),
    cep: yup.string(),
    state: yup.string().nullable(),
    city: yup.string().nullable(),
    address: yup.string(),
    district: yup.string(),
    complement: yup.string(),
  }),
});

const CreateBeneficiary = () => {
  const methods = useForm();
  const toast = useToast();
  const history = useHistory();

  const { data: dataPlans, loading: loadingPlans } = useQuery(GET_PLANS);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  setValue("beneficiary.coverage", true);

  const [noParseDate, setNoParseDate] = useState(null);
  const [noParseBirth, setNoParseBirth] = useState(null);

  useEffect(() => {
    if (noParseDate && noParseDate.replace("_", "").length === 10) {
      const date = noParseDate.split("/");
      setValue("assign_at", `${date[2]}-${date[1]}-${date[0]} 00:00:00`);
    } else setValue("assign_at", null);
  }, [noParseDate]);

  useEffect(() => {
    if (noParseBirth && noParseBirth.replace("_", "").length === 10) {
      const date = noParseBirth.split("/");
      setValue(
        "beneficiary.birthdate",
        `${date[2]}-${date[1]}-${date[0]} 00:00:00`
      );
    }
  }, [noParseBirth]);

  const secondPhone = watch("beneficiary.second_phone", null);
  const wState = watch("beneficiary.state_id", null);

  const [createBeneficiary, { loading, error }] = useCreateBeneficiary({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Proposta criada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        history.push(
          `/sistema/administracao/gestao-vidas/${data.createProposal.beneficiaries[0].id}`
        );
    },
    onError: (errors) => {
      const errorsArr = errors ? Object.keys(errors) : null;
      if (errorsArr)
        errorsArr.map((err) => {
          if (errors[err]) {
            toast({
              title: errors[err][0],
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        });
      else
        toast({
          title: "Ocorreu um erro",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
    },
  });

  const onSubmit = (data) => {
    if (!getValues("beneficiary.state_id"))
      setValue("beneficiary.state_id", null);
    if (!getValues("beneficiary.city_id"))
      setValue("beneficiary.city_id", null);

    const variables = {
      input: {
        proposal_number: getValues("proposal"),
        assign_at: getValues("assign_at"),
        beneficiaries: {
          create: [
            {
              ...getValues("beneficiary"),
              awaiting_schedules: {
                create: [
                  {
                    is_awaiting_schedule: true,
                    awaiting_schedule_at: moment().format(),
                    awaiting_schedule: HEALTH_STATEMENT,
                  },
                ],
              },
            },
          ],
        },
        plan: {
          connect: getValues("plan.plan_id"),
        },
      },
    };

    createBeneficiary({ variables: variables });
  };

  const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
  const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
  const textColor = useColorModeValue("gray.700", "white");

  const [cep, setCep] = useState(watch("cep", ""));

  useEffect(() => {
    if (!!cep) {
      const cleanCEP = cep.replace(/[^0-9]/g, "");
      if (!!cleanCEP && cleanCEP.length === 8) {
        const cepData = getCEP(cleanCEP);
        if (!!cepData) {
          cepData
            .then((data) => {
              if (!!data) {
                if (!!data.logradouro)
                  setValue("beneficiary.address", data.logradouro);
                if (!!data.complemento)
                  setValue("beneficiary.complement", data.complemento);
                if (!!data.bairro)
                  setValue("beneficiary.district", data.bairro);
                if (!!data.uf) {
                  const state = states.find(
                    (state) => state.initials === data.uf
                  );
                  if (!!state)
                    setValue("beneficiary.state_id", String(state.id));
                }
                if (!!data.localidade) {
                  const city = cities.find(
                    (city) => city.name === data.localidade
                  );
                  if (!!city) setValue("beneficiary.city_id", String(city.id));
                }
              }
            })
            .catch((reason) => {
              toast({
                title: "CEP não encontrado.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            });
        }
      }
    }
  }, [cep]);

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="column">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Cadastrar nova proposta
            </Text>
            <Text color="gray.400" fontWeight="normal" fontSize="sm">
              Preencha os campos para realizar o cadastro
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <FormProvider {...methods}>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" w="100%">
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                  gap="24px"
                >
                  <FormControl isInvalid={errors.proposal}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Número da Proposta
                    </FormLabel>
                    <Input
                      {...register("proposal")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.proposal && (
                      <FormErrorMessage>
                        {errors.proposal.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.plan?.plan_id}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Plano
                    </FormLabel>

                    <Skeleton height={"40px"} isLoaded={!loadingPlans}>
                      <Select
                        {...register("plan.plan_id")}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        placeholder="Selecione uma opção..."
                        color="gray.400"
                      >
                        {dataPlans?.plans?.map((plan) => (
                          <option value={plan.id}>{plan.name}</option>
                        ))}
                      </Select>
                    </Skeleton>
                    {errors.plan?.plan_id && (
                      <FormErrorMessage>
                        {errors.plan?.plan_id.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.assign_at}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Data de Adesão
                    </FormLabel>
                    <Input
                      as={InputMask}
                      mask="99/99/9999"
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      onChange={({ target }) => setNoParseDate(target.value)}
                    />
                    {errors.assign_at && (
                      <FormErrorMessage>
                        {errors.assign_at.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.name}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Nome completo
                    </FormLabel>
                    <Input
                      {...register("beneficiary.name")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.name && (
                      <FormErrorMessage>
                        {errors.beneficiary?.name.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.mother_name}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Nome da mãe
                    </FormLabel>
                    <Input
                      {...register("beneficiary.mother_name")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.mother_name && (
                      <FormErrorMessage>
                        {errors.beneficiary?.mother_name.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.email}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      E-mail
                    </FormLabel>
                    <Input
                      {...register("beneficiary.email")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.email && (
                      <FormErrorMessage>
                        {errors.beneficiary?.email.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.cpf}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      CPF
                    </FormLabel>
                    <Input
                      {...register("beneficiary.cpf")}
                      as={InputMask}
                      mask="999.999.999-99"
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.cpf && (
                      <FormErrorMessage>
                        {errors.beneficiary?.cpf.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.rg}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      RG
                    </FormLabel>
                    <Input
                      {...register("beneficiary.rg")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.rg && (
                      <FormErrorMessage>
                        {errors.beneficiary?.rg.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.phone}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Celular
                    </FormLabel>
                    <Input
                      {...register("beneficiary.phone")}
                      as={InputMask}
                      mask="(99) 99999-9999"
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.phone && (
                      <FormErrorMessage>
                        {errors.beneficiary?.phone.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={errors.beneficiary?.second_phone}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Telefone secundário
                    </FormLabel>
                    <Input
                      {...register("beneficiary.second_phone")}
                      as={InputMask}
                      mask="(99) 99999-9999"
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.second_phone && (
                      <FormErrorMessage>
                        {errors.beneficiary?.second_phone.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  {secondPhone &&
                    secondPhone !== "" &&
                    secondPhone !== "(__) _____-____" && (
                      <Box>
                        <FormControl
                          marginBottom="15px"
                          isInvalid={errors.beneficiary?.second_phone_contact}
                        >
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                          >
                            Nome do contato secundário
                          </FormLabel>
                          <Input
                            {...register("beneficiary.second_phone_contact")}
                            focusBorderColor={inputHover}
                            borderRadius="8px"
                            fontSize="md"
                          />
                          {errors.beneficiary?.second_phone_contact && (
                            <FormErrorMessage>
                              {errors.beneficiary?.second_phone_contact.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isInvalid={errors.beneficiary?.second_phone_type}
                        >
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                          >
                            Tipo do contato secundário
                          </FormLabel>
                          <Select
                            {...register("beneficiary.second_phone_type")}
                            focusBorderColor={inputHover}
                            borderRadius="8px"
                            fontSize="md"
                          >
                            <option value="primary_beneficiary">
                              Beneficiário Titular
                            </option>
                            <option value="beneficiary">Beneficiário</option>
                            <option value="kinsman">Parente</option>
                            <option value="external">Terceiro</option>
                          </Select>

                          {errors.beneficiary?.second_phone_type && (
                            <FormErrorMessage>
                              {errors.beneficiary?.second_phone_type.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                    )}

                  <FormControl isInvalid={errors.beneficiary?.gender}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Gênero
                    </FormLabel>
                    <Select
                      {...register("beneficiary.gender")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      placeholder="Selecione uma opção..."
                      color="gray.400"
                    >
                      <option value="m">Masculino</option>
                      <option value="f">Feminino</option>
                    </Select>
                    {errors.beneficiary?.gender && (
                      <FormErrorMessage>
                        {errors.beneficiary?.gender.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.birthdate}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Data de Nascimento
                    </FormLabel>
                    <Input
                      as={InputMask}
                      mask="99/99/9999"
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      onChange={({ target }) => setNoParseBirth(target.value)}
                    />
                    {errors.beneficiary?.birthdate && (
                      <FormErrorMessage>
                        {errors.beneficiary?.birthdate.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.cep}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      CEP
                    </FormLabel>
                    <Input
                      {...register("beneficiary.cep")}
                      as={InputMask}
                      mask="99999-999"
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      onChange={({ target }) => setCep(target.value)}
                    />
                    {errors.beneficiary?.cep && (
                      <FormErrorMessage>
                        {errors.beneficiary?.cep.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.state}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Estado
                    </FormLabel>
                    <Select
                      {...register("beneficiary.state_id")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      placeholder="Selecione uma opção..."
                      color="gray.400"
                    >
                      {states.map((state) => (
                        <option value={state.id}>{state.name}</option>
                      ))}
                    </Select>
                    {errors.beneficiary?.state && (
                      <FormErrorMessage>
                        {errors.beneficiary?.state.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.city}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Cidade
                    </FormLabel>
                    <Select
                      {...register("beneficiary.city_id")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      placeholder={
                        !wState || wState === ""
                          ? "Selecione um estado..."
                          : "Selecione uma opção..."
                      }
                      color="gray.400"
                      disabled={!wState || wState === ""}
                    >
                      {cities
                        .filter(
                          (city) =>
                            city.state_id === Number(wState) ||
                            city.state_id === String(wState)
                        )
                        .map((city) => (
                          <option value={city.id}>{city.name}</option>
                        ))}
                    </Select>
                    {errors.beneficiary?.city && (
                      <FormErrorMessage>
                        {errors.beneficiary?.city.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.address}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Endereço Completo
                    </FormLabel>
                    <Input
                      {...register("beneficiary.address")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.address && (
                      <FormErrorMessage>
                        {errors.beneficiary?.address.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.district}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Bairro
                    </FormLabel>
                    <Input
                      {...register("beneficiary.district")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.district && (
                      <FormErrorMessage>
                        {errors.beneficiary?.district.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.beneficiary?.complement}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Complemento
                    </FormLabel>
                    <Input
                      {...register("beneficiary.complement")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.beneficiary?.complement && (
                      <FormErrorMessage>
                        {errors.beneficiary?.complement.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Grid>
                <Flex mt="24px" align={"center"} justify={"flex-end"}>
                  <NavLink to="/sistema/administracao/gestao-vidas">
                    <Text
                      mr="20px"
                      fontSize="xs"
                      color={textColor}
                      fontWeight="bold"
                    >
                      Cancelar
                    </Text>
                  </NavLink>
                  <Button
                    isLoading={loading}
                    type="submit"
                    variant="no-hover"
                    bg={buttonBg}
                    alignSelf="flex-end"
                    w="100px"
                    h="35px"
                  >
                    <Text fontSize="xs" color="#fff" fontWeight="bold">
                      Criar
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </FormProvider>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CreateBeneficiary;
