import { ChartBar, Files, Wallet, CalendarBlank } from '@phosphor-icons/react';

import {
  roleAdmin,
  roleSuper,
  roleConsu,
  roleEnfer,
  roleMedic,
  roleRecep,
} from "./variables/roles";

const menuItems = [
  {
    name: "Dashboards",
    path: "/dashboards",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleMedic],
    icon: <ChartBar size={18} weight="fill" />,
    collapse: true,
    items: [
      {
        name: "Vidas",
        path: "/dashboards/lives",
        roles: [],
      },
      {
        name: "Saúde",
        path: "/dashboards/health",
        roles: [],
      },
      {
        name: "Operacional",
        path: "/dashboards/operational",
        roles: [],
      },
      {
        name: "WhatsApp",
        path: "/dashboards/whatsapp",
        roles: [],
      },
      {
        name: "CPT",
        path: "/dashboards/cpt",
        roles: [],
      },
	  {
		name: "Análise Cumulativa de PA",
		path: "/dashboards/acumulado",
		roles: [roleSuper]
	},
    ],
  },
  {
    name: "Relatórios",
    path: "/relatorios",
    icon: <Files size={18} weight="fill" />,
    collapse: true,
    items: [
      {
        name: "Relatório",
        path: "/relatorios/relatorio",
        roles: [],
      },
    ],
  },
  {
    name: "Administração",
    path: "/administracao",
    icon: <Wallet size={18} weight="fill" />,
    collapse: true,
    roles: [],
    items: [
      {
        name: "Gestão de Vidas",
        path: "/administracao/gestao-vidas",
        roles: [],
      },
      {
        name: "Empresas",
        path: "/administracao/empresas",
        roles: [roleSuper],
      },
      {
        name: "Usuários",
        path: "/administracao/usuarios",
        roles: [roleAdmin, roleSuper],
      },
      {
        name: "Agenda Profissional",
        path: "/administracao/agenda",
        roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
      },
      {
        name: "Regionais / Planos",
        path: "/administracao/regionais",
        roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
      },
      {
        name: "Planos",
        path: "/administracao/planos",
        roles: [roleAdmin, roleSuper],
      },
      {
        name: "Central de Atendimento",
        path: "/administracao/suporte",
        roles: [],
      },
      {
        name: "Lista de Consultas",
        path: "/administracao/compromissos",
        roles: [],
      },
      {
        name: "Whatsapp",
        path: "/administracao/whatsapp",
        roles: [],
      },
      {
        name: "CPT",
        path: "/administracao/cpt",
        roles: [],
      },
    ],
  },
  {
    name: "Agendamento",
    path: "/agendamento",
    icon: <CalendarBlank size={18} weight="fill" />,
    collapse: true,
    roles: [],
    items: [
      {
        name: "Calendário",
        path: "/agendamento/calendario",
        roles: [],
      },
      {
        name: "Gestão de Compromissos",
        path: "/agendamento/gestao-compromissos",
        roles: [],
      },
    ],
  },
];

export default menuItems;
