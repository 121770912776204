import { Table, TableContainer, Tbody, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react'

const buildHeader = (data) => {
    const header = data.sort((a, b) => a.name > b.name ? 1 : -1).map(el => { return el.name })
    return [...new Set(header)];
}

const getCell = (data, regional, risk, isRow = true) => {
    const ratio_48 = getTotals('48', data, regional, risk, false)
    const ratio_49 = getTotals('49', data, regional, risk, false)

    return (
        <>
            <Th borderLeft="1px"  textAlign="center">{`${ratio_48}%`}</Th>
            <Th textAlign="center">{`${ratio_49}%`}</Th>
        </>
    )
}

const getTotals = (estrat, data, regional, risk, isRow = true) => {
    const regionalRisk = data.find(el => el.name === regional && el[risk] > 0 && el.estrat === estrat)
    if (!regionalRisk) return isRow ? (<Th textAlign="center">? 0%</Th>) : 0
    const ratio = ((regionalRisk.done / regionalRisk[risk]) * 100).toFixed(0)
    return isRow ? (<Th textAlign="center">{`${ratio}%`}</Th>) : ratio ? Number(ratio) : 0
}

const getAverageCell = (data, regional) => {
    const average_48 = getAverage('48', data, regional)
    const average_49 = getAverage('49', data, regional)

    return (<><Th borderLeft="1px"  textAlign="center">{`${average_48}%`}</Th><Th textAlign="center">{`${average_49}%`}</Th></>)
}

const getAverage = (estrat, data, regional) => {
    const low = getTotals(estrat, data, regional, 'lowrisk', false)
    const lowMed = getTotals(estrat, data, regional, 'lowmedrisk', false)
    const highMed = getTotals(estrat, data, regional, 'highmedrisk', false)
    const high = getTotals(estrat, data, regional, 'highrisk', false)

    let average = 0
    let doRatio = 0

    if (low > 0) { average += low; doRatio += 1 }
    if (lowMed > 0) { average += lowMed; doRatio += 1 }
    if (highMed > 0) { average += highMed; doRatio += 1 }
    if (high > 0) { average += high; doRatio += 1 }

    const ratio = (average / doRatio)

    const realAverage = isNaN(ratio) ? 0 : ratio;

    return realAverage.toFixed(0);
}


const getJoinAverage = (data, regional) => {
    const average_48 = Number(getAverage('48', data, regional))
    const average_49 = Number(getAverage('49', data, regional))
    const average = ((average_48 + average_49) / 2).toFixed(0)

    const realAverage = isNaN(average) ? 0 : average;

    return (<Th borderLeft="1px"  colspan="2" textAlign="center">{`${realAverage}%`}</Th>)
}

const RisksHealthDeclaration = ({ data = [] }) => {
    const header = buildHeader(data)

    return (
        <TableContainer w="full">
            <Table colorScheme={useColorModeValue('blackAlpha', 'whiteAlpha')}>
                <Thead>
                    <Tr>
                        <Th colSpan={2} textAlign="left">Risco / Região</Th>
                        {
                            header.map((el, x) => <Th borderLeft="1px"  colSpan={2} textAlign="center">{el}</Th>)
                        }
                    </Tr>
                </Thead>
                <Tbody overflowX="auto">
                    <Tr >
                        <Th colSpan={2}>Idade</Th>
                        {
                            header.map(el =>
                                <>
                                    <Th borderLeft="1px"  textAlign="center">-48</Th>
                                    <Th textAlign="center">+49</Th>
                                </>
                            )
                        }
                    </Tr>
                    <Tr>
                        <Th colSpan={2} textAlign="left">Risco Baixo</Th>
                        {
                            header.map(el => getCell(data, el, 'lowrisk'))
                        }
                    </Tr>
                    <Tr>
                        <Th colSpan={2} textAlign="left">Risco Médio Baixo</Th>
                        {
                            header.map(el => getCell(data, el, 'lowmedrisk'))
                        }
                    </Tr>
                    <Tr>
                        <Th colSpan={2} textAlign="left">Risco Médio Alto</Th>
                        {
                            header.map(el => getCell(data, el, 'highmedrisk'))
                        }
                    </Tr>
                    <Tr>
                        <Th colSpan={2} textAlign="left">Risco Alto</Th>
                        {
                            header.map(el => getCell(data, el, 'highrisk'))
                        }
                    </Tr>
                    <Tr>
                        <Th colSpan={2} textAlign="left">Média</Th>
                        {
                            header.map(el => getAverageCell(data, el))
                        }
                    </Tr>
                    <Tr>
                        <Th colSpan={2} textAlign="left">Média acumulada sem regras</Th>
                        {
                            header.map(el => getJoinAverage(data, el))
                        }
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default RisksHealthDeclaration