/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/Separator/Separator";
import { useDispatch } from "hooks/layout";
import { useLayout } from "hooks/layout";
import { switchVariant } from "hooks/layout/actionCreators";
import { openCloseConfig } from "hooks/layout/actionCreators";
import PropTypes from "prop-types";
import React, { useState } from "react";
import GitHubButton from "react-github-btn";
import { FaFacebook, FaTwitter } from "react-icons/fa";

export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const { configOpen, sidebarVariant } = useLayout();
	const layoutDispatch = useDispatch()

  const [switched, setSwitched] = useState(props.isChecked);

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();
  return (
    <>
      <Drawer
        isOpen={configOpen}
        onClose={() => openCloseConfig(false, layoutDispatch)}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}>
        <DrawerContent >
          <DrawerHeader pt='24px' px='30px'>
            <DrawerCloseButton />
            <Text fontSize='xl' fontWeight='bold' mt='16px'>
              Preferências
            </Text>
            <HSeparator />
          </DrawerHeader>
          <DrawerBody w='340px' ps='24px' pe='40px'>
            <Flex flexDirection='column'>
              <Box mb="20px">
                <Text fontSize='md' fontWeight='600' mb="6px">
                  Tipo de menu
                </Text>
                <Flex>
                  <Button
                    w='50%'
                    p='8px 32px'
                    me='8px'
                    bgColor={sidebarVariant == 'transparent' ? 'notrelife.700' : 'white'}
                    borderColor={sidebarVariant == 'transparent' ? 'none' : 'black'}
                    color={sidebarVariant == 'transparent' ? 'white' : 'black'}
                    fontSize='xs'
                    onClick={() => switchVariant('transparent', layoutDispatch)}>
                    Transparente
                  </Button>
                  <Button
                    w='50%'
                    p='8px 32px'
                    me='8px'
                    bgColor={sidebarVariant == 'opaque' ? 'notrelife.700' : 'white'}
                    borderColor={sidebarVariant == 'opaque' ? 'none' : 'black'}
                    color={sidebarVariant == 'opaque' ? 'white' : 'black'}
                    fontSize='xs'
                    onClick={() => switchVariant('opaque', layoutDispatch)}>
                    Opaco
                  </Button>
                </Flex>
              </Box>
              {/* <Box
                display={fixedDisplay}
                justifyContent='space-between '
                mb='16px'>
                <Text fontSize='md' fontWeight='600' mb='4px'>
                  Navbar Fixed
                </Text>
                <Switch
                  colorScheme='healthlab'
                  isChecked={switched}
                  onChange={(event) => {
                    if (switched === true) {
                      props.onSwitch(false);
                      setSwitched(false);
                    } else {
                      props.onSwitch(true);
                      setSwitched(true);
                    }
                  }}
                />
              </Box> */}
              <Flex
                justifyContent='space-between'
                alignItems='center'
                mb='24px'>
                <Text fontSize='md' fontWeight='600'>
                  Trocar tema
                </Text>
                <Button onClick={toggleColorMode}>
                  Tema {colorMode === "light" ? "escuro" : "claro"}
                </Button>
              </Flex>
              <HSeparator />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
