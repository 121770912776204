import { createContext, useContext, useReducer } from "react";
import { ACTION_TYPES, reducers } from "./reducers";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
    GET_CPT_LIST,
    GET_CPT,
    SET_CID_CPT,
    UPLOAD_CONFIG,
    DELETE_CPT,
    SET_ASSIGN_CPT,
    UPDATE_CPT,
    GET_CID_FOUND_ANOTHER_MEASUREMENTS
} from "graphql/cpt";
import { useParams } from "react-router-dom";
import { STATUS_CPT_AWAITING_CID_DISEASES } from "functions/enums";

const CptHookState = createContext()
const CptHookDispatch = createContext()

const CptHookProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, { page: {}, selectedCpt: {} });
    const { id } = useParams()
    let query = { loading: false };

    if (id) {
        query = useQuery(GET_CPT, {
            variables: {
                id
            },
            onCompleted: (data) => dispatch({ type: ACTION_TYPES.SELECTED_CPT, data: data.cpt })
        })
    }
    const { loading } = query;

    return (
        <CptHookState.Provider value={{ loading, ...state }}>
            <CptHookDispatch.Provider value={dispatch}>
                {children}
            </CptHookDispatch.Provider>
        </CptHookState.Provider>
    )
}
const useCpts = () => {
    const state = useContext(CptHookState)

    if (!state) throw new Error('Provider cpt nao iniciado');

    return state;
}
const useUploadConfig = (options) => {
    return useMutation(UPLOAD_CONFIG, {
        ...options,
        refetchQueries: GET_CPT_LIST
    })
}
const usePagination = () => {
    const dispatch = useContext(CptHookDispatch)
    return useLazyQuery(GET_CPT_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            window.history.replaceState(null, '', `./cpt?page=${data.cpts.paginatorInfo.currentPage}&size=${data.cpts.paginatorInfo.perPage}`);
            dispatch({ type: ACTION_TYPES.LIST, data: data.cpts })
        }
    })
}
const useAssocCid = (options = { onCompleted: () => console.log(''), onError: () => console.log('') }) => {
    const { onCompleted, onError } = options
    const dispatch = useContext(CptHookDispatch)
    return useMutation(SET_CID_CPT, {
        onCompleted: (data) => {
            dispatch({ type: ACTION_TYPES.UPDATE_CPT, data: data.partialCoverage });
            if (onCompleted) onCompleted(data)
        },
        onError
    })
}
const useGetCidMeasurements = (options = { onCompleted: () => console.log(''), onError: () => console.log('') }) => {
    const { onCompleted, onError } = options
    return useLazyQuery(GET_CID_FOUND_ANOTHER_MEASUREMENTS, {
        fetchPolicy: 'no-cache',
        ...options,
    })
}
const useRemoveCpt = (options) => {
    const { onCompleted, onError } = options
    const dispatch = useContext(CptHookDispatch)
    return useMutation(DELETE_CPT, {
        onCompleted: (data) => {
            dispatch({ type: ACTION_TYPES.DELETE_CPT, data: data.deletePartialCoverage });
            if (onCompleted) onCompleted(data.deletePartialCoverage)
        },
        onError
    })
}
const useAssignCpt = () => {
    const dispatch = useContext(CptHookDispatch)
    return useMutation(SET_ASSIGN_CPT, {
        onCompleted: (data) => dispatch({ type: ACTION_TYPES.ASSIGN_CPT, data: data.assignPartialCoverage }),
        refetchQueries: GET_CPT_LIST
    })
}
const useUpdateCpt = (options) => {
    const { onCompleted, onError } = options
    const dispatch = useContext(CptHookDispatch)
    return useMutation(UPDATE_CPT, {
        onCompleted: (data) => {
            dispatch({ type: ACTION_TYPES.UPDATE_CPT, data: data.partialCoverage })
            if (onCompleted) onCompleted(data.partialCoverage)
        },
    })
}
export {
    CptHookProvider,
    useCpts,
    usePagination,
    useAssocCid,
    useUploadConfig,
    useRemoveCpt,
    useAssignCpt,
    useUpdateCpt,
    useGetCidMeasurements
}