import {useFormContext} from "react-hook-form";
import {
    FormControl,
    FormLabel,
    Input, NumberDecrementStepper,
    NumberIncrementStepper, NumberInput,
    NumberInputField,
    NumberInputStepper
} from "@chakra-ui/react";


const PlanFormCreate = () => {
    const { register } = useFormContext()

    return (
        <>
            <FormControl>
                <FormLabel
                    fontWeight="bold"
                    fontSize="xs"
                >
                    Nome
                </FormLabel>
                <Input {...register("plans.create.[0].name")} name={"plans.create.[0].name"} id={"plans.create.[0].name"}/>
            </FormControl>
            <FormControl>
                <FormLabel
                    fontWeight="bold"
                    fontSize="xs"
                >
                    ANS
                </FormLabel>
            <NumberInput >
                <NumberInputField  {...register("plans.create.[0].ans")}/>
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            </FormControl>
            <FormControl>
                <FormLabel
                    fontWeight="bold"
                    fontSize="xs"
                >
                    Secad
                </FormLabel>
            <NumberInput >
                <NumberInputField {...register("plans.create.[0].secad")}/>
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            </FormControl>
        </>
    );
}

export default PlanFormCreate;
