import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const TotalLivesCount = ({total}) => {
    if (!total) total = 0;
    return (
        <Flex justifyContent='center' alignItems='center' width='100%' height='100%'>
          <Text fontSize="30px" fontWeight="600" >{total}</Text>
        </Flex>
    )
};

export default TotalLivesCount
