const ACTION_TYPES = {
  LIST: "LIST",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  SELECT: "SELECT",
  LIST_CAMPAIGN_RECEIVERS: "LIST_RECEIVERS",
  UPDATE_CAMPAIGN_RECEIVERS: "UPDATE_CAMPAIGN_RECEIVERS",
};

const reducers = (state, action) => {
  if (!action.data) return state;

  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return {
        ...state,
        rows: action.data.data,
        page: action.data.paginatorInfo.currentPage,
        perPage: action.data.paginatorInfo.perPage,
        total: action.data.paginatorInfo.total,
        currentPage: action.data.paginatorInfo.currentPage,
      };
    }
    case ACTION_TYPES.CREATE: {
      return {
        ...state,
        rows: [...state.rows, action.data],
      };
    }
    case ACTION_TYPES.UPDATE: {
      return {
        ...state,
        rows:
          state?.rows?.length > 0
            ? state.rows.map((item) =>
                item.id === action.data.whatsappDisclosure.id
                  ? action.data.whatsappDisclosure
                  : item
              )
            : [],
      };
    }
    case ACTION_TYPES.SELECT: {
      return {
        ...state,
        whatsappDisclosure: action.data.whatsappDisclosure,
      };
    }
    case ACTION_TYPES.LIST_CAMPAIGN_RECEIVERS: {
      return {
        ...state,
        rows: action.data.data,
        page: action.data.paginatorInfo.currentPage,
        perPage: action.data.paginatorInfo.perPage,
        total: action.data.paginatorInfo.total,
        currentPage: action.data.paginatorInfo.currentPage,
      };
    }
    case ACTION_TYPES.UPDATE_CAMPAIGN_RECEIVERS: {
      return {
        ...state,
        rows: state?.rows.filter((row) => row.id !== action.data.id),
      };
    }
    default: {
      return state;
    }
  }
};

export { reducers, ACTION_TYPES };
