import { Amplify } from "aws-amplify";

Amplify.configure({
    mandatorySignIn: true,
    region: "sa-east-1",
    userPoolId: "sa-east-1_ZLRa17e9d",
    identityPoolId: "sa-east-1:bba70c43-f135-4c22-b2d0-d1afe96d31e7",
    userPoolWebClientId: "5nrhlpdsgfhdcp801stnr0aqh3"

});

export default Amplify
