import { createContext, useContext, useState } from 'react'
import Pusher from "pusher-js";
import { JWT_TOKEN_COGNITO_ID } from 'variables/enumLabels';

const SocketStateContext = createContext()

const SocketProvider = ({ children }) => {
    const [pusher, setPusher] = useState(null);

    openPusherSocket(pusher, setPusher)

    return (
        <SocketStateContext.Provider value={{ pusher }}>
            {children}
        </SocketStateContext.Provider>
    )
}

const openPusherSocket = (pusher, setPusher) => {
    if (pusher) return;

    const newPusher = new Pusher(
        process.env.REACT_APP_PUSHER_KEY,
        {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            authEndpoint: process.env.REACT_APP_SOCKET_URL,
            encrypted: true,
            auth: {
                headers: { "Authorization": 'Bearer ' + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID) || '' }
            }
        });

    setPusher(newPusher);
}

const useSocket = () => {
    const context = useContext(SocketStateContext)

    if (!context)
        throw new Error('useSocket must be used within an SocketProvider')

    return context
}

export {
    SocketProvider,
    useSocket,
}
