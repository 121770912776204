import React from "react";

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "./components/FiltersBox";

import TotalLivesCount from "components/Charts/Nivo/Lifes/TotalLivesCount";
import ActiveLivesCount from "components/Charts/Nivo/Lifes/ActiveLivesCount";
import CanceledLivesCount from "components/Charts/Nivo/Lifes/CanceledLivesCount";
import LifesPerPlanChart from "components/Charts/Nivo/Lifes/LifesPerPlanChart";
import StatmentAndAssestmentChart from "components/Charts/Nivo/Lifes/StatmentAndAssestmentChart";
import { useLivesDashboard } from "hooks/dashboards/useDashboards";
import ChartContainer from "components/Charts/ChartContainer";
import { useFilter } from "../../hooks/useFilter";
import LifesPerPlanDailyChart from "components/Charts/Nivo/Lifes/LifesPerPlanDailyChart";
import Ages from "../../components/Charts/Nivo/Lifes/Ages";

const Lives = () => {
  const { filters } = useFilter();
  const { data, loading } = useLivesDashboard(filters);
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt={{ sm: "125px", lg: "160px" }}
      >
        <Card px="0px" pb="0px" minHeight="100px">
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Filtros
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <FiltersBox planNameFilter yearMonthFilter />
            </Box>
          </CardBody>
        </Card>
      </Grid>
      {loading ? (
        "Carregando..."
      ) : (
        <>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <ChartContainer
              title="Total de Vidas"
              minHeight="180px"
              tooltip="Situação atual. Valor não afetado pelo filtro de data."
            >
              <TotalLivesCount total={data?.dashboard_lives?.totalLives} />
            </ChartContainer>
            <ChartContainer
              title="Vidas Ativas"
              minHeight="180px"
              tooltip="Situação atual. Valor não afetado pelo filtro de data."
            >
              <ActiveLivesCount total={data?.dashboard_lives?.activeLives} />
            </ChartContainer>
            <ChartContainer
              title="Vidas Canceladas"
              minHeight="180px"
              tooltip="Situação atual. Valor não afetado pelo filtro de data."
            >
              <CanceledLivesCount
                total={data?.dashboard_lives?.canceledLives}
              />
            </ChartContainer>
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <GridItem backgroundColor="#FFF" borderRadius="20px" rowSpan="2">
              <Box height="100%" width="100%" position="relative">
                <ChartContainer title="Vidas por Plano" minHeight="100%" position="absolute">
                  <LifesPerPlanChart
                    data={data?.dashboard_lives?.livesPerPlan}
                    label={"name"}
                    keys={"total"}
                  />
                </ChartContainer>
              </Box>
            </GridItem>
            <ChartContainer title="Evolução de Vidas Diárias">
              <LifesPerPlanDailyChart
                data={data?.dashboard_lives?.dailyLivesEvolution}
              />
            </ChartContainer>
            <ChartContainer title="Declaração de Saúde x Primeiro Acolhimento">
              <StatmentAndAssestmentChart
                data={data?.dashboard_lives?.weeklyHsVsFr}
              />
            </ChartContainer>
            <ChartContainer title="Faixas de vida por idade sobre Homens">
              <Ages data={data?.dashboard_lives?.agesM} />
            </ChartContainer>
            <ChartContainer title="Faixa de vida por idade sobre Mulheres">
              <Ages data={data?.dashboard_lives?.agesF} />
            </ChartContainer>
          </Grid>
        </>
      )}
    </>
  );
};

export default Lives;
