import { ResponsiveBar } from '@nivo/bar'
import { useColorModeValue } from "@chakra-ui/react";

import { padding } from "variables/nivo/bar";
import { axisLeft, themeDark, themeLight, tooltip } from "variables/nivo/default";
import { axisBottom } from 'variables/nivo/default';

const buildData = (data) => {
    const regionals = data.map(vl => {
        const done = vl.done
        const total = vl.done + vl.awaiting
        const ratio = ((done / total) * 100).toFixed(0);
        return {
            name: vl.name,
            amount: done,
            'PA Acumulado': Number(ratio)
        }
    })

    let total = 0;
    regionals.map(reg => total += reg['PA Acumulado'])
    let values = 0;
    regionals.map(reg => values += reg['amount'])

    const average = {
        name: 'Média Acumulada',
        amount: values,
        'PA Acumulado': (total / regionals.length).toFixed(0)
    }

    return [
        ...regionals.sort((a, b) => a.name > b.name ? 1 : -1),
        average
    ]
}

const AverageAccumulated = ({ data = [], goal }) => {
    const colorMode = useColorModeValue(themeLight, themeDark);
    return (
        <ResponsiveBar
            data={buildData(data)}
            keys={["PA Acumulado", "Meta"]}
            indexBy="name"
            layout="vertical"
            margin={{ top: 20, right: 60, bottom: 70, left: 50 }}
            padding={padding}
            colors={["#F15A22", "#2B6CB0"]}
            labelSkipWidth={11}
            theme={colorMode}
            axisBottom={axisBottom}
            axisTop={null}
            axisLeft={axisLeft}
            labelTextColor="#FFF"
            minValue={0}
            maxValue={100}
            label={d => `${d.value}%`}
            tooltip={({ data, indexValue, value }) => tooltip(indexValue, `${data.amount} (${value})%`)}
            markers={[
                {
                    axis: 'y',
                    value: goal,
                    textStyle: { fontWeight: 'bold', color: '#2b6cb0', fill: '#2b6cb0', alignItems: 'flex-end' },
                    lineStyle: { stroke: '#2b6cb0', strokeWidth: 4 },
                    legend: `${goal}%`,
                    legendOrientation: 'horizontal',
                    legendPosition: "right"
                },
            ]}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 60,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

export default AverageAccumulated