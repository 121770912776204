import { Text } from '@chakra-ui/react'

import BasicTable from 'components/Tables/BasicTable'

const columns = [
    {
        Header: "Regiões",
        accessor: "name",
    },
    {
        Header: "Novas vidas",
        accessor: "new_lives",
    },
    {
        Header: "Percentual",
        accessor: "percentage",
        Cell: props => <Text>{props.value}%</Text>
    },
    {
        Header: "PA Realizado",
        accessor: "pa",
    },
    {
        Header: "Percentual",
        accessor: "percentage_pa",
        Cell: props => <Text>{props.value}%</Text>
    },
]

const buildData = (data) => {
    let totalLives = 0
    data.map(el => totalLives += (el.awaiting + el.done) )
    let totalPas = 0
    data.map(el => totalPas += el.done)
    const total = {
        name: 'Total Acumulado',
        new_lives: totalLives,
        percentage: 100,
        pa: totalPas,
        percentage_pa: 100
    }
    
    const regionals = data.map(vl => {
        return {
            name: vl.name,
            new_lives: Number(vl.awaiting + vl.done),
            percentage: (((vl.awaiting + vl.done) / total.new_lives) * 100).toFixed(0),
            pa: Number(vl.done),
            percentage_pa: ((vl.done / total.pa) * 100).toFixed(0),
        }
    })

    return [
        ...regionals.sort((a, b) => a.name > b.name ? 1 : -1),
        total
    ]
}

const HealthDeclarationPA = ({ data = [] }) => {
    return (
        <BasicTable
            tableData={buildData(data)}
            columnsData={columns}
            noView
            noEdit
            noDelete
            noLines
            noOrder
            noPages
            maxSize
        />
    )
}

export default HealthDeclarationPA