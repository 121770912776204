import {
    Flex,
    Stack,
    Icon,
    Text,
    useColorModeValue,
    Spinner
  } from "@chakra-ui/react"
import { Clock } from "@phosphor-icons/react";
  import IconBox from "components/Icons/IconBox";
import dateFormat from "functions/dateFormat";
import { useGetEventsLimit10 } from "hooks/calendar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const EventItem = ({data, iconTeal, textColor}) => (
    <Link to={`../administracao/gestao-vidas/${data.patient.id}`}>
        <Flex align="center">
                <IconBox as="box" h={"50px"} w={"50px"} bg="gray.100" me="16px">
                    <Icon as={Clock} h={"22px"} w={"22px"} color={iconTeal} />
                </IconBox>
                <Flex direction="column">
                    <Text color={textColor} fontSize="sm" fontWeight="bold">
                    {data.patient.name}
                    </Text>
                    <Text color="gray.400" fontSize="sm" fontWeight="normal">
                        {
                            dateFormat(data.scheduled_to, "d MMMM y', as' k:mm")
                        }
                    </Text>
                </Flex>
        </Flex>
    </Link>
)
const NextEventsList = ({date = new Date, eventGamb}) => {
    const [getEvents, {data, loading, error}] = useGetEventsLimit10(date)

    useEffect(() => {
        getEvents()
    }, [eventGamb])

    const textColor = useColorModeValue("gray.700", "white");
    const iconTeal = useColorModeValue("teal.300", "teal.300");

    if(loading) 
        return (
            <Flex w="100%" justifyContent="center" alignItems="center" pt="30px">
                <Spinner />
            </Flex>
        )

    if (error) return <>{error.message}</>;

    return (
        <Stack direction="column" spacing="20px">
            {
                data?.events && data.events.length > 0 ?
                data?.events.map(e => <EventItem iconTeal={iconTeal} data={e} textColor={textColor}/> )
                    : ""
            }
        </Stack>
    )
}

export default NextEventsList
