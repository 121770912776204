import {gql} from "@apollo/client";

export const REGIONAL = gql`
  query REGIONAL($id: ID!) {
      regional(id: $id) {
          plans {
              id
              name
              ans
              secad
          }
          professionals {
              id
              cpf
              user {
                id
                name
                role
              }
          }
      }
  }
`

export const SIMPLE_REGIONAL = gql`
    query SIMPLE_REGIONAL($id: String!) {
        simpleRegional(id: $id) {
            plans {
                plan_name
            }
            professionals {
                id
                cpf
                user {
                    id
                    name
                    role
                }
            }
        }
    }
`