import React from "react";
import {ResponsivePie} from "@nivo/pie";
import {useColorMode} from "@chakra-ui/react";
import {border, tooltip} from "variables/nivo/default";
import {arc, arcLabelsTextColor, arcLinkLabelsColor, pieProps} from "variables/nivo/halfPie";

import {monochromeMainColors} from "variables/colors";

const total = (data) => {
	return data.beneficiaries + data.partialCoverages;
};

const LifesAndCPT = ({data}) => {
	const {colorMode} = useColorMode();

	const getData = (data) => {
		return [{
			id: "sent", label: "Entradas", value: data?.beneficiaries ?? 0,
		}, {
			id: "signed", label: "Análise de CPT em Declaração de Saúde", value: data?.partialCoverages ?? 0,
		}]
	}

	const localLengends = [{
		anchor: 'left',
		direction: 'column',
		justify: false,
		translateX: -250,
		translateY: 0,
		itemsSpacing: 15,
		itemWidth: 50,
		itemHeight: 18,
		itemTextColor: (colorMode === "light") ? "#000" : "#FFF",
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 18,
		symbolShape: 'circle',
	}];

	return (<ResponsivePie
			data={getData(data)}
			margin={{top: 40, right: 10, bottom: 20, left: 250}}
			colors={[monochromeMainColors[0], monochromeMainColors[4]]}
			{...border}
			{...arc}
			arcLinkLabelsColor={arcLinkLabelsColor}
			arcLabelsTextColor={arcLabelsTextColor}
			legends={localLengends}
			tooltip={({datum}) => tooltip(datum.label, datum.value, total(data), datum.color)}
			{...pieProps}
			enableArcLabels={false}
			innerRadius={0.7}
		/>)
};

export default LifesAndCPT
