import { PlansProvider as Provider } from "hooks/plans"
import { FilterProvider } from "hooks/useFilter"
import { ManagerQueryProvider } from "services/manager"

const PlansProvider = ({ children }) => {
    return (
        <FilterProvider>
            <ManagerQueryProvider>
                <Provider>
                    {children}
                </Provider>
            </ManagerQueryProvider>
        </FilterProvider>
    )
}

export default PlansProvider
