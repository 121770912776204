import React, { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";

import { usePaginateCampaignReceivers } from "hooks/whatsappDisclosures";
import AsyncTable from "components/Tables/AsyncTable";
import { useWhatsappDisclosures } from "hooks/whatsappDisclosures";
import { useState } from "react";
import EditCampaignReceiverModal from "../../../views/WhatsappDisclosures/ViewWhatsappDisclosure/Components/EditCampaignReceiverModal";
import queryParamsPage from "functions/queryParamsPage";
import { useLocation } from "react-router-dom";

const DisclosureCampaignReceiversTable = ({ id, status }) => {
  const location = useLocation()
  const { page: queryPage, size: querySize } = queryParamsPage(location)
  const columns = [
    {
      Header: "Nome do beneficiário",
      accessor: "receiver.beneficiary.name",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "Telefone",
      accessor: "receiver.phone",
      Cell: (props) => <div>{props.value}</div>,
    },
  ];

  const { rows, total, perPage, currentPage } = useWhatsappDisclosures();
  const [editModal, setEditModal] = useState(false);
  const [activeCampaignReceiver, setActiveCampaignReceiver] = useState(null);

  const [
    paginateCampaignReceivers,
    { loading },
  ] = usePaginateCampaignReceivers();

  const fetchData = ({ pageSize, pageIndex }) => {
    if (pageSize !== perPage) pageIndex = 1;

    paginateCampaignReceivers({
      variables: {
        whatsapp_disclosure_id: id,
        status: status,
        first: pageSize || querySize || 30,
        page: pageIndex || queryPage || 1,
      },
    });
  };

  const customActions = [
    {
      label: "Editar status",
      icon: "edit",
      callback: (id, row) => {
        setActiveCampaignReceiver(row);
        setEditModal(true);
      },
    },
  ];

  return (
    <>
      <EditCampaignReceiverModal
        receiver={activeCampaignReceiver}
        open={editModal}
        setEditModal={setEditModal}
      />

      <AsyncTable
        isLoaded={!loading}
        fetchData={fetchData}
        data={!loading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        size={perPage}
        asyncPage={currentPage}
        baseRoute="whatsapp"
        noView
        noEdit
        noDelete
        customActions={customActions}
      />
    </>
  );
};

export default DisclosureCampaignReceiversTable;
