import React from "react";

import { Box, Flex, Grid, Skeleton, Text } from "@chakra-ui/react";

import BeneficiaryAssestmentTable from "./BeneficiaryAssessmentTable";
import Icon from "components/FontAwesomeIcons/Icon";
import { useBeneficiaries } from "hooks/beneficiaries";

const BeneficiaryAssessment = () => {
    const { beneficiary } = useBeneficiaries();
    const assessment = beneficiary?.lastAccompaniment[0] ?? null;

    return (
        <Flex width="100%" height="100%" direction="column">
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="20px"
                width="100%"
            >
                {
                    !beneficiary ?
                        <Box width="100%">
                            <Flex width="100%" fontSize="15px">
                                <Icon icon="note" margin="25" size="xl" />
                                <Grid
                                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                    templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                                    gap="6px"
                                    fontSize="20px"
                                    width="100%"
                                >
                                    <Skeleton isLoaded={beneficiary} height="27px" />
                                    <Skeleton isLoaded={beneficiary} height="30px" />
                                </Grid>
                            </Flex>
                        </Box>
                        :
                        !assessment ?
                            <Text fontSize="lg" fontWeight="bold">
                                Formulário ainda não foi respondido
                            </Text>
                            :
                            <BeneficiaryAssestmentTable assessment={assessment} />
                }
            </Grid>
        </Flex>
    )
}

export default BeneficiaryAssessment
