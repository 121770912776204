import { createContext, useContext, useReducer } from "react";
import { reducers } from "./reducers";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
    GET_BENEFICIARIES_PAGINATE
} from "graphql/beneficiaries";

import { cancelBeneficiary, createBeneficiary, createLifePlan, createPhoneContacts, paginateBeneficiaries, removeLifePlan, updateBeneficiary, viewBeneficiary } from "./beneficiaryActionCreators";
import { CREATE_BENEFICIARY, GET_BENEFICIARY, UPLOAD_FILE_CANCEL_BENEFICIARY, UPDATE_PROPOSAL, CANCEL_BENEFICIARY, CREATE_LIFE_PLAN, DELETE_LIFE_PLAN, UPDATE_BENEFICIARY, PENDING_EVENTS_BENEFICIARY } from "graphql/beneficiaries";

const BeneficiaryStateContext = createContext()
const BeneficiaryDispatchContext = createContext()

const initialState = {
    beneficiary: {},
    rows: [],
    first: 30,
    page: 1,
    orderBy: "created_at",
}

const BeneficiaryProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, initialState)

    return (
        <BeneficiaryStateContext.Provider value={{ ...state }}>
            <BeneficiaryDispatchContext.Provider value={dispatch}>{children}</BeneficiaryDispatchContext.Provider>
        </BeneficiaryStateContext.Provider>
    )
}

const useBeneficiaries = () => {
    const context = useContext(BeneficiaryStateContext)

    if (!context)
        throw new Error('useBeneficiaries must be used within an BeneficiaryProvider')

    return context
}

const usePaginateBeneficiaries = () => {
    const dispatch = useContext(BeneficiaryDispatchContext)

    return useLazyQuery(GET_BENEFICIARIES_PAGINATE, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            window.history.replaceState(
                null,
                "",
                `./gestao-vidas?page=${data.beneficiariesPaginate.paginatorInfo.currentPage}&size=${data.beneficiariesPaginate.paginatorInfo.perPage}`
            );
            paginateBeneficiaries(data.beneficiariesPaginate, dispatch)
        }
    });
}

const useCreateBeneficiary = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const { onSuccess, onError } = options;

    return useMutation(CREATE_BENEFICIARY, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data);
            createBeneficiary(data.createBeneficiary, dispatch)
        },
        onError: (error) => {
            if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation);
        },
    });
};

const useUploadCancellationExcel = (options) => {
    return useMutation(UPLOAD_FILE_CANCEL_BENEFICIARY, options);
};

const useGetBeneficiary = (id) => {
    const dispatch = useContext(BeneficiaryDispatchContext)

    return useQuery(
        GET_BENEFICIARY,
        {
            variables: {
                id: id,
            },
            onCompleted: (data) =>
                viewBeneficiary(data.beneficiary, dispatch)
            ,
        },
    );
};

const useUpdateBeneficiary = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const { onSuccess, onError } = options

    return useMutation(UPDATE_PROPOSAL, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess()
            const beneficiary = data.updateProposal.beneficiaries[0];
            updateBeneficiary(beneficiary, dispatch)
        },
        onError: (error) => {
            if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation)
        },
    })
}

const usePendingEventsBeneficiary = (id) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    return useLazyQuery(PENDING_EVENTS_BENEFICIARY, {
        fetchPolicy: "no-cache",
        variables: {
            id
        },
        onCompleted: (data) => {
            dispatch({ type: ACTION_TYPES.PENDING_EVENTS_BENEFICIARY, data: data.beneficiary })
        },
    })
}

const useCancelBeneficiary = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const { onSuccess } = options

    return useMutation(CANCEL_BENEFICIARY, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data.cancelBeneficiary)
            cancelBeneficiary(data.cancelBeneficiary, dispatch)
        },
    })
}

const useCreateLifePlan = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const { onSuccess } = options

    return useMutation(CREATE_LIFE_PLAN, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data.createLifePlan)
            createLifePlan(data.createLifePlan, dispatch)
        },
    })
}

const useRemoveLifePlan = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const { onSuccess } = options

    return useMutation(DELETE_LIFE_PLAN, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data.deleteLifePlan)
            removeLifePlan(data.deleteLifePlan.id, dispatch)
        },
    })
}

const useCreateContactsPhoneMade = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const { onSuccess } = options

    return useMutation(UPDATE_BENEFICIARY, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess()
            createPhoneContacts(data.updateBeneficiary.contactsPhoneMade, dispatch)
        },
    })
}

export {
    BeneficiaryProvider,
    useBeneficiaries,
    usePaginateBeneficiaries,
    useCreateBeneficiary,
    useGetBeneficiary,
    useUploadCancellationExcel,
    useUpdateBeneficiary,
    usePendingEventsBeneficiary,
    useCancelBeneficiary,
    useCreateLifePlan,
    useRemoveLifePlan,
    useCreateContactsPhoneMade
}
