

// Chakra imports
import {
  Button,
  Flex,
  Progress,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";

const ItemLoad = ({label, percentage, color}) => (
  <Flex direction="column">
    <Flex justify="space-between" mb="8px">
      <Text fontSize="md" color="gray.400" fontWeight="500">
        {label}
      </Text>
      <Text fontSize="md" color="gray.400" fontWeight="500">
        {percentage || 0}%
      </Text>
    </Flex>
    <Progress
      colorScheme={color}
      size="sm"
      value={percentage || 0}
      borderRadius="15px"
    ></Progress>
  </Flex>
)

const percentageCalc = (mount, total) => total == 0 ? 0 : (mount * 100) / total

const Statistics = ({loading = false, statistics = {}}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const done = statistics?.done || 0;
  const reschedules = statistics?.reschedules || 0;
  const missing = statistics?.missing || 0;

  const items = [
      {
        label: "Comparecimentos", 
        percentage: percentageCalc(done || 0 , statistics.total).toFixed(2),
        color: "green"
      },
      {
        label: "Reagendamentos", 
        percentage: percentageCalc(reschedules || 0 , statistics.total).toFixed(2),
        color: "blue"
      },
      {
        label: "Não Comparecimento", 
        percentage: percentageCalc(missing || 0 , statistics.total).toFixed(2),
        color: "red"
      },
  ];
  
  return (
    <Card>
      <CardHeader mb="24px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Resumo
        </Text>
      </CardHeader>
      <CardBody height="100%">
        <Flex direction="column" w="100%" height="100%">
          <Stack direction="column" spacing="28px" w="100%" height="100%" mb="40px">
            {
              loading ?
              <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                <Spinner />
              </Flex>
              : items.map(st => <ItemLoad color={st.color} percentage={st.percentage} label={st.label}/>)
            }
          </Stack>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default Statistics