import { useEffect, useState } from "react";

import { Box } from "@chakra-ui/react";

import dateFormat from "functions/dateFormat";
import { roleSuper } from "variables/roles";
import AsyncTable from "components/Tables/AsyncTable";
import Icon from "components/FontAwesomeIcons/Icon";
import {
  useCommitments,
  useMissedCommitments,
} from "hooks/commitmentsManagement";
import { Link } from "react-router-dom";
import CommitmentsAudit from "./CommitmentsAudit";
import { useAuth } from "hooks/useAuth";
import { differenceInYears } from "date-fns";

import { findRiskDS } from "functions/getStratification";
import { findRiskPA } from "functions/getStratification";

const hasNote = (notes, event = "Declaração de Saúde") => {
  if (!notes || notes?.length === 0) return false;
  let cNotes = [...notes];
  const eNotes = cNotes?.filter(
    (nt) => nt?.title === `Não comparecimento - ${event}`
  );
  return eNotes[eNotes?.length - 1];
};

const hasAwaitingSchedule = (form, as) => {
  if (!!form) {
    if (form === 'Acompanhamento') {
      if (!!as) {
        return as;
      } else {
        return form + ' 1';
      }
    }
    return form;
  }
  return 'Declaração de Saúde';
};

const MissingScheduleTable = ({
  plan = null,
  year = null,
  month = null,
  professional = null,
  assessment = null,
  riskDS = null,
  riskPA = null,
  age = null,
  handleNoteModal,
  handleRegisterNoteModal,
  handleEventModal,
}) => {
  const { user } = useAuth();

  const handleModalOpen = (props) => {
    handleNoteModal(
      props?.row?.original?.name,
      hasNote(
        props?.value,
        props?.row?.original?.eventLastExpired?.assessment?.assessmentType?.name
      )?.text
    );
  };

  const [auditId, setAuditId] = useState(null);
  const [open, setOpen] = useState(false);

  const columns = [
    {
      Header: "Atividade Agendada",
      accessor: "eventLastExpired.assessment.assessmentType.name",
      Cell: (props) => <div>{hasAwaitingSchedule(props?.value, props?.row?.original?.eventLastExpired?.awaiting_schedule?.awaiting_schedule)}</div>,
    },
    {
      Header: "Agendado para",
      accessor: "eventLastExpired.scheduled_to",
      Cell: (props) => <div>{dateFormat(props?.value, "dd/MM/y HH:mm")}</div>,
    },
    {
      Header: "Beneficiario",
      accessor: "name",
      Cell: (props) => (
        <Link to={`../administracao/gestao-vidas/${props?.row?.original?.id}`}>
          {props?.value}
        </Link>
      ),
    },
    {
      Header: "Celular",
      accessor: "phone",
    },
    {
      Header: "Plano",
      accessor: "plan[0].name",
    },
    {
      Header: "Responsável",
      accessor: "eventLastExpired.professional.user.name",
    },
    {
      Header: "Tipo de Responsável",
      accessor: "eventLastExpired.professional.user.role",
      Cell: (props) => (
        <div>{props?.value?.replace("_", " ").replace("_", " ") || null}</div>
      ),
    },
    {
      Header: "Idade",
      accessor: "birthdate",
      Cell: (props) => (
        <div>
          {props?.value
            ? differenceInYears(new Date(), new Date(props.value))
            : null}
        </div>
      ),
    },
    {
      Header: "Estratificação DS",
      accessor: "stratification_ds",
      Cell: (props) => (
        <div>{findRiskDS(props?.row?.original?.assessments)}</div>
      ),
    },
    {
      Header: "Estratificação PA",
      accessor: "stratification_pa",
      Cell: (props) => (
        <div>{findRiskPA(props?.row?.original?.assessments)}</div>
      ),
    },
    {
      Header: "Notas",
      accessor: "notes",
      Cell: (props) => {
        return props?.row?.original?.eventLastExpired &&
          hasNote(
            props.value,
            props?.row?.original?.eventLastExpired?.assessment?.assessmentType
              ?.name
          ) ? (
          <Box
            cursor={"pointer"}
            onClick={() => handleModalOpen(props)}
            _hover={{ color: "notrelife.800" }}
          >
            <Icon icon="info" />
          </Box>
        ) : (
          <div></div>
        );
      },
    },
  ];

  const { commitments, total, perPage, currentPage } = useCommitments();
  const [loadComittments, { loading }] = useMissedCommitments();

  const fetchData = ({ pageSize, pageIndex, search }) => {
    const variables = {
      first: pageSize,
      page: pageIndex,
      input: {
        plan: plan,
        year: year,
        month: month,
        professional: professional,
        assessmentType: assessment,
        name: search || "",
        riskDS: riskDS,
        riskPA: riskPA,
        age: age,
      },
    };
    loadComittments({ variables: variables });
  };

  useEffect(() => {
    const variables = {
      first: 30,
      page: 1,
      input: {
        plan: plan,
        year: year,
        month: month,
        professional: professional,
        assessmentType: assessment,
        riskDS: riskDS,
        riskPA: riskPA,
        age: age,
      },
    };
    loadComittments({ variables: variables });
  }, [plan, year, month, professional, assessment, riskDS, riskPA, age]);

  const handleAudit = (id) => {
    setAuditId(id);
    setOpen(true);
  };

  const customActions = [
    {
      label: "Registro de Atividades",
      icon: "documents",
      visible: () => {
        return user?.role === roleSuper;
      },
      callback: (id) => {
        handleAudit(id);
      },
    },
    {
      label: "Adicionar nota",
      icon: "note",
      callback: (id) => {
        const note =
          hasNote(commitments?.find((row) => row?.id == id)?.notes) || null;
        handleRegisterNoteModal(
          commitments?.find((row) => row?.id == id)?.id || " -- ",
          commitments?.find((row) => row?.id == id)?.name || " -- ",
          `Não comparecimento - ${
            commitments?.find((row) => row?.id == id)?.eventLastExpired
              ?.assessment?.assessmentType?.name || "Declaração de Saúde"
          }`,
          note?.text
        );
      },
    },
    {
      label: "Reagendar",
      icon: "calendar",
      callback: (id) => {
        const values = {
          table: "missing",
          event_id: commitments?.find((row) => row?.id == id)?.eventLastExpired
            ?.id,
          name: commitments?.find((row) => row?.id == id)?.name || " -- ",
          type: "reschedule",
          event:
            commitments?.find((row) => row?.id == id)?.eventLastExpired
              ?.assessment?.assessmentType?.name || "Declaração de Saúde",
        };
        handleEventModal(values);
      },
    },
  ];

  return (
    <>
      <AsyncTable
        searchByName
        noView
        noEdit
        customActions={customActions}
        isLoaded={!loading}
        fetchData={fetchData}
        data={
          !loading
            ? commitments?.sort((a, b) => {
                return new Date(a?.scheduled_to) - new Date(b?.scheduled_to);
              })
            : [{}, {}, {}, {}]
        }
        columns={columns}
        size={perPage}
        total={total || 0}
        asyncPage={currentPage}
        baseRoute=""
      />
      {open && (
        <CommitmentsAudit
          id={auditId}
          open={open}
          close={() => setOpen(false)}
          type="patient"
        />
      )}
    </>
  );
};

export default MissingScheduleTable;
