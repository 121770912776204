import React, { useState, useEffect } from "react";

import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { default as ReactSelect } from "react-select";

import {
	Badge,
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Select,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Tr,
	useColorMode,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";

import dateFormat from "functions/dateFormat";
import { useBeneficiaries, useCreateLifePlan } from "hooks/beneficiaries";

import { msDarkTheme, msLightTheme } from "variables/multiselectStyle";
import Icon from "components/FontAwesomeIcons/Icon";
import BeneficiaryLifePlanDeleteModal from "./BeneficiaryLifePlanDeleteModal";

const planNames = [
	{
		value: 'assistencial_idoso',
		label: 'Linha Assistencial do Idoso'
	},
	{
		value: 'case',
		label: 'Case'
	},
	{
		value: 'qualivida',
		label: 'Qualivida (PAI)'
	},
	{
		value: 'nao',
		label: 'Ainda não'
	},
]

const getRiskColor = (estrat) => {
	if (!estrat) return null
	const risk = estrat.toLowerCase()
	switch (risk) {
		case 'risco baixo':
			return 'green.500'
		case 'risco médio baixo':
			return 'orange.600'
		case 'risco médio alto':
			return 'orange.600'
		case 'risco alto':
			return 'red.600'
		default:
			return 'green.500'
	}
}

const getMedicLabel = (estrat) => {
	if (!estrat) return null
	const risk = estrat.toLowerCase()
	switch (risk) {
		case 'risco baixo':
			return '2x'
		case 'risco médio baixo':
			return '3x'
		case 'risco médio alto':
			return '4x'
		case 'risco alto':
			return '6x'
		default:
			return '2x'
	}
}

const getGroupLabel = (estrat) => {
	if (!estrat) return null
	const risk = estrat.toLowerCase()
	switch (risk) {
		case 'risco baixo':
			return 'Teve 4 ou+ participações nos grupos de caminhada/ ou aulas de tai chi chuan e/ou realiza as atividades físicas propostas no espaço 50+ com regularidade, ou atividades físicas externas ao programa?'
		case 'risco médio baixo':
			return 'Teve 3 ou+ participações nos grupos de caminhada/ ou aulas de tai chi chuan e/ou realiza as atividades físicas propostas no espaço 50+ com regularidade?'
		case 'risco médio alto':
			return 'Teve 2 ou+ participações nos grupos de caminhada/ ou aulas de tai chi chuan e/ou realiza as atividades físicas propostas no espaço 50+ com regularidade'
		case 'risco alto':
			return 'Teve participação em pelo menos um dos grupos de caminhada'
		default:
			return 'Teve 4 ou+ participações nos grupos de caminhada/ ou aulas de tai chi chuan e/ou realiza as atividades físicas propostas no espaço 50+ com regularidade, ou atividades físicas externas ao programa?'
	}
}

const getWorkshopLabel = (estrat) => {
	if (!estrat) return null
	const risk = estrat.toLowerCase()
	if (risk == 'risco baixo') return 'Participa de pelo menos uma Oficina de Educação em Saúde?'
	else return 'Participa de uma ou mais Oficina de Educação em Saúde?'
}

const getPlanLabels = (plan) => {
	const plans = JSON.parse(plan);
	let response = '';
	if (plans.assistencial_idoso) response += 'Linha Assistencial do Idoso, ';
	if (plans.case) response += 'Case, ';
	if (plans.qualivida) response += 'Qualivida (PAI), ';

	return response;
}

const getScore = (stringScore, value) => {
	if (!stringScore) return 0;
	const score = JSON.parse(stringScore)

	return score[value]
}

const getTotalScore = (stringScore, value) => {
	if (!stringScore) return null;
	const score = JSON.parse(stringScore)
	const total = score[value]
	if (total < 40) return 'Não atingiu as metas'
	if (total >= 40 && total < 80) return 'Atingiu parcialmente as metas'
	if (total >= 80 && total < 100) return 'Atingiu as metas'
	if (total == 100) return 'Superou as metas acordadas'
	return null
}

const schema = yup.object().shape({
	// plans: yup
	//   .string()
	//   .required("Seleciona uma opção")
})

const BeneficiaryLifePlan = () => {
	const methods = useForm();
	const toast = useToast();
	const { colorMode } = useColorMode();

	const [estrat, setEstrat] = useState(false);
	const [modal, setModal] = useState(false);
	const [toDelete, setToDelete] = useState(null);

	const { beneficiary } = useBeneficiaries();

	const lifePlan = beneficiary?.lifePlan;
	const healthStatement = beneficiary?.healthStatement;

	useEffect(() => {
		if (healthStatement) {
			const result = healthStatement.metrics.find(mt => mt.tag == 'estratificacao_ds')?.result || null
			setEstrat(result);
		}
	}, [healthStatement])

	const { control, register, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm({
		mode: "onBlur",
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const [createLifePlan, { loading: loadingCreate }] = useCreateLifePlan({
		onSuccess: () => {
			reset()
			toast({
				title: 'Plano adicionado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			})
		},
		onError: (err) => {
			toast({
				title: "Ocorreu um erro",
				status: "error",
				duration: 5000,
				isClosable: true,
			})
		},
	})

	const handlePlanChange = (values) => {
		const options = {
			'assistencial_idoso': !!values.find(vl => vl.value == 'assistencial_idoso'),
			'case': !!values.find(vl => vl.value == 'case'),
			'qualivida': !!values.find(vl => vl.value == 'qualivida'),
			'nao': !!values.find(vl => vl.value == 'nao'),
		}
		setValue('plans', options)
	}

	const onSubmit = () => {
		const options = getValues('options')
		const obOptions = {
			'medico_gestor': !!options.find(vl => vl == 'medico_gestor'),
			'oficina_educacao': !!options.find(vl => vl == 'oficina_educacao'),
			'participacao_grupo': !!options.find(vl => vl == 'participacao_grupo'),
			'plano_vida': !!options.find(vl => vl == 'plano_vida')
		}

		const variables = {
			input: {
				plans: JSON.stringify(getValues('plans')),
				options: JSON.stringify(obOptions),
				beneficiary: {
					connect: beneficiary.id
				}
			}
		}

		createLifePlan({ variables: variables });
	}

	const handleDelete = (id) => {
		setToDelete(id);
		setModal(true);
	}

	const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
	const textColor = useColorModeValue("gray.700", "white");
	const bgColor = useColorModeValue('gray.200', 'gray.500');

	return (
		<Flex w="100%" justifyContent="center" alignItems="flex-start" direction="column" mb="15px">
			{
				!healthStatement ?
					<Flex mb="24px">
						<Text size="md">
							Não há informações para serem exibidas
						</Text>
					</Flex>
					:
					<>
						<Flex mb="24px" direction="column">
							<Flex width="100%" alignItems="center" mb="24px">
								<Text fontSize="lg" mr="6px">
									Classificação de Risco:
								</Text>
								<Badge
									backgroundColor={getRiskColor(estrat)}
									color="white"
									size="lg"
								>
									{estrat}
								</Badge>
							</Flex>
							<FormProvider {...methods}>
								<form onSubmit={(handleSubmit(onSubmit))}>
									<Flex direction="column" w="100%">
										<Grid
											templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
											gap="24px"
										>
											<FormControl isInvalid={errors.plan} >
												<FormLabel
													color={textColor}
													fontWeight="bold"
													fontSize="xs"
												>
													Participa de algum plano de saúde dentro da operadora?
												</FormLabel>
												<Controller
													name="plans"
													{...register("plans")}
													control={control}
													render={({ field: { onChange, onBlur, value, ref } }) => (
														<ReactSelect
															name="plans"
															options={planNames}
															isMulti
															isClearable
															backspaceRemovesValue={true}
															placeholder="Selecione"
															onBlur={onBlur}
															onChange={(op) => handlePlanChange(op)}
															selected={value}
															styles={
																colorMode === "light" ? msLightTheme : msDarkTheme
															}
														/>
													)}
												/>
												{errors.plan && <FormErrorMessage>{errors.plan.message}</FormErrorMessage>}
											</FormControl>
										</Grid>
										<Grid
											templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
											templateRows={{ sm: "1fr", md: "repeat(2, 1fr)" }}
											gap="24px"
											mt="24px"
										>
											<Checkbox size='lg' alignItems="center" colorScheme='orange' value={'medico_gestor'} {...register('options[]')} >Passou com médico gestor {getMedicLabel(estrat)} ao ano?</Checkbox>
											<Checkbox size='lg' alignItems="center" colorScheme='orange' value={'participacao_grupo'} {...register('options[]')} >{getGroupLabel(estrat)}</Checkbox>
											<Checkbox size='lg' alignItems="center" colorScheme='orange' value={'oficina_educacao'} {...register('options[]')} >{getWorkshopLabel(estrat)}</Checkbox>
											<Checkbox size='lg' alignItems="center" colorScheme='orange' value={'plano_vida'} {...register('options[]')} >Conforme plano de vida passou nas consultas?</Checkbox>
										</Grid>
									</Flex>
									<Flex mt="24px" align={"center"} justify={"flex-end"}>
										<Button
											type='submit'
											isLoading={loadingCreate}
											isDisabled={loadingCreate}
											variant="no-hover"
											bg={buttonBg}
											alignSelf="flex-end"
											w="100px"
											h="35px"
										>
											<Text fontSize="xs" color="#fff" fontWeight="bold">
												Adicionar
											</Text>
										</Button>
									</Flex>
								</form>
							</FormProvider>
						</Flex>
						<Flex direction="column" width="100%">
							{
								!lifePlan || lifePlan.length == 0 ?
									<Text fontSize="lg" fontWeight="bold">
										Não há histórico para ser exibido
									</Text>
									:
									<>
										<Text fontSize="lg" fontWeight="bold" mb="24px">
											Histórico de Avaliação das Metas
										</Text>
										{
											lifePlan.map((plan, x) => (
												<Box w="100%" mb={x + 1 < lifePlan.length ? `24px` : ''}>
													<Text fontSize="md" fontWeight="bold" mb="24px">
														Avaliado em {plan && dateFormat(plan.created_at)}
													</Text>
													<Flex w="100%" justifyContent="space-between" mb="24px">
														<Text fontSize="md" fontWeight="bold">
															Escala de Avaliação: {getTotalScore(plan.score, 'total')}
														</Text>
														<Box>
															<Text
																cursor="pointer"
																color={textColor}
																_hover={{ color: "red.600" }}
																onClick={() => handleDelete(plan.id)}
															>
																<Icon icon="delete" />
															</Text>
														</Box>
													</Flex>
													<TableContainer width="100%" border="1px" borderColor={bgColor} borderRadius="8px">
														<Table variant='simple' size='md'>
															<Tbody>
																<Tr>
																	<Td>Participa de algum Programa de Saúde dentro da Operadora?</Td>
																	<Td minW="328px">{plan.plans && getPlanLabels(plan.plans)}</Td>
																</Tr>
																<Tr>
																	<Td>Adesão ao Médico Generalista</Td>
																	<Td>{getScore(plan.score, 'medico')}%</Td>
																</Tr>
																<Tr>
																	<Td>Adesão a Atividades Físicas</Td>
																	<Td>{getScore(plan.score, 'atividades')}%</Td>
																</Tr>
																<Tr>
																	<Td>Adesão as Oficinas de Educação em Saúde</Td>
																	<Td>{getScore(plan.score, 'oficinas')}%</Td>
																</Tr>
																<Tr>
																	<Td>Adesão as Consultas com Equipe Interdiscuplinar</Td>
																	<Td>{getScore(plan.score, 'consultas')}%</Td>
																</Tr>
																<Tr bg={bgColor}>
																	<Td >Total da Pontuação</Td>
																	<Td>{getScore(plan.score, 'total')}%</Td>
																</Tr>
															</Tbody>
														</Table>
													</TableContainer>
												</Box>
											))
										}
									</>
							}
						</Flex>
					</>
			}
			<BeneficiaryLifePlanDeleteModal
				open={modal}
				setModal={setModal}
				id={toDelete}
			/>
		</Flex>
	)
}

export default BeneficiaryLifePlan