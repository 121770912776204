import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Controller, useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Skeleton,
  Spinner,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useCreateCustomHour } from "hooks/customHours";
import { useGetProfessionalsByRegional } from "hooks/calendar";
import { useGetRegionals } from "hooks/calendar";
import { isBefore, isValid } from "date-fns";
import ReactDatePicker from "react-datepicker";
import {roleConsu, roleEnfer, roleSuper} from "../../../variables/roles";

const schema = yup.object().shape({
  regional: yup
    .string()
    .required("O campo Regional é obrigatório"),
  professional: yup
    .string()
    .required("O campo Profissional é obrigatório"),
  type: yup
    .string()
    .required("O campo Tipo é obrigatório"),
  event_type: yup
    .string()
    .required("O campo Tipo de Agendamento é obrigatório"),
  start: yup
    .date()
    .required("O campo Horário de Início é obrigatório"),
  end: yup
    .date()
    .when('start', (start, schema) => {
      return schema.test({
        test: end => (isBefore(new Date(`${start}`), new Date(`${end}`))),
        message: "O Horário de Término não pode ser anterior ao Horário de Início"
      })
    })
    .required("O campo Horário de Término é obrigatório"),
  start_time: yup
    .string()
    .when("event_type", {
      is: (field) => field == "presencial",
      then: yup.string()
        .test('time-valid', "O Horário de Início informado não é válido", function(field){
          const date = new Date(`2022-04-08 ${field}`)
          
          return isValid(date);
        })
        .required("Campo Horário de Início é obrigatório")
    }),
  end_time: yup
    .string()
    .when("type", {
      is: (field) => field == "presencial",
      then: yup.string()
        .test('time-valid', "O Horário de Término informado não é válido", function(field){
          const date = new Date(`2022-04-08 ${field}`)
          
          return isValid(date);
        })
        .when('start_time', (start_time, schema) => {
          return schema.test({
            test: end_time => (isBefore(new Date(`2022-04-08 ${start_time}`), new Date(`2022-04-08 ${end_time}`))),
            message: "O Horário de Término não pode ser anterior ao Horário de Início"
          })
        })
        .required("Campo Horário de Término é obrigatório")
    }),
})

const CreateCustomHour = () => {
  const methods = useForm();
  const toast = useToast();
  const history = useHistory();

  const { data, loading: loadingRegionals } = useGetRegionals();
  const [loadProfessinals, { loading: loadingProfessionals }] = useGetProfessionalsByRegional()
  const [professionals, setProfessionals] = useState(null)

  const [noParseStart, setNoParseStart] = useState(null);
  const [noParseEnd, setNoParseEnd] = useState(null);

  const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, watch } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const wType = watch('event_type', null)

  const [createCustomHour, { loading, error } ] = useCreateCustomHour({
    onSuccess: (data) => {
      reset()
      toast({
        title: 'Agenda cadastrada com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      history.push(`/sistema/administracao/agenda-ausencia/${data.createCustomHour.id}`);
    },
    onError: (errors) => {
      const errorsArr = errors ? Object.keys(errors) : null;
      if(errorsArr)
          errorsArr.map(err => {
              if(errors[err]) {
                  toast({
                      title: errors[err][0],
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                  })
              }
          })
      else
          toast({
              title: "Ocorreu um erro",
              status: "error",
              duration: 5000,
              isClosable: true,
          })
    },
  })

  useEffect(() => {
      if (noParseStart && noParseStart.replace("_", "").length === 10) {
          const date = noParseStart.split("/");
          setValue("start", `${date[2]}-${date[1]}-${date[0]} 00:00:00`)
      } else setValue("start", null);
  }, [noParseStart])

  useEffect(() => {
      if (noParseEnd && noParseEnd.replace("_", "").length === 10) {
          const date = noParseEnd.split("/");
          setValue("end", `${date[2]}-${date[1]}-${date[0]} 23:59:59`)
      }
  }, [noParseEnd])

  const onSubmit = (data) => {
    const time = getValues('event_type') == 'presencial' ? {
      start_time: getValues('start_time'),
      end_time: getValues('end_time'),
    }
    : {
      turn: getValues('turn'),
      start_time: getValues('turn') == 'manha' ? '08:00' : '13:00',
      end_time: getValues('turn') == 'manha' ? '12:00' : '18:00' 
    }

    const variables = {
      input: {
        type: getValues('type'),
        event_type: getValues('event_type'),
        start: getValues('start'),
        end: getValues('end'),
        ...time,
        regional: {
          connect: getValues("regional")
        },
        professional: {
          connect: getValues("professional")
        },
      }
    }
    createCustomHour({variables: variables});
  }

  const loadProfessionalsByRegional = async (event) => {
    if(!event.currentTarget.value) return null
    const { data } = await loadProfessinals({
      variables: {
        regional_id: event.currentTarget.value,
        accepted_roles: [roleEnfer, roleConsu, roleSuper]
      }
    })
    setProfessionals(data.professionals)
  }

  const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
  const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w={{ md: "100%", lg: "50%" }}>
          <CardHeader mb="40px">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Registrar Agenda por dia
              </Text>
              <Text color="gray.400" fontWeight="normal" fontSize="sm">
                Preencha os campos para realizar o cadastro
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <FormProvider {...methods}>
              <form style={{width: '100%'}} onSubmit={(handleSubmit(onSubmit))}>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                    gap="24px"
                  >
                    <FormControl isInvalid={errors.regional}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Regional
                      </FormLabel>
                      <Skeleton height={"40px"} isLoaded={!loadingRegionals} >
                        <Select 
                          {...register("regional")}
                          onChange={(item) => loadProfessionalsByRegional(item)}
                          placeholder="Selecione uma opção..."
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                          color="gray.400"
                        >
                          { 
                            data && data.regionals.map(reg => (
                              <option key={reg.id} value={reg.id}>{reg.name}</option>
                            ))
                          }
                        </Select>
                      </Skeleton>
                      {errors.regional && <FormErrorMessage>{errors.regional.message}</FormErrorMessage>}
                    </FormControl>
                  </Grid>
                    {
                      loadingProfessionals ?
                      <Flex width="100%" justifyContent="center" mt="24px">
                        <Spinner />
                      </Flex>
                      :
                      professionals &&
                      <>
                        <Grid
                          templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                          gap="24px"
                          mt="24px"
                        >
                            <>
                              <FormControl isInvalid={errors.professional}>
                                <FormLabel
                                  color={textColor}
                                  fontWeight="bold"
                                  fontSize="xs"
                                >
                                  Profissional
                                </FormLabel>
                                <Select 
                                  {...register("professional")}
                                  placeholder="Selecione uma opção..."
                                  focusBorderColor={inputHover}
                                  borderRadius="8px"
                                  fontSize="md"
                                  color="gray.400"
                                >
                                  { 
                                    professionals && professionals.filter(pro => [roleEnfer, roleConsu].includes(pro.user.role)).map(pro => (
                                      <option key={pro.id} value={pro.id}>{pro.user.name}</option>
                                    ))
                                  }
                                </Select>
                                {errors.professional && <FormErrorMessage>{errors.professional.message}</FormErrorMessage>}
                              </FormControl>
                              <FormControl isInvalid={errors.type}>
                                <FormLabel
                                  color={textColor}
                                  fontWeight="bold"
                                  fontSize="xs"
                                >
                                  Tipo
                                </FormLabel>
                                <Select 
                                  {...register("type")}
                                  placeholder="Selecione uma opção..."
                                  focusBorderColor={inputHover}
                                  borderRadius="8px"
                                  fontSize="md"
                                  color="gray.400"
                                >
                                  <option value="included">Liberar data</option>
                                  <option value="excluded">Bloquear data</option>
                                </Select>
                                {errors.type && <FormErrorMessage>{errors.type.message}</FormErrorMessage>}
                              </FormControl>
                              <FormControl isInvalid={errors.event_type}>
                                <FormLabel
                                  color={textColor}
                                  fontWeight="bold"
                                  fontSize="xs"
                                >
                                  Tipo de agenda
                                </FormLabel>
                                <Select 
                                  {...register("event_type")}
                                  placeholder="Selecione uma opção..."
                                  focusBorderColor={inputHover}
                                  borderRadius="8px"
                                  fontSize="md"
                                  color="gray.400"
                                >
                                  <option value="presencial">Presencial</option>
                                  <option value="tele">Tele Atendimento</option>
                                </Select>
                                {errors.event_type && <FormErrorMessage>{errors.event_type.message}</FormErrorMessage>}
                              </FormControl>
                              <FormControl isInvalid={errors.start}>
                                <FormLabel
                                  color={textColor}
                                  fontWeight="bold"
                                  fontSize="xs"
                                >
                                  Data de Início
                                </FormLabel>
                                <Input
                                    as={InputMask}
                                    mask="99/99/9999"
                                    focusBorderColor={inputHover}
                                    borderRadius="8px"
                                    fontSize="md"
                                    onChange={({target}) => setNoParseStart(target.value)}
                                />
                                {errors.start && <FormErrorMessage>{errors.start.message}</FormErrorMessage>}
                              </FormControl>
                              <FormControl isInvalid={errors.end}>
                                <FormLabel
                                  color={textColor}
                                  fontWeight="bold"
                                  fontSize="xs"
                                >
                                  Data de Término
                                </FormLabel>
                                  <Input
                                      as={InputMask}
                                      mask="99/99/9999"
                                      focusBorderColor={inputHover}
                                      borderRadius="8px"
                                      fontSize="md"
                                      onChange={({target}) => setNoParseEnd(target.value)}
                                  />
                                {errors.end && <FormErrorMessage>{errors.end.message}</FormErrorMessage>}
                              </FormControl>
                            {
                              wType == 'presencial' ?
                              <>
                                <FormControl isInvalid={errors.start_time}>
                                  <FormLabel
                                    color={textColor}
                                    fontWeight="bold"
                                    fontSize="xs"
                                  >
                                    Horário de Início
                                  </FormLabel>
                                  <Input
                                    {...register('start_time')} 
                                    as={InputMask}
                                    mask="99:99"
                                    placeholder="hh:mm"
                                    focusBorderColor={inputHover}
                                    borderRadius="8px"
                                    fontSize="md"
                                  />
                                  {errors.start_time && <FormErrorMessage>{errors.start_time.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={errors.end_time}>
                                  <FormLabel
                                    color={textColor}
                                    fontWeight="bold"
                                    fontSize="xs"
                                  >
                                    Horário de Término
                                  </FormLabel>
                                    <Input
                                      {...register('end_time')} 
                                      as={InputMask}
                                      mask="99:99"
                                      placeholder="hh:mm"
                                      focusBorderColor={inputHover}
                                      borderRadius="8px"
                                      fontSize="md"
                                    />
                                  {errors.end_time && <FormErrorMessage>{errors.end_time.message}</FormErrorMessage>}
                                </FormControl>
                              </>
                              : wType == 'tele' ?
                              <>
                                <FormControl isInvalid={errors.turn}>
                                  <FormLabel
                                    color={textColor}
                                    fontWeight="bold"
                                    fontSize="xs"
                                  >
                                    Turno
                                  </FormLabel>
                                  <Select 
                                    {...register("turn")}
                                    placeholder="Selecione uma opção..."
                                    defaultValue={0}
                                    focusBorderColor={inputHover}
                                    borderRadius="8px"
                                    fontSize="md"
                                    color="gray.400"
                                    
                                  >
                                    <option value={"manha"}>Manhã</option>
                                    <option value={"tarde"}>Tarde</option>
                                  </Select>
                                  {errors.turn && <FormErrorMessage>{errors.turn.message}</FormErrorMessage>}
                                </FormControl>
                              </>
                              : <></>
                            }
                            </>
                        </Grid>
                      </>
                    }
                  <Flex mt="24px" align={"center"} justify={"flex-end"}>
                    <NavLink to="/sistema/administracao/agenda">
                      <Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
                        Cancelar
                      </Text>
                    </NavLink>
                    <Button
                      isLoading={loading}
                      type='submit'
                      variant="no-hover"
                      bg={buttonBg}
                      alignSelf="flex-end"
                      w="100px"
                      h="35px"
                    >
                      <Text fontSize="xs" color="#fff" fontWeight="bold">
                        Criar
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </Flex>
  );
}

export default CreateCustomHour;
