

import { getToken } from "functions/token";
import { CptQueryProvider } from "services/clients/cpt"
import { CptHookProvider } from "../hooks/cpt"

export const CptHookQueryProvider = ({ children }) => {
    const token = getToken();
    return (
        <CptQueryProvider token={token} >
            <CptHookProvider>{children}</CptHookProvider>
        </CptQueryProvider>
    )
}