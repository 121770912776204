import { CompaniesProvider as Provider } from "hooks/companies";
import { ManagerQueryProvider } from "../services/manager";

const CompaniesProvider = ({ children }) => {
  return (
    <ManagerQueryProvider>
      <Provider>{children}</Provider>
    </ManagerQueryProvider>
  );
};

export default CompaniesProvider;
