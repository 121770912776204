import { Box, Text } from '@chakra-ui/react'

import BasicTable from 'components/Tables/BasicTable'

const columns = [
    {
        Header: "Regiões",
        accessor: "name",
    },
    {
        Header: "PA Acumulado carteira",
        accessor: "value",
        Cell: props => <Text>{props.value}%</Text>
    },
    {
        Header: "Metas",
        accessor: "goal",
    },
    {
        Header: "GAP",
        accessor: "gap",
        Cell: props => <Text fontWeight="bold" color={props.value < 0 ? 'red.500' : props.value > 0 ? 'green.500' : 'black'}>{props.value}%</Text>
    },
]

const buildData = (data, goal) => {
    const regionals = data.map(vl => {
        const done = vl.done
        const total = vl.done + vl.awaiting
        const ratio = ((done / total) * 100).toFixed(0);
        return {
            name: vl.name,
            value: Number(ratio),
            goal: `${goal}%`,
            gap: Number(`${ratio - goal}`)
        }
    })

    let total = 0;
    let totalGap = 0;
    regionals.map(reg => total += reg.value)
    regionals.map(reg => totalGap += reg.gap)

    const average = {
        name: 'Média Acumulada',
        value: (total / regionals.length).toFixed(0),
        goal: `${goal}%`,
        gap: (totalGap / regionals.length).toFixed(0),
    }

    return [
        ...regionals.sort((a, b) => a.name > b.name ? 1 : -1),
        average
    ]
}

const RegionalsGoal = ({ data = [], goal }) => {
    const rows = buildData(data, goal)

    return (
        <Box w="full" h="auto">
            <BasicTable
                tableData={rows}
                columnsData={columns}
                noView
                noEdit
                noDelete
                noLines
                noOrder
                noPages
                maxSize
            />
        </Box>
    )
}

export default RegionalsGoal