import { gql } from '@apollo/client';

export const AUDIT =  gql`
  query GetAudit($id: ID!) {
    audit(id: $id) {
      date
      user
      event
      updated {
        field
        old
        new
      }
    }
  }
`

export const COMMITMENT_AUDIT =  gql`
  query GetCommitmentAudit($id: ID!, $type: String) {
    commitmentAudit(id: $id, type: $type) {
      date
      user
      event
      updated {
        field
        old
        new
      }
    }
  }
`