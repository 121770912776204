import React from 'react'
import Form from './Form';
import { QueryProvider } from 'providers/queryClient';

export default () => {
	return (
		<QueryProvider>
			<Form />
		</QueryProvider>
	);
}
