import React from "react";
import {padding} from "variables/nivo/bar";
import {useColorMode} from "@chakra-ui/react";
import {monochromeMainColors} from "variables/colors";
import {axisLeft, themeDark, themeLight, tooltip} from "variables/nivo/default";
import {ResponsiveRadialBar} from "@nivo/radial-bar";

const EstablishedCPT = ({data, keys, label}) => {
	data = [
		{
			id: "Declaração de Saúde",
			data: [
				{
					x: "Declaração de Saúde",
					y: data?.find((i) => i?.name === "Declaração de Saúde")?.total || 0,
				},
			],
		},
		{
			id: "Primeiro Acolhimento",
			data: [
				{
					x: "Primeiro Acolhimento",
					y: data?.find((i) => i?.name === "Primeiro Acolhimento")?.total || 0,
				},
			],
		},
		{
			id: "Acompanhamento",
			data: [
				{
					x: "Acompanhamento",
					y: data?.find((i) => i.name === "Acompanhamento")?.total || 0,
				},
			],
		}
	];

	const {colorMode} = useColorMode();

	return (<ResponsiveRadialBar
		data={data}
		keys={[
			"Declaração de Saúde",
			"Primeiro Acolhimento",
			"Acompanhamento"
		]}
		indexBy="id"
		layout="horizontal"
		margin={{top: 30, right: 25, bottom: 30, left: 25}}
		padding={padding}
		cornerRadius={0}
		colors={monochromeMainColors}
		labelSkipWidth={11}
		radialAxisStart={{tickSize: 5, tickPadding: 5, tickRotation: 0}}
		circularAxisOuter={{tickSize: 5, tickPadding: 12, tickRotation: 0}}
		theme={colorMode === "light" ? themeLight : themeDark}
		axisBottom={null}
		axisLeft={axisLeft}
		labelTextColor="#FFF"
		tooltip={({bar}) => tooltip(bar.groupId, bar.value)}
	/>)
};

export default EstablishedCPT
