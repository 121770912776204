import axios from 'axios';
import { JWT_TOKEN_COGNITO } from 'variables/enumLabels';

import { JWT_TOKEN_COGNITO_ID } from "variables/enumLabels";

const API_INSTANCE = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

API_INSTANCE.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('token') || ' ';
API_INSTANCE.defaults.headers.common['Content-Type'] = 'application/json';

API_INSTANCE.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response.status === 401 && !window.location.pathname.includes('campanha') && !window.location.pathname.includes('agendamento')) {
		window.localStorage.removeItem(JWT_TOKEN_COGNITO_ID);
		window.localStorage.removeItem(JWT_TOKEN_COGNITO);
		// window.localStorage.clear()
		location.reload();
	}
	if (error.response.status === 403 && !window.location.pathname.includes('campanha') && !window.location.pathname.includes('agendamento')) {
		window.localStorage.removeItem(JWT_TOKEN_COGNITO_ID);
		window.localStorage.removeItem(JWT_TOKEN_COGNITO);
		// window.localStorage.clear()
		location.reload();
	}

	throw error;
});

API_INSTANCE.interceptors.request.use((config) => {
	config.headers['Authorization'] = "Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
		config.headers['Content-Type'] = 'application/json';

	return config;
})

export const API = API_INSTANCE;
