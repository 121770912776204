import React, {useState} from "react";

import {
    Flex,
    Grid,
    Text,
    Button,
    Box,
    useColorModeValue,
    Spinner,
    useDisclosure,
    Slide,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Heading, Modal, ModalOverlay, ModalBody, useToast
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import RegionalsTable from "./components/RegionalsTable";
import RegionalDetails from "./components/RegionalDetails";
import {useGetAllDataRegional, useRegionals, useCreateRegional} from "../../hooks/regionals";
import ModalCreateRegional from "./components/ModalCreateRegional";
import states from "variables/states";
import cities from "variables/cities";
import { useAuth } from "hooks/useAuth";
import {roleAdmin, roleSuper} from "../../variables/roles";

const DetailCard = ({isOpen, regionalSelected}) => {
    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const buttonColor = useColorModeValue("white", "white");
    const textColor = useColorModeValue("gray.700", "white");

    const getState = (regionalSelected) => {
        if(regionalSelected && regionalSelected.state_id){
            const state = states.find(state => state.id == regionalSelected.state_id)
            return !!state ? state.initials : null
        }
        return null
    }

    const getCity = (regionalSelected) => {
        if(regionalSelected && regionalSelected.city_id){
            const city = cities.find(city => city.id == regionalSelected.city_id)
            return !!city ? city.name : null
        }
        return null
    }

    if (!isOpen) return <></>;
    return (
        <Flex direction={'column'} w={"100%"} style={{gridColumn: "5 / span 6"}}>
        <Card >
            <Box w="100%">
                <Text color={textColor} fontSize="lg" fontWeight="bold">
                    {regionalSelected?.name}
                </Text>
                <Flex>
                    <Text color={textColor} fontSize="md">
                        { getState(regionalSelected) } - { getCity(regionalSelected) }
                    </Text>
                </Flex>
            </Box>
        </Card>
            <RegionalDetails regional={regionalSelected}/>
        </Flex>
    );
}
export default () => {
    const { user } = useAuth();
    const { regionalSelected } = useRegionals()

    const toast = useToast();

    const { isOpen, onToggle } = useDisclosure()
    const [isOpenRegionalCreate, onToggleRegionalCreate]  = useState(false)
    const [loadAllDataRegional, { loading: loadingDetails }] = useGetAllDataRegional()
    const [createRegional, { loading: loadingCreate }] = useCreateRegional({
        onCompleted: (data) => {
            toast({
                title: "Regional cadastrada com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
            }),
            onToggleRegionalCreate(false)
        },
        onError: () => {
            toast({
                title: 'Ocorreu um erro.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    })


    const onSubmit = (data) => {
        createRegional(data)
    }
    
    const textColor = useColorModeValue("gray.700", "white");
    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const buttonColor = useColorModeValue("white", "white");

    const openDetail = (data) => {
        loadAllDataRegional({
            variables: {
                id: data.id
            }
        })
        if (!isOpen)
            onToggle()
    }
    return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{sm: "120px", lg: "160px"}}
        >
            <Grid
                templateColumns={{sm: "1fr", md: "repeat(6, 6fr)"}}
                templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                w={"100%"}
            >
                <Card style={{gridColumn: `1 / span ${isOpen ? '4' : '6'}`}} px="0">
                    <CardHeader mb="34px" px="22px">
                        <Box w="100%">
                            <Flex
                                justify="space-between"
                                align="center"
                                fontSize={{sm: "10px", lg: "12px"}}
                                color="gray.400"
                            >
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Regionais
                                </Text>
                                {
                                    (user?.role === roleAdmin || user?.role === roleSuper) &&
                                    <Button
                                        bg={buttonBg}
                                        variant="no-hover"
                                        onClick={() => onToggleRegionalCreate(true)}
                                        color={buttonColor}>
                                        Criar Regional
                                    </Button>
                                }
                            </Flex>
                        </Box>
                    </CardHeader>
                    <CardBody>
                        <RegionalsTable onItemClick={openDetail}/>
                    </CardBody>
                </Card>
                <DetailCard
                    isOpen={isOpen}
                    regionalSelected={regionalSelected}
                    loading={loadingDetails}
                />
                <ModalCreateRegional
                    isOpen={isOpenRegionalCreate}
                    loading={loadingCreate}
                    onClose={onToggleRegionalCreate}
                    onSubmit={(data) => onSubmit({
                        variables: {input: data}
                    })}
                />
            </Grid>
        </Flex>
)
}
