import { useState, useEffect } from "react";

import { Flex, Text, useColorMode, } from "@chakra-ui/react";

const BeneficiaryAssessmentTable = ({ assessment }) => {
    const { colorMode } = useColorMode();
    const evaluations = assessment ? [...assessment.evaluations] : []
    const [hospiCheck, setHospiCheck] = useState(false);

    const textColor = colorMode === "light" ? "black" : "white";
    const bgColor = colorMode === "light" ? "gray.200" : "gray.500";

    if (!assessment?.evaluations) return "Formulário não preenchido";

    return (
        <>
            <Flex w="100%" direction={"column"}>
                {evaluations.sort((a, b) => { return a.order > b.order ? 1 : -1 }).map((evaluation) => {
                    if (evaluation.key === "label" || evaluation.value == '')
                        return (
                            <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight="bold"
                                dangerouslySetInnerHTML={{ __html: evaluation.text }}
                            />
                        );
                    if (evaluation.tag == 'pesoaltura' || evaluation.tag == 'peso_altura' || evaluation.tag == 'pesoxaltura')
                        return (
                            <>
                                <Flex margin=".75em 2em" direction="column">
                                    <Text marginBottom="10px" color={textColor} fontSize="md">
                                        Altura
                                    </Text>
                                    {
                                        evaluation.value ?
                                            <Text
                                                borderLeft="5px solid"
                                                borderColor="notrelife.700"
                                                borderRadius="5px"
                                                padding=".75em 1em"
                                                backgroundColor="gray.100"
                                                marginLeft="10px"
                                                color="gray.600"
                                                fontWeight="bold"
                                                fontSize="md"
                                            >
                                                {evaluation?.data?.height || null}cm
                                            </Text> :
                                            null
                                    }
                                </Flex>
                                <Flex margin=".75em 2em" direction="column">
                                    <Text marginBottom="10px" color={textColor} fontSize="md">
                                        Peso
                                    </Text>
                                    {
                                        evaluation.value ?
                                            <Text
                                                borderLeft="5px solid"
                                                borderColor="notrelife.700"
                                                borderRadius="5px"
                                                padding=".75em 1em"
                                                backgroundColor="gray.100"
                                                marginLeft="10px"
                                                color="gray.600"
                                                fontWeight="bold"
                                                fontSize="md"
                                            >
                                                {evaluation?.data?.weight || null}kg
                                            </Text> :
                                            null
                                    }
                                </Flex>
                                <Flex margin=".75em 2em" direction="column">
                                    <Text marginBottom="10px" color={textColor} fontSize="md">
                                        IMC
                                    </Text>
                                    {
                                        evaluation.value ?
                                            <Text
                                                borderLeft="5px solid"
                                                borderColor="notrelife.700"
                                                borderRadius="5px"
                                                padding=".75em 1em"
                                                backgroundColor="gray.100"
                                                marginLeft="10px"
                                                color="gray.600"
                                                fontWeight="bold"
                                                fontSize="md"
                                            >
                                                {evaluation.value}
                                            </Text> :
                                            null
                                    }
                                </Flex>
                            </>
                        )
                    if (evaluation.tag == 'quantas_internacoes_ou_passagem' && assessment?.evaluations?.find(ev => ev.tag == 'internacao')?.value == 'Lugar nenhum')
                        return <></>
                    return (
                        <Flex margin=".75em 2em" direction="column">
                            <Text marginBottom="10px" color={textColor} fontSize="md">
                                {evaluation.key}
                            </Text>
                            {
                                evaluation.value ?
                                    <Text
                                        borderLeft="5px solid"
                                        borderColor="notrelife.700"
                                        borderRadius="5px"
                                        padding=".75em 1em"
                                        backgroundColor="gray.100"
                                        marginLeft="10px"
                                        color="gray.600"
                                        fontWeight="bold"
                                        fontSize="md"
                                    >
                                        {evaluation.value}
                                    </Text> :
                                    null
                            }
                        </Flex>
                    );
                })}
            </Flex>
        </>
    );
};

export default BeneficiaryAssessmentTable;
