import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

export default ({ defaultValues } = {}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const textColor = useColorModeValue("gray.700", "white");
  const inputHover = useColorModeValue("notrelife.800", "notrelife.800");

  return (
    <>
      <FormControl isInvalid={errors.url}>
        <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
          Link informativo
        </FormLabel>
        <Input
          {...register("url")}
          focusBorderColor={inputHover}
          borderRadius="8px"
          fontSize="md"
          defaultValue={defaultValues?.url}
        />
        {errors.url && (
          <FormErrorMessage>{errors.url.message}</FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};
