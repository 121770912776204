import {gql} from '@apollo/client';

export const GET_PROFESSIONALS_BY_REGIONAL = gql`
  query GET_P($regional_id: Mixed, $accepted_roles: Mixed) {
      professionals(regionals: {column: ID, operator: EQ, value: $regional_id}, roles: {column: ROLE, operator: IN, value: $accepted_roles}) {
          id
          user {
              id
              name
              role
          }
      }
  }
`