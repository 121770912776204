import { gql } from '@apollo/client';

export const GET_FREEHOURS_PAGINATE = gql`
    query GetFreeHours(
        $where: QueryFreeHoursPaginateWhereWhereConditions!, 
        $professional: QueryFreeHoursPaginateProfessionalWhereHasConditions, 
        $first: Int! = 15, 
        $page: Int! = 1
      ) {
        freeHoursPaginate(where: $where, professional: $professional, first: $first, page: $page) {
            paginatorInfo {
              count
              currentPage
              firstItem
              perPage
              lastItem
              total
            }
            data {
              id
              type
              week_day
              start_time
              end_time
              turn
              events_limit
              regional {
                id
                name
              }
              professional {
                id
                user {
                  name
                }
              }
            }
        }
    }
`

export const GET_FREEHOUR =  gql`
    query GetFreeHour($id: ID!) {
        freeHour(id: $id) {
            id
            type
            week_day
            start_time
            end_time
            turn
            events_limit
            regional {
              id
              name
            }
            professional {
              id
              user {
                name
              }
            }
        }
    }
`

export const CREATE_FREEHOUR = gql`
  mutation CreateFreeHour($input: CreateFreeHours) {
        createFreeHour(input: $input) {
            id
        }
    }
`

export const UPDATE_FREEHOUR = gql`
  mutation UpdateFreeHour($input: UpdateFreeHours) {
        updateFreeHour(input: $input) {
            id
        }
    }
`

export const DELETE_FREEHOUR = gql`
  mutation DeleteFreeHour(
        $id: ID!,
    ) {
      deleteFreeHour(
        id: $id, 
      ) {
          id
      }
  }
`

export const GET_PROFESSIONAL_TIME =  gql`
    query GetProfessionalTime($input: SplitTimeInput!) {
      splitTime(input: $input) {
        title
        start
        end
      }
  }
`