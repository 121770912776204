const priorities = [
  {
    name: "Baixa",
    value: "baixa",
    color: "green.600",
  },
  {
    name: "Normal",
    value: "normal",
    color: "blue.600",
  },
  {
    name: "Alta",
    value: "alta",
    color: "orange.500",
  },
  {
    name: "Urgente",
    value: "urgente",
    color: "red.600",
  },
];

export default priorities;