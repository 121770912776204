const ACTION_TYPES = {
	LIST: "LIST",
	CLEAR_ROWS: "CLEAR_ROWS",
	ADD_NOTE: 'ADD_NOTE',
	SCHEDULE_EVENT: 'SCHEDULE_EVENT',
	RESCHEDULE_EVENT: 'RESCHEDULE_EVENT',
	RESCHEDULE_EVENT_MISSING: 'RESCHEDULE_EVENT_MISSING',
	REFUSE_PA: "REFUSE_PA"
}

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return {
				...state,
				commitments: action.data.data,
				page: action.data.paginatorInfo.currentPage,
				perPage: action.data.paginatorInfo.perPage,
				total: action.data.paginatorInfo.total,
				currentPage: action.data.paginatorInfo.currentPage,
			}
		}
		case ACTION_TYPES.CLEAR_ROWS: {
			return {
				...state,
				commitments: []
			}
		}
		case ACTION_TYPES.ADD_NOTE: {
			let newCmt = {
				...state.commitments.find(
					cm => cm?.patient?.id == action.data.beneficiary.id || cm.id == action.data.beneficiary.id ||
						cm?.patient?.patient_id == action.data.beneficiary.id || cm.patient_id == action.data.beneficiary.id
				)
			}
			let other = state.commitments.filter(cm => {
				if (!!cm.patient) return cm.patient.id != action.data.beneficiary.id && cm.patient.patient_id != action.data.beneficiary.id;
				else return cm.id != action.data.beneficiary.id && cm.patient_id != action.data.beneficiary.id
			})
			if (!!newCmt.patient) {
				newCmt.patient.notes = [...newCmt.patient.notes, action.data];
			} else {
				newCmt.notes = [...newCmt.notes, action.data];
			}

			return {
				...state,
				commitments: [
					...other,
					newCmt
				].sort((a, b) => a.days_awaiting < b.days_awaiting ? 1 : -1)
			}
		}
		case ACTION_TYPES.SCHEDULE_EVENT: {
			return {
				...state,
				commitments: state.commitments.filter(cm => cm.id != action.data.patient.id)
			}
		}
		case ACTION_TYPES.RESCHEDULE_EVENT: {
			let newCmt = { ...state.commitments.find(cm => cm.id == action.data.parent_id) }
			let other = state.commitments.filter(cm => cm.id != action.data.parent_id)
			newCmt.scheduled_to = action.data.scheduled_to
			newCmt.professional = action.data.professional
			return {
				...state,
				commitments: [
					...other,
					newCmt
				]
			}
		}
		case ACTION_TYPES.RESCHEDULE_EVENT_MISSING: {
			return {
				...state,
				commitments: state.commitments.filter(cm => cm.id != action.data.parent_id)
			}
		}
		case ACTION_TYPES.REFUSE_PA: {
			return {
				...state,
				commitments: state.commitments.filter(cm => cm.id != action.data.id)
			}
		}
		default: {
			return state
		}
	}
}

export {
	reducers,
	ACTION_TYPES
}