import {
	Badge,
	Box,
	Center,
	Divider,
	Flex,
	Grid,
	Skeleton,
	SkeletonText,
	Text,
	Tooltip,
	VStack,
} from "@chakra-ui/react";
import Icon from "components/FontAwesomeIcons/Icon";
import { differenceInYears } from "date-fns";
import dateFormat from "functions/dateFormat";
import { cpfFormat } from "functions/maskFormats";
import { rgFormat } from "functions/maskFormats";
import { phoneFormat } from "functions/maskFormats";

import states from "variables/states";
import cities from "variables/cities";

import React from "react";

const BeneficiaryDetails = ({ beneficiary, loading }) => {
	const idDoTitular = beneficiary.id;

	const handleLife = () => {
		if (beneficiary?.manager) return beneficiary?.manager.user?.name;
		if (!beneficiary?.eligibility) return "Vidas em automação";
		return "Sem consultor";
	};

	return (
		<>
			<Flex align="center" mb={4} >
				<VStack spacing={4}>
					<Skeleton
						isLoaded={!loading}
						minWidth="200px"
						minH="20px"
						textAlign="center"
					>
						<Text color="gray.500" fontSize={14} textAlign="left">
							{beneficiary?.is_dependent ? "Dependente" : "Titular"}
						</Text>
						<Flex align="center" gap="12px">
							<Text
								fontSize="18px"
								fontWeight="bold"
								display="flex"
								textAlign="left"
							>
								{beneficiary?.name}
							</Text>

							<Tooltip
								label={beneficiary?.active ? "Ativo" : "Inativo"}
								placement="top"
								closeOnClick={true}
							>
								<Badge
									backgroundColor={
										beneficiary?.active ? "green.500" : "red.500"
									}
									minW="20px"
									minH="20px"
									borderRadius="50%"
								></Badge>
							</Tooltip>

							{beneficiary?.receiver && (
								<Tooltip
									label={
										beneficiary.receiver?.phone_disabled
											? "Não recebe Whatsapp"
											: "Recebe Whatsapp"
									}
									placement="top"
									closeOnClick={true}
								>
									<Text
										size="lg"
										display="flex"
										justifyContent="center"
										alignItems="center"
										color={
											beneficiary?.receiver?.phone_disabled
												? "red.500"
												: "green.500"
										}
									>
										<Icon icon="whatsapp" size="lg" />
									</Text>
								</Tooltip>
							)}
						</Flex>
					</Skeleton>
				</VStack>
			</Flex>
			<Box ml="14px" mb={4} justify="space-around">
				<Flex flexDirection="column" gap="12px">
					<Flex gap="15px" alignItems="center">
						<Text maxW="20px" color="gray.500">
							<Icon icon="birthday" size="lg" />
						</Text>
						<Skeleton
							isLoaded={!loading}
							minWidth="200px"
							height="20px"
							maxW="20px"
						>
							<Text>
								{dateFormat(beneficiary?.birthdate)}
								{", "}
								{differenceInYears(
									new Date(),
									new Date(beneficiary?.birthdate)
								)}{" "}
								anos
							</Text>
						</Skeleton>
					</Flex>

					<Flex gap="15px" alignItems="center">
						<Text maxW="20px" color="gray.500">
							<Icon icon="id" size="lg" />
						</Text>
						<Skeleton
							isLoaded={!loading}
							minWidth="200px"
							height="20px"
							maxW="20px"
						>
							<Text>
								{beneficiary?.cpf ? cpfFormat(beneficiary?.cpf) : null}
							</Text>
						</Skeleton>
					</Flex>

					<Flex gap="15px" alignItems="center">
						<Text maxW="20px" color="gray.500">
							<Icon icon="phone" size="lg" />
						</Text>
						<Skeleton
							isLoaded={!loading}
							minWidth="200px"
							height="20px"
							maxW="20px"
						>
							<Text>
								{beneficiary?.phone ? phoneFormat(beneficiary.phone) : null}
							</Text>
						</Skeleton>
					</Flex>
				</Flex>
			</Box>
			<VStack align="stretch">
				<Skeleton isLoaded={!loading} minWidth="200px" height="100%">
					<Text color="gray.500" fontSize="14px" alignSelf="start">
						Profissional Responsável
					</Text>
					<Text fontSize="16px" display="flex" fontWeight="bold">
						{handleLife()}
					</Text>
				</Skeleton>
			</VStack>
			<Center>
				<Divider
					orientation="horizontal"
					marginY="35px"
					maxW="75%"
					borderWidth="1px"
					borderColor="gray.400"
				/>
			</Center>
			<Flex flexDirection="column" gap="12px" position="relative">
				{loading ? (
					<SkeletonText noOfLines={4} spacing="4" skeletonHeight="20px" />
				) : (
					<>
						{beneficiary?.is_dependent && (
							<VStack spacing={4}>
								<VStack align="stretch" spacing="1">
									<Text textAlign="left" fontSize="14px" color="gray.500">
										Grau de Parentesco
									</Text>
									<Text textAlign="left" fontSize="18px">
										{beneficiary?.dependency}
									</Text>
								</VStack>
								<VStack align="stretch" spacing="1">
									<Text textAlign="left" fontSize="14px" color="gray.500">
										Titular
									</Text>
									<Tooltip label="Ir até beneficiario" placement="top">
										<Link to={`./${idDoTitular}`}>
											<Text
												_hover={{ opacity: 0.8 }}
												textAlign="left"
												fontSize="18px"
											>
												{beneficiary?.holder?.name}
											</Text>
										</Link>
									</Tooltip>
								</VStack>
							</VStack>
						)}

						<Grid templateColumns="1.5fr 0.5fr" mr="20px">
							{beneficiary?.gender && (
								<VStack align="stretch" spacing="1">
									<Text textAlign="left" fontSize="14px" color="gray.500">
										Sexo Biológico
									</Text>
									<Text textAlign="left" fontSize="18px">
										{beneficiary?.gender === "m" ? "Masculino" : "Feminino"}
									</Text>
								</VStack>
							)}
							{beneficiary?.rg && (
								<VStack align="stretch" spacing="1">
									<Text textAlign="left" fontSize="14px" color="gray.500">
										RG
									</Text>
									<Text textAlign="left" fontSize="18px">
										{beneficiary?.rg ? rgFormat(beneficiary.rg) : null}
									</Text>
								</VStack>
							)}
						</Grid>

						{beneficiary?.mother_name && (
							<VStack align="stretch" spacing="1">
								<Text textAlign="left" fontSize="14px" color="gray.500">
									Nome da Mãe
								</Text>
								<Text textAlign="left" fontSize="18px">
									{beneficiary?.mother_name}
								</Text>
							</VStack>
						)}

						{beneficiary?.email && (
							<VStack align="stretch" spacing="1">
								<Text textAlign="left" fontSize="14px" color="gray.500">
									E-mail
								</Text>
								<Text textAlign="left" fontSize="18px">
									{beneficiary?.email}
								</Text>
							</VStack>
						)}

						{beneficiary?.second_phone && (
							<VStack align="stretch" spacing="1">
								<Text
									textAlign="left"
									fontColor="gray.500"
									fontSize="14px"
									color="gray.500"
								>
									Telefone secundário
								</Text>
								<Flex alignItems="center">
									<Text textAlign="left" fontSize="145x" color="gray.500">
										{beneficiary?.second_phone_contact}&nbsp;-&nbsp;
									</Text>
									<Text textAlign="right" fontSize="18px">
										{beneficiary?.second_phone
											? phoneFormat(beneficiary.second_phone)
											: null}
									</Text>
								</Flex>
							</VStack>
						)}

						{beneficiary?.address && (
							<VStack align="stretch" spacing="1">
								<Text textAlign="left" fontSize="14px" color="gray.500">
									Endereço
								</Text>
								<>
									<Text textAlign="left" fontSize="18px">
										{beneficiary?.address}
										{beneficiary?.district && `, ${beneficiary?.district}. `}
										{beneficiary?.complement && `${beneficiary?.complement}. `}
									</Text>
									<Text textAlign="left" fontSize="18px">
										{String(
											cities.find((city) => city.id == beneficiary?.city_id)
												?.name || ""
										)}
										{beneficiary?.state_id && "/"}
										{String(
											states.find((state) => state.id == beneficiary?.state_id)
												?.initials || ""
										)}
										{beneficiary?.cep && `, ${beneficiary?.cep}.`}
									</Text>
									<Text textAlign="left" fontSize="18px"></Text>
								</>
							</VStack>
						)}
					</>
				)}
			</Flex>
			<Center>
				<Divider
					orientation="horizontal"
					marginY="35px"
					maxW="75%"
					borderWidth="1px"
					borderColor="gray.400"
				/>
			</Center>
			<Flex flexDirection="column" gap="12px" position="relative">
				{loading ? (
					<SkeletonText noOfLines={4} spacing="4" skeletonHeight="20px" />
				) : (
					<VStack align="stretch">
						<VStack align="stretch" spacing="1">
							<Text textAlign="left" fontSize="14px" color="gray.500">
								Nome do Plano
							</Text>
							<Text textAlign="left" fontSize="18px">
								{beneficiary?.proposal?.plan.name}
							</Text>
						</VStack>
						<VStack align="stretch" spacing="1">
							<Text textAlign="left" fontSize="14px" color="gray.500">
								Número da Proposta
							</Text>
							<Text textAlign="left" fontSize="18px">
								{beneficiary?.proposal?.proposal_number}
							</Text>
						</VStack>
						<VStack align="stretch" spacing="1">
							<Text textAlign="left" fontSize="14px" color="gray.500">
								Número ANS
							</Text>
							<Text textAlign="left" fontSize="18px">
								{beneficiary?.proposal?.plan.ans}
							</Text>
						</VStack>
						{beneficiary?.company?.name && (
							<VStack align="stretch" spacing="1">
								<Text textAlign="left" fontSize="14px" color="gray.500">
									Razão social
								</Text>
								<Text textAlign="left" fontSize="18px">
									{beneficiary?.company?.name}
								</Text>
							</VStack>
						)}
					</VStack>
				)}
			</Flex>
		</>
	);
};

export default BeneficiaryDetails;
