import { ApolloProvider } from '@apollo/client';
import { getToken } from 'functions/token';
import createClient from "./client"

const ManagerQueryProvider = ({ children }) => {
    const token = getToken();
    return (
        <ApolloProvider client={createClient(token)}>
            {children}
        </ApolloProvider>
    )
}

export {
    ManagerQueryProvider
}