import React from "react";
import { padding } from "variables/nivo/bar";
import { useColorMode } from "@chakra-ui/react";
import { risksBooleanColors } from "variables/colors";
import {
  axisLeft,
  axisTop,
  labelTextColor,
  themeDark,
  themeLight,
  tooltip,
} from "variables/nivo/default";
import { ResponsiveLine } from "@nivo/line";

const colorScheme = ["#3E993C", "#CB4333", "#1E73DB", "#D97325", "#E0A51B"];

const emptyData = [
  {
    id: "Realizados",
    data: [
      {
        x: "",
        y: 0,
      },
    ],
  },
  {
    id: "No show",
    data: [
      {
        x: "",
        y: 0,
      },
    ],
  },
  {
    id: "Agendados",
    data: [
      {
        x: "",
        y: 0,
      },
    ],
  },
  {
    id: "Reagendados",
    data: [
      {
        x: "",
        y: 0,
      },
    ],
  },
  {
    id: "Cancelados",
    data: [
      {
        x: "",
        y: 0,
      },
    ],
  },
];

const ThirtyDaysOperational = ({ data }) => {
  if (!data) data = emptyData;

  const ret = [
    {
      id: "Realizados",
      data: data.map((item) => {
        return { x: item.date, y: item.attended };
      }),
    },
    {
      id: "No show",
      data: data.map((item) => {
        return { x: item.date, y: item.missing };
      }),
    },
    {
      id: "Agendados",
      data: data.map((item) => {
        return { x: item.date, y: item.scheduled };
      }),
    },
    {
      id: "Reagendados",
      data: data.map((item) => {
        return { x: item.date, y: item.rescheduled };
      }),
    },
    {
      id: "Cancelados",
      data: data.map((item) => {
        return { x: item.date, y: item.canceled };
      }),
    },
  ];

  const { colorMode } = useColorMode();

  return (
    <ResponsiveLine
      data={ret}
      margin={{ top: 50, right: 125, bottom: 50, left: 100 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={10}
      pointBorderWidth={2}
      enableSlices="x"
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      colors={colorScheme}
      theme={colorMode === "light" ? themeLight : themeDark}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default ThirtyDaysOperational;
