import { gql } from '@apollo/client';

export const GET_CUSTOMHOURS_PAGINATE = gql`
    query GetCustomHours(
        $where: QueryCustomHoursPaginateWhereWhereConditions!, 
        $professional: QueryCustomHoursPaginateProfessionalWhereHasConditions, 
        $first: Int! = 15, 
        $page: Int! = 1
      ) {
        customHoursPaginate(where: $where, professional: $professional, first: $first, page: $page) {
            paginatorInfo {
              count
              currentPage
              firstItem
              perPage
              lastItem
              total
            }
            data {
              id
              type
              event_type
              start
              end
              start_time
              end_time
              turn
              regional {
                id
                name
              }
              professional {
                id
                user {
                  name
                }
              }
            }
        }
    }
`

export const GET_CUSTOMHOUR =  gql`
    query GetCustomHour($id: ID!) {
        customHour(id: $id) {
          id
          type
          event_type
          start
          end
          start_time
          end_time
          turn
          regional {
            id
            name
          }
          professional {
            id
            user {
              name
            }
          }
        }
    }
`

export const CREATE_CUSTOMHOUR = gql`
  mutation CreateCustomHour($input: CreateCustomFreeHour) {
      createCustomHour(input: $input) {
        id
      }
  }
`

export const UPDATE_CUSTOMHOUR = gql`
  mutation UpdateCustomHour($input: UpdateCustomFreeHour) {
    updateCustomHour(input: $input) {
      id
    }
  }
`

export const DELETE_CUSTOMHOUR = gql`
  mutation DeleteCustomHour(
        $id: ID!,
    ) {
      deleteCustomHour(
        id: $id, 
      ) {
          id
      }
  }
`

export const GET_PROFESSIONAL_TIME =  gql`
    query GetProfessionalTime($input: SplitTimeInput!) {
      splitTime(input: $input) {
        title
        start
        end
      }
  }
`