import React from 'react'
import { createContext, useContext, useReducer } from 'react'

import { reducers } from './reducers'

const LayoutStateContext = createContext()
const LayoutDispatchContext = createContext()

const initialState = {
    configOpen: false,
    sidebarFixed: false,
    sidebarWidth: 275,
    sidebarVariant: 'transparent',
}

const LayoutProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState)

	return (
		<LayoutStateContext.Provider value={{ ...state }}>
			<LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
		</LayoutStateContext.Provider>
	)
}

const useLayout = () => {
	const context = useContext(LayoutStateContext)

	if (!context)
		throw new Error('useLayout must be used within an LayoutProvider')

	return context
}

const useDispatch = () => {
	const dispatch = useContext(LayoutDispatchContext)

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a LayoutProvider')

	return dispatch
}

export {
	LayoutProvider,
	useLayout,
    useDispatch
}
