import {
  Box,
  Button,
  Flex,
  Grid,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useCpts, useUpdateCpt } from "hooks/cpt";
import Categories from "../components/Categories";
import Assesments from "../components/Assesments";
import Header from "components/Header/Profile";
import {
  ACCOMPANIMENT,
  CPT_DISABLED_REASON_ANOTHERS,
  CPT_DISABLED_REASON_ISENTO,
  CPT_DISABLED_REASON_LIST,
  CPT_DISABLED_REASON_RED5,
  FIRST_RECEPTION,
  HEALTH_STATEMENT,
  STATUS_CPT_AWAITING_CID_DISEASES,
  STATUS_CPT_DISABLED,
} from "functions/enums";
import { ManagerQueryProvider } from "services/manager";
import { HealthStatementProvider } from "hooks/healthStatement";
import { FirstReceptionProvider } from "hooks/firstReception";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { useHistory } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { roleAdmin, roleMedic, roleSuper } from "../../../variables/roles";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import { AccompanimentProvider } from "../../../hooks/accompaniment";
import { STATUS_CPT_MEDICAL_ANALYSIS } from "functions/enums";

export default () => {
  const { user } = useAuth();
  const canEdit = [roleAdmin, roleSuper, roleMedic].includes(
    user?.role || null
  );
  let history = useHistory();
  const toast = useToast();
  const [modalRemoveConfirmation, setRemoveConfirmation] = useState(false);
  const [state, setState] = useState({ ds: true, fr: false, ac: false });
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { selectedCpt, loading } = useCpts();
  const [onUpdate, { loading: isUpdating }] = useUpdateCpt({
    onCompleted: (data) => {
      toast({
        title: `Paciente ${data.name} removida do programa cpt`,
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        setRemoveConfirmation(false);
        history.goBack();
    },
  });

  const handleState = () => {
    if (!!state.ds) return HEALTH_STATEMENT;
    if (!!state.fr) return FIRST_RECEPTION;
    return ACCOMPANIMENT;
  };

  return (
    <Grid
      templateColumns={{ sm: "1fr", md: "1fr" }}
      templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
      gap="12px"
      pt={{ sm: "125px", lg: "160px" }}
    >
      <Header
        backgroundProfile={bgProfile}
        name={selectedCpt?.name}
        cpf={selectedCpt?.cpf}
        email={selectedCpt?.email}
      >
        <Button
          p="10px"
          bg="transparent"
          _hover={{ bg: "none" }}
          variant="transparent-with-icon"
        >
          <Flex
            align="center"
            w={{ sm: "100%", lg: "135px" }}
            transition="all .5s ease"
            borderRadius="15px"
            justifyContent="center"
            py="10px"
            cursor="pointer"
            px="10px"
          >
            <Text
              fontSize="xs"
              color={selectedCpt?.assign_at ? "black" : "red"}
              fontWeight="bold"
            >
              {selectedCpt?.assign_at
                ? `Assinado em ${selectedCpt?.assign_at}`
                : "CPT Não Assinada"}
            </Text>
          </Flex>
        </Button>

        <Button
          p="0px"
          bg="transparent"
          _hover={{ bg: "none" }}
          variant="transparent-with-icon"
        >
          <Flex
            align="center"
            w={{ sm: "100%", lg: "135px" }}
            bg={state.ds ? "notrelife.700" : null}
            boxShadow={state.ds ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null}
            borderColor={state.ds ? "black" : null}
            transition="all .5s ease"
            borderRadius="15px"
            justifyContent="center"
            py="10px"
            cursor="pointer"
            onClick={() => setState({ ds: true, fr: false, ac: false })}
          >
            <Text
              fontSize="xs"
              color={state.ds ? "white" : "black"}
              fontWeight="bold"
            >
              {HEALTH_STATEMENT}
            </Text>
          </Flex>
        </Button>
        <Button
          p="0px"
          bg="transparent"
          _hover={{ bg: "none" }}
          variant="transparent-with-icon"
        >
          <Flex
            align="center"
            w={{ sm: "100%", lg: "135px" }}
            bg={state.fr ? "notrelife.700" : null}
            boxShadow={state.fr ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null}
            borderColor={state.fr ? "black" : null}
            transition="all .5s ease"
            borderRadius="15px"
            justifyContent="center"
            py="10px"
            cursor="pointer"
            onClick={() => setState({ ds: false, fr: true, ac: false })}
          >
            <Text
              fontSize="xs"
              color={state.fr ? "white" : "black"}
              fontWeight="bold"
            >
              {FIRST_RECEPTION}
            </Text>
          </Flex>
        </Button>
        <Button
          p="0px"
          bg="transparent"
          _hover={{ bg: "none" }}
          variant="transparent-with-icon"
        >
          <Flex
            align="center"
            w={{ sm: "100%", lg: "135px" }}
            bg={state.ac ? "notrelife.700" : null}
            boxShadow={state.ac ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null}
            borderColor={state.ac ? "black" : null}
            transition="all .5s ease"
            borderRadius="15px"
            justifyContent="center"
            py="10px"
            cursor="pointer"
            onClick={() => setState({ ds: false, fr: false, ac: true })}
          >
            <Text
              fontSize="xs"
              color={state.ac ? "white" : "black"}
              fontWeight="bold"
            >
              {ACCOMPANIMENT}
            </Text>
          </Flex>
        </Button>
      </Header>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr 1fr 1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
      >
        {!loading && (
          <Flex
            direction={"column"}
            gridColumnStart={{ md: "1" }}
            gridColumnEnd={{ md: "2" }}
          >
            <Button
              hidden={
                !(
                  !selectedCpt?.assign_at &&
                  selectedCpt?.status !== STATUS_CPT_DISABLED &&
                  canEdit
                )
              }
              colorScheme="red"
              mb={"10px"}
              onClick={() => setRemoveConfirmation(true)}
            >
              Remover da Cpt
            </Button>
            <Card hidden={!selectedCpt?.reason_disable}>
              <CardHeader>
                <Text fontSize="lg" text={bgProfile} fontWeight="bold">
                  Motivo do desligamento
                </Text>
              </CardHeader>
              <CardBody w="100%" pt="28px">
                <Stack direction="column" spacing="24px" w="100%">
                  <Box>
                    {selectedCpt?.reason_disable === "anothers"
                      ? selectedCpt?.reason_disable_extended
                      : CPT_DISABLED_REASON_LIST[selectedCpt?.reason_disable]}
                  </Box>
                  <Text fontWeight="bold" fontSize="sm" color={bgProfile}>
                    {selectedCpt?.reason_disable === "anothers"
                      ? selectedCpt?.reason_disable_extended
                      : CPT_DISABLED_REASON_LIST[selectedCpt?.reason_disable]}
                  </Text>
                </Stack>
              </CardBody>
            </Card>
            <Categories
              hidden={!selectedCpt?.identifier_id}
              status={selectedCpt?.status}
              awaiting_diseases={selectedCpt?.awaiting_diseases}
              cidList={selectedCpt?.diseases}
              assign={selectedCpt?.assign_at}
              awaitingCid={
                selectedCpt?.status === STATUS_CPT_AWAITING_CID_DISEASES
              }
              beneficiary_id={selectedCpt?.identifier_id}
              user={user}
            />
          </Flex>
        )}

        {!loading && selectedCpt?.identifier_id && (
          <ManagerQueryProvider>
            <HealthStatementProvider
              beneficiary_id={selectedCpt?.identifier_id}
            >
              <FirstReceptionProvider
                beneficiary_id={selectedCpt?.identifier_id}
              >
                <AccompanimentProvider
                  beneficiary_id={selectedCpt?.identifier_id}
                >
                  <Assesments
                    gridColumnStart={{ md: "2" }}
                    gridColumnEnd={{ md: "span 4" }}
                    state={state}
                    title={handleState()}
                    identifierId={selectedCpt?.identifier_id}
                  />
                </AccompanimentProvider>
              </FirstReceptionProvider>
            </HealthStatementProvider>
          </ManagerQueryProvider>
        )}

        <ConfirmationModal
          onConfirm={(cpt, reason, extended) =>
            onUpdate({
              variables: {
                input: {
                  id: cpt.id,
                  reason_disable: reason,
                  reason_disable_extended: extended,
                },
              },
            })
          }
          closeModal={() => setRemoveConfirmation(false)}
          data={selectedCpt}
          select={[
            CPT_DISABLED_REASON_RED5,
            CPT_DISABLED_REASON_ISENTO,
            CPT_DISABLED_REASON_ANOTHERS,
          ]}
          isLoading={isUpdating}
          message={
            "Tem certeza que deseja REMOVER " +
            selectedCpt?.name +
            " do programa de Cobertura parcial?"
          }
          btnMessage={"Remover"}
          isOpen={modalRemoveConfirmation}
        />
      </Grid>
    </Grid>
  );
};
