import React from "react";

import { Box, Grid, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "./components/FiltersBox";
import { useHealthDashboard } from "../../hooks/dashboards/useDashboards";
import LifesPerPlanChart from "../../components/Charts/Nivo/Lifes/LifesPerPlanChart";
import Ages from "../../components/Charts/Nivo/Lifes/Ages";
import IMC from "../../components/Charts/Nivo/Lifes/IMC";
import LifeHabitsChart from "components/Charts/Nivo/Lifes/LifeHabitsChart";
import RadialRisksChart from "components/Charts/Nivo/Lifes/RadialRisksChart";
import ChartContainer from "components/Charts/ChartContainer";
import {useFilter} from "../../hooks/useFilter";
import DiseasesChart from "components/Charts/Nivo/Lifes/DiseasesChart";

const Health = () => {
  const {filters} = useFilter();
  const { data, loading } = useHealthDashboard(filters);
  const textColor = useColorModeValue("gray.700", "white");
  
  return (
    <>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt={{ sm: "125px", lg: "160px" }}
      >
        <Card px="0px" pb="0px" minHeight="100px">
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Filtros
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <FiltersBox planNameFilter yearMonthFilter />
            </Box>
          </CardBody>
        </Card>
      </Grid>
      {loading ? (
        "Carregando..."
      ) : (
        <>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <ChartContainer minHeight="550px" title="Principais Doenças na Declaração de Saúde">
              <DiseasesChart data={data?.dashboard_health?.diseasesDS} label={'label'} keys={'total'} />
            </ChartContainer>
            <ChartContainer minHeight="550px" title="Principais Doenças no Primeiro Acolhimento">
              <DiseasesChart data={data?.dashboard_health?.diseasesFR} label={'label'} keys={'total'}/>
            </ChartContainer>
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <ChartContainer title="Estratificação dos Riscos Declaração de Saúde">
              <RadialRisksChart data={data?.dashboard_health?.metricDS} />
            </ChartContainer>
            <ChartContainer title="Estratificação dos Riscos Primeiro Acolhimento 49+">
              <RadialRisksChart data={data?.dashboard_health?.metricFRPlus} />
            </ChartContainer>
            <ChartContainer title="Estratificação dos Riscos Primeiro Acolhimento 48-">
              <RadialRisksChart data={data?.dashboard_health?.metricFRMinus} />
            </ChartContainer>
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <ChartContainer title="Índice de Massa Corporal sobre Declaração de Saúde">
              <IMC data={data?.dashboard_health?.imcDS} />
            </ChartContainer>
            <ChartContainer title="Índice de Massa Corporal sobre Primeiro Acolhimento">
              <IMC data={data?.dashboard_health?.imcPA} />
            </ChartContainer>
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <ChartContainer title="Hábitos de vida 49+">
              <LifeHabitsChart
                  tabaco={data?.dashboard_health?.tabacco49Plus}
                  booze={data?.dashboard_health?.booze49Plus}
                  physical={data?.dashboard_health?.physical49Plus}
              />
            </ChartContainer>
            <ChartContainer title="Hábitos de vida 48-">
              <LifeHabitsChart
                  tabaco={data?.dashboard_health?.tabacco48Minus}
                  booze={data?.dashboard_health?.booze48Minus}
                  physical={data?.dashboard_health?.physical48Minus}
              />
            </ChartContainer>
          </Grid>
        </>
      )}
    </>
  );
};

export default Health;
