
import { CptHookProvider } from "../hooks/cpt"
import { FilterProvider } from "hooks/useFilter";
import { CptQueryProvider } from "services/cpt";
import { getToken } from "functions/token";

export const CptFilterHookQueryProvider = ({ children }) => {
    const token = getToken();
    return (
        <FilterProvider target="cpt">
            <CptQueryProvider token={token} >
                <CptHookProvider>{children}</CptHookProvider>
            </CptQueryProvider>
        </FilterProvider>
    )
}