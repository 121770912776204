export const commitmentStatus = [ 
    {
        value: "pending",
        label: "Pendente"
    },
    {
        value: "contact_attempt",
        label: "Tentativa de contato"
    },
    {
        value: "scheduled",
        label: "Agendado"
    },
    {
        value: "refused",
        label: "Recusado"
    },
]