import React from "react"

import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCreateNote, useNotes } from "hooks/notes";

import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    Skeleton,
    Text,
    Textarea,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";

import Icon from "components/FontAwesomeIcons/Icon";

import dateFormat from "functions/dateFormat";
import { useParams } from "react-router-dom";

const schema = yup.object().shape({
    title: yup
        .string()
        .required("O Título da Nota é obrigatório"),
    text: yup
        .string()
        .required("O Texto da Nota é obrigatório"),
})

const BeneficiaryNotes = () => {
    const { id } = useParams();
    const methods = useForm();
    const toast = useToast();

    const { notes, data, loading } = useNotes(id);

    const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
    });

    const [createNote, { loading: loadingCreate }] = useCreateNote({
        onSuccess: (data) => {
            reset()
            toast({
                title: "Nota adicionada com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        },
        onError: (err) => {
            toast({
                title: "Ocorreu um erro",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        },
    })

    const onSubmit = (data) => {

        const variables = {
            input: {
                ...getValues(),
                beneficiary: {
                    connect: id
                }
            }
        }

        createNote({ variables: variables });
    }

    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Flex width="100%" height="100%" direction="column">
            <Flex mb="24px">
                <FormProvider {...methods}>
                    <form style={{ width: "100%" }} onSubmit={(handleSubmit(onSubmit))}>
                        <Flex direction="column" w="100%">
                            <Grid
                                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                gap="24px"
                            >
                                <FormControl isInvalid={errors.title}>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Título
                                    </FormLabel>
                                    <Input
                                        {...register("title")}
                                        focusBorderColor={inputHover}
                                        borderRadius="8px"
                                        fontSize="md"
                                    />
                                    {errors.title && <FormErrorMessage>{errors.title.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={errors.text}>
                                    <Textarea
                                        {...register("text")}
                                        placeholder={"Digite a nota aqui..."}
                                        focusBorderColor={inputHover}
                                        borderRadius="8px"
                                        fontSize="md"
                                    />
                                    {errors.text && <FormErrorMessage>{errors.text.message}</FormErrorMessage>}
                                </FormControl>
                            </Grid>
                        </Flex>
                        <Flex mt="24px" align={"center"} justify={"flex-end"}>
                            <Button
                                type="submit"
                                isLoading={loadingCreate}
                                isDisabled={loadingCreate}
                                variant="no-hover"
                                bg={buttonBg}
                                alignSelf="flex-end"
                                w="100px"
                                h="35px"
                            >
                                <Text fontSize="xs" color="#fff" fontWeight="bold">
                                    Criar nota
                                </Text>
                            </Button>
                        </Flex>
                    </form>
                </FormProvider>
            </Flex>
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="20px"
                width="100%"
            >
                {
                    loading ?
                        <Box width="100%">
                            <Flex width="100%" fontSize="15px">
                                <Icon icon="note" margin="25" size="xl" />
                                <Grid
                                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                    templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                                    gap="6px"
                                    fontSize="20px"
                                    width="100%"
                                >
                                    <Skeleton isLoaded={!loading} height="27px" />
                                    <Skeleton isLoaded={!loading} height="30px" />
                                </Grid>
                            </Flex>
                        </Box>
                        :
                        !notes || notes.length == 0 ?
                            <Text fontSize="lg" fontWeight="bold">
                                Não há registro de notas para o beneficiário
                            </Text>
                            :
                            notes.map((note, x) => (
                                <Box width="100%">
                                    <Flex width="100%" fontSize="15px">
                                        <Icon icon="note" margin="25" size="xl" />
                                        <Grid
                                            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                                            gap="6px"
                                            fontSize="20px"
                                        >
                                            <Text fontSize="18px">
                                                {note.title}
                                            </Text>
                                            <Text fontSize="20px">
                                                {note.text}
                                            </Text>
                                            <Text fontSize="16px">
                                                {note?.user?.name}, {dateFormat(note.created_at)}
                                            </Text>
                                        </Grid>
                                    </Flex>
                                    {x + 1 < notes.length && <Divider orientation="horizontal" mt="20px" height="2px"
                                        backgroundColor="gray.300" />}
                                </Box>
                            ))
                }
            </Grid>
        </Flex>
    )
}

export default BeneficiaryNotes
