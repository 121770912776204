import React, { useState } from "react";
import {
  Box,
  Grid,
  Modal,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useCommitments, usePaginateCommitments } from "hooks/commitments";
import Icon from "components/FontAwesomeIcons/Icon";
import EditCommitment from "views/Commitments/components/EditCommitment";
import AsyncTable from "../AsyncTable";
import FiltersBox from "views/Dashboards/components/FiltersBox";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import RegisterNoteModal from "views/Commitments/components/RegisterNoteModal";
import NoteModal from "views/CommitmentsManagement/components/NoteModal";
import { commitmentStatus } from "variables/commitmentStatus";
import { commitmentRefusalMotives } from "variables/commitmentRefusalMotives";
import { useFilter } from "hooks/useFilter";
import queryParamsPage from "functions/queryParamsPage";
import { findRiskPA } from "functions/getStratification";
import { useLocation } from "react-router-dom";
import { differenceInYears } from "date-fns";

const CommitmentsTable = () => {
  const location = useLocation();
  const { page: queryPage, size: querySize } = queryParamsPage(location);
  const { filters: filtersBox } = useFilter();
  const [activeRow, setActiveRow] = React.useState(null);
  const [note, setNote] = useState({});
  const [noteModal, setNoteModal] = useState(false);
  const [registerNoteModal, setRegisterNoteModal] = useState(false);
  const textColor = useColorModeValue("gray.700", "white");

  const hasNote = (notes, event) => {
    if (!notes) return false;
    let cNotes = [...notes];
    const eNotes = cNotes.filter((nt) => nt.title === `Consulta - ${event}`);
    return eNotes[eNotes.length - 1];
  };

  const handleModalOpen = (props) => {
    handleNoteModal(
      props?.row?.original?.beneficiary?.name,
      hasNote(props?.value, props?.row?.original?.type_desc)?.text
    );
  };

  const handleNoteModal = (name, notes) => {
    const values = {
      name: name,
      notes: notes,
    };
    setNote(values);
    setNoteModal(true);
  };

  const handleRegisterNoteModal = (id, name, title, notes) => {
    const values = {
      id: id,
      name: name,
      title: title,
      notes: notes,
    };

    setNote(values);
    setRegisterNoteModal(true);
  };

  const getStatusLabel = (status) => {
    return (
      commitmentStatus.find((cStatus) => status === cStatus.value)?.label ||
      status
    );
  };

  const getRefusalMotiveLabel = (motive) => {
    return (
      commitmentRefusalMotives.find((cMotive) => motive === cMotive.value)
        ?.label || motive
    );
  };

  const getAwaitingTime = (dt1, dt2 = new Date()) => {
    try {
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;
      return Math.abs(Math.round(diff));
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const openEditModal = (row) => {
    onOpen();
    setActiveRow(row);
  };

  const { loading, rows, total, perPage, currentPage } = useCommitments();
  const [
    paginateCommitments,
    { loading: reloading },
  ] = usePaginateCommitments();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize !== perPage) pageIndex = 1;
    const filters = { ...filtersBox, ...(search && { name: search }) };

    paginateCommitments({
      variables: {
        first: pageSize || querySize || 30,
        page: pageIndex || queryPage || 1,
        filters: filters || [],
      },
    });
  };

  const columns = [
    {
      Header: "Aguardando (em horas)",
      accessor: "created_at",
      Cell: (props) =>
        props.row.original.status === "scheduled" ||
        props.row.original.status === "refused" ? (
          <div>
            Resolvido em{" "}
            {getAwaitingTime(
              new Date(props.value),
              new Date(props.row.original.updated_at)
            )}
            h
          </div>
        ) : (
          <div>Aguardando à {getAwaitingTime(new Date(props.value))}h</div>
        ),
    },
    {
      Header: "Nome do beneficiário",
      accessor: "beneficiary.name",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "Idade",
      accessor: "beneficiary.birthdate",
      Cell: (props) => (
        <div>
          {props?.value
            ? differenceInYears(new Date(), new Date(props.value))
            : null}
        </div>
      ),
    },
    {
      Header: "Telefone",
      accessor: "beneficiary.phone",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "Tipo de compromisso",
      accessor: "type_desc",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "Nome do Plano",
      accessor: "beneficiary.proposal.plan.name",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "Estratificação PA",
      accessor: "stratification_pa",
      Cell: (props) => (
        <div>{findRiskPA(props?.row?.original?.beneficiary?.assessments)}</div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => <div>{getStatusLabel(props.value)}</div>,
    },
    {
      Header: "Motivo da recusa",
      accessor: "refusal_motive",
      Cell: (props) => <div>{getRefusalMotiveLabel(props.value)}</div>,
    },
    {
      Header: "Tentativas de contato",
      accessor: "contact_attempts",
      Cell: (props) => <div>{props.value || 0}</div>,
    },
    {
      Header: "Profissional responsável",
      accessor: "professional.user.name",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "Notas",
      accessor: `beneficiary.notes`,
      Cell: (props) =>
        hasNote(props.value, props?.row?.original?.type_desc) ? (
          <Box
            cursor={"pointer"}
            onClick={() => handleModalOpen(props)}
            _hover={{ color: "notrelife.800" }}
          >
            <Icon icon="info" />
          </Box>
        ) : (
          <div></div>
        ),
    },
  ];

  const customActions = [
    {
      label: "Adicionar nota",
      icon: "note",
      callback: (id, row) => {
        const note = hasNote(row?.beneficiary?.notes) || null;
        handleRegisterNoteModal(
          row?.beneficiary?.id,
          row?.beneficiary?.name,
          `Consulta - ${row?.type_desc}`,
          note?.text
        );
      },
    },

    {
      label: "Editar Consulta",
      icon: "edit",
      callback: (id, row) => {
        openEditModal(row);
      },
    },
  ];

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
        <EditCommitment commitment={activeRow} onClose={onClose} />
      </Modal>

      <NoteModal
        beneficiary={note}
        open={noteModal}
        setNoteModal={setNoteModal}
      />

      <RegisterNoteModal
        beneficiary={note}
        open={registerNoteModal}
        setRegisterModal={setRegisterNoteModal}
      />

      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
      >
        <Card px="0px" pb="0px" minHeight="100px">
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Filtros
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <FiltersBox
                statusFilter
                startDateFilter
                endDateFilter
                isCommitment
              />
            </Box>
          </CardBody>
        </Card>
      </Grid>

      <AsyncTable
        isLoaded={!loading && !reloading}
        fetchData={fetchData}
        size={perPage}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        searchByName
        baseRoute="compromissos"
        noView
        noEdit
        noDelete
        customActions={customActions}
      />
    </React.Fragment>
  );
};

export default CommitmentsTable;
