import { useFormContext } from "react-hook-form";
import {FormControl, FormLabel, Input,  NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Grid,
    Flex,
    useColorModeValue,
    Select,
    useToast,
    FormErrorMessage,} from "@chakra-ui/react";
    
import InputMask from 'react-input-mask';

import AsyncPlansMultiselect from "./AsyncPlansMultiselect";
import AsyncProfessionalsMultiselect from "./AsyncProfessionalsMultiselect";

import states from "variables/states";
import cities from "variables/cities";
import { getAddress } from "functions/getAddress";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup"

const schema = yup.object().shape({
    name: yup
      .string()
      .required("O campo Nome é obrigatório"),
    beneficiary_limit: yup
      .number()
      .required("O campo Total de vidas é obrigatório"),
    cep: yup
      .string()
      .required("O campo CEP é obrigatório"),
    state_id: yup
      .string()
      .required("O campo Estado é obrigatório"),
    city_id: yup
        .string()
        .required("O campo Cidade é obrigatório"),
    district: yup
      .string()
      .required("O campo Bairro é obrigatório"),
    address: yup
      .string()
      .required("O campo Endereço é obrigatório"),
    address_number: yup
      .string()
      .required("O campo Número é obrigatório"),
})

const RegionalFormCreate = () => {
    const { register, watch, getValues, setValue, formState: { errors } } = useFormContext({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
    });
    const toast = useToast();
    
    const wState = watch("state_id", null)

    const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
    const textColor = useColorModeValue("gray.700", "white");

    const findAddress = async (zipcode) => {
        const address = await getAddress(zipcode);
        if(!address) {
            toast({
                title: 'CEP não encontrado.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } else {
            setValue("state_id", String(states.find(state => state.initials == address.uf).id))
            setValue("city_id", String(cities.find(city => city.name == address.localidade).id))
            setValue("district", address.bairro)
            setValue("address", address.logradouro)
        }
    }

    return (
        <Flex direction="column" w="100%">
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                gap="24px"
            >
                <FormControl isInvalid={errors.name}>
                    <FormLabel
                        fontWeight="bold"
                        fontSize="xs"
                    >
                        Nome
                    </FormLabel>
                    <Input {...register("name")} />
                    {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.beneficiary_limit}>
                    <FormLabel
                        fontWeight="bold"
                        fontSize="xs"
                    >
                        Total de vidas Permitidas
                    </FormLabel>
                    <NumberInput >
                        <NumberInputField  {...register("beneficiary_limit")} name={"beneficiary_limit"} id={"beneficiary_limit"} />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    {errors.beneficiary_limit && <FormErrorMessage>{errors.beneficiary_limit.message}</FormErrorMessage>}
                </FormControl>
                <FormControl>
                    <FormLabel
                        fontWeight="bold"
                        fontSize="xs"
                    >
                        Associar Planos
                    </FormLabel>
                    <AsyncPlansMultiselect />

                </FormControl>
                <FormControl>
                    <FormLabel
                        fontWeight="bold"
                        fontSize="xs"
                    >
                        Associar Profissionais
                    </FormLabel>
                    <AsyncProfessionalsMultiselect />
                </FormControl>
            </Grid>
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                gap="24px"
                mt="24px"
            >
                <FormControl >
                    <FormLabel
                    color={textColor}
                    fontWeight="bold"
                    fontSize="xs"
                    >
                        CEP
                    </FormLabel>
                    <Input
                        {...register('cep')} 
                        as={InputMask} 
                        mask="99999-999"
                        onChange={({target}) => (target.value.replace('_', '').length == 9) && findAddress(target.value)}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                    />
                </FormControl>
                <FormControl>
                    <FormLabel
                    color={textColor}
                    fontWeight="bold"
                    fontSize="xs"
                    >
                    Estado
                    </FormLabel>
                    <Select
                    {...register('state_id')} 
                    focusBorderColor={inputHover}
                    borderRadius="8px"
                    fontSize="md"
                    placeholder="Selecione uma opção..."
                    color="gray.400"
                    >
                    {
                        states.map(state => (
                        <option value={state.id}>{state.name}</option>
                        ))
                    }
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                    >
                        Cidade
                    </FormLabel>
                    <Select
                        {...register('city_id')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        placeholder={(!wState || wState == '') ? "Selecione um estado..." : "Selecione uma opção..."}
                        color="gray.400"
                        disabled={!wState || wState == '' }
                    >
                        {
                            cities.filter(city => city.state_id == wState).map(city => (
                                <option value={city.id}>{city.name}</option>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl >
                    <FormLabel
                    color={textColor}
                    fontWeight="bold"
                    fontSize="xs"
                    >
                        Bairro
                    </FormLabel>
                    <Input
                        {...register('district')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                    />
                </FormControl>
                <FormControl >
                    <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                    >
                        Endereço
                    </FormLabel>
                    <Input
                        {...register('address')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                    />
                </FormControl>
                <FormControl >
                    <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                    >
                        Número
                    </FormLabel>
                    <Input
                        {...register('address_number')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                    />
                </FormControl>
                <FormControl >
                    <FormLabel
                    color={textColor}
                    fontWeight="bold"
                    fontSize="xs"
                    >
                        Complemento
                    </FormLabel>
                    <Input
                        {...register('complement')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                    />
                </FormControl>
            </Grid>
        </Flex>
    );
}

export default RegionalFormCreate;
