import React from 'react'

import { useCreateNote } from 'hooks/commitmentsManagement';

import { 
  Button,
  Flex,
  Modal, 
  ModalBody, 
  ModalCloseButton, 
  ModalContent, 
  ModalFooter, 
  ModalHeader, 
  ModalOverlay, 
  Textarea, 
  toast, 
  useToast 
} from '@chakra-ui/react'

import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { assessmentsTypes } from 'variables/assesmentsTypes';

const schema = yup.object().shape({
    text: yup
      .string()
      .required("Preencha o campo da nota"),
})

const RegisterNoteModal = ({beneficiary, open, setRegisterModal}) => {
  const toast = useToast()

  const { register, formState: { errors }, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const [createNote, { loading }] = useCreateNote({
    onSuccess: () => {
        toast({
            title: "Nota adicionada com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
        })
        setRegisterModal(false)
    },
    onError: (error) => {
      toast({
          title: "Ocorreu um erro",
          status: "error",
          duration: 5000,
          isClosable: true,
      })
    }
  })

  const onSubmit = () => {
    const variables = {
      input: {
        title: beneficiary.title,
        text: getValues('text'),
        beneficiary: {
          connect: beneficiary.id
        }
      }
    }
    createNote({variables: variables})
  }

  return (
    <Modal
        isCentered
        onClose={() => setRegisterModal(false)}
        isOpen={open}
        motionPreset='slideInBottom'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registrar nota para {beneficiary?.name || null}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex width="100%">
              <Textarea 
                {...register('text')}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} variant='ghost' onClick={() => setRegisterModal(false)}>Cancelar</Button>
            <Button 
              isLoading={loading}
              disabled={loading}
              bgColor={"notrelife.800"}
              color="#FFF"
              variant="no-hover"
              onClick={() => onSubmit()}
            >
              Atualizar nota
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default RegisterNoteModal