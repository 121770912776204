import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useBeneficiaries, useGetBeneficiary } from "hooks/beneficiaries";

import {
	Box,
	Flex,
	Grid,
	Text,
	useColorMode,
	useColorModeValue,
	useDisclosure,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import EditBeneficiary from "./EditBeneficiary";
import FolderControl from "./components/FolderControl";
import CancelBeneficiary from "./CancelBeneficiary";
import BeneficiaryEvents from "./BeneficiaryEvents";
import { BeneficiaryEventsProvider } from "hooks/beneficiaryEvents";
import BeneficiaryDetails from "./BeneficiaryDetails";

const ViewBeneficiary = () => {
	const { id } = useParams();
	const { beneficiary } = useBeneficiaries();
	const { loading, error } = useGetBeneficiary(id);

	const {
		isOpen: editOpen,
		onOpen: onEditOpen,
		onClose: onEditClose,
	} = useDisclosure();

	const {
		isOpen: cancelOpen,
		onOpen: onCancelOpen,
		onClose: onCancelClose,
	} = useDisclosure();

	const textColor = useColorModeValue("black", "white");

	const history = useHistory();

	useEffect(() => {
		if (!loading && error) {
			history.push(`/sistema/administracao/gestao-vidas`);
		}
	}, [loading, error]);

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: "120px", lg: "160px" }}
		>
			<Grid
				templateColumns={{ sm: "1fr", md: "repeat(1, 1fr 2fr)" }}
				templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
				gap="12px"
				w={"100%"}
				pt={{ sm: "12px", lg: "12px" }}
			>
				<Flex direction={"column"}>
					{beneficiary?.active && <BeneficiaryEventsProvider>
						<BeneficiaryEvents
							beneficiary={beneficiary}
							awaitingSchedules={beneficiary?.pending_awaiting_schedules} 
						/>
					</BeneficiaryEventsProvider>}
					<Card
						px="0px"
						pb="22px"
						minHeight="100px"
						overflowX={{ sm: "auto", lg: "unset" }}
					>
						<CardHeader px="22px">
							<Text color={textColor} fontSize="lg" fontWeight="bold"></Text>
						</CardHeader>
						<CardBody h="100%" px="22px">
							<Box w="100%" h="100%">
								<Flex flexDirection="column" position="relative">
									<Flex w="100%" minH="60px" justify="right" gap="12px">
										<EditBeneficiary
											beneficiary={beneficiary || null}
											open={editOpen}
											loadingBenef={loading}
											onOpen={onEditOpen}
											onClose={onEditClose}
										/>
										<CancelBeneficiary
											beneficiary={beneficiary || null}
											open={cancelOpen}
											loadingBenef={loading}
											onOpen={onCancelOpen}
											onClose={onCancelClose}
										/>
									</Flex>
								</Flex>
								<BeneficiaryDetails
									beneficiary={beneficiary}
									loading={loading}
								/>
							</Box>
						</CardBody>
					</Card>
				</Flex>
				<FolderControl beneficiary={beneficiary} />
			</Grid>
		</Flex>
	);
};

export default ViewBeneficiary;
