import { useEffect } from "react";
import AsyncTable from "components/Tables/AsyncTable";
import {
  useAutoCommitments,
  useCommitments,
} from "hooks/commitmentsManagement";
import { Link } from "react-router-dom";
import { differenceInYears } from "date-fns";

import { findRiskDS } from "functions/getStratification";
import { findRiskPA } from "functions/getStratification";

const columns = [
  {
    Header: "Atividade Pendente",
    accessor: "awaiting_schedule",
    Cell: (props) => (
      <div>{props?.value ? props.value : "Declaração de Saúde"}</div>
    ),
  },
  {
    Header: "Beneficiario",
    accessor: "name",
    Cell: (props) => (
      <Link to={`../administracao/gestao-vidas/${props?.row?.original?.id}`}>
        {props.value}
      </Link>
    ),
  },
  {
    Header: "Idade",
    accessor: "birthdate",
    Cell: (props) => (
      <div>
        {props?.value
          ? differenceInYears(new Date(), new Date(props.value))
          : null}
      </div>
    ),
  },
  {
    Header: "Estratificação DS",
    accessor: "stratification_ds",
    Cell: (props) => <div>{findRiskDS(props?.row?.original?.assessments)}</div>,
  },
  {
    Header: "Estratificação PA",
    accessor: "stratification_pa",
    Cell: (props) => <div>{findRiskPA(props?.row?.original?.assessments)}</div>,
  },
  {
    Header: "Celular",
    accessor: "phone",
  },
  {
    Header: "Plano",
    accessor: "plan_name",
  },
];

const AutoScheduleTable = ({
  plan = null,
  year = null,
  month = null,
  professional = null,
  assessment = null,
  riskDS = null,
  riskPA = null,
  age = null,
  handleMessageModal,
}) => {
  const { commitments, total, perPage, currentPage } = useCommitments();
  const [loadComittments, { loading }] = useAutoCommitments();

  const fetchData = ({ pageSize, pageIndex, search }) => {
    const variables = {
      first: pageSize,
      page: pageIndex,
      input: {
        plan: plan,
        year: year,
        month: month,
        professional: professional,
        assessmentType: assessment,
        name: search || "",
        riskDS: riskDS,
        riskPA: riskPA,
        age: age,
      },
    };
    loadComittments({ variables: variables });
  };

  useEffect(() => {
    const variables = {
      first: 30,
      page: 1,
      input: {
        plan: plan,
        year: year,
        month: month,
        professional: professional,
        assessmentType: assessment,
        riskDS: riskDS,
        riskPA: riskPA,
        age: age,
      },
    };
    loadComittments({ variables: variables });
  }, [plan, month, professional, assessment, riskDS, riskPA, age]);

  const customActions = [
    {
      label: "Enviar Mensagem",
      icon: "whatsapp",
      callback: (id) =>
        handleMessageModal({
          id: id,
          name: commitments.find((row) => row.id == id).name,
        }),
    },
  ];

  return (
    <AsyncTable
      searchByName
      noView
      noEdit
      customActions={!loading ? customActions : null}
      isLoaded={!loading}
      fetchData={fetchData}
      data={
        !loading
          ? commitments.sort((a, b) => {
              return new Date(a.scheduled_to) - new Date(b.scheduled_to);
            })
          : [{}, {}, {}, {}]
      }
      columns={columns}
      size={perPage}
      total={total || 0}
      asyncPage={currentPage}
      baseRoute=""
    />
  );
};

export default AutoScheduleTable;
