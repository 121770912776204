import React, {createContext, useContext, useReducer} from "react"
import {useMutation, useQuery} from "@apollo/client";

import {GET_DOCUMENT, GET_DOCUMENT_LINK, GET_DOCUMENTS} from "graphql/documents"
import {ACTION_TYPES, reducers} from "./reducers"
import {useParams} from "react-router-dom";

const DocumentsStateContext = createContext()
const DocumentsDispatchContext = createContext()

const DocumentsProvider = ({children, beneficiary}) => {
    const [state, dispatch] = useReducer(reducers, {documents: []});
    const { id } = beneficiary;

    const {data, loading, error} = useQuery(GET_DOCUMENTS, {
        variables: {
            beneficiary_id: id
        }, onCompleted: (data) => {
            dispatch({type: ACTION_TYPES.LIST, data})
        }
    })

    return (<DocumentsStateContext.Provider value={{data, loading, error, ...state}}>
            <DocumentsDispatchContext.Provider value={dispatch}>{children}</DocumentsDispatchContext.Provider>
        </DocumentsStateContext.Provider>)
}

const useDocuments = () => {
    const context = useContext(DocumentsStateContext)

    if (!context) throw new Error("useDocuments must be used within an DocumentsProvider")

    return context
}

const useDispatch = () => {
    const dispatch = useContext(DocumentsDispatchContext)

    if (dispatch === undefined) throw new Error("useDispatch must be used within a DocumentsProvider")

    return dispatch
}

const useRequestAuthorizedLink = () => {
    // const dispatch = useDispatch()

    return useMutation(GET_DOCUMENT_LINK)
}

export {
    DocumentsProvider, useDocuments, useRequestAuthorizedLink
}
