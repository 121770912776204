import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useColorMode } from "@chakra-ui/react";
import { monochromeMainColors } from "variables/colors";
import { themeDark, themeLight, tooltip } from "variables/nivo/default";

const emptyData = [{ name: "", total: 0 }];

const DiseasesChart = ({ data, keys, label }) => {
  if (typeof data === "string") data = JSON.parse(data);
  const { colorMode } = useColorMode();

  const getWrapped = (value) => {
    const groups = [];
    const maxCharactersPerLine = 21;
    const splitIn = Math.floor(value.length / maxCharactersPerLine);

    if (value.length < maxCharactersPerLine) return [value];
    if (value.length > 63) {
      if (value.includes('(')) {
        const start = value.split('').findIndex((element) => element == '(');
        const end = value.split('').findLastIndex((element) => element == ')');
        const offset = value.slice(start, end + 1);
        value = value.replace(offset, '').trim();
      }

      if (value.length > 64) return [value.slice(0, 32) + '...']
    }

    value = value.split(" ");

    for (let i = 0; i < splitIn; i++) {
      groups[i] = [];
      value.map((word, index) => {
        if (groups[i].join("").length > maxCharactersPerLine) return;

        groups[i].push(word);
      });
      value = value.slice(groups[i].length, value.length);
      groups[i] = groups[i].join(" ");
    }
    return groups;
  };

  return (
    <ResponsiveBar
      data={data}
      keys={[keys]}
      indexBy={label}
      layout="horizontal"
      margin={{ right: 75, left: 275 }}
      padding={0.1}
      colors={monochromeMainColors}
      labelSkipWidth={24}
      theme={colorMode === "light" ? themeLight : themeDark}
      axisBottom={null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        renderTick: ({
          opacity,
          textAnchor,
          textBaseline,
          textX,
          textY,
          value,
          x,
          y,
        }) => {
          return (
            <g transform={`translate(${x},${y})`} style={{ opacity }}>
              <text
                alignmentBaseline={textBaseline}
                textAnchor={textAnchor}
                transform={`translate(${textX},${textY - 5})`}
              >
                {getWrapped(value).map((group, index) => {
                  return (
                    <tspan
                      x="0"
                      dy={index == 0 ? 0 : "1em"}
                    >
                      {group}
                    </tspan>
                  );
                })}
              </text>
            </g>
          );
        },
      }}
      labelTextColor={"#fff"}
      tooltip={({ indexValue, value }) => tooltip(indexValue, value)}
    />
  );
};

export default DiseasesChart;
