
import { gql } from '@apollo/client';

export const GET_PROFESSIONALS = gql`
query GET_P {
    professionals {
        id
        user {
            id
            name
            role
        }
    }
}
`

export const GET_PROFESSIONALS_BY_REGIONAL = gql`
query GET_P($regional_id: String, $accepted_roles: [String]) {
    professionals(regionals: $regional_id, accepted_roles: $accepted_roles) {
        id
        user {
            id
            name
            role
        }
    }
}
`