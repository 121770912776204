import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useMutation, useQuery } from "@apollo/client";

import { GET_LIFE_PLANS, CREATE_LIFE_PLAN } from 'graphql/lifePlans'
import { ACTION_TYPES, reducers } from './reducers'
import { useParams } from 'react-router-dom';

const LifePlansStateContext = createContext()
const LifePlansDispatchContext = createContext()

const LifePlansProvider = ({ children, beneficiary }) => {
    const [state, dispatch] = useReducer(reducers, { plans: [] })
	const { id } = beneficiary;

    const { data, loading, error } = useQuery(GET_LIFE_PLANS, {
			variables : {
				id: id
			},
      onCompleted: (data) => {
          dispatch({type: ACTION_TYPES.LIST, data: data.lifePlans})
      }
  })
	
    return (
			<LifePlansStateContext.Provider value={{ data, loading, error, ...state }}>
					<LifePlansDispatchContext.Provider value={dispatch}>{children}</LifePlansDispatchContext.Provider>
			</LifePlansStateContext.Provider>
    )
}

const useLifePlans = () => {
	const context = useContext(LifePlansStateContext)
	
	if (!context)
		throw new Error('useLifePlans must be used within an LifePlansProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(LifePlansDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a LifePlansProvider')

	return dispatch
}

const useCreateLifePlan = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(CREATE_LIFE_PLAN, {
			onCompleted: (data) => {
				if(onSuccess) onSuccess()
				dispatch({ type: ACTION_TYPES.ADD, data: data.createLifePlan })
			},
			onError: (error) => {
				if(onError) onError(error)
			},
	})
}

export {
	LifePlansProvider,
	useLifePlans,
	useCreateLifePlan,
}
