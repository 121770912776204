import React from 'react'
import { useState, createContext, useContext, useReducer } from 'react'
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_CUSTOMHOURS_PAGINATE, GET_CUSTOMHOUR, CREATE_CUSTOMHOUR, UPDATE_CUSTOMHOUR } from 'graphql/customHours'
import { ACTION_TYPES, reducers } from './reducers'
import { DELETE_CUSTOMHOUR } from 'graphql/customHours';

const CustomHoursStateContext = createContext()
const CustomHoursDispatchContext = createContext()

const CustomHoursProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, { rows: [], first: 15, page: 1 })

	const [toDelete, setToDelete] = useState(null)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)

	const { data, loading, error } = useQuery(GET_CUSTOMHOURS_PAGINATE, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.customHoursPaginate })
		}
	})

	return (
		<CustomHoursStateContext.Provider value={{ ...state, toDelete, setToDelete, deleteModalOpen, setDeleteModalOpen, data, loading }}>
			<CustomHoursDispatchContext.Provider value={dispatch}>{children}</CustomHoursDispatchContext.Provider>
		</CustomHoursStateContext.Provider>
	)
}

const useCustomHours = () => {
	const context = useContext(CustomHoursStateContext)

	if (!context)
		throw new Error('useCustomHours must be used within an CustomHoursProvider')

	return context
}

const useDispatch = () => {
	const dispatch = useContext(CustomHoursDispatchContext)

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a CustomHoursProvider')

	return dispatch
}

const usePaginateCustomHours = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_CUSTOMHOURS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(null, '', `./gestao-vidas?page=${data.customHoursPaginate.paginatorInfo.currentPage}&size=${data.customHoursPaginate.paginatorInfo.perPage}`);
			dispatch({ type: ACTION_TYPES.LIST, data: data.customHoursPaginate })
		},
	})
}

const useCreateCustomHour = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(CREATE_CUSTOMHOUR, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data)
			dispatch({ type: ACTION_TYPES.ADD, data: data.createCustomHour })
		},
		onError: (error) => {
			if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation)
		},
	})
}

const useCustomHour = (id) => {
	const dispatch = useDispatch()

	return useQuery(GET_CUSTOMHOUR, {
		fetchPolicy: "no-cache",
		variables: {
			id: id
		}
	},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.SELECT, data: data.customHour })
			},
		}
	)
}

const useUpdateCustomHour = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(UPDATE_CUSTOMHOUR, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data)
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateCustomHour })
		},
		onError: (error) => {
			if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation)
		},
		refetchQueries: [
			{
				query: GET_CUSTOMHOURS_PAGINATE
			}
		]
	})
}

const useDeleteCustomHour = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options
	return useMutation(DELETE_CUSTOMHOUR, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess()
			dispatch({ type: ACTION_TYPES.REMOVE, data: data.deleteCustomHour })
		},
		refetchQueries: [
			{
				query: GET_CUSTOMHOURS_PAGINATE
			}
		]
	},
	)
}

export {
	CustomHoursProvider,
	useCustomHours,
	usePaginateCustomHours,
	useCreateCustomHour,
	useCustomHour,
	useUpdateCustomHour,
	useDeleteCustomHour
}
