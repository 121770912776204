import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { padding } from "variables/nivo/bar";
import { Box, useColorMode } from "@chakra-ui/react";
import { monochromeMainColors } from "variables/colors";
import {
  axisLeft,
  axisTop,
  labelTextColor,
  themeDark,
  themeLight,
  tooltip,
} from "variables/nivo/default";

const emptyData = [{ name: "", total: 0 }];

const LifesPerPlanChart = ({ data, keys, label }) => {
  if (typeof data === "string") data = JSON.parse(data);
  const { colorMode } = useColorMode();
  
  return (
    <ResponsiveBar
      data={data}
      keys={[keys]}
      indexBy={label}
      layout="horizontal"
      margin={{ right: 10, left: 250 }}
      padding={0.1}
      colors={monochromeMainColors}
      labelSkipWidth={24}
      theme={colorMode === "light" ? themeLight : themeDark}
      axisBottom={null}
      axisLeft={axisLeft}
      labelTextColor={"#fff"}
      tooltip={({ indexValue, value }) => tooltip(indexValue, value)}
    />
  );
};

export default LifesPerPlanChart;
