import {gql} from "@apollo/client";

export const CPT = gql`
    query CPT($filters: Filters) {
        dashboard_cpt(filters: $filters) {
            DiseasesChart {
                ds {
                    name
                    total
                }
                pa {
                    name
                    total
                }
            }
            LivesChart {
                partialCoverages
                beneficiaries
            }
            DoctorsChart {
                doctors  {
                    name
                    total
                }
            }
            EstablishedChart {
                establishedQuery {
                    name
                    total
                }
            }
            ProgressChart {
                progressQuery {
                    status
                    total
                }
            }
        }
    }
`

export const LIVES = gql`
    query LIVES($filters: Filters) {
        dashboard_lives(filters: $filters) {
            totalLives
            activeLives
            canceledLives
            livesPerPlan {
                name
                total
            }
            dailyLivesEvolution
            weeklyHsVsFr{
                id
                label
                value
            }
            agesM {
                zero_four
                five_nine
                ten_fourteen
                fifteen_nineteen
                twenty_twentyfour
                twentyfive_twentynine
                thirty_thirtyfour
                thirtyfive_thirtynine
                fourty_fourtyfour
                fourtyfive_fourtynine
                fifty_fiftyfour
                fiftyfive_fiftynine
                sixty_plus
            }
            agesF {
                zero_four
                five_nine
                ten_fourteen
                fifteen_nineteen
                twenty_twentyfour
                twentyfive_twentynine
                thirty_thirtyfour
                thirtyfive_thirtynine
                fourty_fourtyfour
                fourtyfive_fourtynine
                fifty_fiftyfour
                fiftyfive_fiftynine
                sixty_plus
            }
        }
    }
`

export const HEALTH = gql`
    query HEALTH($filters: Filters) {
        dashboard_health(filters: $filters) {
            diseasesDS
            diseasesFR
            metricDS {
                name
                total
            }
            metricFRPlus {
                name
                total
            }
            metricFRMinus {
                name
                total
            }
            imcDS {
                underweight
                normal_weight
                overweight
                obesity_grade_one
                obesity_grade_two
                obesity_grade_three
            }
            imcPA {
                underweight
                normal_weight
                overweight
                obesity_grade_one
                obesity_grade_two
                obesity_grade_three
            }
            tabacco49Plus {
                name
                total
            }
            booze49Plus {
                name
                total
            }
            physical49Plus {
                name
                total
            }
            tabacco48Minus {
                name
                total
            }
            booze48Minus {
                name
                total
            }
            physical48Minus {
                name
                total
            }
        }
    }
`

export const OPERATIONAL = gql`
    query OPERATIONAL($filters: Filters) {
        dashboard_operational(filters: $filters) {
            pendingScheduling
            done
            canceled
            scheduled
            physical
            tele
            rescheduled
            missing
            thirtyDays {
                date
                scheduled
                missing
                canceled
                attended
                rescheduled
            }
        }
    }
`

export const WHATS_APP = gql`
    query WHATS_APP($filters: Filters) {
        dashboard_whats_app(filters: $filters) {
            initialMessage
            welcomeMessage
            confirmationMessage
            appointmentMessage
            noShowMessage
            appointmentReminderMessage
            automationMessage
            firstAcknowledgementMessage
            eventInvitationMessage
            eventConfirmationMessage
            campaignMessage
            eventLimitReachedMessage
            thirtyDays {
                date
                initial
                confirmation
                reminder
                missing
                campaign
                lecture
                activity
            }
        }
    }
`

export const ACCUMULATED = gql`
    query ACCUMULATED($filters: Filters) {
        dashboard_accumulated(filters: $filters) {
            regionalsGoals_48 {
                name
                awaiting
                done
            }
            regionalsGoals_49 {
                name
                awaiting
                done
            }
            risks {
                name
                estrat
                lowrisk
                lowmedrisk
                highmedrisk
                highrisk
                done
            }
            schedules_48 {
                name
                awaiting
                scheduled
            }
            schedules_49 {
                name
                awaiting
                scheduled
            }
        }
    }
`

export const REGIONAL = gql`
    query REGIONAL($id: String!) {
        regional(id: $id) {
            plans {
                plan_name
            }
            professionals {
                id
                cpf
                name
                role
            }
        }
    }
`
