import React, { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateWhatsappDisclosure } from "hooks/whatsappDisclosures";

import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Dropzone, { useDropzone } from "react-dropzone";
import { useState } from "react";
import dateFormat from "functions/dateFormat";
import { invitationTypes } from "../variables/invitationTypes";
import { ComponentPropsToStylePropsMap } from "@aws-amplify/ui-react";

const CreateWhatsappDisclosure = () => {
  const [formFields, setFormFields] = useState(null);
  const [schemaRules, setSchemaRules] = useState(null);

  const schema = yup.object().shape({
    invitation_type: yup
      .string()
      .oneOf(["campaign", "activity", "lecture"])
      .required("Esse campo é obrigatório"),
    title: yup.string().required("Esse campo é obrigatório"),
    capacity: yup
      .number("O valor desse campo deve ser númerico")
      .typeError("O valor desse campo deve ser númerico")
      .required("Esse campo é obrigatório"),
    ...schemaRules,
  });

  const toast = useToast();
  const history = useHistory();
  const [files, setAccepted] = React.useState([]);
  const [rejected, setRejected] = React.useState(false);

  const handleDropAccept = (acceptedFiles) => {
    setAccepted(acceptedFiles);
  };

  const handleDropReject = (rejectedFiles) => {
    setRejected(true);
  };

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      invitation_type: "campaign",
      ambience: "virtual",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    watch,
    getValues,
    unregister,
  } = methods;

  const [createDisclosure, { loading, error }] = useCreateWhatsappDisclosure({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Divulgação criada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        history.push(`/sistema/administracao/whatsapp`);
    },
    onError: (errors) => {
      const errorsArr = errors ? Object.keys(errors) : null;
      if (errorsArr)
        errorsArr.map((err) => {
          if (errors[err]) {
            toast({
              title: errors[err][0],
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        });
      else
        toast({
          title: "Ocorreu um erro",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
    },
  });

  const handleFields = (data) => {
    if (data?.scheduled_to) {
      const splitted = data.scheduled_to.split(" ");
      const date = splitted[0].split("/");
      const hour = splitted[1].split(":");
      const dateTime = new Date(
        date[2],
        date[1] - 1,
        date[0],
        hour[0],
        hour[1]
      );

      data.scheduled_to = `${dateFormat(dateTime, "Y-MM-dd HH:mm")}`;
    }

    if (data.capacity) data.capacity = parseInt(getValues("capacity")) || null;

    return data;
  };

  const onSubmit = (data) => {
    data = handleFields(data);

    const variables = {
      input: {
        invitation_type: getValues("invitation_type"),
        title: getValues("title"),
        imports: files,
        ...data,
      },
    };

    createDisclosure({ variables });
  };

  const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
  const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
  const textColor = useColorModeValue("gray.700", "white");
  const wInvitationType = watch("invitation_type");

  useEffect(() => {
    const selectedFormType = invitationTypes.find(
      (i) => i.value === wInvitationType
    );

    const fields = getValues();

    for (let field of Object.keys(fields)) {
      if (field != "invitation_type") unregister(field);
    }

    if (selectedFormType)
      setFormFields(React.createElement(selectedFormType?.component, {}));

    setSchemaRules(selectedFormType?.schema);

    clearErrors();
  }, [wInvitationType]);

  const [capacity, setCapacity] = useState(0);

  const handleCapacity = (e) => {
    setCapacity(e.target.value.replace(/[^0-9]/g, ""));
  };

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
            Criar nova divulgação
          </Text>
        </CardHeader>
        <CardBody>
          <FormProvider {...methods}>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" w="100%" gap="1.5em">
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                  gap="24px"
                >
                  <FormControl isInvalid={errors.invitation_type}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Tipo de convite
                    </FormLabel>
                    <Select
                      {...register("invitation_type")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      defaultValue="campaign"
                    >
                      {invitationTypes.map((i) => (
                        <option value={i.value} disabled={i.disabled}>
                          {i.label}
                        </option>
                      ))}
                    </Select>
                    {errors.invitation_type && (
                      <FormErrorMessage>
                        {errors.invitation_type.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.title}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Título
                    </FormLabel>

                    <Input
                      {...register("title")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      color="gray.400"
                    />
                    {errors.title && (
                      <FormErrorMessage>
                        {errors.title.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Grid>

                <FormControl isInvalid={errors.capacity}>
                  <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                    Capacidade
                  </FormLabel>

                  <Input
                    {...register("capacity")}
                    focusBorderColor={inputHover}
                    borderRadius="8px"
                    fontSize="md"
                    color="gray.400"
                    placeholder="0"
                    onChange={handleCapacity}
                    value={capacity}
                  />

                  {errors.capacity && (
                    <FormErrorMessage>
                      {errors.capacity.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                {formFields}

                <FormControl>
                  <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                    Anexo
                  </FormLabel>
                  <Center>
                    <Flex
                      align="center"
                      justify="center"
                      border="1px dashed #C7CCD4"
                      borderRadius="15px"
                      w="99%"
                      mb="12px"
                      padding="1em"
                      minH="224px"
                      cursor="pointer"
                    >
                      <Dropzone
                        onDropAccepted={handleDropAccept}
                        onDropRejected={handleDropReject}
                        accept={["text/csv"]}
                        onDrop={() => setRejected(false)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            {files.length === 0 ? (
                              <p>Arraste ou selecione um ou mais arquivos</p>
                            ) : (
                              <p>{`Arquivo${
                                (files.length > 1 && "s") || ""
                              } selecionado${
                                (files.length > 1 && "s") || ""
                              }: ${files.length}`}</p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </Flex>
                  </Center>
                  {rejected && (
                    <Text color="red.500">
                      Alguns dos arquivos não foram aceitos, utilize apenas
                      arquivos do tipo CSV
                    </Text>
                  )}
                </FormControl>

                <Flex mt="24px" align={"center"} justify={"flex-end"}>
                  <NavLink to="/sistema/administracao/whatsapp">
                    <Text
                      mr="20px"
                      fontSize="xs"
                      color={textColor}
                      fontWeight="bold"
                    >
                      Cancelar
                    </Text>
                  </NavLink>
                  <Button
                    isLoading={loading}
                    type="submit"
                    variant="no-hover"
                    bg={buttonBg}
                    alignSelf="flex-end"
                    w="100px"
                    h="35px"
                  >
                    <Text fontSize="xs" color="#fff" fontWeight="bold">
                      Criar
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </FormProvider>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CreateWhatsappDisclosure;
