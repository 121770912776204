import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useColorMode } from "@chakra-ui/react";
import {
	axisLeft,
	themeDark,
	themeLight,
	tooltip,
} from "variables/nivo/default";
import { margin } from "variables/nivo/bar";
import { monochromeMainColors } from "variables/colors";
import { legends } from "variables/nivo/bar";
import { axisBottom } from "variables/nivo/default";

const DiseasesChart = ({ data, labels }) => {
	const { colorMode } = useColorMode();
	
	const getColor = (label) => {
		return label == 'Declaração de Saúde' ? monochromeMainColors[0] : monochromeMainColors[1]
	}

	return (
		<ResponsiveBar
			data={data}
			keys={["total"]}
      layout="horizontal"
			indexBy="name"
			margin={{ ...margin, right: 50, left: 400 }}
			padding={0.1}
			innerPadding={0.1}
			colors={monochromeMainColors}
			colorBy={({indexValue}) => getColor(indexValue)}
			theme={colorMode === "light" ? themeLight : themeDark}
			axisBottom={axisBottom}
			axisLeft={axisLeft}
			labelTextColor="#FFF"
			enableLabel={false}
			tooltip={({ id, indexValue, value }) => tooltip(`${indexValue}`, value)}
		/>
	);
};

export default DiseasesChart;
