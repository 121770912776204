import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addWeeks, format as fnsFormat, getWeek } from "date-fns";

import {
	useLazyBeneficiaries,
	useGetProfessionalsByRegional,
	useCreateEvent,
} from "hooks/calendar";

import {
	Button,
	Modal,
	Input,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
	VStack,
	useToast,
	Flex,
	Grid,
	FormControl,
	FormLabel,
	useColorModeValue,
	useColorMode,
	FormErrorMessage,
	Spinner,
	Skeleton,
} from "@chakra-ui/react";
import { msDarkTheme, msLightTheme } from "variables/multiselectStyle";
import EventCalendar from "components/Calendars/EventCalendar";
import { useGetProfessionalSplitTime } from "hooks/calendar";
import dateFormat from "functions/dateFormat";
import { useRegionals } from "hooks/globalRegionals";
import { useRescheduleEvent } from "hooks/commitmentsManagement";
import { assessmentsTypes } from "variables/assesmentsTypes";
import { useScheduleEvent } from "hooks/commitmentsManagement";
import { roleConsu, roleEnfer, roleSuper } from "../../../variables/roles";

const events = [
	{
		label: "Agendamento",
		value: "schedule",
	},
	{
		label: "Reagendamento",
		value: "reschedule",
	},
];

const schema = yup.object().shape({
	professional_id: yup.string().required("O campo Profissional é obrigatório"),
	type: yup.string().oneOf(["presencial", "tele"]),
	scheduled_to: yup
		.string()
		.nullable()
		.required("O campo Date e Hora é obrigatório"),
});

const EventModal = ({
	beneficiary,
	open,
	setEventModal,
	checked = null,
	onSuccess = null,
}) => {
	const toast = useToast();
	const { regional: globalRegional } = useRegionals();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
		getValues,
		setValue,
	} = useForm({
		mode: "onBlur",
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const [currentDate, setCurrentDate] = useState(new Date());
	const [eventDate, setEventDate] = useState(null);
	const [brDate, setBrDate] = useState(null);
	const [awaitingSchedule, setAwaitingSchedule] = useState(
		beneficiary.checked || beneficiary.events?.[0]?.id || null
	);
	const [professionals, setProfessionals] = useState(null);
	const [professional, setProfessional] = useState(null);
	const [type, setType] = useState("presencial");
	const [duration, setDuration] = useState("20min");

	const wType = watch("type");
	const wProfessional = watch("professional_id", null);

	const [turn, setTurn] = useState(null);
	const [splitTime, setSplitTime] = useState([]);
	const [regional, setRegional] = useState("");

	const closeModal = () => {
		reset();
		setEventDate(null);
		setBrDate(null);
		setProfessional(null);
		setTurn(null);
		setSplitTime([]);
		setEventModal(false);
	};

	const [createEvent, { loading: loadingCreate }] = useScheduleEvent({
		onSuccess: () => {
			toast({
				title: "Agendamento criado com sucesso.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			if (onSuccess)
				onSuccess('remove',
					getValues("awaiting_schedule_id") !== beneficiary.exclude
						? getValues("awaiting_schedule_id")
						: null
				);
			closeModal();
		},
		onError: (err) => {
			toast({
				title: "Ocorreu um erro",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		},
	});

	const [rescheduleEvent, { loading: loadingReschedule }] = useRescheduleEvent(
		beneficiary.table,
		{
			onSuccess: (data) => {
				toast({
					title: "Reagendamento realizado com sucesso.",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
				if (onSuccess)
					onSuccess('update', { id: beneficiary.event_id, newId: data.rescheduleEvent.id, scheduled_to: getValues('scheduled_to'), type: type });
				closeModal();
			},
			onError: (err) => {
				toast({
					title: "Ocorreu um erro",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			},
		}
	);

	React.useEffect(() => {
		if (eventDate) {
			const date = dateFormat(eventDate, "yyyy-MM-dd HH:mm:ss");
			setValue("scheduled_to", date);
			if (type == "presencial") {
				const datee = dateFormat(eventDate, "dd/MM/yyyy HH:mm:ss");
				setBrDate(datee);
			} else {
				const datee = dateFormat(eventDate, "dd/MM/yyyy");
				setBrDate(`${datee} ${turn}`);
			}
		}
	}, [eventDate]);

	React.useEffect(() => {
		loadProfessionalTime(professional);
	}, [type, duration]);

	const resetDate = () => {
		setEventDate(null);
		setBrDate(null);
		setValue("scheduled_to", null);
	};

	const [
		loadProfessinals,
		{ loading: loadingProfessionals },
	] = useGetProfessionalsByRegional();
	const [
		loadProfessionalSplitTime,
		{ loading: loadingTime },
	] = useGetProfessionalSplitTime();

	useEffect(() => {
		if (globalRegional) {
			setRegional(globalRegional);
			loadProfessionalsByRegional(globalRegional);
		}
	}, [globalRegional]);

	const loadProfessionalsByRegional = async (value) => {
		setRegional(value);
		setValue("regional_id", value);
		const { data } = await loadProfessinals({
			variables: {
				regional_id: value,
				accepted_roles: [roleEnfer, roleConsu],
			},
		});
		setProfessionals(data?.professionals);
	};

	const handleEventClick = (time, turn = null) => {
		setTurn(turn);
		setEventDate(time);
	};

	const loadProfessionalTime = async (value) => {
		setSplitTime([]);
		const { data } = await loadProfessionalSplitTime({
			variables: {
				input: {
					week: getWeek(currentDate),
					regional: regional,
					professional: value || null,
					type: type,
					duration: duration,
				},
			},
		});
		let newSplitTime = [];
		if (data?.splitTime) {
			data.splitTime.map((st) => {
				newSplitTime.push({ ...st, className: "fc-event-primary" });
			});
		}
		setSplitTime(newSplitTime);
	};

	const changeDate = async (event) => {
		let week = null;
		if (event !== "today") {
			setCurrentDate(addWeeks(currentDate, event == "next" ? 1 : -1));
			week = getWeek(addWeeks(currentDate, event == "next" ? 1 : -1));
		} else {
			setCurrentDate(new Date());
			week = getWeek(currentDate);
		}
		const { data } = await loadProfessionalSplitTime({
			variables: {
				input: {
					week: week,
					regional: regional,
					professional: professional,
					type: type,
					duration: duration,
				},
			},
		});
		let newSplitTime = [];
		if (data?.splitTime) {
			data.splitTime.map((st) => {
				newSplitTime.push({ ...st, className: "fc-event-primary" });
			});
		}

		setSplitTime(newSplitTime);
	};

	const onSubmit = () => {
		const scheduleType = beneficiary?.type;

		if (scheduleType == "reschedule") {
			const variables = {
				input: {
					patient_id: beneficiary.id,
					event_id: beneficiary.event_id,
					professional_id: getValues("professional_id"),
					scheduled_to: getValues("scheduled_to"),
					type: type,
					attendance_duration: duration,
				},
			};

			rescheduleEvent({ variables: variables });
		} else {
			if (!getValues("awaiting_schedule_id")) {
				setError("awaiting_schedule_id", "Campo Compromisso é obrigatório");
				return false;
			}
			const variables = {
				input: {
					patient_id: beneficiary.id,
					regional_id: globalRegional,
					event_id: beneficiary.event_id,
					awaiting_schedule_id: getValues("awaiting_schedule_id"),
					professional_id: getValues("professional_id"),
					scheduled_to: getValues("scheduled_to"),
					type: type,
					attendance_duration: duration,
				},
			};
			createEvent({ variables: variables });
		}
	};
	const textColor = useColorModeValue("gray.700", "white");

	return (
		<Modal
			isCentered
			onClose={() => closeModal()}
			isOpen={open}
			motionPreset="slideInBottom"
			size={"xl"}
			autoFocus={false}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{events.find((ev) => ev.value == beneficiary?.type || "")?.label ||
							null}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex direction="column" w="100%">
							<Grid
								templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
								gap="24px"
							>
								<FormControl isInvalid={errors.patient_id}>
									<FormLabel color={textColor} fontWeight="bold" fontSize="xs">
										Beneficiário
									</FormLabel>
									<Text fontSize="lg">{beneficiary?.name || null}</Text>
								</FormControl>
								<FormControl isInvalid={errors.awaiting_schedule_id}>
									<FormLabel color={textColor} fontWeight="bold" fontSize="xs">
										Tipo de Compromisso
									</FormLabel>
									{beneficiary.type == "reschedule" ? (
										<Text fontSize="lg">
											{assessmentsTypes[beneficiary.events] ||
												beneficiary?.event ||
												"Declaração de Saúde"}
										</Text>
									) : (
										<Select
											{...register("awaiting_schedule_id")}
											value={awaitingSchedule}
											onChange={({ target }) =>
												setAwaitingSchedule(target.value)
											}
											disabled={!beneficiary}
											placeholder="Selecione uma opção..."
										>
											{beneficiary?.events?.map((ev) => (
												<option value={ev.id}>{ev.awaiting_schedule}</option>
											))}
										</Select>
									)}
									{errors.awaiting_schedule_id && (
										<FormErrorMessage>
											{errors.awaiting_schedule_id.message}
										</FormErrorMessage>
									)}
								</FormControl>
								<FormControl isInvalid={errors.professional_id}>
									<FormLabel color={textColor} fontWeight="bold" fontSize="xs">
										Selecione o Profissional
									</FormLabel>
									<Skeleton isLoaded={!loadingProfessionals} height="40px">
										<Select
											{...register("professional_id")}
											onChange={({ target }) => {
												setProfessional(target.value);
												loadProfessionalTime(target.value);
											}}
											placeholder="Selecione uma opção..."
										>
											{professionals &&
												professionals.map((p) => (
													<option value={p.id}>{p.user.name}</option>
												))}
										</Select>
									</Skeleton>
									{errors.professional_id && (
										<FormErrorMessage>
											{errors.professional_id.message}
										</FormErrorMessage>
									)}
								</FormControl>
								{professional != null && professional != "" && (
									<FormControl>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Selecione o tipo de agendamento
										</FormLabel>
										<Select onChange={({ target }) => setType(target.value)}>
											<option value="presencial">Presencial</option>
											<option value="tele">Tele-atendimento</option>
										</Select>
									</FormControl>
								)}
								{professional != null &&
									professional != "" &&
									type === "presencial" && (
										<FormControl isInvalid={errors.attendance_duration}>
											<FormLabel
												color={textColor}
												fontWeight="bold"
												fontSize="xs"
											>
												Selecione a duração do atendimento
											</FormLabel>
											<Select
												value={duration}
												onChange={({ target }) => setDuration(target.value)}
												disabled={
													!professionals ||
													(professionals && professionals.length < 1)
												}
											>
												<option value="20min">20min</option>
												<option value="10min">10min</option>
											</Select>
											{errors.attendance_duration && (
												<FormErrorMessage>
													{errors.attendance_duration.message}
												</FormErrorMessage>
											)}
										</FormControl>
									)}
								{professional && professional != null && (
									<FormControl
										isInvalid={errors.scheduled_to}
										mb={eventDate == null ? "120px" : ""}
										pb="30px"
										height={"100%"}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Selecione a data e hora
										</FormLabel>
										{loadingTime ? (
											<Flex
												width="100%"
												height="100%"
												justifyContent="center"
												alignItems="center"
											>
												<Spinner />
											</Flex>
										) : (splitTime &&
											splitTime.length > 0 &&
											!getValues("scheduled_to")) ||
											getValues("scheduled_to") == null ? (
											<EventCalendar
												calendarData={splitTime}
												initialDate={currentDate}
												handleEventClick={handleEventClick}
												event
												changeDate={changeDate}
												loading={loadingTime}
												weekView
											/>
										) : (
											<Flex alignItems="center">
												<Text fontSize="20px" mr="15px">
													{brDate}
												</Text>
												<Button
													backgroundColor="notrelife.800"
													color="white"
													variant="no-hover"
													onClick={() => resetDate()}
												>
													Alterar
												</Button>
											</Flex>
										)}
										{errors.scheduled_to && (
											<FormErrorMessage>
												{errors.scheduled_to.message}
											</FormErrorMessage>
										)}
									</FormControl>
								)}
							</Grid>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<Button
							disabled={loadingCreate || loadingReschedule}
							mr={3}
							variant="ghost"
							onClick={() => closeModal()}
						>
							Cancelar
						</Button>
						<Button
							isLoading={loadingCreate || loadingReschedule}
							backgroundColor="notrelife.800"
							color="white"
							variant="no-hover"
							type="submit"
						>
							Agendar
						</Button>
					</ModalFooter>
				</ModalContent>
			</form>
		</Modal>
	);
};

export default EventModal;
