// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Spacer,
	Stack,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import IconBox from "components/Icons/IconBox";
import React, { useEffect, useState } from "react";
import { RiArrowDropRightLine, RiCloseLine } from "react-icons/ri";
import FaIcon from "components/FontAwesomeIcons/Icon";
import { useQuery } from "@apollo/client";
import { GET_CID_COUNT } from "graphql/cpt";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import ModalAssociateCid10 from "./ModalAssociateCid10";
import { useAssocCid, useGetCidMeasurements } from "hooks/cpt";
import { useHistory, useParams } from "react-router-dom";
import {
	STATUS_CPT_AWAITING_ASSIGN_AT,
	STATUS_CPT_AWAITING_DOCUMENTS,
	STATUS_CPT_MEDICAL_ANALYSIS,
	STATUS_CPT_MEDICAL_ANALYSIS_DOCUMENTS,
	STATUS_CPT_SIGNED,
} from "../../../functions/enums";
import { useAuth } from "hooks/useAuth";
import { roleAdmin, roleMedic, roleSuper } from "../../../variables/roles";
import ModalUpdateCid10 from "./ModalUpdateCid10";

const DiseaseItem = ({ disease, index, onRemove, onSelectCid }) => {
	const { user } = useAuth();
	const canEdit = [roleAdmin, roleSuper, roleMedic].includes(
		user?.role || null
	);

	const textColor = useColorModeValue("gray.700", "white");
	const bgButton = useColorModeValue("notrelife.900", "notrelife.900");
	const iconBoxInside = useColorModeValue("white", "white");
	const diseaseSearch = disease.replace(" ", "%").replace(",", "%");
	const { data, loading } = useQuery(GET_CID_COUNT, {
		variables: {
			search: `%${diseaseSearch}%`,
		},
	});

	return (
		<Flex align="center" w="100%">
			<Flex align="center">
				<IconBox as="box" minH="40px" minW="40px" bg={bgButton} me="18px">
					<FaIcon
						h={"20px"}
						w={"20px"}
						color={iconBoxInside}
						icon="medical_case"
					/>
				</IconBox>
				<Flex direction="column">
					<Text fontSize="sm" fontWeight="bold" color={textColor}>
						{disease}
					</Text>
					{!loading && (
						<Text color="gray.400" fontSize="xs">
							Códigos CID encontrados,{" "}
							<Text as="span" fontWeight="bold">
								{data?.cid} casos
							</Text>
						</Text>
					)}
				</Flex>
			</Flex>
			<Spacer />
			{canEdit && (
				<>
					<Button variant="transparent-with-icon" px="0px">
						<Icon
							as={RiCloseLine}
							color="gray.300"
							w="30px"
							h="30px"
							cursor="pointer"
							onClick={() => onRemove(disease)}
						/>
					</Button>
					<Button variant="transparent-with-icon" px="0px">
						<Icon
							as={RiArrowDropRightLine}
							color="gray.400"
							w="30px"
							h="30px"
							cursor="pointer"
							transition="all .25s ease"
							onClick={() => onSelectCid(disease, index)}
							_hover={{ transform: "translateX(25%)" }}
						/>
					</Button>
				</>
			)}
		</Flex>
	);
};

const CidItem = ({ disease, index, onSelectCid }) => {
	const { user } = useAuth();
	const canEdit = [roleAdmin, roleSuper, roleMedic].includes(
		user?.role || null
	);

	const textColor = useColorModeValue("gray.700", "white");
	const bgButton = useColorModeValue("blue.400", "blue.400");
	const iconBoxInside = useColorModeValue("white", "white");

	return (
		<Flex align="center" w="100%">
			<Flex align="center">
				<IconBox as="box" minH="40px" minW="40px" bg={bgButton} me="18px">
					<FaIcon
						h={"20px"}
						w={"20px"}
						color={iconBoxInside}
						icon="medical_case"
					/>
				</IconBox>
				<Flex direction="column">
					<Text fontSize="sm" fontWeight="bold" color={textColor}>
						{disease.code} - {disease.name}
					</Text>
				</Flex>
			</Flex>
			<Spacer />
			{canEdit && (
				<>
					<Button variant="transparent-with-icon" px="0px">
						<Icon
							as={RiArrowDropRightLine}
							color="gray.400"
							w="30px"
							h="30px"
							cursor="pointer"
							transition="all .25s ease"
							onClick={() => onSelectCid(disease, index)}
							_hover={{ transform: "translateX(25%)" }}
						/>
					</Button>
				</>
			)}
		</Flex>
	);
};

const Categories = ({
	status = null,
	awaiting_diseases = [],
	cidList = [],
	assign,
	awaitingCid,
	beneficiary_id,
	user,
	...props
}) => {
	const history = useHistory();
	const textColor = useColorModeValue("gray.700", "white");
	const [dataRemoveConfirmation, setDataRemoveConfirmation] = useState(null);
	const [dataFinishAnalize, setDataFinishAnalize] = useState(null);
	const [signDocument, setSignDocument] = useState(null);
	const [dataMedicalAnalysis, setDataMedicalAnalysis] = useState(null);
	const [finishSign, setFinishSign] = useState(null);
	const { id } = useParams();
	const [assocCid, setAssocCid] = useState(null);
	const [editCid, setEditCid] = useState(null);
	const [dataCidMeasurements, setCidMeasurements] = useState(null);

	const [
		getCidMeasurements,
		{ loading: loadingMeasurementsCid, data },
	] = useGetCidMeasurements({
		onCompleted: (data) => {
			setCidMeasurements(data);
		},
		onError: () => {
			console.log("Errou!");
		},
	});

	const [updateCpt, { loading: loadingCPT }] = useAssocCid({
		onCompleted: (data) => {
			setDataRemoveConfirmation(null);
		},
		onError: () => {
			console.log("Errou!");
		},
	});

	const bgButton = useColorModeValue("notrelife.900", "notrelife.900");
	const iconBoxInside = useColorModeValue("white", "white");

	const onRemove = async (d) => {
		const newAwaiting = awaiting_diseases.filter((i) => i !== d);

		await updateCpt({
			variables: {
				input: {
					id,
					awaiting_diseases: newAwaiting,
					user_id: user.id,
				},
			},
		});
		setDataFinishAnalize(null);
	};

	const noFinalize = async () => {
		await updateCpt({
			variables: {
				input: {
					id,
					awaiting_diseases: [],
					status:
						status == STATUS_CPT_MEDICAL_ANALYSIS
							? STATUS_CPT_MEDICAL_ANALYSIS_DOCUMENTS
							: STATUS_CPT_AWAITING_DOCUMENTS,
					user_id: user.id,
				},
			},
		});
		setDataFinishAnalize(null);
		history.goBack();
	};

	const sendDocument = async () => {
		await updateCpt({
			variables: {
				input: {
					id,
					status: STATUS_CPT_AWAITING_ASSIGN_AT,
					user_id: user.id,
				},
			},
		});
		setSignDocument(null);
		history.goBack();
	};

	const sendToMedicalAnalysis = async () => {
		await updateCpt({
			variables: {
				input: {
					id,
					status:
						status == "awaiting_cid"
							? STATUS_CPT_MEDICAL_ANALYSIS
							: STATUS_CPT_MEDICAL_ANALYSIS_DOCUMENTS,
					user_id: user.id,
				},
			},
		});
		setDataMedicalAnalysis(null);
	};

	const sendSignature = async () => {
		await updateCpt({
			variables: {
				input: {
					id,
					status: STATUS_CPT_SIGNED,
					assign_at: new Date(),
					user_id: user.id,
				},
			},
		});
		setFinishSign(null);
		history.goBack();
	};

	const openModalCid = (disease, index) => {
		setAssocCid(disease);
	};
	const openEditModalCid = (disease, index) => {
		setEditCid(disease);
	};

	useEffect(() => {
		getCidMeasurements({
			variables: {
				cpt: {
					column: "IDENTIFIER_ID",
					operator: "EQ",
					value: beneficiary_id,
					AND: [
						{
							column: "ID",
							operator: "NEQ",
							value: id,
						},
					],
				},
			},
		});
	}, [beneficiary_id, id]);

	return (
		<React.Fragment>
			{(status == "awaiting_documents" || awaitingCid) && (
				<Button
					p="5px"
					mb="12px"
					color={"white"}
					bg="green.400"
					_hover={{ bg: "green.500" }}
					onClick={() => setDataMedicalAnalysis(id)}
				>
					Enviar para Análise Médica
				</Button>
			)}
			{awaiting_diseases.length > 0 ? (
				<Card>
					<CardHeader>
						<Text fontSize="lg" text={textColor} fontWeight="bold">
							Aguardando seleção de código cid
						</Text>
					</CardHeader>
					<CardBody w="100%" pt="28px">
						<Stack direction="column" spacing="24px" w="100%">
							{awaiting_diseases.map((d, index) => (
								<DiseaseItem
									disease={d}
									index={index}
									onRemove={setDataRemoveConfirmation}
									onSelectCid={openModalCid}
								/>
							))}
						</Stack>
					</CardBody>
				</Card>
			) : (
				!assign &&
				(awaitingCid || status == "medical_analysis") && (
					<Button
						p="5px"
						color={"white"}
						bg="green.400"
						_hover={{ bg: "green.500" }}
						onClick={() => setDataFinishAnalize(id)}
					>
						Finalizar Analise
					</Button>
				)
			)}
			{(status == "awaiting_documents" ||
				status == "medical_analysis_documents") && (
					<Button
						p="5px"
						color={"white"}
						bg="green.400"
						_hover={{ bg: "green.500" }}
						onClick={() => setSignDocument(id)}
					>
						Termo Enviado
					</Button>
				)}
			{status == "awaiting_assign_at" && (
				<Button
					p="5px"
					color={"white"}
					bg="green.400"
					_hover={{ bg: "green.500" }}
					onClick={() => setFinishSign(id)}
				>
					Assinatura Coletada
				</Button>
			)}
			<Card mt="10px">
				<CardHeader>
					<Text fontSize="lg" text={textColor} fontWeight="bold">
						Doenças CID encontradas
					</Text>
				</CardHeader>
				<CardBody w="100%" pt="28px">
					<Stack direction="column" spacing="24px" w="100%">
						{cidList.map((d, index) => (
							<CidItem
								disease={d}
								index={index}
								onSelectCid={openEditModalCid}
							/>
						))}
					</Stack>
				</CardBody>
			</Card>
			<Card mt="10px">
				<CardHeader>
					<Text fontSize="lg" text={textColor} fontWeight="bold">
						Doenças encontradas em outras CPT
					</Text>
				</CardHeader>
				<CardBody w="100%" pt="28px">
					<Stack direction="column" spacing="24px" w="100%">
						{dataCidMeasurements &&
							dataCidMeasurements?.cidFoundAnotherMeasurements?.map((d) => (
								<Flex align="center" w="100%">
									<Flex align="center">
										<IconBox
											as="box"
											h={"40px"}
											w={"40px"}
											bg={"blue.400"}
											me="18px"
											style={{ minWidth: "40px" }}
										>
											<FaIcon
												h={"20px"}
												w={"20px"}
												color={iconBoxInside}
												icon="medical_case"
											/>
										</IconBox>
										<Flex direction="column">
											<Text fontSize="sm" fontWeight="bold" color={textColor}>
												{d.code} - {d.name}
											</Text>
										</Flex>
									</Flex>
									<Spacer />
								</Flex>
							))}
					</Stack>
				</CardBody>
			</Card>
			<ConfirmationModal
				data={dataRemoveConfirmation}
				onConfirm={onRemove}
				isLoading={loadingCPT}
				closeModal={() => setDataRemoveConfirmation(null)}
				isOpen={dataRemoveConfirmation !== null}
				title={"Remover Doença"}
				message={"Deseja realmente remover a doença aferida"}
				btnMessage={"Sim, Desejo"}
			/>

			<ConfirmationModal
				data={dataFinishAnalize}
				onConfirm={noFinalize}
				isLoading={loadingCPT}
				closeModal={() => setDataFinishAnalize(null)}
				isOpen={dataFinishAnalize !== null}
				title={"Finalizar analise"}
				message={
					"Deseja realmente finalizar o processo de analise das doenças aferidas?"
				}
				btnMessage={"Sim, Desejo"}
			/>

			<ConfirmationModal
				data={signDocument}
				onConfirm={sendDocument}
				isLoading={loadingCPT}
				closeModal={() => setSignDocument(null)}
				isOpen={signDocument !== null}
				title={"Envio do termo"}
				message={"Deseja realmente realizar o envio do termo?"}
				btnMessage={"Sim, Desejo"}
			/>

			<ConfirmationModal
				data={setDataMedicalAnalysis}
				onConfirm={sendToMedicalAnalysis}
				isLoading={loadingCPT}
				closeModal={() => setDataMedicalAnalysis(null)}
				isOpen={dataMedicalAnalysis !== null}
				title={"Enviar para Análise Médica"}
				message={"Deseja realmente enviar para análise médica?"}
				btnMessage={"Sim, Desejo"}
			/>

			<ConfirmationModal
				data={finishSign}
				onConfirm={sendSignature}
				isLoading={loadingCPT}
				closeModal={() => setFinishSign(null)}
				isOpen={finishSign !== null}
				title={"Assinatura Coletada"}
				message={"Deseja realmente finalizar a assinatura coletada?"}
				btnMessage={"Sim, Desejo"}
			/>

			<ModalAssociateCid10
				isOpen={assocCid !== null}
				disease={assocCid}
				closeModal={() => setAssocCid(null)}
				data={awaiting_diseases}
				onSuccess={() => setAssocCid(null)}
				user={user}
			/>

			<ModalUpdateCid10
				isOpen={editCid !== null}
				disease={editCid}
				closeModal={() => setEditCid(null)}
				data={awaiting_diseases}
				onSuccess={() => setEditCid(null)}
				user={user}
			/>
		</React.Fragment>
	);
};

export default Categories;
