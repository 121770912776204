import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import { cpf as cpfValidator } from "cpf-cnpj-validator";

import { useUpdateBeneficiary } from "hooks/beneficiaries";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Spinner,
  Tooltip,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import states from "variables/states";
import cities from "variables/cities";
import dateFormat from "functions/dateFormat";
import getCEP from "../../../../functions/getCEP";
import AsyncPlansMultiselect from "./components/AsyncPlansMultiselect";
import { useGetProfessionalsByRegional } from "hooks/beneficiary";
import { useRegionals } from "hooks/globalRegionals";
import Icon from "components/FontAwesomeIcons/Icon";
import { isAfter, parse } from "date-fns";

const schema = yup.object().shape({
  proposal: yup.string().required("O campo Número da Proposta é obrigatório"),
  beneficiary: yup.object().shape({
    name: yup.string().required("O campo Nome é obrigatório"),
    mother_name: yup.string(),
    email: yup.string().email("Insira um e-mail válido").nullable(),
    cpf: yup
      .string()
      .required("O campo CPF é obrigatório")
      .test("cpf-valid", "O CPF informado não é válido", function (field) {
        return cpfValidator.isValid(field);
      }),
    rg: yup.string(),
    phone: yup
      .string()
      .required("O campo Celular é obrigatório")
      .matches(/\(\d{2}\) (\d{5})-(\d{4})/, "Insira um telefone válido"),
    gender: yup.string().required("O campo Gênero é obrigatório"),
    birthdate: yup
      .string()
      .required("O campo Data de Nascimento é obrigatório")
			.test("is-after-1900", "Data de nascimento inválida", (date => {
        if(!date || date == '') return false;
				const referenceDate = new Date(1900, 0, 1);
				const inputDate = date.substring(0, 10);
				const splitedDate = inputDate.split('-');
				const realDate = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
				const parsedDate = parse(realDate, 'dd/MM/yyyy', new Date());
				const isAfterDate = isAfter(parsedDate, referenceDate);
				return isAfterDate;
			})),
    cep: yup.string(),
    state_id: yup.string().nullable(),
    city_id: yup.string().nullable(),
    address: yup.string(),
    district: yup.string(),
    complement: yup.string(),
  }),
});

const EditBeneficiary = ({ beneficiary, loadingBenef, open, onOpen, onClose }) => {
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      beneficiary: {
        state_id: beneficiary?.state_id,
      },
    },
  });
  const toast = useToast();

  const [
    getProfessionalsByRegional,
    { data: professionals, loading: loadingProfessionals },
  ] = useGetProfessionalsByRegional();

  const [professional, setProfessional] = useState(
    beneficiary.manager?.id || null
  );

  const close = () => {
    onClose();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
  } = methods;

  const wState = watch("beneficiary.state_id");

  setValue("beneficiary.coverage", true);

  const [noParseBirth, setNoParseBirth] = useState(null);

  const { regional: regionalId } = useRegionals();

  useEffect(() => {
    getProfessionalsByRegional({
      variables: {
        regional_id: regionalId,
        accepted_roles: ["Enfermeiro", "Consultor_de_Saúde"],
      },
    });
  }, []);

  useEffect(() => {
    setValue("beneficiary.professional_id", professional);
  }, [professional]);

  useEffect(() => {
    if (beneficiary.birthdate) {
      setValue("beneficiary.birthdate", beneficiary.birthdate);
    }
  }, [beneficiary]);

  useEffect(() => {
    if (noParseBirth && noParseBirth.replace("_", "").length === 10) {
      const date = noParseBirth.split("/");
      setValue(
        "beneficiary.birthdate",
        `${date[2]}-${date[1]}-${date[0]} 00:00:00`
      );
    }
  }, [noParseBirth]);

  const [
    EditBeneficiary,
    { eventData, loading: loadingCreate, eventError },
  ] = useUpdateBeneficiary({
    onSuccess: () => {
      reset(),
        toast({
          title: "Beneficiário atualizado com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        }),
        close();
    },
    onError: (errors) => {
      const errorsArr = errors ? Object.keys(errors) : null;
      if (errorsArr)
        errorsArr.map((err) => {
          if (errors[err]) {
            toast({
              title: errors[err][0],
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        });
      else
        toast({
          title: "Ocorreu um erro",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
    },
  });

  const onSubmit = (data) => {
    if (!getValues("beneficiary.state_id"))
      setValue("beneficiary.state_id", null);
    if (!getValues("beneficiary.city_id"))
      setValue("beneficiary.city_id", null);

    const variables = {
      input: {
        id: beneficiary.proposal.id,
        proposal_number: getValues("proposal"),
        plan_id: getValues("plan_id"),
        beneficiaries: {
          update: [
            {
              id: beneficiary.id,
              ...getValues("beneficiary"),
            },
          ],
        },
      },
    };

    EditBeneficiary({ variables: variables });
  };

  const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
  const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
  const textColor = useColorModeValue("gray.700", "white");

  const secondPhone = watch("beneficiary.second_phone", null);
  const [cep, setCep] = useState(watch("cep", ""));

  useEffect(() => {
    if (cep) {
      const cleanCEP = cep.replace(/[^0-9]/g, "");
      if (cleanCEP && cleanCEP.length === 8) {
        getCEP(cleanCEP)
          .then((data) => {
            if (data) {
              if (data?.erro) throw new Error("Cep não encontrado");

              if (data.uf) {
                const state = states.find(
                  (state) => state.initials === data.uf
                );
                if (state) setValue("state_id", String(state.id));
              }
              if (data.logradouro) setValue("address", data.logradouro);
              if (data.complemento) setValue("complement", data.complemento);
              if (data.bairro) setValue("district", data.bairro);
              if (data.localidade) {
                const city = cities.find(
                  (city) => city.name === data.localidade
                );
                if (city) setValue("city_id", String(city.id));
              }
            }
          })
          .catch((error) =>
            toast({
              title: "CEP não encontrado.",
              status: "error",
              duration: 5000,
              isClosable: true,
            })
          );
      }
    }
  }, [cep]);

  return (
    <>
      <Box>
        <Tooltip label="Editar dados" placement="top" closeOnClick={true}>
          <Button
            background="notrelife.800"
            _hover={{ background: "notrelife.700" }}
            color="white"
            disabled={loadingBenef}
            onClick={onOpen}
          >
            {loadingBenef ? <Spinner /> : <Icon icon="edit" />}
          </Button>
        </Tooltip>
      </Box>
      <Modal
        isCentered
        onClose={() => close()}
        isOpen={open}
        motionPreset="slideInBottom"
        size="xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Atualizar dados de {beneficiary.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormProvider {...methods}>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                    gap="24px"
                  >
                    <FormControl isInvalid={errors.plan_id}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Plano
                      </FormLabel>
                      <AsyncPlansMultiselect
                        isMulti={false}
                        {...register("plan_id")}
                        defaultValues={beneficiary?.proposal?.plan}
                      />
                      {errors.proposal && (
                        <FormErrorMessage>
                          {errors.proposal.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.proposal}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Número da Proposta
                      </FormLabel>
                      <Input
                        {...register("proposal")}
                        defaultValue={
                          beneficiary?.proposal?.proposal_number || null
                        }
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.proposal && (
                        <FormErrorMessage>
                          {errors.proposal.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.state}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Profissional Responsável
                      </FormLabel>
                      <Skeleton height="40px" isLoaded={!loadingProfessionals}>
                        <Select
                          focusBorderColor={inputHover}
                          value={professional}
                          onChange={({ target }) =>
                            setProfessional(target?.value)
                          }
                          borderRadius="8px"
                          fontSize="md"
                          placeholder="Selecione uma opção..."
                          color="gray.400"
                        >
                          {professionals?.professionals?.map((p) => (
                            <option value={p?.id}>{p?.user?.name}</option>
                          ))}
                        </Select>
                      </Skeleton>
                      {errors?.state && (
                        <FormErrorMessage>
                          {errors?.state?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors?.beneficiary?.name}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Nome completo
                      </FormLabel>
                      <Input
                        {...register("beneficiary.name")}
                        defaultValue={beneficiary?.name || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.name && (
                        <FormErrorMessage>
                          {errors.beneficiary?.name.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.mother_name}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Nome da mãe
                      </FormLabel>
                      <Input
                        {...register("beneficiary.mother_name")}
                        defaultValue={beneficiary?.mother_name || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.mother_name && (
                        <FormErrorMessage>
                          {errors.beneficiary?.mother_name.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.email}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        E-mail
                      </FormLabel>
                      <Input
                        {...register("beneficiary.email")}
                        defaultValue={beneficiary?.email || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.email && (
                        <FormErrorMessage>
                          {errors.beneficiary?.email.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.cpf}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        CPF
                      </FormLabel>
                      <Input
                        {...register("beneficiary.cpf")}
                        defaultValue={beneficiary?.cpf || null}
                        as={InputMask}
                        mask="999.999.999-99"
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.cpf && (
                        <FormErrorMessage>
                          {errors.beneficiary?.cpf.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.rg}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        RG
                      </FormLabel>
                      <Input
                        {...register("beneficiary.rg")}
                        defaultValue={beneficiary?.rg || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.rg && (
                        <FormErrorMessage>
                          {errors.beneficiary?.rg.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.phone}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Celular
                      </FormLabel>
                      <Input
                        {...register("beneficiary.phone")}
                        defaultValue={beneficiary?.phone || null}
                        as={InputMask}
                        mask="(99) 99999-9999"
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.phone && (
                        <FormErrorMessage>
                          {errors.beneficiary?.phone.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl isInvalid={errors.beneficiary?.second_phone}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Telefone secundário
                      </FormLabel>
                      <Input
                        {...register("beneficiary.second_phone")}
                        defaultValue={beneficiary?.second_phone || null}
                        as={InputMask}
                        mask="(99) 99999-9999"
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.second_phone && (
                        <FormErrorMessage>
                          {errors.beneficiary?.second_phone.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    {((secondPhone &&
                      secondPhone !== "" &&
                      secondPhone !== "(__) _____-____") ||
                      beneficiary.second_phone) && (
                      <Box>
                        <FormControl
                          marginBottom="15px"
                          isInvalid={errors.beneficiary?.second_phone_contact}
                        >
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                          >
                            Nome do contato secundário
                          </FormLabel>
                          <Input
                            {...register("beneficiary.second_phone_contact")}
                            defaultValue={
                              beneficiary?.second_phone_contact || null
                            }
                            focusBorderColor={inputHover}
                            borderRadius="8px"
                            fontSize="md"
                          />
                          {errors.beneficiary?.second_phone_contact && (
                            <FormErrorMessage>
                              {errors.beneficiary?.second_phone_contact.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isInvalid={errors.beneficiary?.second_phone_type}
                        >
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                          >
                            Tipo do contato secundário
                          </FormLabel>
                          <Select
                            {...register("beneficiary.second_phone_type")}
                            defaultValue={
                              beneficiary?.second_phone_type || null
                            }
                            focusBorderColor={inputHover}
                            borderRadius="8px"
                            fontSize="md"
                          >
                            <option value="primary_beneficiary">
                              Beneficiário Titular
                            </option>
                            <option value="beneficiary">Beneficiário</option>
                            <option value="kinsman">Parente</option>
                            <option value="external">Terceiro</option>
                          </Select>

                          {errors.beneficiary?.second_phone_type && (
                            <FormErrorMessage>
                              {errors.beneficiary?.second_phone_type.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                    )}
                    <FormControl isInvalid={errors.beneficiary?.gender}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Gênero
                      </FormLabel>
                      <Select
                        {...register("beneficiary.gender")}
                        defaultValue={beneficiary?.gender || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        placeholder="Selecione uma opção..."
                        color="gray.400"
                      >
                        <option value="m">Masculino</option>
                        <option value="f">Feminino</option>
                      </Select>
                      {errors.beneficiary?.gender && (
                        <FormErrorMessage>
                          {errors.beneficiary?.gender.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.birthdate}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Data de Nascimento
                      </FormLabel>
                      <Input
                        as={InputMask}
                        mask="99/99/9999"
                        defaultValue={
                          beneficiary?.birthdate
                            ? dateFormat(beneficiary.birthdate)
                            : null
                        }
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        onChange={({ target }) => setNoParseBirth(target.value)}
                      />
                      {errors.beneficiary?.birthdate && (
                        <FormErrorMessage>
                          {errors.beneficiary?.birthdate.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.cep}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        CEP
                      </FormLabel>
                      <Input
                        {...register("beneficiary.cep")}
                        as={InputMask}
                        mask="99999-999"
                        defaultValue={beneficiary?.cep || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        onChange={({ target }) => setCep(target.value)}
                      />
                      {errors.beneficiary?.cep && (
                        <FormErrorMessage>
                          {errors.beneficiary?.cep.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.state_id}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Estado
                      </FormLabel>
                      <Select
                        {...register("beneficiary.state_id")}
                        defaultValue={beneficiary?.state_id || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        placeholder="Selecione uma opção..."
                        color="gray.400"
                      >
                        {states.map((state) => (
                          <option value={state.id}>{state.name}</option>
                        ))}
                      </Select>
                      {errors.beneficiary?.state_id && (
                        <FormErrorMessage>
                          {errors.beneficiary?.state_id.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.city_id}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Cidade
                      </FormLabel>
                      <Select
                        {...register("beneficiary.city_id")}
                        defaultValue={beneficiary?.city_id || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        placeholder={
                          !wState
                            ? "Selecione um estado..."
                            : "Selecione uma opção..."
                        }
                        color="gray.400"
                        disabled={!wState}
                      >
                        {cities
                          .filter((city) => city.state_id == wState)
                          .map((city) => (
                            <option value={city.id}>{city.name}</option>
                          ))}
                      </Select>
                      {errors.beneficiary?.city_id && (
                        <FormErrorMessage>
                          {errors.beneficiary?.city_id.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.address}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Endereço Completo
                      </FormLabel>
                      <Input
                        {...register("beneficiary.address")}
                        defaultValue={beneficiary?.address || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.address && (
                        <FormErrorMessage>
                          {errors.beneficiary?.address.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.district}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Bairro
                      </FormLabel>
                      <Input
                        {...register("beneficiary.district")}
                        defaultValue={beneficiary?.district || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.district && (
                        <FormErrorMessage>
                          {errors.beneficiary?.district.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.beneficiary?.complement}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Complemento
                      </FormLabel>
                      <Input
                        {...register("beneficiary.complement")}
                        defaultValue={beneficiary?.complement || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.beneficiary?.complement && (
                        <FormErrorMessage>
                          {errors.beneficiary?.complement.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Grid>
                </Flex>
              </FormProvider>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={loadingCreate}
                backgroundColor="notrelife.800"
                color="white"
                variant="no-hover"
                type="submit"
              >
                Atualizar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditBeneficiary;
