import { useQuery } from '@apollo/client';
import AuditModal from 'components/AuditTrack/AuditModal'
import { AUDIT } from 'graphql/audit';
import React from 'react'

const ProfessionalScheduleAudit = ({id, open, close}) => {
  const {data, loading} = useQuery(AUDIT, {
    variables: {
      id: id
    }
  })

  return (
    <AuditModal 
      data={data?.audit || null}
      open={open}
      close={() => close()}
      isLoading={loading}
    />
  )
}

export default ProfessionalScheduleAudit