import React, {createContext, useContext, useReducer} from 'react'
import {useMutation, useQuery} from "@apollo/client";

import {CREATE_UNSCHEDULED_EVENT, GET_UNSCHEDULED_EVENTS} from 'graphql/unscheduledEvents'
import {ACTION_TYPES, reducers} from './reducers'

const UnscheduledEventsStateContext = createContext()
const UnscheduledEventsDispatchContext = createContext()

const UnscheduledEventsProvider = ({children, beneficiary}) => {
    const [state, dispatch] = useReducer(reducers, {unscheduledEvents: []})
    const {id} = beneficiary;
    const {data, loading, error} = useQuery(GET_UNSCHEDULED_EVENTS, {
        variables: {
            patient_id: id
        },
        onCompleted: (data) => {
            dispatch({type: ACTION_TYPES.LIST, data})
        }
    })

    return (
        <UnscheduledEventsStateContext.Provider value={{data, loading, error, ...state}}>
            <UnscheduledEventsDispatchContext.Provider value={dispatch}>{children}</UnscheduledEventsDispatchContext.Provider>
        </UnscheduledEventsStateContext.Provider>
    )
}

const useUnscheduledEvents = () => {
    const context = useContext(UnscheduledEventsStateContext)

    if (!context)
        throw new Error('useUnscheduledEvents must be used within an UnscheduledEventsProvider')

    return context
}

const useDispatch = () => {
    const dispatch = useContext(UnscheduledEventsDispatchContext)

    if (dispatch === undefined)
        throw new Error('useDispatch must be used within a UnscheduledEventsProvider')

    return dispatch
}

const useCreateUnscheduledEvent = (options = {}) => {
    const dispatch = useDispatch()
    const {onSuccess, onError} = options

    return useMutation(CREATE_UNSCHEDULED_EVENT, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data)
            dispatch({type: ACTION_TYPES.ADD, data: data.createUnscheduledEvent})
        },
        onError: (error) => {
            if (onError) onError(error)
        },
    })
}

export {
    UnscheduledEventsProvider,
    useUnscheduledEvents,
    useCreateUnscheduledEvent,
}
