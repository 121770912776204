import { msDarkTheme, msLightTheme } from "variables/multiselectStyle";
import AsyncSelect from "react-select/async";
import { useLazyQuery } from "@apollo/client";
import { useColorMode } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { GET_PROFESSIONALS_SEARCH } from "services/professionals";


const AsyncProfessionalsMultiselect = ({ whereCondition, defaultValues = [] }) => {
    const [loadPlans, { loading }] = useLazyQuery(GET_PROFESSIONALS_SEARCH);
    const { colorMode } = useColorMode();
    const { unregister, setValue } = useFormContext()

    const asyncValues = async (item) => {
        const { data } = await loadPlans({
            variables: { search: `%${item}%` }
        })
        return data.professionals;
    }
    const handleAction = (actualData) => {
        const professionals = [...defaultValues, ...actualData].map((i) => ({ ...i, action: actualData.includes(i) ? "sync" : "disconnect" }));
        unregister('assistedProfessionals.disconnect');
        unregister('assistedProfessionals.sync');
        const form = {
            sync: professionals.filter(i => i.action === "sync").map(i => i.id),
            disconnect: professionals.filter(i => i.action === "disconnect").map(i => i.id)
        }

        if (form.disconnect.length > 0)
            setValue('assistedProfessionals.disconnect', form.disconnect)
        if (form.sync.length > 0)
            setValue('assistedProfessionals.sync', form.sync)
    }
    return (
        <AsyncSelect
            isMulti={true}
            loadOptions={asyncValues}
            isLoading={loading}
            cacheOptions
            getOptionLabel={e => e.user.name}
            getOptionValue={e => e.id}
            color="#FFF"
            defaultValue={defaultValues}
            onChange={(i) => handleAction(i)}
            placeholder="Selecione uma opção..."
            loadingMessage={() => "Procurando..."}
            noOptionsMessage={({ inputValue }) => !inputValue ? "Digite para pesquisar" : "Não foram encontrados resultados"}
            styles={
                colorMode === "light" ? msLightTheme : msDarkTheme
            }
        />
    )
}

export default AsyncProfessionalsMultiselect;
