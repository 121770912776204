import AuthProvider from './authProvider'
import Amplify from "services/clients/cognitoAmplify"; // Manter mesmo que nao usado pelo componente
import "@aws-amplify/ui-react/dist/styles.css"
import { RegionalsProvider } from 'hooks/globalRegionals';
import { useEffect, useState } from "react";
import { QueryProvider } from "./queryClient";
import { Auth } from "aws-amplify";
import { JWT_TOKEN_COGNITO, JWT_TOKEN_COGNITO_ID } from "../variables/enumLabels";
import { useSetToken } from 'hooks/token';
import { LayoutProvider } from 'hooks/layout';

const AppProvider = ({ signOut, user, children }) => {
	const [provider, setProvider] = useState(<b>Carregando....</b>);
	const setToken = useSetToken();
	useEffect(() => {
		Auth.currentSession().then(session => {
			window.localStorage.setItem(JWT_TOKEN_COGNITO, session.accessToken.jwtToken)
			window.localStorage.setItem(JWT_TOKEN_COGNITO_ID, session.accessToken.jwtToken)
			setToken(session.accessToken.jwtToken);
			setProvider(
				<QueryProvider>
					<AuthProvider user={user}>
						<RegionalsProvider>
							<LayoutProvider>
								{children}
							</LayoutProvider>
						</RegionalsProvider>
					</AuthProvider>
				</QueryProvider>
			)
		})
	}, [user])

	return provider;
}

export default AppProvider
