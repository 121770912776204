import { gql } from "@apollo/client";

export const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      id
      name
      document
    }
  }
`;

export const GET_COMPANIES_PAGINATE = gql`
  query GetCompanies(
    $first: Int! = 30
    $page: Int! = 1
    $search: String
    $orderBy: String
  ) {
    companiesPaginate(
      first: $first
      page: $page
      search: $search
      orderBy: $orderBy
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
        document
        beneficiaries {
          id
        }
        proposals {
          id
          proposal_number
          assign_at
        }
        created_at
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($id: String!) {
    company(id: $id) {
      id
      name
      document
    }
  }
`;
