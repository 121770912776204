import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Controller, useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';

import { useCreateUser } from "hooks/users";

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Input,
	Select,
	Skeleton,
	Text,
	useColorMode,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useQuery } from "@apollo/client";
import { GET_REGIONALS } from "graphql/regionals";
import moment from "moment";
import { roleConsu, roleEnfer, roleMedic, roleRecep, roles } from "variables/roles";
import AsyncProfessionalsMultiselect from "./../components/AsyncProfessionalsMultiselect";

const schema = yup.object().shape({
	name: yup
		.string()
		.required("O campo Nome é obrigatório"),
	email: yup
		.string()
		.email("Insira um e-mail válido")
		.required("O campo E-mail é obrigatório"),
	role: yup
		.string()
		.required("O campo Tipo de Usuário é obrigatório"),
	status: yup
		.string()
		.required("O campo Status é obrigatório"),
	cpf: yup
		.string()
		.when("role", {
			is: (field) => [roleEnfer, roleConsu, roleRecep, roleMedic].includes(field),
			then: yup.string()
				.required("O campo CPF é obrigatório")
				.test("cpf-valid", "O CPF informado não é válido", function (field) {
					return cpfValidator.isValid(field);
				}),
		}),
	birthdate: yup
		.string()
		.when("role", {
			is: (field) => [roleEnfer, roleConsu, roleRecep, roleMedic].includes(field),
			then: yup.string()
				.required("O campo Data de Nascimento é obrigatório")
				.test("date-valid", "A Data de Nascimento informada não é válida", function (field) {
					const date = moment(field, "DD/MM/YYYY");
					return date.isValid() && date.isBefore(moment());
				}),
		}),
	phone: yup
		.string()
		.when("role", {
			is: (field) => [roleEnfer, roleConsu, roleRecep, roleMedic].includes(field),
			then: yup.string()
				.required("O campo Telefone Celular é obrigatório"),
		}),
})

const CreateUser = () => {
	const methods = useForm({
		mode: "onBlur",
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	const toast = useToast();
	const history = useHistory();

	const { register, handleSubmit, formState: { errors }, reset, watch, getValues } = methods;

	const watchRole = watch("role", null);

	const [createUser, { data, loading, error, }] = useCreateUser({
		onSuccess: (data) => {
			reset()
			toast({
				title: 'Usuário criado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			}),
				history.push(`/sistema/administracao/usuarios/${data.createUser.id}`);
		},
		onError: (err) => {
			toast({
				title: "Ocorreu um erro",
				status: "error",
				duration: 5000,
				isClosable: true,
			})
		},
	})

	const onSubmit = (data) => {

		const assistedProfessionals = ["Recepcionista"].includes(getValues('role')) ? {
			assistedProfessionals: {
				...getValues('assistedProfessionals')
			}
		} : null

		const professional = [roleEnfer, roleConsu, roleRecep, roleMedic].includes(getValues('role')) ? {
			professional: {
				create: {
					cpf: getValues('cpf'),
					birthdate: moment(getValues("birthdate"), "DD/MM/YYYY").format("YYYY-MM-DD"),
					phone: getValues('phone'),
					...assistedProfessionals
				},
			}
		} : null

		const variables = {
			input: {
				name: getValues('name'),
				email: getValues('email'),
				role: getValues('role'),
				status: getValues('status') === "true",
				...professional,
			}
		}

		createUser({ variables: variables });
	}

	const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
	const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
	const textColor = useColorModeValue("gray.700", "white");

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: "120px", lg: "160px" }}
		>
			<Card w={{ md: "100%", lg: "50%" }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Criar Usuário
						</Text>
						<Text color="gray.400" fontWeight="normal" fontSize="sm">
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form style={{ width: '100%' }} onSubmit={(handleSubmit(onSubmit))}>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										<Input
											{...register('name')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
									gap="24px"
									mt="24px"
								>
									<FormControl isInvalid={errors.email}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											E-mail
										</FormLabel>
										<Input
											{...register('email')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.status}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Status
										</FormLabel>
										<Select
											{...register('status')}
											defaultValue={true}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
											placeholder="Selecione uma opção..."
											color="gray.400"
										>
											<option value={true}>Ativo</option>
											<option value={false}>Inativo</option>
										</Select>
										{errors.status && <FormErrorMessage>{errors.status.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.role}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Tipo de Usuário
										</FormLabel>
										<Select
											{...register('role')}
											defaultValue={1}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
											placeholder="Selecione uma opção..."
											color="gray.400"
										>
											{
												roles.map(role => (
													<option key={role.id} value={role.value}>{role.label}</option>
												))
											}
										</Select>
										{errors.role && <FormErrorMessage>{errors.role.message}</FormErrorMessage>}
									</FormControl>
									{
										[roleEnfer, roleConsu, roleRecep, roleMedic].includes(watchRole) &&
										<>
											<FormControl isInvalid={errors.cpf}>
												<FormLabel
													color={textColor}
													fontWeight="bold"
													fontSize="xs"
												>
													CPF
												</FormLabel>
												<Input
													{...register('cpf')}
													as={InputMask}
													mask="999.999.999-99"
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
												{errors.cpf && <FormErrorMessage>{errors.cpf.message}</FormErrorMessage>}
											</FormControl>
											<FormControl isInvalid={errors.birthdate}>
												<FormLabel
													color={textColor}
													fontWeight="bold"
													fontSize="xs"
												>
													Data de Nascimento
												</FormLabel>
												<Input
													{...register('birthdate')}
													as={InputMask}
													mask="99/99/9999"
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
												{errors.birthdate && <FormErrorMessage>{errors.birthdate.message}</FormErrorMessage>}
											</FormControl>
											<FormControl isInvalid={errors.phone}>
												<FormLabel
													color={textColor}
													fontWeight="bold"
													fontSize="xs"
												>
													Telefone Celular
												</FormLabel>
												<Input
													{...register('phone')}
													as={InputMask}
													mask="(99) 99999-9999"
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
												{errors.phone && <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
											</FormControl>
										</>
									}
									{
										["Recepcionista"].includes(watchRole) &&
										<FormControl isInvalid={errors.assisted}>
											<FormLabel
												color={textColor}
												fontWeight="bold"
												fontSize="xs"
											>
												Associar profissionais
											</FormLabel>
											<AsyncProfessionalsMultiselect />
										</FormControl>
									}
								</Grid>
								<Flex mt="24px" align={"center"} justify={"flex-end"}>
									<NavLink to="/sistema/administracao/usuarios">
										<Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
											Cancelar
										</Text>
									</NavLink>
									<Button
										isLoading={loading}
										type='submit'
										variant="no-hover"
										bg={buttonBg}
										alignSelf="flex-end"
										w="100px"
										h="35px"
									>
										<Text fontSize="xs" color="#fff" fontWeight="bold">
											Criar
										</Text>
									</Button>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
}

export default CreateUser;
