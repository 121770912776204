import { gql } from "@apollo/client";

export const GET_UNSCHEDULED_EVENTS = gql`
  query GetUnscheduledEvents($patient_id: String!) {
    unscheduledEvents(patient_id: $patient_id) {
      id
      professional_id
      patient_id
      type
      attendance_type
      type_desc
      motive_desc
      duration
      info
      start
      end
      professional {
        user {
          name
        }
      }
    }
  }
`;

export const CREATE_UNSCHEDULED_EVENT = gql`
  mutation CreateUnscheduledEvent($input: CreateUnscheduledEvent) {
    createUnscheduledEvent(input: $input) {
      id
      professional_id
      patient_id
      type
      attendance_type
      type_desc
      motive
      motive_desc
      info
      start
      end
      professional {
        user {
          name
        }
      }
    }
  }
`;
