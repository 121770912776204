import {gql} from "@apollo/client";
import {ACCOMPANIMENT, FIRST_RECEPTION, HEALTH_STATEMENT} from "../functions/enums";

export const GET_ASSESSMENTS = gql`
    query GetAssessments($beneficiary_id: String!, $assessment_type_name: Mixed!) {
        assessments(beneficiary_id: $beneficiary_id, assessmentType: {column: NAME, operator: LIKE, value: $assessment_type_name}) {
            id
            beneficiary_id
            assessments_type_id
            answered_in
            created_at
            updated_at
            deleted_at
            evaluations {
                id
                assessment_id
                key
                tag
                value
                created_at
                updated_at
                deleted_at
            }
            assessmentType {
                id
                next_type_id
                name
                builder_tag
                description
                priority
                max_fills
                created_at
                updated_at
                deleted_at
            }
        }
    }
`

export const GET_ASSESSMENT = gql`
    query GetAssessment($id: String!) {
        assessment(id: $id) {
            id
            beneficiary_id
            assessments_type_id
            answered_in
            created_at
            updated_at
            deleted_at
            evaluations {
                id
                assessment_id
                key
                tag
                value
                created_at
                updated_at
                deleted_at
            }
            assessmentTypes {
                id
                next_type_id
                name
                builder_tag
                description
                priority
                max_fills
                created_at
                updated_at
                deleted_at
            }
        }
    }
`

export const GET_HEALTH_STATEMENT = gql`
    query GetAssessment($beneficiary_id: Mixed!) {
        assessment(where: {
            column: BENEFICIARY_ID, operator: EQ, value: $beneficiary_id
        }, assessmentType: {
            column: NAME, operator: EQ,value: "${HEALTH_STATEMENT}"
        }) {
            id
            beneficiary_id
            answered_in
            created_at
            updated_at
            deleted_at
            evaluations {
                id
                assessment_id
                key
                tag
                value
                order
                data {
                    height
                    weight
                }
                created_at
                updated_at
                deleted_at
            }
        }
    }
`

export const GET_FIRST_RECEPTION = gql`
    query GetAssessment($beneficiary_id: Mixed!) {
        assessment(where: {
            column: BENEFICIARY_ID, operator: EQ, value: $beneficiary_id
        }, assessmentType: {
            column: NAME, operator: EQ,value: "${FIRST_RECEPTION}"
        }) {
            id
            beneficiary_id
            answered_in
            created_at
            updated_at
            deleted_at
            evaluations {
                id
                assessment_id
                key
                tag
                value
                order
                data {
                    height
                    weight
                }
                created_at
                updated_at
                deleted_at
            }
        }
    }
`

export const GET_ACCOMPANIMENT = gql`
    query GetAssessments($beneficiary_id: String!) {
        assessmentstwo(beneficiary_id: $beneficiary_id, answered_in: "", assessmentType: {column: NAME, operator: LIKE, value: "${ACCOMPANIMENT}"}) {
            id
            beneficiary_id
            answered_in
            created_at
            updated_at
            deleted_at
            evaluations {
                id
                assessment_id
                key
                tag
                value
                order
                data {
                    height
                    weight
                }
                created_at
                updated_at
                deleted_at
            }
        }
    }
`