import { FreeHoursProvider } from "../hooks/freeHours"
import { CustomHoursProvider } from "hooks/customHours"
import { CalendarQueryProvider } from "services/calendar"

const ScheduleProvider = ({ children }) => {
    return (
        <CalendarQueryProvider>
            <FreeHoursProvider>
                <CustomHoursProvider>
                    {children}
                </CustomHoursProvider>
            </FreeHoursProvider>
        </CalendarQueryProvider>
    )
}

export default ScheduleProvider