import { Box, Flex } from '@chakra-ui/react';
import dateFormat from 'functions/dateFormat';
import { useTickets, usePaginateTickets } from 'hooks/tickets'
import priorities from 'variables/priorities';
import status from 'variables/status';
import AsyncTable from '../AsyncTable';

const columns = [
  {
    Header: "Aberto em",
    accessor: "created_at",
    Cell: props => <div>{dateFormat(props.value)}</div>
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: props => <Flex alignItems="center">
      <Box backgroundColor={status.find(stat => stat.value == props.value)?.color || null} width={3} height={3} borderRadius={100} mr={2} />
      {status.find(stat => stat.value == props.value)?.name || null}
    </Flex>
  },
  {
    Header: "Prioridade",
    accessor: "priority",
    Cell: props => <Flex alignItems="center">
      <Box backgroundColor={priorities.find(prior => prior.value == props.value)?.color || null} width={3} height={3} borderRadius={100} mr={2} />
      {priorities.find(prior => prior.value == props.value)?.name || null}
    </Flex>
  },
  {
    Header: "Onde encontrou o erro?",
    accessor: "source",
    Cell: props => {
      return !props.value ? (
        <div></div>
      ) : props?.value?.length < 30 ? (
        <div>{props.value}</div>
      ) : (
        <div>{props.value.slice(0, 27) + '...'}</div>
      )
    }
  },
  {
    Header: "O que o erro causa?",
    accessor: "causes",
    Cell: props => {
      return !props.value ? (
        <div></div>
      ) : props?.value?.length < 30 ? (
        <div>{props.value}</div>
      ) : (
        <div>{props.value.slice(0, 27) + '...'}</div>
      )
    }
  },
]

const TicketsTable = () => {
  const { loading, rows, total, perPage, currentPage } = useTickets()
  const [paginateTickets, { loading: reloading }] = usePaginateTickets()

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage)
      pageIndex = 1
    paginateTickets({
      variables: {
        first: pageSize || 30,
        page: pageIndex || 1,
        search: search || ''
      }
    })
  }

  return (
    <AsyncTable
      withoutActions={true}
      isLoaded={!loading && !reloading}
      fetchData={fetchData}
      data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
      columns={columns}
      total={total}
      size={perPage}
      asyncPage={currentPage}
      searchByName
      baseRoute="suporte"
      noView
      linkRow
    />
  );
}

export default TicketsTable