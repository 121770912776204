
const ACTION_TYPES = {
    LIST: "LIST",
    SELECT: 'SELECT',
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    REMOVE: 'REMOVE',
  }

const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.LIST: {
            return {
                ...state,
                rows: action.data.data,
                page: action.data.paginatorInfo.currentPage,
                perPage: action.data.paginatorInfo.perPage,
                total: action.data.paginatorInfo.total,
            }
        }
        case ACTION_TYPES.ADD: {
            return {
                ...state,
                rows: [...state.rows, action.data ]
            }
        }
        case ACTION_TYPES.SELECT: {
            return {
                ...state,
                regionalSelected: action.data
            }
        }
        case ACTION_TYPES.UPDATE: {
            return {
                ...state,
                rows: state.rows.map(r => r.id === action.data.id ? action.data : r),
                regionalSelected: action.data
            }
        }
    }
    return state;
}


export {
    reducers,
    ACTION_TYPES
}
