import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useMutation, useQuery } from "@apollo/client";

import { GET_NOTES, CREATE_NOTE } from 'graphql/notes'
import { ACTION_TYPES, reducers } from './reducers'
import { useParams } from 'react-router-dom';

const NotesStateContext = createContext()
const NotesDispatchContext = createContext()

const NotesProvider = ({ children, beneficiary }) => {
    const [state, dispatch] = useReducer(reducers, { notes: [] })
	const { id } = beneficiary;
    const { data, loading, error } = useQuery(GET_NOTES, {
			variables : {
				id: id
			},
      onCompleted: (data) => {
          dispatch({type: ACTION_TYPES.LIST, data})
      }
  })
	
    return (
			<NotesStateContext.Provider value={{ data, loading, error, ...state }}>
					<NotesDispatchContext.Provider value={dispatch}>{children}</NotesDispatchContext.Provider>
			</NotesStateContext.Provider>
    )
}

const useNotes = () => {
	const context = useContext(NotesStateContext)
	
	if (!context)
		throw new Error('useNotes must be used within an NotesProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(NotesDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a NotesProvider')

	return dispatch
}

const useCreateNote = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(CREATE_NOTE, {
			onCompleted: (data) => {
				if(onSuccess) onSuccess(data)
				dispatch({ type: ACTION_TYPES.ADD, data: data.createNote })
			},
			onError: (error) => {
				if(onError) onError(error)
			},
	})
}

export {
	NotesProvider,
	useNotes,
	useCreateNote,
}
