import React from "react";
import InputMask from "react-input-mask";
import {NavLink, useHistory, useParams} from "react-router-dom";
import * as yup from "yup";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {yupResolver} from "@hookform/resolvers/yup"
import {FormProvider, useForm} from "react-hook-form";
import {useRegional, useUpdateRegional} from "hooks/regionals";
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Skeleton,
	Switch,
	Text,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import states from "variables/states";
import cities from "variables/cities";
import {getAddress} from "functions/getAddress";

const schema = yup.object().shape({
	name: yup
		.string()
		.required("O campo Nome é obrigatório"),
	beneficiary_limit: yup
		.string()
		.required("O campo Total de vidas é obrigatório"),
	cep: yup
		.string()
		.required("O campo CEP é obrigatório"),
	state_id: yup
		.string()
		.required("O campo Estado é obrigatório"),
	city_id: yup
		.string()
		.required("O campo Cidade é obrigatório"),
	district: yup
		.string()
		.required("O campo Bairro é obrigatório"),
	address: yup
		.string()
		.required("O campo Endereço é obrigatório"),
	address_number: yup
		.string()
		.required("O campo Número é obrigatório"),
})

const EditRegional = () => {
	const {id} = useParams();
	const {data, loading} = useRegional(id);
	const history = useHistory();

	const methods = useForm();
	const toast = useToast()

	const {control, register, reset, handleSubmit, formState: {errors}, watch, getValues, setValue} = useForm({
		mode: "onBlur",
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const wState = watch("state_id", data?.regional?.state_id)
	const wIsAdmin = watch("is_admin", false)
	const wSendWhatsapp = watch("send_whatsapp", false)
	const wAutom02 = watch("autom_0_2", false)
	const wAutom48 = watch("autom_48", false)
	const wAutom49LowRisk = watch("autom_49_low_risk", false)
	const wAutom49MedLowRisk = watch("autom_49_med_low_risk", false)
	const wAutom49MedHighRisk = watch("autom_49_med_high_risk", false)
	const wAutom49HighRisk = watch("autom_49_high_risk", false)

	const [city, setCity] = React.useState(null)

	React.useEffect(() => {
		if (data) {
			setValue('state_id', data.regional.state_id)
			setCity(data.regional.city_id)
		}
	}, [data])

	React.useEffect(() => {
		setValue('city_id', city)
	}, [city])

	React.useEffect(() => {
		if (wIsAdmin) {
			setValue('send_whatsapp', false)
			setValue('autom_0_2', false)
			setValue('autom_48', false)
			setValue('autom_49_low_risk', false)
			setValue('autom_49_med_low_risk', false)
			setValue('autom_49_med_high_risk', false)
			setValue('autom_49_high_risk', false)
		}
	}, [wIsAdmin])


	const [updateRegional, {loading: loadingUpdate, error,}] = useUpdateRegional({
		onSuccess: () => {
			toast({
				title: "Regional atualizada com sucesso.",
				status: "success",
				duration: 5000,
				isClosable: true,
			}),
				history.push(`/sistema/administracao/regionais`);
		},
		onError: (err) => {
			toast({
				title: "Ocorreu um erro",
				status: "error",
				duration: 5000,
				isClosable: true,
			})
		},
	})

	const onSubmit = () => {
		const variables = {
			input: {
				id: id,
				...getValues()
			}
		}
		updateRegional({variables: variables});
	}

	const findAddress = async (zipcode) => {
		const address = await getAddress(zipcode);
		if (!address) {
			toast({
				title: 'CEP não encontrado.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			})
		} else {
			setValue("state_id", String(states.find(state => state.initials == address?.uf)?.id))
			setValue("city_id", String(cities.find(city => city.name == address?.localidade)?.id))
			setValue("district", address?.bairro)
			setValue("address", address?.logradouro)
		}
	}

	const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
	const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
	const textColor = useColorModeValue("gray.700", "white");

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{sm: "120px", lg: "160px"}}
		>
			<Card w={{md: "100%", lg: "50%"}}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Regional
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form style={{width: "100%"}} onSubmit={(handleSubmit(onSubmit))}>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}}
									gap="24px"
									mt="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<Input
													{...register("name")}
													defaultValue={data?.regional.name || null}
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
										}
										{errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.beneficiary_limit}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Total de vidas Permitidas
										</FormLabel>
										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<NumberInput
													defaultValue={data?.regional.beneficiary_limit}
												>
													<NumberInputField
														{...register("beneficiary_limit")} />
													<NumberInputStepper>
														<NumberIncrementStepper/>
														<NumberDecrementStepper/>
													</NumberInputStepper>
												</NumberInput>
										}
										{errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.cep}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											CEP
										</FormLabel>
										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<Input
													{...register('cep')}
													defaultValue={data?.regional.cep || null}
													as={InputMask}
													mask="99999-999"
													onChange={({target}) => (target.value.replace('_', '').length == 9) && findAddress(target.value)}
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
										}
										{errors.cep && <FormErrorMessage>{errors.cep.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.state_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Estado
										</FormLabel>

										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<Select
													{...register('state_id')}
													defaultValue={data?.regional.state_id}
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
													placeholder="Selecione uma opção..."
													color="gray.400"
												>
													{
														states.map(state => (
															<option value={state.id}>{state.name}</option>
														))
													}
												</Select>
										}
										{errors.state_id &&
											<FormErrorMessage>{errors.stete_id.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.city_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Cidade
										</FormLabel>
										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<Select
													{...register('city_id')}
													defaultValue={data?.regional.city_id || null}
													value={city}
													onChange={({target}) => setCity(target.value)}
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
													placeholder={(!wState || wState == '') ? "Selecione um estado..." : "Selecione uma opção..."}
													color="gray.400"
													disabled={!wState || wState == ''}
												>
													{
														cities.filter(city => city.state_id == wState).map(city => (
															<option value={city.id}>{city.name}</option>
														))
													}
												</Select>
										}
										{errors.city_id &&
											<FormErrorMessage>{errors.city_id.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.district}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Bairro
										</FormLabel>

										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<Input
													{...register('district')}
													defaultValue={data?.regional.district || null}
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
										}
										{errors.district &&
											<FormErrorMessage>{errors.district.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.address}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Endereço
										</FormLabel>

										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<Input
													{...register('address')}
													defaultValue={data?.regional.address || null}
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
										}
										{errors.address &&
											<FormErrorMessage>{errors.address.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.address_number}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Número
										</FormLabel>

										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<Input
													{...register('address_number')}
													defaultValue={data?.regional.address_number || null}
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
										}
										{errors.email &&
											<FormErrorMessage>{errors.address_number.message}</FormErrorMessage>}
									</FormControl>
									<FormControl isInvalid={errors.complement}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Complemento
										</FormLabel>
										{
											loading ?
												<Skeleton height={"40px"}/>
												:
												<Input
													{...register('complement')}
													defaultValue={data?.regional.complement || null}
													focusBorderColor={inputHover}
													borderRadius="8px"
													fontSize="md"
												/>
										}
										{errors.complement &&
											<FormErrorMessage>{errors.complement.message}</FormErrorMessage>}
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}}
									gap="24px"
									mt="24px"
								>
									<FormControl isInvalid={errors.complement}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Regional Administrativa
										</FormLabel>
										{
											loading ?
												<Skeleton height={"30px"}/>
												:
												<Switch
													defaultChecked={data?.regional.is_admin} {...register("is_admin")}
													colorScheme='green'/>
										}
									</FormControl>
									<FormControl isInvalid={errors.send_whatsapp}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Automação de Whatsapp
										</FormLabel>
										{
											loading
												? <Skeleton height={"30px"}/>
												: <Switch defaultChecked={data?.regional.send_whatsapp}
												          isChecked={(!wIsAdmin && wSendWhatsapp)} {...register("send_whatsapp")}
												          colorScheme='green' isDisabled={wIsAdmin}/>
										}
									</FormControl>
									<FormControl isInvalid={errors.autom_0_2}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Automação 0-2 Anos
										</FormLabel>
										{
											loading
												? <Skeleton height={"30px"}/>
												: <Switch defaultChecked={data?.regional?.autom_0_2}
												          isChecked={(!wIsAdmin && wAutom02)} {...register("autom_0_2")}
												          colorScheme='green' isDisabled={wIsAdmin}/>
										}
									</FormControl>
									<FormControl isInvalid={errors.autom_48}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Automação 48-
										</FormLabel>
										{
											loading
												? <Skeleton height={"30px"}/>
												: <Switch defaultChecked={data?.regional?.autom_48}
												          isChecked={(!wIsAdmin && wAutom48)} {...register("autom_48")}
												          colorScheme='green' isDisabled={wIsAdmin}/>
										}
									</FormControl>
									<FormControl isInvalid={errors.autom_49_low_risk}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Automação 49+ Risco Baixo
										</FormLabel>
										{
											loading
												? <Skeleton height={"30px"}/>
												: <Switch defaultChecked={data?.regional?.autom_49_low_risk}
												          isChecked={(!wIsAdmin && wAutom49LowRisk)} {...register("autom_49_low_risk")}
												          colorScheme='green' isDisabled={wIsAdmin}/>
										}
									</FormControl>
									<FormControl isInvalid={errors.autom_49_med_low_risk}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Automação 49+ Risco Médio Baixo
										</FormLabel>
										{
											loading
												? <Skeleton height={"30px"}/>
												: <Switch defaultChecked={data?.regional?.autom_49_med_low_risk}
												          isChecked={(!wIsAdmin && wAutom49MedLowRisk)} {...register("autom_49_med_low_risk")}
												          colorScheme='green' isDisabled={wIsAdmin}/>
										}
									</FormControl>
									<FormControl isInvalid={errors.autom_49_med_high_risk}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Automação 49+ Risco Médio Alto
										</FormLabel>
										{
											loading
												? <Skeleton height={"30px"}/>
												: <Switch defaultChecked={data?.regional?.autom_49_med_high_risk}
												          isChecked={(!wIsAdmin && wAutom49MedHighRisk)} {...register("autom_49_med_high_risk")}
												          colorScheme='green' isDisabled={wIsAdmin}/>
										}
									</FormControl>
									<FormControl isInvalid={errors.autom_49_high_risk}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Automação 49+ Risco Alto
										</FormLabel>
										{
											loading
												? <Skeleton height={"30px"}/>
												: <Switch defaultChecked={data?.regional?.autom_49_high_risk}
												          isChecked={(!wIsAdmin && wAutom49HighRisk)} {...register("autom_49_high_risk")}
												          colorScheme='green' isDisabled={wIsAdmin}/>
										}
									</FormControl>
								</Grid>
								<Flex mt="24px" align={"center"} justify={"flex-end"}>
									<NavLink to={`/sistema/administracao/regionais`}>
										<Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
											Cancelar
										</Text>
									</NavLink>
									<Button
										isLoading={loadingUpdate}
										type="submit"
										variant="no-hover"
										bg={buttonBg}
										alignSelf="flex-end"
										w="100px"
										h="35px"
									>
										<Text fontSize="xs" color="#fff" fontWeight="bold">
											Atualizar
										</Text>
									</Button>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
}

export default EditRegional;
