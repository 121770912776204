import { useEffect, useState } from "react";
import { useUsers } from "hooks/users";

import { Box, Flex, Text } from "@chakra-ui/react";

import dateFormat from "functions/dateFormat";
import { useUsersFilter } from "hooks/users";
import { usePaginateUsers } from "hooks/users";
import { roleAdmin, roles, roleSuper } from "variables/roles";
import AsyncTable from "components/Tables/AsyncTable";
import Icon from "components/FontAwesomeIcons/Icon";
import { useScheduledCommitments } from "hooks/commitmentsManagement";
import { useCommitments } from "hooks/commitmentsManagement";
import { assessmentsTypes } from "variables/assesmentsTypes";
import { Link } from "react-router-dom";
import CommitmentsAudit from "./CommitmentsAudit";
import { useAuth } from "hooks/useAuth";
import { differenceInYears } from "date-fns";

import { findRiskDS } from "functions/getStratification";
import { findRiskPA } from "functions/getStratification";

const hasNote = (notes, event) => {
	if (!notes || notes.length == 0) return false;
	let cNotes = [...notes];
	const eNotes = cNotes.filter((nt) => nt.title == `Agendamento - ${event}`);
	return eNotes[eNotes.length - 1];
};

const hasAwaitingSchedule = (form, as) => {
	if (!!form) {
		if (form === 'Acompanhamento' && !!as) {
			return as;
		}
		return form;
	}
	return 'Declaração de Saúde';
};

const getTeleTurn = (tele) => {
	try {
		const timestamp = new Date(tele);
		const turn = timestamp.getHours() < 12 ? "Manhã" : "Tarde";
		return dateFormat(timestamp) + " - " + turn;
	} catch (error) {
		console.log(error);
	}
};

const ScheduleTable = ({
	plan = null,
	year = null,
	month = null,
	professional = null,
	assessment = null,
	riskDS = null,
	riskPA = null,
	age = null,
	handleNoteModal,
	handleRegisterNoteModal,
	handleEventModal,
}) => {
	const { user } = useAuth();

	const handleModalOpen = (props) => {
		handleNoteModal(
			props.row.original.patient.name,
			hasNote(props.value, props?.row?.original.assessment?.assessmentType.name)
				.text
		);
	};

	const [auditId, setAuditId] = useState(null);
	const [open, setOpen] = useState(false);

	const columns = [
		{
			Header: "Atividade Agendada",
			accessor: "awaiting_schedule.awaiting_schedule",
			Cell: (props) => <div>{props.value}</div>,
			//Cell: (props) => <div>{hasAwaitingSchedule(props?.value, props?.row?.original?.awaiting_schedule?.awaiting_schedule)}</div>,
		},
		{
			Header: "Agendado para",
			accessor: "scheduled_to",
			Cell: (props) =>
				props?.row?.original?.type == "tele" ? (
					<div>{getTeleTurn(props.value)}</div>
				) : (
					<div>{dateFormat(props.value, "dd/MM/y HH:mm")}</div>
				),
		},
		{
			Header: "Beneficiario",
			accessor: "patient.name",
			Cell: (props) => (
				<Link
					to={`../administracao/gestao-vidas/${props?.row?.original?.patient?.id}`}
				>
					{props.value}
				</Link>
			),
		},
		{
			Header: "Celular",
			accessor: "patient.phone",
		},
		{
			Header: "Plano",
			accessor: "patient.plan[0].name",
		},
		{
			Header: "Responsável",
			accessor: "professional.user.name",
		},
		{
			Header: "Tipo de Responsável",
			accessor: "professional.user.role",
			Cell: (props) => (
				<div>{props?.value?.replace("_", " ").replace("_", " ") || null}</div>
			),
		},
		{
			Header: "Idade",
			accessor: "patient.birthdate",
			Cell: (props) => (
				<div>
					{props?.value
						? differenceInYears(new Date(), new Date(props.value))
						: null}
				</div>
			),
		},
		{
			Header: "Estratificação DS",
			accessor: "stratification_ds",
			Cell: (props) => (
				<div>{findRiskDS(props?.row?.original?.patient?.assessments)}</div>
			),
		},
		{
			Header: "Estratificação PA",
			accessor: "stratification_pa",
			Cell: (props) => (
				<div>{findRiskPA(props?.row?.original?.patient?.assessments)}</div>
			),
		},
		{
			Header: "Notas",
			accessor: "patient.notes",
			Cell: (props) =>
				hasNote(
					props.value,
					props?.row?.original?.assessment?.assessmentType?.name
				) ? (
					<Box
						cursor={"pointer"}
						onClick={() => handleModalOpen(props)}
						_hover={{ color: "notrelife.800" }}
					>
						<Icon icon="info" />
					</Box>
				) : (
					<div></div>
				),
		},
	];

	const { commitments, total, perPage, currentPage } = useCommitments();
	const [loadComittments, { loading }] = useScheduledCommitments();

	const fetchData = ({ pageSize, pageIndex, search }) => {
		const variables = {
			first: pageSize,
			page: pageIndex,
			input: {
				plan: plan,
				year: year,
				month: month,
				professional: professional,
				assessmentType: assessment,
				name: search || "",
				riskDS: riskDS,
				riskPA: riskPA,
				age: age,
			},
		};
		loadComittments({ variables: variables });
	};

	useEffect(() => {
		const variables = {
			first: 30,
			page: 1,
			input: {
				plan: plan,
				year: year,
				month: month,
				professional: professional,
				assessmentType: assessment,
				riskDS: riskDS,
				riskPA: riskPA,
				age: age,
			},
		};
		loadComittments({ variables: variables });
	}, [plan, month, professional, assessment, riskDS, riskPA, age]);

	const handleAudit = (id) => {
		setAuditId(id);
		setOpen(true);
	};

	const customActions = [
		{
			label: "Registro de Atividades",
			icon: "documents",
			visible: () => {
				return user?.role === roleSuper;
			},
			callback: (id) => {
				handleAudit(id);
			},
		},
		{
			label: "Adicionar nota",
			icon: "note",
			callback: (id) => {
				const note =
					hasNote(commitments.find((row) => row.id == id)?.patient?.notes) ||
					null;
				handleRegisterNoteModal(
					commitments.find((row) => row.id == id).patient.id,
					commitments.find((row) => row.id == id).patient.name,
					`Agendamento - ${commitments.find((row) => row.id == id).assessment?.assessmentType
						.name || "Declaração de Saúde"
					}`,
					note?.text
				);
			},
		},
		{
			label: "Reagendar",
			icon: "calendar",
			callback: (id) => {
				const values = {
					table: "scheduled",
					event_id: id,
					name: commitments.find((row) => row.id == id).patient.name,
					type: "reschedule",
					event:
						commitments.find((row) => row.id == id).assessment?.assessmentType
							.name || "Declaração de Saúde",
				};
				handleEventModal(values);
			},
		},
	];

	return (
		<>
			<AsyncTable
				searchByName
				noView
				noEdit
				customActions={!loading ? customActions : null}
				isLoaded={!loading}
				fetchData={fetchData}
				data={
					!loading
						? commitments.sort((a, b) => {
							return new Date(a.scheduled_to) - new Date(b.scheduled_to);
						})
						: [{}, {}, {}, {}]
				}
				columns={columns}
				size={perPage}
				total={total || 0}
				asyncPage={currentPage}
				baseRoute=""
			/>
			{open && (
				<CommitmentsAudit
					id={auditId}
					open={open}
					close={() => setOpen(false)}
					type="event"
				/>
			)}
		</>
	);
};

export default ScheduleTable;
