const ACTION_TYPES = {
	OPEN_CLOSE_CONFIG: 'OPEN_CLOSE_CONFIG',
	SWITCH_VARIANT: 'SWITCH_VARIANT',
	SWITCH_FIXED: 'SWITCH_FIXED',
	SET_WIDTH: 'SET_WIDTH',
}

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.OPEN_CLOSE_CONFIG: {
			return {
				...state,
				configOpen: action.data
			}
		}
		case ACTION_TYPES.SWITCH_VARIANT: {
			return {
				...state,
				sidebarVariant: action.data
			}
		}
		case ACTION_TYPES.SWITCH_FIXED: {
			return {
				...state,
				sidebarFixed: action.data
			}
		}
		case ACTION_TYPES.SET_WIDTH: {
			return {
				...state,
				sidebarWidth: action.data
			}
		}
		default: {
			return state
		}
	}
}

export {
	reducers,
	ACTION_TYPES
}