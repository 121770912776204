import React from 'react'
import { ResponsiveLine } from '@nivo/line'

import { useColorMode } from "@chakra-ui/react";

import { margin, padding, legendsLight, legendsDark, lineProps } from 'variables/nivo/line'
import { themeDark, themeLight, axisBottom, axisLeft, labelTextColor, tooltip } from 'variables/nivo/default'
import { defaultColors } from 'variables/colors';

// const data = [
//   {
//     "id": "Risco",
//     "data": [
//       {
//         "x": "Q1",
//         "y": 1
//       },
//       {
//         "x": "Q2",
//         "y": 3
//       },
//       {
//         "x": "Q3",
//         "y": 5
//       },
//       {
//         "x": "Q4",
//         "y": 4
//       },
//     ]
//   },
// ]

const RisksGrowthChart = ({data = []}) => {
	const { colorMode } = useColorMode();

  return (
    <ResponsiveLine
      data={data}
      margin={margin}
      padding={padding}
      theme={colorMode === 'light' ? themeLight : themeDark}
      axisBottom={axisBottom}
      axisLeft={{...axisLeft, tickValues: [0, 1, 2, 3, 4, 5] }}
      colors={ defaultColors }
      labelTextColor="#FFF"
      tooltip={({point}) => tooltip(`${point.data.x} - ${point.data.label}`, point.data.yStacked, null, point.color)}
      legends={colorMode === 'light' ? legendsLight : legendsDark}
      {...lineProps}
    />
  )
}

export default RisksGrowthChart
