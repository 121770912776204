import { WhatsappDisclosureProvider as Provider } from "hooks/whatsappDisclosures";
import { FilterProvider } from "hooks/useFilter";
import { CommunicationQueryProvider } from "../services/clients/communication";

const WhatsappDisclosureProvider = ({ children }) => {
  return (
    <FilterProvider>
      <CommunicationQueryProvider>
          <Provider>{children}</Provider>
      </CommunicationQueryProvider>
    </FilterProvider>
  );
};

export default WhatsappDisclosureProvider;
