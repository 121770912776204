import { gql } from '@apollo/client';

export const GET_EVENTS_LIMIT =  gql`
        query GetEvents($now: Mixed!) {
            events(where: {column: SCHEDULED_TO, operator: GT, value: $now}, 
            limit: 10, orderBy: {column: SCHEDULED_TO, order: ASC}) {
                patient {
                    id
                    name
                }
                professional {
                    id
                    user {
                        id
                        name
                        role
                    }
                }
                scheduled_to
            }
        }
    `

export const GET_EVENT = gql`
        query GetEvent($id: String) {
            event(id: $id) {
                id
                scheduled_to
            }
        }
`

export const CREATE_EVENT = gql`
        mutation CreateEvent($input: CreateEvent) {
            createEvent(input: $input) {
                id
                patient {
                    id
                }
            }
        }
`

export const GET_EVENTS_MONTH = gql`
        query GetEventsMonth($month: String!, $regional_id: String, $role: String, $professional_id: String) {
            eventsMonth(month: $month, regional_id: $regional_id, role: $role, professional_id: $professional_id) {
                start
                title
              }
        }
`

export const GET_REGIONALS = gql`
   query GetRegionals {
        regionals {
            id
            name
        }
    } 
`

export const GET_BENEFICIARIES_QUICK = gql`
    query GET_BENEFICIARIES_QUICK($name: String, $active: Boolean, $regional_id: String) {
        patientsQuick(search: $name, limit: 30, active: $active, regional_id: $regional_id) {
            id
            name
            pending_awaiting_schedules {
                id
                is_awaiting_schedule
                awaiting_schedule_at
                awaiting_schedule
            }
            professional_id
            plan {
                regionals {
                    id
                    name
                }
            }
        }
    }
`

export const GET_BENEFICIARIES = gql`
        query GET_BENEFICIARIES($name: Mixed, $active: Mixed) {
            patients(where: {column: NAME, operator: LIKE, value: $name, AND: [{column: ACTIVE, operator: EQ, value: $active}] } ) {
                id
                name
                pending_awaiting_schedules {
                    is_awaiting_schedule
                    awaiting_schedule_at
                    awaiting_schedule
                }
                manager {
                    id
                    regionals {
                        id
                    }
                }
                plan {
                    regionals {
                        id
                        name
                    }
                }
              }
        }
`

export const GET_STATISTICS_EVENTS_DATE = gql`
        query GET_STATISTICS_EVENTS_DATE(
            $date: String!,
            $professional: String,
            $role: String) {
            statistics(to: $date, professional: $professional, role: $role) {
                commitments
                facetoface
                teleservice
                status {
                    total
                    label
                }
            }
        }
`

export const GET_EVENTS_DAY_PAGINATE = gql`
    query GET_EVENTS_DAY_PAGINATE(
            $where: QueryEventsPaginationWhereWhereConditions!, 
            $professional: QueryEventsPaginationProfessionalWhereHasConditions, 
            $perPage: Int! = 30, 
            $page: Int! = 1
        ) {
        eventsPagination(where: $where, professional: $professional, first: $perPage, page: $page) {
            paginatorInfo {
            count
            currentPage
            perPage
            lastItem
            total
            }
            data {
                id
                scheduled_to
                canceled_at
                expired_at
                rescheduled_at
                attended
                type
                parent {
                    scheduled_to
                }
                assessment {
                    id
                    numbered_title
                    assessmentType {
                        id
                        name
                    }
                }
                assessment_type {
                    id
                    name
                }
                awaiting_schedule {
                    id
                    awaiting_schedule
                }
                unscheduled_event {
                    type
                    info
                    motive
                }
                professional{
                    id
                    user {
                        id
                        name
                        role
                    }
                }
                patient {
                    id
                    name
                }
            }
        }
    }
`

export const GET_ASSESTMENTS_TYPES = gql`
    query {
        eventTypes {
            id
            name
        }
    }
`
export const CANCEL_EVENT = gql`
    mutation CANCEL_EVENT($id: ID!) {
        cancelEvent(id: $id, input: {canceled_at: "now"}) {
            id
        }
    }
`
