import React from "react";

import {Box, Grid, Text, useColorModeValue} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "./components/FiltersBox";
import TotalLivesCount from "../../components/Charts/Nivo/Lifes/TotalLivesCount";
import {useOperationalDashboard} from "../../hooks/dashboards/useDashboards";
import ThirtyDaysOperational from "../../components/Charts/Nivo/Lifes/ThirtyDaysOperational";
import {useFilter} from "../../hooks/useFilter";

const Operational = () => {
    const {filters} = useFilter();
    const {data, loading} = useOperationalDashboard(filters);
    const textColor = useColorModeValue("gray.700", "white");
    return (
        <>
            <Grid
                templateColumns={{sm: "1fr", md: "1fr"}}
                templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                pt={{sm: "125px", lg: "160px"}}
            >
                <Card px="0px" pb="0px" minHeight="100px">
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Filtros
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <FiltersBox
                                planNameFilter
                                professionalIdFilter
                                byBeneFilter
                                yearMonthFilter
                                ageFilter
                                source = "dashboard_operacional"
                            />
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            {
                loading ?
                    'Carregando...'
                    :
                    <>
                        <Grid
                            templateColumns={{sm: "1fr", md: "1fr 1fr 1fr 1fr"}}
                            templateRows={{sm: "2fr 2fr", md: "2fr 2fr"}}
                            gap="12px"
                            pt={{sm: "12px", lg: "12px"}}
                        >
                            <Card px="0px" pb="0px" minHeight="180px" height="180px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold" marginBottom={0}>
                                        Aguardando agendamento
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <TotalLivesCount total={data?.dashboard_operational?.pendingScheduling}/>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card px="0px" pb="0px" minHeight="180px" height="180px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold" marginBottom={0}>
                                        Agendados
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <TotalLivesCount total={data?.dashboard_operational?.scheduled}/>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card px="0px" pb="0px" minHeight="180px" height="180px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader  px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                                        Reagendamentos
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <TotalLivesCount
                                            total={data?.dashboard_operational?.rescheduled}/>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card px="0px" pb="0px" minHeight="180px" height="180px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold" marginBottom={0}>
                                        Realizados
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <TotalLivesCount total={data?.dashboard_operational?.done}/>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card px="0px" pb="0px" minHeight="180px" height="180px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold" marginBottom={0}>
                                        No Show
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <TotalLivesCount total={data?.dashboard_operational?.missing}/>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card px="0px" pb="0px" minHeight="180px" height="180px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader  px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                                        Agendamento presencial
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <TotalLivesCount total={data?.dashboard_operational?.physical}/>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card px="0px" pb="0px" minHeight="180px" height="180px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader  px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                                        Tele-Agendamento
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <TotalLivesCount
                                            total={data?.dashboard_operational?.tele}/>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card px="0px" pb="0px" minHeight="180px" height="180px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader  px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                                        Cancelados
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <TotalLivesCount
                                            total={data?.dashboard_operational?.canceled}/>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Grid>
                        <Grid
                            templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
                            templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                            gap="12px"
                            pt={{sm: "12px", lg: "12px"}}
                        >
                            <Card px="0px" pb="0px" minHeight="400px" height="400px"
                                  overflowX={{sm: "auto", lg: "unset"}}>
                                <CardHeader px="22px">
                                    <Text color={textColor} fontSize="lg" fontWeight="bold" marginBottom={0}>
                                        Taxa de evolução e comparativo
                                    </Text>
                                </CardHeader>
                                <CardBody h="100%">
                                    <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                        <ThirtyDaysOperational data={data?.dashboard_operational?.thirtyDays}/>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Grid>
                    </>
            }
        </>
    );
}

export default Operational;
