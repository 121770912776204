import React from "react";
import { useColorMode } from "@chakra-ui/react";
import {
  themeDark,
  themeLight,
  tooltip,
} from "variables/nivo/default";
import { ResponsiveLine } from "@nivo/line";

const LifesPerPlanDailyChart = ({ data, keys, label }) => {
  if (typeof data === "string") data = JSON.parse(data);
  const { colorMode } = useColorMode();

  const ret = [
    {
      id: "Vidas",
      data: data || [{x: "", y: 0}],
    },
  ];

  return (
    <ResponsiveLine
      data={ret}
      margin={{ top: 50, right: 125, bottom: 50, left: 100 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      theme={colorMode === "light" ? themeLight : themeDark}
      tooltip={({point}) => tooltip(`${point?.data?.x}: ${point?.data?.y}`)}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LifesPerPlanDailyChart;
