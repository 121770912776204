const ACTION_TYPES = {
  LIST: "LIST",
  UPDATE: "UPDATE",
};

const reducers = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return {
        ...state,
        rows: action.data.data,
        page: action.data.paginatorInfo.currentPage,
        perPage: action.data.paginatorInfo.perPage,
        total: action.data.paginatorInfo.total,
        currentPage: action.data.paginatorInfo.currentPage,
      };
    }
    case ACTION_TYPES.ADD_NOTE: {
      let editted = {
        ...state.rows.find(
          (commitment) =>
            commitment?.beneficiary?.id == action.data.beneficiary.id ||
            commitment.id == action.data.beneficiary.id
        ),
      };
      let other = state.rows.filter((commitment) => {
        if (!!commitment.beneficiary)
          return commitment.beneficiary.id != action.data.beneficiary.id;
        else return commitment.id != action.data.beneficiary.id;
      });

      return {
        ...state,
        rows: [editted, ...other],
      };
    }
    case ACTION_TYPES.UPDATE: {
      return {
        ...state,
        rows: state.rows.map((item) =>
          item.id === action.data.commitment.id ? action.data.commitment : item
        ),
      };
    }
    default: {
      return state;
    }
  }
};

export { reducers, ACTION_TYPES };
