import React from "react";
import InputMask from "react-input-mask";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import { GET_REGIONALS } from "graphql/regionals";
import { NavLink, useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { fromAnyFormatToBr } from "functions/momentFormat";
import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateUser, useUser } from "hooks/users";
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    Select,
    Skeleton,
    Text,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import moment from "moment/moment";
import { roleConsu, roleEnfer, roleMedic, roleRecep, roles } from "variables/roles";
import AsyncProfessionalsMultiselect from "../components/AsyncProfessionalsMultiselect";

const schema = yup.object().shape({
    name: yup
        .string()
        .required("O campo Nome é obrigatório"),
    email: yup
        .string()
        .email("Insira um e-mail válido")
        .required("O campo E-mail é obrigatório"),
    role: yup
        .string()
        .required("O campo Tipo de Usuário é obrigatório"),
    status: yup
        .string()
        .required("O campo Status é obrigatório"),
    cpf: yup
        .string()
        .when("role", {
            is: (field) => [roleEnfer, roleConsu, roleRecep, roleMedic].includes(field),
            then: yup.string()
                .required("O campo CPF é obrigatório")
                .test("cpf-valid", "O CPF informado não é válido", function (field) {
                    return cpfValidator.isValid(field);
                }),
        }),
    birthdate: yup
        .string()
        .when("role", {
            is: (field) => [roleEnfer, roleConsu, roleRecep, roleMedic].includes(field),
            then: yup.string()
                .required("O campo Data de Nascimento é obrigatório")
                .test("date-valid", "A Data de Nascimento informada não é válida", function (field) {
                    const date = moment(field, "DD/MM/YYYY");
                    return date.isValid() && date.isBefore(moment());
                }),
        }),
    phone: yup
        .string()
        .when("role", {
            is: (field) => [roleEnfer, roleConsu, roleRecep, roleMedic].includes(field),
            then: yup.string()
                .required("O campo Telefone Celular é obrigatório"),
        }),
})

const EditUser = () => {
    const { id } = useParams();
    const { data, loading } = useUser(id);

    const history = useHistory();

    const methods = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
    });
    const toast = useToast()

    const { control, register, reset, handleSubmit, formState: { errors }, watch, getValues } = methods;

    const watchRole = watch("role", null)

    const [updateUser, { loading: loadingUpdate, error, }] = useUpdateUser({
        onSuccess: (data) => {
            reset()
            toast({
                title: "Usuário atualizado com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
            }),
                history.push(`/sistema/administracao/usuarios/${data.updateUser.id}`);
        },
        onError: (err) => {
            toast({
                title: "Ocorreu um erro",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        },
    })

    const onSubmit = () => {

        const assistedProfessionals = ["Recepcionista"].includes(getValues('role')) ? {
            assistedProfessionals: {
                ...getValues('assistedProfessionals')
            }
        } : null

        const professional = [roleEnfer, roleConsu, roleRecep, roleMedic].includes(getValues('role')) ? {
            professional: {
                update: {
                    id: data?.user?.professional?.id || null,
                    cpf: getValues('cpf'),
                    birthdate: moment(getValues("birthdate"), "DD/MM/YYYY").format("YYYY-MM-DD"),
                    phone: getValues('phone'),
                    ...assistedProfessionals
                }
            }
        }
            : null

        const variables = {
            input: {
                id: id,
                name: getValues("name"),
                email: getValues("email"),
                role: getValues("role"),
                status: getValues("status") === "true",
                ...professional
            }
        }

        updateUser({ variables: variables });
    }

    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{ sm: "120px", lg: "160px" }}
        >
            <Card w={{ md: "100%", lg: "50%" }}>
                <CardHeader mb="40px">
                    <Flex direction="column">
                        <Text
                            color={textColor}
                            fontSize="lg"
                            fontWeight="bold"
                            mb="3px"
                        >
                            Editar Usuário
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <FormProvider {...methods}>
                        <form style={{ width: "100%" }} onSubmit={(handleSubmit(onSubmit))}>
                            <Flex direction="column" w="100%">
                                <Grid
                                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                    gap="24px"
                                >
                                    <FormControl isInvalid={errors.name}>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Nome
                                        </FormLabel>
                                        {
                                            loading ?
                                                <Skeleton height={"40px"} />
                                                :
                                                <Input
                                                    {...register("name")}
                                                    defaultValue={data?.user.name || null}
                                                    focusBorderColor={inputHover}
                                                    borderRadius="8px"
                                                    fontSize="md"
                                                />
                                        }
                                        {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
                                    </FormControl>
                                </Grid>
                                <Grid
                                    templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                                    gap="24px"
                                    mt="24px"
                                >
                                    <FormControl isInvalid={errors.email}>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            E-mail
                                        </FormLabel>
                                        {
                                            loading ?
                                                <Skeleton height={"40px"} />
                                                :
                                                <Input
                                                    {...register("email")}
                                                    defaultValue={data?.user.email || null}
                                                    focusBorderColor={inputHover}
                                                    borderRadius="8px"
                                                    fontSize="md"
                                                />
                                        }
                                        {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
                                    </FormControl>
                                    <FormControl isInvalid={errors.status}>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Status
                                        </FormLabel>
                                        {
                                            loading ?
                                                <Skeleton height={"40px"} />
                                                :
                                                <Select
                                                    {...register("status")}
                                                    defaultValue={data?.user?.status}
                                                    focusBorderColor={inputHover}
                                                    borderRadius="8px"
                                                    fontSize="md"
                                                    placeholder="Selecione uma opção..."
                                                    color="gray.400"
                                                >
                                                    <option value={true}>Ativo</option>
                                                    <option value={false}>Inativo</option>
                                                </Select>
                                        }
                                        {errors.status && <FormErrorMessage>{errors.status.message}</FormErrorMessage>}
                                    </FormControl>
                                    <FormControl isInvalid={errors.role}>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Tipo de Usuário
                                        </FormLabel>
                                        {
                                            loading ?
                                                <Skeleton height={"40px"} />
                                                :
                                                <Select
                                                    {...register("role")}
                                                    defaultValue={data?.user.role || null}
                                                    focusBorderColor={inputHover}
                                                    borderRadius="8px"
                                                    fontSize="md"
                                                    placeholder="Selecione uma opção..."
                                                    color="gray.400"
                                                >
                                                    {
                                                        roles.map(role => (
                                                            <option key={role.id} value={role.value}>{role.label}</option>
                                                        ))
                                                    }
                                                </Select>
                                        }
                                        {errors.role && <FormErrorMessage>{errors.role.message}</FormErrorMessage>}
                                    </FormControl>
                                    {
                                        (!watchRole && [roleEnfer, roleConsu, roleRecep, roleMedic].includes(data?.user.role) ||
                                            [roleEnfer, roleConsu, roleRecep, roleMedic].includes(watchRole)) &&
                                        <>
                                            <FormControl isInvalid={errors.cpf}>
                                                <FormLabel
                                                    color={textColor}
                                                    fontWeight="bold"
                                                    fontSize="xs"
                                                >
                                                    CPF
                                                </FormLabel>
                                                {
                                                    loading ?
                                                        <Skeleton height={"40px"} />
                                                        :
                                                        <Input
                                                            {...register("cpf")}
                                                            defaultValue={data?.user?.professional?.cpf || null}
                                                            as={InputMask}
                                                            mask="999.999.999-99"
                                                            focusBorderColor={inputHover}
                                                            borderRadius="8px"
                                                            fontSize="md"
                                                        />
                                                }
                                                {errors.cpf &&
                                                    <FormErrorMessage>{errors.cpf.message}</FormErrorMessage>}
                                            </FormControl>
                                            <FormControl isInvalid={errors.professional?.birthdate}>
                                                <FormLabel
                                                    color={textColor}
                                                    fontWeight="bold"
                                                    fontSize="xs"
                                                >
                                                    Data de Nascimento
                                                </FormLabel>
                                                {
                                                    loading ?
                                                        <Skeleton height={"40px"} />
                                                        :
                                                        <Input
                                                            {...register("birthdate")}
                                                            as={InputMask}
                                                            mask="99/99/9999"
                                                            defaultValue={data?.user?.professional?.birthdate ? fromAnyFormatToBr(data?.user?.professional?.birthdate) : null}
                                                            focusBorderColor={inputHover}
                                                            borderRadius="8px"
                                                            fontSize="md"
                                                        />
                                                }
                                                {errors.birthdate &&
                                                    <FormErrorMessage>{errors.birthdate.message}</FormErrorMessage>}
                                            </FormControl>
                                            <FormControl isInvalid={errors.phone}>
                                                <FormLabel
                                                    color={textColor}
                                                    fontWeight="bold"
                                                    fontSize="xs"
                                                >
                                                    Telefone Celular
                                                </FormLabel>
                                                {
                                                    loading ?
                                                        <Skeleton height={"40px"} />
                                                        :
                                                        <Input
                                                            {...register("phone")}
                                                            as={InputMask}
                                                            mask="(99) 99999-9999"
                                                            defaultValue={data?.user?.professional?.phone || null}
                                                            focusBorderColor={inputHover}
                                                            borderRadius="8px"
                                                            fontSize="md"
                                                        />
                                                }
                                                {errors.phone &&
                                                    <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
                                            </FormControl>
                                        </>
                                    }
                                    {
                                        (!watchRole && ["Recepcionista"].includes(data?.user.role) ||
                                            ["Recepcionista"].includes(watchRole)) &&
                                        <FormControl isInvalid={errors.assisted}>
                                            <FormLabel
                                                color={textColor}
                                                fontWeight="bold"
                                                fontSize="xs"
                                            >
                                                Associar profissionais
                                            </FormLabel>
                                            {
                                                loading ?
                                                    <Skeleton height={"40px"} />
                                                    :
                                                    <AsyncProfessionalsMultiselect
                                                        defaultValues={data.user.professional?.assistedProfessionals}
                                                    />
                                            }
                                        </FormControl>
                                    }
                                </Grid>
                                <Flex mt="24px" align={"center"} justify={"flex-end"}>
                                    <NavLink to={`/sistema/administracao/usuarios/${id}`}>
                                        <Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
                                            Cancelar
                                        </Text>
                                    </NavLink>
                                    <Button
                                        isLoading={loadingUpdate}
                                        type="submit"
                                        variant="no-hover"
                                        bg={buttonBg}
                                        alignSelf="flex-end"
                                        w="100px"
                                        h="35px"
                                    >
                                        <Text fontSize="xs" color="#fff" fontWeight="bold">
                                            Atualizar
                                        </Text>
                                    </Button>
                                </Flex>
                            </Flex>
                        </form>
                    </FormProvider>
                </CardBody>
            </Card>
        </Flex>
    );
}

export default EditUser;
