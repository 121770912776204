import moment from "moment/moment";

const fromAnyFormatToAnyFormat = (value, format) => {
    try {
        let time = moment(value, "YYYY-MM-DD hh:mm:ss");
        if (time.isValid()) return time.format(format);

        time = moment(value, "DD/MM/YYYY hh:mm:ss");
        if (time.isValid()) return time.format(format);

        time = moment(value, "DD/MM/YYYY");
        if (time.isValid()) return time.format(format);

        time = moment(value, "YYYY-MM-DD");
        if (time.isValid()) return time.format(format);

        return null;
    } catch (e) {
        return null;
    }
}

export const fromAnyFormatToAny = (value, any) => {
    return fromAnyFormatToAnyFormat(value, any);
}

export const fromAnyFormatToBr = (value) => {
    return fromAnyFormatToAnyFormat(value, "DD/MM/YYYY");
}

export const fromAnyFormatToBrWithHours = (value) => {
    return fromAnyFormatToAnyFormat(value, "DD/MM/YYYY hh:mm");
}

export const fromAnyFormatToInt = (value) => {
    return fromAnyFormatToAnyFormat(value, "YYYY-MM-DD");
}

export const fromAnyFormatToIntWithHours = (value) => {
    return fromAnyFormatToAnyFormat(value, "YYYY-MM-DD hh:mm:ss");
}

export default {
    fromAnyFormatToAny, fromAnyFormatToBr, fromAnyFormatToBrWithHours, fromAnyFormatToInt, fromAnyFormatToIntWithHours,
}