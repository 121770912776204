import React, { useEffect } from "react";
import { Grid } from "@chakra-ui/react";
import AsyncTable from "../AsyncTable";
import {
  useWhatsappDisclosures,
  usePaginateWhatsappDisclosures,
} from "hooks/whatsappDisclosures";
import states from "variables/states";
import cities from "variables/cities";
import dateFormat from "functions/dateFormat";
import queryParamsPage from "functions/queryParamsPage";
import { useLocation } from "react-router-dom";

const WhatsappTable = ({ type_filter }) => {
  const location = useLocation();
  const { page: queryPage, size: querySize } = queryParamsPage(location);
  const getFormattedType = (value) => {
    switch (value) {
      case "campaign":
        return "Campanha";
      case "activity":
        return "Atividade";
      case "lecture":
        return "Palestra";
      default:
        return value;
    }
  };

  const columns = [
    {
      Header: "Data da criação",
      accessor: "created_at",
      Cell: ({ value }) => <>{dateFormat(value)}</>,
    },
    {
      Header: "Tipo de convite",
      accessor: "invitation_type",
      Cell: (props) => <div>{getFormattedType(props.value)}</div>,
    },
    {
      Header: "Título",
      accessor: "title",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "Ambiente",
      accessor: "ambience",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "Estado",
      accessor: "state_id",
      Cell: (props) => (
        <div>{states.find((i) => i.id == props.value)?.name}</div>
      ),
    },
    {
      Header: "Cidade",
      accessor: "city_id",
      Cell: (props) => (
        <div>{cities.find((i) => i.id == props.value)?.name}</div>
      ),
    },
    {
      Header: "Data e hora",
      accessor: "scheduled_to",
      Cell: (props) => <div>{dateFormat(props.value, "dd/MM/y HH:mm")}</div>,
    },
    {
      Header: "Capacidade",
      accessor: "capacity",
      Cell: (props) => <div>{props.value}</div>,
    },
  ];

  const {
    loading,
    rows,
    total,
    perPage,
    currentPage,
  } = useWhatsappDisclosures();

  const [
    paginateWhatsappDisclosures,
    { loading: reloading },
  ] = usePaginateWhatsappDisclosures();

  const fetchData = ({ pageSize, pageIndex, search }) => {
    paginateWhatsappDisclosures({
      variables: {
        first: pageSize || querySize || 30,
        page: pageIndex || queryPage || 1,
        search: search || "",
        ...(type_filter && { invitation_type: type_filter }),
      },
    });
  };

  useEffect(() => {
    fetchData({});
  }, [type_filter]);

  return (
    <React.Fragment>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
      ></Grid>
      <AsyncTable
        isLoaded={!loading && !reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        size={perPage}
        asyncPage={currentPage}
        searchByName
        baseRoute="whatsapp"
      />
    </React.Fragment>
  );
};

export default WhatsappTable;
