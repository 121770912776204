import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {padding} from "variables/nivo/bar";
import {useColorMode} from "@chakra-ui/react";
import {monochromeMainColors} from "variables/colors";
import {axisLeft, themeDark, themeLight, tooltip} from "variables/nivo/default";

const ProgressCPT = ({data, keys, label}) => {
	const {colorMode} = useColorMode();

	return (<ResponsiveBar
			data={data?.progressQuery ?? [{status: "", total: 0}]}
			keys={["total"]}
			indexBy={"status"}
			layout="horizontal"
			margin={{top: 20, right: 20, bottom: 10, left: 250}}
			padding={padding}
			colors={monochromeMainColors}
			labelSkipWidth={11}
			theme={colorMode === "light" ? themeLight : themeDark}
			axisBottom={null}
			axisLeft={axisLeft}
			labelTextColor={'#fff'}
			enableLabel={false}
			tooltip={({indexValue, value}) => tooltip(indexValue, value)}
		/>)
};

export default ProgressCPT
