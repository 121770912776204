import {gql} from "@apollo/client";

export const GET_DOCUMENTS = gql`
    query GetDocuments($beneficiary_id: String!){
        documents(beneficiary_id: $beneficiary_id) {
            id
            name
            label
            url
            created_at
        }
    }
`
export const GET_DOCUMENT_LINK = gql`
    mutation GET_DOCUMENT_LINK($docId: String!) {
        renewDocumentLink(doc_id: $docId) {
            id
            url
        }
    }
`