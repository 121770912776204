const ACTION_TYPES = {
    PAGINATE: "PAGINATE",
    CREATE: "CREATE",
    VIEW: "VIEW",
    UPDATE: "UPDATE",
    PENDING_EVENTS_BENEFICIARY: "PENDING_EVENTS_BENEFICIARY",
    CANCEL_ACTIVE: "CANCEL_ACTIVE",
    CREATE_LIFE_PLAN: "CREATE_LIFE_PLAN",
    REMOVE_LIFE_PLAN: "REMOVE_LIFE_PLAN",
    CREATE_PHONE_CONTACT: "CREATE_PHONE_CONTACT"
}

const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.PAGINATE: {
            return {
                ...state,
                rows: action.data.data,
                page: action.data.paginatorInfo.currentPage,
                perPage: action.data.paginatorInfo.perPage,
                total: action.data.paginatorInfo.total,
                currentPage: action.data.paginatorInfo.currentPage,
            }
        }
        case ACTION_TYPES.CREATE: {
            return {
                ...state,
                rows: [...state.rows, action.data]
            }
        }
        case ACTION_TYPES.VIEW: {
            return {
                ...state,
                beneficiary: action.data
            }
        }
        case ACTION_TYPES.UPDATE: {
            return {
                ...state,
                beneficiary: action.data,
                rows: state.rows.map(row => {
                    if (row.id == action.data.id) row = action.data
                    return row
                }),
            }
        }
        case ACTION_TYPES.CANCEL_ACTIVE: {
            return {
                ...state,
                beneficiary: {
                    ...state.beneficiary,
                    active: action.data.active
                },
                rows: state.rows.map(row => {
                    if (row.id == action.data.id) row.active = action.data.active
                    return row
                }),
            }
        }
        case ACTION_TYPES.CREATE_LIFE_PLAN: {
            return {
                ...state,
                beneficiary: {
                    ...state.beneficiary,
                    lifePlan: [...state.beneficiary.lifePlan, action.data]
                }
            }
        }
        case ACTION_TYPES.REMOVE_LIFE_PLAN: {
            return {
                ...state,
                beneficiary: {
                    ...state.beneficiary,
                    lifePlan: state.beneficiary.lifePlan.filter(plan => plan.id !== action.data)
                }
            }
        }
        case ACTION_TYPES.CREATE_PHONE_CONTACT: {
            return {
                ...state,
                beneficiary: {
                    ...state.beneficiary,
                    contactsPhoneMade: action.data
                }
            }
        }
    }
    return state;
}

export {
    reducers,
    ACTION_TYPES
}
