import { ACTION_TYPES } from './reducers'

export const openCloseConfig = (value, dispatch) => {
    dispatch({ type: ACTION_TYPES.OPEN_CLOSE_CONFIG, data: value })
}

export const switchVariant = (variant, dispatch) => {
    dispatch({ type: ACTION_TYPES.SWITCH_VARIANT, data: variant })
}

export const switchFixedSidebar = (fixed, dispatch) => {
    dispatch({ type: ACTION_TYPES.SWITCH_FIXED, data: fixed })
}

export const changeWidth = (width, dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_WIDTH, data: width })
}