import { gql } from "@apollo/client";

export const GET_PLANS = gql`
  query getPlans {
    plans {
      id
      name
      ans
      secad
    }
  }
`;

export const GET_PLANS_PAGINATE = gql`
  query getPlansPaginate(
    $first: Int! = 15
    $page: Int! = 1
    $search: String
    $filters: Filters
  ) {
    plansPaginate(
      first: $first
      page: $page
      search: $search
      filters: $filters
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
        ans
        secad
      }
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation updatePlan($input: UpdatePlanInput) {
    updatePlan(input: $input) {
      id
      name
    }
  }
`;
