import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {GET_CID_CODES} from "graphql/cpt";
import AsyncSelect from "react-select/async";
import {useAssocCid} from "hooks/cpt";
import {useParams} from "react-router-dom";

function removeItemAll(arr, value) {
    let i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}

export default ({onSuccess, isOpen, closeModal, disease, isLoading, user}) => {
    const {id} = useParams();
    const [loadCidDisseases, {data: diseases, loading}] = useLazyQuery(
        GET_CID_CODES
    );
    const [assocCid, {loading: loadingAssoc}] = useAssocCid({
        onCompleted: (data) => {
            onSuccess();
        },
        onError: () => {
            console.log("errrou");
        },
    });
    const [cid, setCid] = useState([]);
    const onSubmit = () => {

        let connect = cid.map((i) => i.id);
        let disconnect = [];

        // Para não remover
        if (!connect.includes(disease.id)) {
            disconnect.push(disease.id);
        }

        // Para não inserir mais de uma vez
        connect = removeItemAll(connect, disease.id);

        if (disconnect.length < 1 && connect.length < 1) {
            return;
        }

        assocCid({
            variables: {
                input: {
                    id,
                    diseases: {
                        connect: connect,
                        disconnect: disconnect,
                    },
                    user_id: user.id
                },
            },
        });
    };

    const asyncValues = async (item) => {
        const {data} = await loadCidDisseases({
            variables: {
                search: `%${item.toLowerCase()}%`,
            },
        });
        return data?.getCidCodes;
    };

    useEffect(() => {
        if (!!disease && !!disease.id) {
            setCid([disease]);
        }

        loadCidDisseases({
            variables: {
                search: `%${disease?.name}%`,
            },
        });
    }, [disease]);

    return (
        <Modal
            isCentered
            onClose={() => closeModal()}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            autoFocus={false}
        >
            <ModalOverlay/>
            <ModalContent minWidth={"500px"}>
                <ModalHeader>Alterando CID #{disease?.name}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <AsyncSelect
                        defaultOptions={diseases?.getCidCodes || []}
                        getOptionLabel={(e) => `${e.code} - ${e.name}`}
                        loadOptions={asyncValues}
                        isLoading={loading}
                        placeholder={"Selecione o código correspondente"}
                        loadingMessage={() => "Buscando cid..."}
                        noOptionsMessage={() => "Digite para buscar"}
                        onChange={(event) => setCid(event)}
                        isMulti={true}
                        getOptionValue={(e) => e.id}
                        defaultValue={disease || null}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button mr={3} variant="ghost" onClick={() => closeModal()}>
                        Cancelar
                    </Button>
                    <Button
                        isLoading={isLoading || loadingAssoc}
                        colorScheme="red"
                        onClick={() => onSubmit()}
                    >
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
