import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { ACTION_TYPES, reducers } from "./reducers";
import { useFilter } from "hooks/useFilter";
import {
  CREATE_WHATSAPP_DISCLOSURE,
  GET_WHATSAPP_DISCLOSURES_PAGINATE,
  GET_WHATSAPP_DISCLOSURES,
  GET_WHATSAPP_DISCLOSURE,
  UPDATE_WHATSAPP_DISCLOSURE,
} from "graphql/whatsappDisclosures";
import {
  PAGINATE_DISCLOSURE_CAMPAIGN_RECEIVERS,
  UPDATE_CAMPAIGN_RECEIVER,
} from "graphql/campaignReceivers";

const WhatsappDisclosureStateContext = createContext();
const WhatsappDisclosureDispatchContext = createContext();

const WhatsappDisclosureProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });

  return (
    <WhatsappDisclosureStateContext.Provider value={state}>
      <WhatsappDisclosureDispatchContext.Provider value={dispatch}>
        {children}
      </WhatsappDisclosureDispatchContext.Provider>
    </WhatsappDisclosureStateContext.Provider>
  );
};

const useWhatsappDisclosures = () => {
  const context = useContext(WhatsappDisclosureStateContext);

  if (!context)
    throw new Error(
      "useWhatsappDisclosures must be used within an WhatsappDisclosureProvider"
    );

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(WhatsappDisclosureDispatchContext);

  if (dispatch === undefined)
    throw new Error(
      "useDispatch must be used within a WhatsappDisclosureProvider"
    );

  return dispatch;
};

const usePaginateWhatsappDisclosures = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_WHATSAPP_DISCLOSURES_PAGINATE, {
    onCompleted: (data) => {
      window.history.replaceState(null, '', `./whatsapp?page=${data.whatsappDisclosuresPaginate.paginatorInfo.currentPage}&size=${data.whatsappDisclosuresPaginate.paginatorInfo.perPage}`);
      dispatch({
        type: ACTION_TYPES.LIST,
        data: data.whatsappDisclosuresPaginate,
      });
    },
  });
};

const useCreateWhatsappDisclosure = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_WHATSAPP_DISCLOSURE, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.createWhatsappDisclosure);
      dispatch({
        type: ACTION_TYPES.CREATE,
        data: data.createWhatsappDisclosure,
      });
    },
    onError: (error) => {
      if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation);
    },
  });
};

const useWhatsappDisclosure = (id) => {
  const dispatch = useDispatch();

  return useQuery(
    GET_WHATSAPP_DISCLOSURE,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.SELECT, data: data.whatsappDisclosure });
      },
    }
  );
};

const useUpdateWhatsappDisclosure = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_WHATSAPP_DISCLOSURE, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.useUpdateWhatsappDisclosure);
      dispatch({
        type: ACTION_TYPES.UPDATE,
        data: data.useUpdateWhatsappDisclosure,
      });
    },
    onError: (error) => {
      if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation);
    },
    refetchQueries: [
      {
        query: GET_WHATSAPP_DISCLOSURES,
      },
    ],
  });
};

const usePaginateCampaignReceivers = (options = {}) => {
  const dispatch = useDispatch();

  return useLazyQuery(PAGINATE_DISCLOSURE_CAMPAIGN_RECEIVERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({
        type: ACTION_TYPES.LIST_CAMPAIGN_RECEIVERS,
        data: data.campaignReceiversPaginate,
      });
    },
  });
};

const useUpdateCampaignReceiver = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_CAMPAIGN_RECEIVER, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.updateCampaignReceiver);
      dispatch({
        type: ACTION_TYPES.UPDATE_CAMPAIGN_RECEIVERS,
        data: data.updateCampaignReceiver,
      });
    },
    onError: (error) => {
      if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation);
    },
  });
};

export {
  WhatsappDisclosureProvider,
  useWhatsappDisclosures,
  useWhatsappDisclosure,
  usePaginateWhatsappDisclosures,
  useCreateWhatsappDisclosure,
  useUpdateWhatsappDisclosure,
  usePaginateCampaignReceivers,
  useUpdateCampaignReceiver,
};
