// Chakra imports
import { Box, ChakraProvider, Flex, Image, Portal, Text, useDisclosure, useToast } from "@chakra-ui/react";
import "assets/css/pud-dashboard-styles.css";
import Configurator from "components/Configurator/Configurator";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";
import Footer from "components/Footer/Footer.js";
// Custom components
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
// Custom Chakra theme
import theme from "theme/theme.js";
import menuItems from 'menu'
import BackgroundCard from "components/BackgroundCard/BackgroundCard";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import { useLayout } from "hooks/layout";

import Logo from 'assets/img/logo-hapvida-notredame.png';
import Home_Icon from 'assets/img/home_icon.png';

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed, setFixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // ref for main panel div
  const mainPanel = React.createRef();
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = window.location.pathname == "/sistema/perfil" ? "Perfil" : "Notrelife";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.provider !== null)
        return (
          <PrivateRoute
            exact
            path={prop.layout + prop.path}
            key={key}
            roles={prop.roles}
          >
            <prop.provider>
              <prop.component />
            </prop.provider>
          </PrivateRoute>
        )
      return (
        <PrivateRoute
          exact
          path={prop.layout + prop.path}
          key={key}
          component={prop.component}
          roles={prop.roles}
        />
      )
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "admin";
  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Box w="full" position="relative" id="notredame-header">
        <Box h="80px" w="265px" right="calc(50% - 132.5px)" position="absolute" >
          <Image src={Logo} w="full" h="full" />
        </Box>
        <Flex alignItems="center" w="full" h="60px" px={12} backgroundColor="blue.600" fontSize="26px">
          <a href="https://hub.hapvida.healthidlab.com.br/">
            <Flex> 
              <Image src={Home_Icon} />
            </Flex>
          </a>
        </Flex>
        <Box w="full" h="20px" backgroundColor="orange.500" />
      </Box>
      <Sidebar
        routes={menuItems}
        logoText={""}
        display="none"
        {...rest}
      />
      <MainPanel
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            logoText={""}
            brandText={getActiveRoute(menuItems)}
            secondary={getActiveNavbar(menuItems)}
            fixed={fixed}
            {...rest}
          />
        </Portal>

        {getRoute() ? (
          <PanelContent>
            <PanelContainer>
              <BackgroundCard />
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/sistema" to="/sistema/dashboards/default" />
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : null}
        <Footer />
        <Portal>
          <FixedPlugin fixed={fixed} onOpen={onOpen} />
        </Portal>
        <Configurator
          secondary={getActiveNavbar(routes)}
          isOpen={isOpen}
          onClose={onClose}
          isChecked={fixed}
          onSwitch={(value) => {
            setFixed(value);
          }}
        />
      </MainPanel>
    </ChakraProvider>
  );
}
