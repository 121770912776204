import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { fromAnyFormatToBr } from "functions/momentFormat";
import React from "react";
import { useUser } from "hooks/users";
import { NavLink, useParams } from "react-router-dom";
import { Badge, Button, Flex, FormControl, FormLabel, Grid, Skeleton, Text, useColorModeValue, } from "@chakra-ui/react";
import { roleConsu, roleEnfer, roleMedic, roleRecep } from "../../../variables/roles";

const ViewUser = () => {
    const { id } = useParams();
    const { data, loading } = useUser(id);

    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{ sm: "120px", lg: "160px" }}
        >
            <Card w={{ md: "100%", lg: "50%" }}>
                <CardHeader mb="40px">
                    <Flex direction="row" w="100%" justify="space-between">
                        <Text
                            color={textColor}
                            fontSize="lg"
                            fontWeight="bold"
                            mb="3px"
                        >
                            Usuário
                        </Text>
                        <Flex>
                            <NavLink to={`/sistema/administracao/usuarios/${id}/editar`}>
                                <Button
                                    backgroundColor={buttonBg}
                                    color="white"
                                    variant="no-hover"
                                    mr="5"
                                >
                                    Editar
                                </Button>
                            </NavLink>
                            <NavLink to="/sistema/administracao/usuarios">
                                <Button>
                                    Voltar
                                </Button>
                            </NavLink>
                        </Flex>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex direction="column" w="100%">
                        <Grid
                            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                            gap="24px"
                        >
                            <FormControl>
                                <FormLabel
                                    color={textColor}
                                    fontWeight="bold"
                                    fontSize="xs"
                                >
                                    Nome
                                </FormLabel>
                                <Skeleton height={"25px"} isLoaded={!loading}>
                                    <Text
                                        color={textColor}
                                        fontSize="lg"
                                        fontWeight="bold"
                                        mb="3px"
                                    >
                                        {data?.user.name || null}
                                    </Text>
                                </Skeleton>
                            </FormControl>
                        </Grid>
                        <Grid
                            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                            gap="24px"
                            mt="24px"
                        >
                            <FormControl>
                                <FormLabel
                                    color={textColor}
                                    fontWeight="bold"
                                    fontSize="xs"
                                >
                                    E-mail
                                </FormLabel>
                                <Skeleton height={"25px"} isLoaded={!loading}>
                                    <Text
                                        color={textColor}
                                        fontSize="lg"
                                        fontWeight="bold"
                                        mb="3px"
                                    >
                                        {data?.user.email || null}
                                    </Text>
                                </Skeleton>
                            </FormControl>
                            <FormControl>
                                <FormLabel
                                    color={textColor}
                                    fontWeight="bold"
                                    fontSize="xs"
                                >
                                    Tipo de Usuário
                                </FormLabel>
                                <Skeleton height={"25px"} isLoaded={!loading}>
                                    <Text
                                        color={textColor}
                                        fontSize="lg"
                                        fontWeight="bold"
                                        mb="3px"
                                    >
                                        {data?.user.role.replace('_', ' ').replace('_', ' ') || null}
                                    </Text>
                                </Skeleton>
                            </FormControl>
                            <FormControl>
                                <FormLabel
                                    color={textColor}
                                    fontWeight="bold"
                                    fontSize="xs"
                                >
                                    Status
                                </FormLabel>
                                <Skeleton height={"25px"} isLoaded={!loading}>
                                    <Text
                                        color={textColor}
                                        fontSize="lg"
                                        fontWeight="bold"
                                        mb="3px"
                                    >
                                        <Flex
                                            alignItems="center"
                                        >
                                            <Badge
                                                backgroundColor={(data?.user?.status === "true" || data?.user?.status === true) ? "green.500" : "red.500"}
                                                height="20px"
                                                width="20px"
                                                borderRadius="100px"
                                                mr="10px"
                                            ></Badge>
                                            <Text
                                                lineHeight="20px"
                                            >
                                                {(data?.user?.status === "true" || data?.user?.status === true) ? "Ativo" : "Inativo"}
                                            </Text>
                                        </Flex>
                                    </Text>
                                </Skeleton>
                            </FormControl>
                            {
                                [roleEnfer, roleConsu, roleRecep, roleMedic].includes(data?.user.role) &&
                                <>
                                    <FormControl>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            CPF
                                        </FormLabel>
                                        <Skeleton height={"25px"} isLoaded={!loading}>
                                            <Text
                                                color={textColor}
                                                fontSize="lg"
                                                fontWeight="bold"
                                                mb="3px"
                                            >
                                                {data?.user?.professional?.cpf || null}
                                            </Text>
                                        </Skeleton>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Data de Nascimento
                                        </FormLabel>
                                        <Skeleton height={"25px"} isLoaded={!loading}>
                                            <Text
                                                color={textColor}
                                                fontSize="lg"
                                                fontWeight="bold"
                                                mb="3px"
                                            >
                                                {fromAnyFormatToBr(data?.user?.professional.birthdate)}
                                            </Text>
                                        </Skeleton>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel
                                            color={textColor}
                                            fontWeight="bold"
                                            fontSize="xs"
                                        >
                                            Telefone Celular
                                        </FormLabel>
                                        <Skeleton height={"25px"} isLoaded={!loading}>
                                            <Text
                                                color={textColor}
                                                fontSize="lg"
                                                fontWeight="bold"
                                                mb="3px"
                                            >
                                                {data?.user?.professional.phone || null}
                                            </Text>
                                        </Skeleton>
                                    </FormControl>
                                </>
                            }
                        </Grid>
                        {
                            ["Recepcionista"].includes(data?.user.role) &&
                            <Grid
                                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                gap="24px"
                                mt="24px"
                                w="100%"
                            >
                                <FormControl>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Profissionais Associados
                                    </FormLabel>
                                    <Skeleton height={"25px"} isLoaded={!loading}>
                                        <Text
                                            color={textColor}
                                            fontSize="lg"
                                            fontWeight="bold"
                                            mb="3px"
                                        >
                                            {data?.user?.professional.assistedProfessionals &&
                                                data?.user?.professional.assistedProfessionals.map(pro => (
                                                    `${pro.user.name}, `
                                                ))
                                            }
                                        </Text>
                                    </Skeleton>
                                </FormControl>
                            </Grid>
                        }
                    </Flex>
                </CardBody>
            </Card>
        </Flex>
    )
}

export default ViewUser