import { gql } from '@apollo/client';

export const GET_BENEFICIARIES = gql`
    query GetBeneficiaries {
        beneficiaries {
            id
            name
            cpf
            coverage
            email
            created_at
            proposal {
                proposal_number
                assign_at
                plan {
                    name
                    secad
                    ans
                }
            }
        }
    }
`

export const GET_BENEFICIARIES_PAGINATE = gql`
    query GetBeneficiaries($first: Int! = 30, $page: Int! = 1, $search: String, $orderBy: String, $proposalId: String) {
        beneficiariesPaginate(first: $first, page: $page, search: $search, orderBy: $orderBy, proposalId: $proposalId) {
            paginatorInfo {
                count
                currentPage
                firstItem
                perPage
                lastItem
                total
            }
            data {
                id
                name
                cpf
                active
                coverage
                email
                created_at
                proposal {
                    proposal_number
                    assign_at
                    plan {
                        name
                        secad
                        ans
                    }
                }
            }
        }
    }
`

export const GET_BENEFICIARY = gql`
    query GetBeneficiary($id: String!) {
        beneficiary(id: $id) {
            id
            name
            cpf
            rg
            coverage
            active
            email
            birthdate
            created_at
            mother_name
            gender
            cep
            state_id
            city_id
            address
            number
            district
            complement
            phone
            second_phone
            second_phone_contact
            second_phone_type
            eligibility
            type_ineligibility
            strike_fr_scheduled
            company {
                name
            }
            manager {
                id
                user {
                    name
                    role
                }
            }
            events {
                id
                type
                scheduled_to
            }
            indicators {
                imc
                stratification
                timeline {
                    stratificated_at
                    answers {
                        hospitalization
                        medical_appointment
                        disease
                        smoking
                        alcohol_consumption
                        physical_activity
                    }
                }
            }
            contactsPhoneMade {
                pending_activity
                tries
                contact_type
                was_scheduled
                created_at
            }
            timeline {
                id
                logo
                title
                titleColor
                date
                color
                description
                tags {
                    bgTag
                    titleTag
                }
                taggable {
                    __typename
                    ... on Assessment {
                        id
                        type {
                            name
                        }
                    }
                }
            }
            notes {
                id
                title
                text
                created_at
                user {
                    name
                }
            }
            healthStatement {
                id
                answered_in
                url_document
                metrics {
                    name
                    tag
                    value
                    result
                    results
                }
                evaluations {
                    key
                    tag
                    value
                    order
                    data {
                        height
                        weight
                    }
                }
            }
            firstReception {
                id
                answered_in
                metrics {
                    name
                    tag
                    value
                    result
                    results
                }
                evaluations {
                    key
                    tag
                    value
                    order
                    data {
                        height
                        weight
                    }
                }
            }
            lastAccompaniment {
                id
                answered_in
                assessmentType {
                    id
                    name
                }
                metrics {
                    name
                    tag
                    value
                    result
                    results
                }
                evaluations {
                    key
                    tag
                    value
                    order
                    data {
                        height
                        weight
                    }
                }
            }
            proposal {
                id
                proposal_number
                assign_at
                plan {
                    id
                    name
                    secad
                    ans
                    regionals {
                        id
                        name
                    }
                }
            }

            lifePlan {
                id
                plans
                options
                score
                created_at
            }
            carePlan {
                id
                prisma_risk
                boult_risk
                first_assestment
                medic
                frequency
                presential
                counseling
            }
            receiver {
                phone_disabled
            }
            pending_awaiting_schedules {
                id
                is_awaiting_schedule
                awaiting_schedule_at
                awaiting_schedule
            }
        }
    }
`
export const PENDING_EVENTS_BENEFICIARY = gql`
    query GetBeneficiary($id: String!) {
        beneficiary(id: $id) {
            assessmentsPending {
                id
                type {
                    name
                }
                url
                event {
                    id
                    scheduled_to
                    awaiting_schedule {
                        awaiting_schedule
                    }
                    type
                    professional {
                        user {
                            name
                        }
                    }
                }
            }
        }
    }
`

export const PENDING_AWAITING_SCHEDULES = gql`
    query getBeneficiaryAwaitingSchedules($id: String!) {
        beneficiary(id: $id) {
            name
            eligibility
            type_ineligibility
            strike_fr_scheduled
            pending_awaiting_schedules {
                id
                is_awaiting_schedule
                awaiting_schedule_at
                awaiting_schedule
            }
        }
    }
`

export const COMMITMENTS_BENEFICIARY = gql`
    query GetCommitmentsBeneficiary($id: String!) {
        commitments(patient_id: $id, type: 2, status: "pending") {
            id
            professional_id
            patient_id
            type
            type_desc
            url
            status
            created_at
        }
    }
`
export const CREATE_BENEFICIARY = gql`
    mutation CREATE_PROPOSAL($input: CreateProposalInput) {
        createProposal(input: $input) {
            id
            beneficiaries {
                id
            }
        }
    }
`
export const UPDATE_PROPOSAL = gql`
    mutation UPDATE_PROPOSAL($input: UpdateProposalInput) {
        updateProposal(input: $input) {
            id
            beneficiaries {
                id
                name
                cpf
                rg
                coverage
                email
                birthdate
                created_at
                mother_name
                gender
                cep
                state_id
                city_id
                address
                number
                district
                complement
                phone
                second_phone
                second_phone_contact
                second_phone_type
                proposal {
                    id
                    proposal_number
                    assign_at
                    plan {
                        id
                        name
                        secad
                        ans
                        regionals {
                            id
                            name
                        }
                    }
                }
                manager {
                    id
                    user {
                        name
                        role
                    }
                }
            }
        }
    }
`
export const UPDATE_BENEFICIARY = gql`
    mutation UpdateBeneficiary($input: UpdateBeneficiary) {
        updateBeneficiary(input: $input) {
            id
            name
            cpf
            rg
            coverage
            email
            birthdate
            created_at
            mother_name
            gender
            cep
            state_id
            city_id
            address
            number
            district
            complement
            phone
            second_phone
            second_phone_contact
            second_phone_type
            contactsPhoneMade {
                pending_activity
                tries
                contact_type
                was_scheduled
                created_at
            }
        }
    }
`

export const CREATE_LIFE_PLAN = gql`
    mutation CreateLifePlan($input: CreateLifePlanInput) {
        createLifePlan(input: $input) {
            id
            plans
            options
            score
            created_at
        }
    }
`

export const DELETE_LIFE_PLAN = gql`
    mutation DeleteLifePlan(
        $id: ID!,
    ) {
        deleteLifePlan(
            id: $id,
        ) {
            id
        }
    }
`

export const UPDATE_CARE_PLAN = gql`
    mutation UpdateCarePlan($input: UpdateCarePlanInput) {
        updateCarePlan(input: $input) {
            id
            first_assestment
            medic
            frequency
            presential
            counseling
        }
    }
`

export const CANCEL_BENEFICIARY = gql`
    mutation CancelBeneficiary($input: CancelBeneficiary) {
        cancelBeneficiary(input: $input) {
            id
            active
        }
    }
`

export const GET_BENEFICIARY_EVALUATION = gql`
    query GetBeneficiaryEvaluation($where: QueryEvaluationsWhereWhereConditions!) {
        evaluations(where: $where) {
            key
            tag
            value
            order
            data {
                height
                weight
            }
        }
    }
`

export const UPLOAD_FILE_CANCEL_BENEFICIARY = gql`
    mutation UPLOAD_FILE_CANCEL_BENEFICIARY ($file: Upload!) {
        uploadImporting(file: $file) {
            id
            label
            status
            url
        }
    }
`


