import { BeneficiaryProvider as Provider } from "hooks/beneficiaries"
import { ManagerQueryProvider } from "../services/manager";

const BeneficiaryProvider = ({ children }) => {
    return (
        <ManagerQueryProvider>
            <Provider>
                {children}
            </Provider>
        </ManagerQueryProvider>
    )
}

export default BeneficiaryProvider
