const ACTION_TYPES = {
  LIST: "LIST",
  SELECT: 'SELECT',
}

const reducers = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return {
          ...state,
          regionals: action.data,
      }
    }
    case ACTION_TYPES.SELECT: {
      return { 
        ...state, 
        regional: action.data.regional_id
      }
    }
    default: {
      return state
    }
  }
}

export {
  reducers,
  ACTION_TYPES
}