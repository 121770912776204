import React, { useMemo } from "react";

import {
	Box,
	Flex,
	Stack, Tag,
	Text,
} from "@chakra-ui/react";

import BodyMassGrowthChart from "components/Charts/Nivo/Beneficiaries/BodyMassGrowthChart";
import RisksGrowthChart from "components/Charts/Nivo/Beneficiaries/RisksGrowthChart";
import { useBeneficiary } from "hooks/beneficiary";
import stratificationToNumber from "functions/stratificationToNumber";
import TimelineRow from "components/Tables/TimelineRow";
import Icon from "../../../../components/FontAwesomeIcons/Icon";
import { FaInfoCircle, } from "react-icons/fa";
import dateFormat from "functions/dateFormat";
import { useBeneficiaries } from "hooks/beneficiaries";

const TimelineIndicators = ({ item, key }) => {
	const hoverColor = "notrelife.800"
	return (
		<TimelineRow
			title={`Colhido em ${dateFormat(item.stratificated_at, 'dd/MM/Y HH:mm:ss')}`}
			haveTemplate={true}
			color={'#3788d8'}
			logo={FaInfoCircle}
			key={key}
		>
			<Flex direction={'column'}>
				{Object.keys(item.answers).filter(i => i !== '__typename' && item.answers[i])
					.map((i, index) => (
						<Tag
							bg={hoverColor}
							fontSize="xs"
							size="lg"
							color="#fff"
							mb="16px"
							borderRadius="15px"
							style={{ marginRight: '10px' }}
							key={index}
						>
							<Text fontWeight="bold" fontSize="sm" mr={4}>
								<Flex direction={'row'}>
									<Icon icon={i} margin={10} pointer />{item.answers[i]}
								</Flex>
							</Text>
						</Tag>
					))}
			</Flex>
		</TimelineRow>
	);
}
const BeneficiaryCharts = () => {
	const { beneficiary } = useBeneficiaries();
	const indicators = beneficiary.indicators || [];

	const timeline = (indicators != null && indicators?.timeline != null) ? [...indicators.timeline] : []

	const data = useMemo(() => {
		const indicator = (indicators && indicators.imc) ? indicators.imc.map((i, index) => ({ y: i, x: `Q${index + 1}` })) : [];
		return [{
			"id": "IMC",
			"data": indicator
		}]
	}, [indicators]);

	const stratification = useMemo(() => {
		const indicator = (indicators && indicators.stratification) ? indicators.stratification.map((i, index) => ({ y: stratificationToNumber[i.toUpperCase()], x: `Q${index + 1}`, label: i })) : [];
		return [{
			"id": "Risco",
			"data": indicator
		}]
	}, [indicators]);

	return (
		<>
			<Box w="100%">
				<Flex justifyContent="flex-start" alignItems="center">
					<Text fontSize="lg" fontWeight="bold">
						Controle de IMC
					</Text>
				</Flex>
				<Box w="100%" minHeight="300px" height="300px">
					<BodyMassGrowthChart data={data} />
				</Box>
			</Box>
			<Box w="100%">
				<Flex justifyContent="flex-start" alignItems="center">
					<Text fontSize="lg" fontWeight="bold">
						Estratificação de Risco
					</Text>
				</Flex>
				<Box w="100%" minHeight="300px" height="300px">
					<RisksGrowthChart data={stratification} />
				</Box>
			</Box>
			<Box w="100%">
				<Flex justifyContent="flex-start" alignItems="center">
					<Text fontSize="lg" fontWeight="bold" mb="22px">
						Indicadores de Saúde
					</Text>
				</Flex>
				<Box w="100%">
					<Stack direction="column" spacing="4px">
						{timeline?.sort((a, b) => new Date(b.stratificated_at) - new Date(a.stratificated_at)).map((row, index) => (<TimelineIndicators item={row} key={index} />))}
					</Stack>
				</Box>
			</Box>
		</>
	)
}

export default BeneficiaryCharts
