import * as yup from "yup";
import CampaignForm from "../components/CampaignForm";
import ActivityLectureCampaignForm from "../components/ActivityLectureCampaignForm";

const validateUrl = /^(http|https):\/\//

const validateDateTime = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(2[0-9][2-9][3-9]) ([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/

const schemas = {
  campaign: {
    url: yup
      .string()
      .required("O campo Link Informativo é obrigatório")
      .matches(validateUrl, "Insira um link válido"),
  },
  activityLecture: {
    about: yup.string(),
    scheduled_to: yup.string().matches(validateDateTime, "Insira uma data e hora válida"),
    ambience: yup
      .string("Insira um valor válido")
      .oneOf(["presencial", "virtual"], "Insira um valor válido"),
    url: yup
      .string()
      .when("ambience", {
        is: (ambience) => ambience === "virtual",
        then: yup
          .string()
          .required("Esse campo é obrigatório")
          .matches(validateUrl, "Insira um link válido"),
      }),
    cep: yup.string().when("ambience", {
      is: (ambience) => ambience === "presencial",
      then: yup
        .string()
        .required("Esse campo é obrigatório")
    }),
  },
};

export const invitationTypes = [
  {
    label: "Campanha",
    value: "campaign",
    disabled: false,
    component: CampaignForm,
    schema: schemas.campaign,
  },
  {
    label: "Atividade",
    value: "activity",
    disabled: false,
    component: ActivityLectureCampaignForm,
    schema: schemas.activityLecture,
  },
  {
    label: "Palestra",
    value: "lecture",
    disabled: false,
    component: ActivityLectureCampaignForm,
    schema: schemas.activityLecture,
  },
];

