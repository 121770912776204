import { gql } from '@apollo/client';
export const CREATE_REPORT =  gql`
    mutation CREATE_REPORT($input: InputReportCreate!) {
        createReport(input: $input) {
            id
            label
            status
            url
            created_at
        }
    }
`

export const GET_REPORT =  gql`
    query GetPlans {
        plans {
            id
            label
            status
            url
            created_at
        }
    }
`;

export const GET_REPORTS_PAGINATE =  gql`
    query GET_REPORTS_PAGE($first: Int!, $page: Int) {
        reports(first: $first, page: $page) {
            data {
                id
                label
                status
                url
                report_tag
                created_at
            }
            paginatorInfo {
                count
                currentPage
                perPage
                total
            }
        }
    }
`;