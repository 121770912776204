import BasicTable from "../../../components/Tables/BasicTable";
import {
    Box,
    Flex,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {useState} from "react";
import ModalAssocPlan from "./ModalAssocPlan";
import ModalNewPlan from "./ModalNewPlan";
import {useUpdateRegional} from "../../../hooks/regionals";
import ModalAssocProfissionals from "./ModalAssocProfissionals";
import { useAuth } from "hooks/useAuth";
import {roleAdmin, roleSuper} from "../../../variables/roles";
import { List, PlusCircle, Repeat } from "@phosphor-icons/react";

const columnPlans = [
    {Header: "Nome", accessor: "name"},
    {Header: "ANS", accessor: "ans"},
    {Header: "Secad", accessor: "secad"},
]

const columnProfessionals = [
    {Header: "Nome", accessor: "user.name"},
    {Header: "CPF", accessor: "cpf"},
]

const TablePlans = ({ loading, plans = [] , modalCreatePlan, modalAssocPlan }) => {
    const { user } = useAuth()
    
    return (
        <Card style={{"margin": "12px 0 0"}}>
            <CardHeader mb="20px">
                <Box w="100%">
                    <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{sm: "10px", lg: "12px"}}
                        color="gray.400"
                    >
                        <Text fontSize="lg" fontWeight="bold" mb="6px">
                            Planos
                        </Text>
                        {
                            (user?.role === roleAdmin || user?.role === roleSuper) &&
                            <Menu>
                                <MenuButton
                                    aria-label='Options'
                                    variant='outline'
    
                                >
                                    <List size="18" />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem icon={<PlusCircle size="18" />} command='⌘T' onClick={() => modalCreatePlan() }>
                                        Criar Plano e Associar a regional
                                    </MenuItem>
                                    <MenuItem icon={<Repeat size="18" />} command='⌘N' onClick={() => modalAssocPlan() }>
                                        Associar/Remover Associação do plano
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        }
                    </Flex>
                </Box>
            </CardHeader>
            <CardBody>
                <BasicTable
                    isLoading={loading}
                    tableData={plans}
                    columnsData={columnPlans}
                    noEdit={true}
                    noView={true}
                    noDelete={true}
                />
            </CardBody>
        </Card>
    )
}

const TableProfessionals = ({ loading, modalAssocProf, professionals = [] }) => {
    const { user } = useAuth()

    return (
        <Card style={{"margin": "12px 0 0"}}>
            <CardHeader mb="20px">
                <Box w="100%">
                    <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{sm: "10px", lg: "12px"}}
                        color="gray.400"
                    >
                        <Text fontSize="lg" fontWeight="bold" mb="6px">
                            Profissionais

                        </Text>
                        {
                            (user?.role === roleAdmin || user?.role === roleSuper) &&
                            <Menu>
                                <MenuButton
                                    aria-label='Options'
                                    variant='outline'

                                >
                                    <List size="18" />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem icon={<Repeat size="18" />} command='⌘N' onClick={() => modalAssocProf() }>
                                        Associar/Remover Associação aos Profissionais
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        }
                    </Flex>
                </Box>
            </CardHeader>
            <CardBody>
                    <BasicTable
                        isLoading={loading}
                        tableData={professionals}
                        columnsData={columnProfessionals}
                        noEdit={true}
                        noView={true}
                        noDelete={true}
                    />
            </CardBody>
        </Card>
    )
}


const RegionalDetails = ({ regional, loading }) => {
    const toast = useToast()

    const [updateRegional, { loading: loadingSave }] = useUpdateRegional({
        onSuccess: (data) => {
            setOpenModalNewPlan(false);
            setOpenModalAssocPlan(false);
            setOpenModalAssocProf(false);
            toast({
                title: 'Regional atualizada.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        }
    })
    const [ openModalNewPlan, setOpenModalNewPlan ] = useState(false)
    const [ openModalAssocPlan, setOpenModalAssocPlan ] = useState(false)
    const [ openModalAssocProf, setOpenModalAssocProf ] = useState(false)

    const handleUpdate = (data) => {
        updateRegional({
            variables: {
                input: {
                    id: regional.id,
                    ...data
                }
            }
        })
    }
    return (
        <Flex direction={"column"} w="100%">

            <TablePlans
                loading={loading}
                plans={regional?.plans}
                modalAssocPlan={() => setOpenModalAssocPlan(true)}
                modalCreatePlan={() => setOpenModalNewPlan(true)}
                noView
                linkRow
            />

            <TableProfessionals
                loading={loading}
                professionals={regional?.professionals}
                modalAssocProf={() => setOpenModalAssocProf(true)}
            />


                        <ModalAssocPlan
                            defaultValues={regional?.plans}
                            isOpen={openModalAssocPlan}
                            loadingSave={loadingSave}
                            onClose={() => setOpenModalAssocPlan(false)}
                            onSubmit={handleUpdate}
                        />
                        <ModalAssocProfissionals
                            defaultValues={regional?.professionals}
                            isOpen={openModalAssocProf}
                            loadingSave={loadingSave}
                            onClose={() => setOpenModalAssocProf(false)}
                            onSubmit={handleUpdate}
                        />
                        <ModalNewPlan
                            isOpen={openModalNewPlan}
                            loadingSave={loadingSave}
                            onClose={() => setOpenModalNewPlan(false)}
                            onSubmit={handleUpdate}
                        />


        </Flex>
    )
}

export default RegionalDetails;
