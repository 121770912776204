import {getYear} from "date-fns"

const getCurrentYear = (currDate) => {
    const start = new Date(currDate.getFullYear(), 0, 0);
    const diff = currDate - start;
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const weeks = Math.floor(diff / oneWeek);


    const currYear = getYear(currDate);
    return (weeks >= 49) ? currYear + 1 : currYear;
}

export default getCurrentYear