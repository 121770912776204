import { gql } from "@apollo/client";

export const GET_COMMITMENTS = gql`
  query GetCommitments {
    commitments {
      id
      type
      status
      type_desc
      contact_attempts
      refusal_motive
      professional {
        user {
          id
          name
        }
      }
      beneficiary {
        id
        name
        phone
        proposal {
          plan {
            name
          }
        }
        assessments {
          id
          answered_in
          assessmentType {
            name
            builder_tag
          }
          metrics {
            tag
            result
          }
        }
        notes {
          title
          text
        }
      }
      created_at
      updated_at
    }
  }
`;

export const GET_COMMITMENTS_PAGINATE = gql`
  query GET_COMMITMENTS($first: Int! = 15, $page: Int! = 1, $filters: Filters) {
    commitmentsPaginate(first: $first, page: $page, filters: $filters) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        type
        status
        type_desc
        contact_attempts
        refusal_motive
        professional {
          user {
            id
            name
          }
        }
        beneficiary {
          id
          name
          phone
          birthdate
          assessments {
            id
            answered_in
            assessmentType {
              name
              builder_tag
            }
            metrics {
              tag
              result
            }
          }
          proposal {
            plan {
              name
            }
          }
          notes {
            title
            text
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_COMMITMENT = gql`
  mutation UpdateCommitment($input: UpdateCommitmentInput) {
    updateCommitment(input: $input) {
      id
      type
      status
      type_desc
      contact_attempts
      refusal_motive
      professional {
        user {
          id
          name
        }
      }
      beneficiary {
        name
        phone
        proposal {
          plan {
            name
          }
        }
        notes {
          title
        }
      }
      created_at
      updated_at
    }
  }
`;
