export const findRiskDS = (value) => {
	if (!value || value?.length === 0) return null;
	const ds = value.find(
		(vl) =>
			vl?.assessmentType?.builder_tag === "declaracao_saude" &&
			vl?.metrics.length > 0
	);
	if (!ds || !ds?.metrics) return null;
	const metric = ds?.metrics?.find((mt) => mt?.tag === "estratificacao_ds");
	if (!metric) return null;
	else return metric?.result;
};

export const findRiskPA = (value) => {
	if (!value || value?.length === 0) return null;
	const pa = value.find(
		(vl) =>
			vl?.assessmentType?.name === "Primeiro Acolhimento" &&
			vl?.metrics.length > 0
	);

	if (!pa || !pa?.metrics) return null;

	const metric = pa?.metrics?.find(
		(mt) =>
			mt?.tag === "estratificacao_pa_49" || mt?.tag === "estratificacao_pa_48"
	);

	if (!metric) return null;
	else return metric?.result;
};
