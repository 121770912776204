import React, { useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateUnscheduledEvent,
  useUnscheduledEvents,
} from "hooks/unscheduledEvents";

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Skeleton,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import moment from "moment";
import { fromAnyFormatToAny } from "functions/momentFormat";
import { useGetProfessionalsByRegional } from "hooks/beneficiary";
import { useRegionals } from "hooks/globalRegionals";

const schema = yup.object().shape({
  type: yup
    .string()
    .nullable()
    .required("O campo 'Tipo de Atendimento' é obrigatório"),
  motive: yup
    .string()
    .nullable()
    .required("O campo ' Motivo do Atendimento' é obrigatório"),
  start: yup
    .string()
    .nullable()
    .required("O campo 'Data e Hora do Início do Atendimento é obrigatório")
    .test(
      "date-valid",
      "O valor do campo 'Data e Hora do Início do Atendimento' não é válido",
      function (field) {
        const start = moment(field, "DD/MM/YYYY hh:mm");
        if (!!this.parent.end) {
          const end = moment(this.parent.end, "DD/MM/YYYY hh:mm");
          return (
            start.isValid() && start.isBefore(moment()) && start.isBefore(end)
          );
        }
        return start.isValid() && start.isBefore(moment());
      }
    ),
  end: yup
    .string()
    .nullable()
    .required("O campo 'Data e Hora do Término do Atendimento é obrigatório")
    .test(
      "date-valid",
      "O valor do campo 'Data e Hora do Término do Atendimento' não é válido",
      function (field) {
        const end = moment(field, "DD/MM/YYYY hh:mm");
        if (!!this.parent.start) {
          const start = moment(this.parent.start, "DD/MM/YYYY hh:mm");
          return end.isValid() && end.isBefore(moment()) && end.isAfter(start);
        }
        return end.isValid() && end.isBefore(moment());
      }
    ),
  professional_id: yup
    .string()
    .nullable()
    .required("O campo ' Profissional' é obrigatório"),
});

const BeneficiaryUnscheduledEvent = () => {
  const { id } = useParams();
  const methods = useForm();
  const toast = useToast();

  const { regional: regionalId } = useRegionals();

  const { unscheduledEvents, data, loading } = useUnscheduledEvents();
  const [
    getProfessionalsByRegional,
    { data: professionals, loading: loadingProfessionals },
  ] = useGetProfessionalsByRegional();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const wType = watch("type", null);
  const wMotive = watch("motive", null);

  const [
    createUnscheduledEvent,
    { loading: loadingCreate },
  ] = useCreateUnscheduledEvent({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Atendimento adicionado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: (err) => {
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onSubmit = (data) => {
    const variables = {
      input: {
        patient_id: id,
        end: moment(data.end, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss"),
        motive: parseInt(data.motive),
        professional_id: data.professional_id,
        start: moment(data.start, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        type: parseInt(data.type),
        attendance_type: parseInt(data.attendance_type),
      },
    };

    if (!!data.info) variables.input.info = data.info;

    createUnscheduledEvent({ variables: variables });
  };

  const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
  const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    getProfessionalsByRegional({
      variables: {
        regional_id: regionalId,
        accepted_roles: ["Enfermeiro", "Consultor_de_Saúde", "Recepcionista"],
      },
    });
  }, []);

  return (
    <Flex width="100%" height="100%" direction="column">
      <Flex mb="24px">
        <FormProvider {...methods}>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" w="100%">
              <Grid
                templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                templateRows={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                gap="24px"
              >
                <FormControl isInvalid={errors.type}>
                  <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                    Tipo de Compromisso
                  </FormLabel>
                  <Select
                    {...register("type")}
                    focusBorderColor={inputHover}
                    placeholder="Selecione uma opção..."
                    color="gray.500"
                    size="md"
                    borderRadius="12px"
                    cursor="pointer"
                  >
                    <option value={1}>Demanda espontânea</option>
                    <option value={2}>Monitoramento de Internados</option>
                  </Select>
                  {errors.type && (
                    <FormErrorMessage>{errors.type.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.motive}>
                  <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                    Motivo do Atendimento
                  </FormLabel>
                  <Select
                    {...register("motive")}
                    disabled={wType == null || wType == ""}
                    focusBorderColor={inputHover}
                    placeholder={
                      wType == null || wType == ""
                        ? "Selecione um tipo de atendimento"
                        : "Selecione uma opção..."
                    }
                    color="gray.500"
                    size="md"
                    borderRadius="12px"
                    cursor="pointer"
                  >
                    {(wType === 1 || wType === "1") && (
                      <>
                        <option value={1}>Demanda administrativa</option>
                        <option value={2}>Queixa/dúvida clínica</option>
                        <option value={3}>Dúvida de CPT</option>
                        <option value={4}>Acolhimento NIP</option>
                        <option value={5}>Dúvida do plano de cuidados</option>
                        <option value={6}>
                          Dúvida sobre rede assistencial
                        </option>
                        <option value={7}>Reclamação de atendimento</option>
                        <option value={8}>
                          Pedido de agendamento com especialidade sem
                          encaminhamento
                        </option>
                        <option value={9}>
                          Renovação/pedido de receita ou guia de exame
                        </option>
                        <option value={10}>Formulário de alto custo SUS</option>
                        <option value={11}>Outro</option>
                      </>
                    )}
                    {(wType === 2 || wType === "2") && (
                      <>
                        <option value={12}>Visita hospitalar</option>
                        <option value={13}>
                          Telemonitoramento durante internação
                        </option>
                        <option value={14}>
                          Telemonitoramento após alta hospitalar
                        </option>
                      </>
                    )}
                  </Select>
                  {errors.motive && (
                    <FormErrorMessage>{errors.motive.message}</FormErrorMessage>
                  )}
                </FormControl>
                {(wType === 1 || wType === "1") &&
                  (wMotive === 11 || wMotive === "11") && (
                    <FormControl isInvalid={errors.info}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Mais Informações
                      </FormLabel>
                      <Input
                        {...register("info")}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.info && (
                        <FormErrorMessage>
                          {errors.info.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                <FormControl isInvalid={errors.start}>
                  <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                    Data e Hora do Início do Atendimento
                  </FormLabel>
                  <Input
                    {...register("start")}
                    as={InputMask}
                    mask="99/99/9999 99:99"
                    focusBorderColor={inputHover}
                    borderRadius="8px"
                    fontSize="md"
                  />
                  {errors.start && (
                    <FormErrorMessage>{errors.start.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.end}>
                  <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                    Data e Hora do Término do Atendimento
                  </FormLabel>
                  <Input
                    {...register("end")}
                    as={InputMask}
                    mask="99/99/9999 99:99"
                    focusBorderColor={inputHover}
                    borderRadius="8px"
                    fontSize="md"
                  />
                  {errors.end && (
                    <FormErrorMessage>{errors.end.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.professional_id}>
                  <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                    Profissional
                  </FormLabel>
                  <Select
                    {...register("professional_id")}
                    focusBorderColor={inputHover}
                    placeholder="Selecione uma opção..."
                    color="gray.500"
                    size="md"
                    borderRadius="12px"
                    cursor="pointer"
                  >
                    {professionals?.professionals?.map((pro) => (
                      <option value={pro.id}>
                        {!!pro?.name
                          ? pro.name
                          : !!pro?.user?.name
                          ? pro.user.name
                          : ""}
                      </option>
                    ))}
                  </Select>
                  {errors.professional_id && (
                    <FormErrorMessage>
                      {errors.professional_id.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.attendance_type}>
                  <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                    Tipo de atendimento
                  </FormLabel>
                  <Select
                    {...register("attendance_type")}
                    focusBorderColor={inputHover}
                    placeholder="Selecione uma opção..."
                    color="gray.500"
                    size="md"
                    borderRadius="12px"
                    cursor="pointer"
                  >
                    <option value={0}>Presencial</option>
                    <option value={1}>Tele-atendimento</option>
                  </Select>
                  {errors.attendance_type && (
                    <FormErrorMessage>
                      {errors.attendance_type.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Grid>
            </Flex>
            <Flex mt="24px" align={"center"} justify={"flex-end"}>
              <Button
                type="submit"
                isLoading={loadingCreate}
                isDisabled={loadingCreate}
                variant="no-hover"
                bg={buttonBg}
                alignSelf="flex-end"
                w="125px"
                h="35px"
              >
                <Text fontSize="xs" color="#fff" fontWeight="bold">
                  Criar Atendimento
                </Text>
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="20px"
        width="100%"
      >
        {loading ? (
          <Box width="100%">
            <Flex width="100%" fontSize="15px">
              <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="6px"
                fontSize="20px"
                width="100%"
              >
                <Skeleton isLoaded={!loading} height="40px" />
                <Skeleton isLoaded={!loading} height="40px" />
                <Skeleton isLoaded={!loading} height="40px" />
                <Skeleton isLoaded={!loading} height="40px" />
              </Grid>
            </Flex>
          </Box>
        ) : !unscheduledEvents || unscheduledEvents.length === 0 ? (
          <Text fontSize="lg" fontWeight="bold">
            Não há registro de atendimentos não previstos para o beneficiário
          </Text>
        ) : (
          unscheduledEvents.map((unscheduledEvent, x) => (
            <Box width="100%">
              <Flex width="100%" fontSize="15px">
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                  templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                  gap="6px"
                  fontSize="20px"
                >
                  <Text fontSize="18px">
                    Tipo do Atendimento: {unscheduledEvent.type_desc}
                  </Text>
                  <Text fontSize="18px">
                    Motivo do Atendimento: {unscheduledEvent.motive_desc}
                  </Text>
                  <Text fontSize="18px">
                    Tipo do Atendimento:{" "}
                    {unscheduledEvent.attendance_type === 0
                      ? "Presencial"
                      : "Tele-atendimento"}
                  </Text>
                  <Text fontSize="18px">
                    Profissonal:{" "}
                    {!!unscheduledEvent?.professional?.user?.name &&
                      unscheduledEvent.professional.user.name}
                  </Text>
                  <Text fontSize="18px">
                    Data e Hora do Início do Atendimento:{" "}
                    {fromAnyFormatToAny(
                      unscheduledEvent.start,
                      "DD/MM/YYYY HH:mm"
                    )}
                  </Text>
                  <Text fontSize="18px">
                    Data e Hora do Término do Atendimento:{" "}
                    {fromAnyFormatToAny(
                      unscheduledEvent.end,
                      "DD/MM/YYYY HH:mm"
                    )}
                  </Text>
                </Grid>
              </Flex>
              {x + 1 < unscheduledEvents.length && (
                <Divider
                  orientation="horizontal"
                  mt="20px"
                  height="2px"
                  backgroundColor="gray.300"
                />
              )}
            </Box>
          ))
        )}
      </Grid>
    </Flex>
  );
};

export default BeneficiaryUnscheduledEvent;
