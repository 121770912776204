import { useEffect, useState } from 'react';
import { useCustomHours, usePaginateCustomHours } from 'hooks/customHours';
import dateFormat from 'functions/dateFormat';
import AsyncTable from '../AsyncTable';
import { useRegionals } from "../../../hooks/globalRegionals";
import ProfessionalScheduleAudit from './ProfessionalScheduleAudit';
import { useAuth } from 'hooks/useAuth';
import { roleAdmin, roleSuper } from "../../../variables/roles";
import queryParamsPage from 'functions/queryParamsPage';
import { useLocation } from 'react-router-dom';

const columns = [
  {
    Header: "Regional",
    accessor: "regional.name",
  },
  {
    Header: "Profissional",
    accessor: "professional.user.name",
  },
  {
    Header: "Tipo",
    accessor: "type",
    Cell: props => <div>{props.value == 'included' ? 'Liberado' : 'Bloqueado'}</div>
  },
  {
    Header: "Data Inicial",
    accessor: "start",
    Cell: props => <div>{dateFormat(props.value)}</div>
  },
  {
    Header: "Data Final",
    accessor: "end",
    Cell: props => <div>{dateFormat(props.value)}</div>
  },
]

const CustomHourTable = ({ filter }) => {
  const location = useLocation()
  const { page: queryPage, size: querySize } = queryParamsPage(location)
  const { user } = useAuth();
  const { regional: regionalId } = useRegionals();

  const { loading, rows, total, perPage, currentPage, setToDelete, setDeleteModalOpen } = useCustomHours()
  const [paginateCustomHours, { loading: reloading }] = usePaginateCustomHours()

  const [auditId, setAuditId] = useState(null)
  const [open, setOpen] = useState(false);

  const deleteCustomHour = (id) => {
    setToDelete(id),
      setDeleteModalOpen(true)
  }

  const fetchData = ({ pageSize, pageIndex }) => {
    if (pageSize != perPage)
      pageIndex = 1

    const roleFilter = (filter.role && filter.role !== null) ? {
      professional: {
        "column": "ROLE",
        "operator": "EQ",
        "value": filter.role,
      }
    } : null

    const professionalFilter = (filter.professional && filter.professional !== null) ? {
      "column": "PROFESSIONAL_ID",
      "operator": "EQ",
      "value": filter.professional
    } : null

    const variables = {
      where: {
        "column": !!regionalId ? "REGIONAL_ID" : "ID",
        "operator": !!regionalId ? "EQ" : "NEQ",
        "value": !!regionalId ? regionalId : null,
        AND: [
          { ...professionalFilter }
        ],
      },
      ...roleFilter,
      first: pageSize || querySize || 20,
      page: pageIndex || queryPage || 1,
    }

    paginateCustomHours({
      variables: variables
    })
  }

  useEffect(() => {
    if (!!regionalId) fetchData(1, 20);
  }, [filter, regionalId]);

  const handleAudit = (id) => {
    setAuditId(id)
    setOpen(true)
  }

  const customActions = [
    {
      label: 'Registro de Atividades',
      icon: 'documents',
      visible: () => {
        return user?.role === roleSuper;
      },
      callback: (id) => {
        handleAudit(id)
      }
    }
  ]

  return (
    <>
      <AsyncTable
        isLoaded={!loading && !reloading && !!regionalId}
        fetchData={fetchData}
        data={!loading && !reloading && !!regionalId ? rows : [{}, {}, {}, {}]}
        customActions={!loading ? customActions : null}
        columns={columns}
        size={perPage}
        total={total}
        asyncPage={currentPage}
        baseRoute="agenda-ausencia"
        removeAction={deleteCustomHour}
        noView
        linkRow
      />
      {
        open &&
        <ProfessionalScheduleAudit
          id={auditId}
          open={open}
          close={() => setOpen(false)}
        />
      }
    </>
  );
}

export default CustomHourTable
