import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_USERS, GET_USER, CREATE_USER, UPDATE_USER } from 'graphql/users'
import { ACTION_TYPES, reducers } from './reducers'
import { GET_USERS_PAGINATE } from 'graphql/users';

const UsersStateContext = createContext()
const UsersDispatchContext = createContext()

const UsersProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, { rows: [], first: 15, page: 1 })

	const { data, loading, error } = useQuery(GET_USERS_PAGINATE, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.usersPaginate })
		}
	})

	return (
		<UsersStateContext.Provider value={{ data, loading, error, ...state }}>
			<UsersDispatchContext.Provider value={dispatch}>{children}</UsersDispatchContext.Provider>
		</UsersStateContext.Provider>
	)
}

const useUsers = () => {
	const context = useContext(UsersStateContext)

	if (!context)
		throw new Error('useUsers must be used within an UsersProvider')

	return context
}

const useDispatch = () => {
	const dispatch = useContext(UsersDispatchContext)

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a UsersProvider')

	return dispatch
}

const usePaginateUsers = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_USERS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(null, '', `./gestao-vidas?page=${data.usersPaginate.paginatorInfo.currentPage}&size=${data.usersPaginate.paginatorInfo.perPage}`);
			dispatch({ type: ACTION_TYPES.LIST, data: data.usersPaginate })
		}
	})
}

const useCreateUser = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(CREATE_USER, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data)
			dispatch({ type: ACTION_TYPES.ADD, data: data.createUser })
		},
		onError: (error) => {
			if (onError) onError(error)
		},
	})
}

const useUser = (id) => {
	const dispatch = useContext(UsersDispatchContext);

	return useQuery(GET_USER, {
		fetchPolicy: "no-cache",
		variables: {
			id: id
		}
	},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data: data.user })
			},
		}
	)
}

const useUpdateUser = (options = {}) => {
	const dispatch = useContext(UsersDispatchContext);
	const { onSuccess, onError } = options

	return useMutation(UPDATE_USER, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data)
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateUser })
		},
		onError: (error) => {
			if (onError) onError(error)
		},
		refetchQueries: [
			{
				query: GET_USERS_PAGINATE
			}
		]
	})
}

// const useDeleteUser = (id, options = {}) => {
// 	const dispatch = useDispatch()
// 	const { onSuccess } = options

// 	return useMutation(() => DELETE_USER(id), {
// 			...options,
// 			onSuccess: () => {
// 				onSuccess()
// 				dispatch({ type: ACTION_TYPES.REMOVE, id })
// 			},
// 	})
// }

export {
	UsersProvider,
	useUsers,
	useCreateUser,
	usePaginateUsers,
	useUser,
	useUpdateUser,
	// useDeleteUser
}
