import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {GET_CID_CODES} from "graphql/cpt";
import AsyncSelect from "react-select/async";
import {useAssocCid} from "hooks/cpt";
import {useParams} from "react-router-dom";

export default ({
                    data: dataDefault,
                    onSuccess,
                    isOpen,
                    closeModal,
                    disease,
                    isLoading,
                    user,
                }) => {
    const {id} = useParams();
    const [loadCidDisseases, {data: diseases, loading}] = useLazyQuery(
        GET_CID_CODES
    );
    const [assocCid, {loading: loadingAssoc}] = useAssocCid({
        onCompleted: (data) => {
            onSuccess();
        },
        onError: () => {
            console.log("errrou");
        },
    });
    const [cid, setCid] = useState([]);
    const onSubmit = () => {
        const newAwaiting = dataDefault.filter((i) => i !== disease);
        assocCid({
            variables: {
                input: {
                    id,
                    awaiting_diseases: newAwaiting,
                    diseases: {
                        connect: cid.map((i) => i.id),
                    },
                    user_id: user.id
                },
            },
        });
    };

    const asyncValues = async (item) => {
        const {data} = await loadCidDisseases({
            variables: {
                search: `%${item.toLowerCase()}%`,
            },
        });
        return data?.getCidCodes;
    };
    useEffect(() => {
        loadCidDisseases({
            variables: {
                search: `%${disease}%`,
            },
        });
    }, [disease]);
    return (
        <Modal
            isCentered
            onClose={() => closeModal()}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            autoFocus={false}
        >
            <ModalOverlay/>
            <ModalContent minWidth={"500px"}>
                <ModalHeader>
                    Selecionar a descrição adequada para a doença #{disease}
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <AsyncSelect
                        defaultOptions={diseases?.getCidCodes || []}
                        getOptionLabel={(e) => `${e.code} - ${e.name}`}
                        loadOptions={asyncValues}
                        isLoading={loading}
                        placeholder={"Selecione o código correspondente"}
                        loadingMessage={() => "Buscando cid..."}
                        noOptionsMessage={() => "Digite para buscar"}
                        onChange={(event) => setCid(event)}
                        isMulti={true}
                        getOptionValue={(e) => e.id}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button mr={3} variant="ghost" onClick={() => closeModal()}>
                        Cancelar
                    </Button>
                    <Button
                        isLoading={isLoading || loadingAssoc}
                        colorScheme="red"
                        onClick={() => onSubmit()}
                    >
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
