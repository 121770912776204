import React, { useState } from "react";
import {
	Box,
	Flex,
	SimpleGrid,
	Stat,
	StatLabel,
	StatNumber,
	Text,
	Tooltip,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
// Custom icons
import { useParams } from "react-router-dom";
import Icon from "components/FontAwesomeIcons/Icon";
import { usePendingAwaitingSchedules } from "hooks/beneficiary";
import CommitmentsManagementProvider from "providers/commitmentsManagementProvider";
import PaRefusalModal from "views/CommitmentsManagement/components/PaRefusalModal";
import { Clock } from "@phosphor-icons/react";

const AwaitingAssessment = ({
	awaitingSchedules = [],
	handleModal,
}) => {
	const { id } = useParams();
	const [
		getBeneficiaryAwaitingSchedules,
		{ data: { beneficiary } = {}, loading },
	] = usePendingAwaitingSchedules(id);
	const [refusalModal, setRefusalModal] = useState(false);

	const iconBoxInside = useColorModeValue("white", "white");
	const textColor = useColorModeValue("gray.700", "white");

	React.useEffect(() => {
		getBeneficiaryAwaitingSchedules();
	}, [refusalModal]);

	const handleClick = (assessmentId) => {
		handleModal({
			id: id,
			name: beneficiary?.name,
			type: "schedule",
			events: awaitingSchedules,
			checked: assessmentId,
			exclude: assessmentId,
		});
	};

	return (
		<>
			<CommitmentsManagementProvider>
				<PaRefusalModal
					beneficiaryId={id}
					open={refusalModal}
					setRefusalModal={setRefusalModal}
				/>
			</CommitmentsManagementProvider>
			<SimpleGrid spacing="24px">
				<Flex direction={"column"}>
					<Text>{beneficiary?.beneficiary?.name}</Text>
					{awaitingSchedules?.map((e, index) => (
						<Card minH="83px" w={"100%"} marginBottom={"10px"}>
							<CardBody>
								<Flex
									flexDirection="row"
									align="center"
									justify="center"
									w="100%"
								>
									<Stat me="auto">
										<StatLabel
											fontSize="sm"
											color="gray.400"
											fontWeight="bold"
											pb=".1rem"
										>
											{(e.awaiting_schedule === "Primeiro Acolhimento" &&
												beneficiary?.strike_fr_scheduled >= 3)
												? "Recusado"
												: "Aguardando agendamento"}
										</StatLabel>
										<Flex>
											<StatNumber fontSize="lg" color={textColor}>
												{e.awaiting_schedule}
											</StatNumber>
										</Flex>
									</Stat>
									<Tooltip
										label="Agendar"
										placement="top"
										closeOnClick={true}
									>
										<Box onClick={() => handleClick(e.id)}>
											<IconBox
												as="box"
												h={"45px"}
												w={"45px"}
												background="green.600"
												_hover={{ background: "green.500" }}
												cursor="pointer"
											>
												<Clock
													size="24"
													color={iconBoxInside}
												/>
											</IconBox>
										</Box>
									</Tooltip>
									{e.awaiting_schedule === "Primeiro Acolhimento" &&
										beneficiary?.strike_fr_scheduled < 3 && (
											<Tooltip
												label="Recusar PA"
												placement="top"
												closeOnClick={true}
											>
												<Box ml={2} onClick={() => setRefusalModal(true)}>
													<IconBox
														as="box"
														h={"45px"}
														w={"45px"}
														background="red.600"
														_hover={{ background: "red.500" }}
														cursor="pointer"
													>
														<Icon
															icon="cancel"
															h={"24px"}
															w={"24px"}
															color={iconBoxInside}
														/>
													</IconBox>
												</Box>
											</Tooltip>
										)}
								</Flex>
							</CardBody>
						</Card>
					))}
				</Flex>
			</SimpleGrid>
		</>
	);
};

export default AwaitingAssessment;
