import { useEffect, useState } from "react";

import { Box } from "@chakra-ui/react";

import AsyncTable from "components/Tables/AsyncTable";
import Icon from "components/FontAwesomeIcons/Icon";
import {
	useAwaitingCommitments,
	useCommitments,
} from "hooks/commitmentsManagement";
import { assessmentsTypes } from "variables/assesmentsTypes";
import { Link } from "react-router-dom";
import PaRefusalModal from "./PaRefusalModal";
import { differenceInYears } from "date-fns";

import { findRiskPA } from "functions/getStratification";
import { findRiskDS } from "functions/getStratification";

const hasNote = (notes, event = "Declaração de Saúde") => {
	if (!notes || notes.length === 0) return false;
	let cNotes = [...notes];
	const eNotes = cNotes.filter(
		(nt) => nt.title === `Aguardando agendamento - ${event}`
	);
	return eNotes[eNotes.length - 1];
};

const AwaitingScheduleTable = ({
	plan = null,
	month = null,
	professional = null,
	assessment = null,
	year = null,
	riskDS = null,
	riskPA = null,
	age = null,
	handleNoteModal,
	handleRegisterNoteModal,
	handleEventModal,
}) => {
	const handleModalOpen = (props) => {
		handleNoteModal(
			props.row.original.name,
			hasNote(props.value, props?.row?.original.assessment_type?.builder_tag)
				.text
		);
	};

	const columns = [
		{
			Header: "Atividade Pendente",
			accessor: "awaiting_schedule",
			Cell: (props) => (
				<div>{props?.value ? props.value : "Declaração de Saúde"}</div>
			),
		},
		{
			Header: "Aguardando Quantos Dias",
			accessor: "days_awaiting",
		},
		{
			Header: "Beneficiario",
			accessor: "name",
			Cell: (props) => (
				<Link to={`../administracao/gestao-vidas/${props?.row?.original?.id}`}>
					{props.value}
				</Link>
			),
		},
		{
			Header: "Celular",
			accessor: "phone",
		},
		{
			Header: "Plano",
			accessor: "plan[0].name",
		},
		{
			Header: "Responsável",
			accessor: "manager.user.name",
		},
		{
			Header: "Tipo de Responsável",
			accessor: "manager.user.role",
			Cell: (props) => (
				<div>
					{props?.value
						? props.value.replace("_", " ").replace("_", " ")
						: props?.row?.original?.manager_type
							?.replace("_", " ")
							.replace("_", " ")}
				</div>
			),
		},
		{
			Header: "Idade",
			accessor: "birthdate",
			Cell: (props) => (
				<div>
					{props?.value
						? differenceInYears(new Date(), new Date(props.value))
						: null}
				</div>
			),
		},
		{
			Header: "Estratificação DS",
			accessor: "stratification_ds",
			Cell: (props) => (
				<div>{findRiskDS(props?.row?.original?.assessments)}</div>
			),
		},
		{
			Header: "Estratificação PA",
			accessor: "stratification_pa",
			Cell: (props) => (
				<div>{findRiskPA(props?.row?.original?.assessments)}</div>
			),
		},
		{
			Header: "Notas",
			accessor: "notes",
			Cell: (props) =>
				hasNote(
					props.value,
					props?.row?.original.assessment_type?.builder_tag
				) ? (
					<Box
						cursor={"pointer"}
						onClick={() => handleModalOpen(props)}
						_hover={{ color: "notrelife.800" }}
					>
						<Icon icon="info" />
					</Box>
				) : (
					<div></div>
				),
		},
	];

	const { commitments, total, perPage, currentPage } = useCommitments();
	const [loadComittments, { loading }] = useAwaitingCommitments();

	const fetchData = ({ pageSize, pageIndex, search }) => {
		const variables = {
			first: pageSize,
			page: pageIndex,
			input: {
				plan: plan,
				month: month,
				year: year,
				professional: professional,
				assessmentType: assessment,
				name: search || "",
				riskDS: riskDS,
				riskPA: riskPA,
				age: age,
			},
		};
		loadComittments({ variables: variables });
	};

	useEffect(() => {
		const variables = {
			first: 30,
			page: 1,
			input: {
				plan: plan,
				month: month,
				year: year,
				professional: professional,
				assessmentType: assessment,
				riskDS: riskDS,
				riskPA: riskPA,
				age: age,
			},
		};
		loadComittments({ variables: variables });
	}, [plan, month, year, professional, assessment, riskDS, riskPA, age]);

	const customActions = [
		{
			label: "Adicionar nota",
			icon: "note",
			callback: (id) =>
				handleRegisterNoteModal(
					id,
					commitments.find((row) => row.id == id).name,
					`Aguardando agendamento - ${assessmentsTypes[
					commitments.find((row) => row.id == id).awaiting_schedule
					] || "Declaração de Saúde"
					}`,
					commitments.find((row) => row.id == id).notes
				),
		},
		{
			label: "Agendar",
			icon: "calendar",
			callback: (id) => {
				const values = {
					id: id,
					name: commitments.find((row) => row.id == id).name,
					type: "schedule",
					events: commitments.find((row) => row.id == id)
						.pending_awaiting_schedules,
				};
				handleEventModal(values);
			},
		},
		{
			label: "Recusa de PA",
			icon: "cancel",
			visible: (row) => row.awaiting_schedule === "Primeiro Acolhimento",
			callback: (id, row) => {
				setRefusalModal(true);
				setRow(row);
			},
		},
	];

	const [refusalModal, setRefusalModal] = useState(false);
	const [currentRow, setRow] = useState(null);

	return (
		<>
			<PaRefusalModal
				beneficiaryId={currentRow?.id}
				open={refusalModal}
				setRefusalModal={setRefusalModal}
			/>
			<AsyncTable
				searchByName
				noView
				noEdit
				customActions={!loading ? customActions : null}
				isLoaded={!loading}
				fetchData={fetchData}
				data={!loading ? commitments : [{}, {}, {}, {}]}
				columns={columns}
				size={perPage}
				total={total || 0}
				asyncPage={currentPage}
				baseRoute=""
			/>
		</>
	);
};

export default AwaitingScheduleTable;
