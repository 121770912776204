import React from "react";

import {Box, Grid, Text, useColorModeValue} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "./components/FiltersBox";
import {useCptDashboard} from "../../hooks/dashboards/useDashboards";
import ChartContainer from "components/Charts/ChartContainer";
import {useFilter} from "../../hooks/useFilter";
import DiseasesChart from "components/Charts/Nivo/Cpt/DiseasesChart";
import SentAndAssigned from "components/Charts/Nivo/Cpt/SentAndAssigned";
import LifesAndCPT from "components/Charts/Nivo/Cpt/LifesAndCPT";
import MedicsCPT from "components/Charts/Nivo/Cpt/MedicsCPT";
import {roleMedic} from "../../variables/roles";
import EstablishedCPT from "../../components/Charts/Nivo/Cpt/EstablishedCPT";
import ProgressCPT from "../../components/Charts/Nivo/Cpt/ProgressCPT";

const CPT = () => {
    const {filters} = useFilter();
    const {data, loading} = useCptDashboard(filters);
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <>
            <Grid
                templateColumns={{sm: "1fr", md: "1fr"}}
                templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                pt={{sm: "125px", lg: "160px"}}
            >
                <Card px="0px" pb="0px" minHeight="100px">
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Filtros
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <FiltersBox planNameFilter startDateFilter endDateFilter professionalIdFilter assessmentFilter roleFilter={roleMedic}/>
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            {loading && !data?.dashboard_cpt ? (
                "Carregando..."
            ) : (
                <>
                    <Grid
                        templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
                        templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                        gap="12px"
                        pt={{sm: "12px", lg: "12px"}}
                    >
                        <ChartContainer title="Principais Doenças (CID) assinaladas em Declaração de Saúde"
                                        tooltip="Valores não são afetados pelo filtro de tipo.">
                            <DiseasesChart data={data?.dashboard_cpt?.DiseasesChart?.ds || []} labels={['ds']}
                                           label={'label'} keys={'total'}/>
                        </ChartContainer>
                        <ChartContainer title="Principais Doenças (CID) assinaladas em Primeiro Acolhimento"
                                        tooltip="Valores não são afetados pelo filtro de tipo.">
                            <DiseasesChart data={data?.dashboard_cpt?.DiseasesChart?.pa || []} labels={['pa']}
                                           label={'label'} keys={'total'}/>
                        </ChartContainer>
                    </Grid>
                    <Grid
                        templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
                        templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                        gap="12px"
                        pt={{sm: "12px", lg: "12px"}}
                    >
                        <ChartContainer title="Médicos responsáveis">
                            <MedicsCPT data={data?.dashboard_cpt?.DoctorsChart} label={'label'} keys={'total'}/>
                        </ChartContainer>
                    </Grid>
                    <Grid
                        templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}}
                        templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                        gap="12px"
                        pt={{sm: "12px", lg: "12px"}}
                    >
                        <ChartContainer title="Entradas x CPTs para Análise de Declaração de Saúde">
                            <LifesAndCPT data={data?.dashboard_cpt?.LivesChart || []} label={'label'} keys={'total'}/>
                        </ChartContainer>
                        <ChartContainer title="Estabelecidas em">
                            <EstablishedCPT data={data?.dashboard_cpt?.EstablishedChart?.establishedQuery} label={'name'} keys={'total'}/>
                        </ChartContainer>
                    </Grid>
                    <Grid
                        templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
                        templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                        gap="12px"
                        pt={{sm: "12px", lg: "12px"}}
                    >
                        <ChartContainer title="Andamento">
                            <ProgressCPT data={data?.dashboard_cpt?.ProgressChart} label={'status'} keys={'total'}/>
                        </ChartContainer>
                    </Grid>
                </>
            )}
        </>
    );
};

export default CPT;
