import { gql } from "@apollo/client";

export const GET_WHATSAPP_DISCLOSURES = gql`
  query getWhatsappDisclosures {
    whatsappDisclosures {
      id
      title
      about
      url
      invitation_type
      ambience
      capacity
      cep
      address
      city_id
      state_id
      district
      complement
      city_id
      state_id
      scheduled_to
      created_at
    }
  }
`;

export const GET_WHATSAPP_DISCLOSURES_PAGINATE = gql`
  query getWhatsappDisclosures(
    $first: Int! = 15
    $page: Int! = 1
    $search: String
    $invitation_type: String
  ) {
    whatsappDisclosuresPaginate(first: $first, page: $page, search: $search, invitation_type: $invitation_type) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        title
        about
        url
        invitation_type
        ambience
        capacity
        cep
        address
        city_id
        state_id
        district
        complement
        city_id
        state_id
        scheduled_to
      created_at
      }
    }
  }
`;

export const CREATE_WHATSAPP_DISCLOSURE = gql`
  mutation createWhatsappDisclosure($input: CreateWhatsappDisclosureInput) {
    createWhatsappDisclosure(input: $input) {
      id
    }
  }
`;

export const UPDATE_WHATSAPP_DISCLOSURE = gql`
  mutation updateWhatsappDisclosure($input: UpdateWhatsappDisclosureInput) {
    updateWhatsappDisclosure(input: $input) {
      id
    }
  }
`;

export const GET_WHATSAPP_DISCLOSURE = gql`
  query getWhatsappDisclosure($id: ID!) {
    whatsappDisclosure(id: $id) {
      id
      title
      about
      url
      invitation_type
      ambience
      capacity
      cep
      address
      city_id
      state_id
      district
      complement
      city_id
      state_id
      scheduled_to
      created_at
      imports {
        path
        name
        disk
        status
      }
      documents {
        name
        label
        url
      }
    }
  }
`;
