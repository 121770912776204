import { Text } from '@chakra-ui/react';
import { Route } from 'react-router-dom'
import { JWT_TOKEN_COGNITO_ID } from "../../variables/enumLabels";

const PrivateRoute = ({ component: Component, roles = [], ...props }) => {
  // if (!window.localStorage.getItem(JWT_TOKEN_COGNITO_ID)) return <Text>Nada</Text>;
  return (
    <Route
      {...props}
      render={({ location }) =>
      (
        <Component />
      )
      }
    />
  )
}

export default PrivateRoute
