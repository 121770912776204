import { useToken } from 'hooks/token';

const getToken = () => {
    const token = useToken();

    if (!token.bearer)
        return null;

    return token.bearer;
};

export {
    getToken
}