
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import Select from 'react-select'

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	FormErrorMessage,
	HStack,
	Icon,
	Input,
	Link,
	Switch,
	Text,
	useColorModeValue,
	Grid,
	Skeleton,
	Spinner,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
import greenBg from "assets/img/backgroundcard.png";
import React, { forwardRef, useEffect, useState } from "react";
import { msLightTheme } from 'variables/multiselectStyle';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { differenceInYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import ptBR from 'date-fns/esm/locale/pt-BR';
import dateFormat from 'functions/dateFormat';
import { GET_EVENT } from 'services/customers/customerSchedule';
import { addDays, getWeek } from 'date-fns';
import { GET_SPLIT_TIME } from 'services/customers/customerSchedule';
import { POST_EVENT } from 'services/customers/customerSchedule';
import { GET_AVAILABLE_DATES } from 'services/customers/customerSchedule';
registerLocale('ptBR', ptBR)

const scheduleTypes = [
	{
		label: 'Presencial',
		value: 'presencial'
	},
	{
		label: 'Tele-atendimento',
		value: 'tele'
	},
]

const schema = yup.object().shape({
	regional_id: yup
		.string()
		.nullable()
		.required("Selecione uma regional para o agendamento"),
	type: yup
		.string()
		.nullable()
		.required("Selecione o tipo de agendamento"),
	date: yup
		.string()
		.nullable()
		.required("Selecione o dia do agendamento"),
	hour: yup
		.string()
		.nullable()
		.when('type', {
			is: (type) => type == 'presencial',
			then: yup.string()
				.nullable()
				.required("Selecione um horário para o agendamento"),
		}),
	turn: yup
		.string()
		.nullable()
		.when('type', {
			is: (type) => type == 'tele',
			then: yup.string()
				.nullable()
				.required("Selecione um horário para o agendamento"),
		}),
})

const DateInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
	<Input
		value={dateFormat(value)}
		disabled={disabled}
		focusBorderColor={"notrelife.800"}
		borderRadius="8px"
		fontSize="md"
		onClick={onClick}
		onChange={onChange}
		ref={ref}
	/>
));

const filterDate = (date) => {
	const currentDate = new Date();
	const selectedDate = new Date(date);

	return currentDate < selectedDate;
};

const CustomerEvent = () => {
	const { key } = useParams()

	const currentDate = new Date()
	const [event, setEvent] = React.useState({});
	const [eligibility, setEligibility] = React.useState(null);
	const [birthdate, setBirthdate] = React.useState(null);
	const [available, setAvailable] = React.useState('both');
	const [regionalsFailed, setRegionalsFailed] = React.useState(false)
	const [professional, setProfessional] = React.useState(null)
	const [managerType, setManagerType] = React.useState(null)
	const [availableDates, setAvailableDate] = React.useState([]);
	const [loadingRegionals, setLoadingRegionals] = React.useState(false);
	const [loadingDates, setLoadingDates] = React.useState(false);
	const [loadingSplit, setLoadingSplit] = React.useState(false);
	const [loadingSubmit, setLoadingSubmit] = React.useState(false);
	const [finished, setFinished] = React.useState(false)

	React.useEffect(() => {
		if (eligibility === true) {
			const age = birthdate ? differenceInYears(new Date(), new Date(birthdate)) : null
			if (age >= 18 || age === null) {
				setAvailable('both')
			} else {
				setAvailable('tele')
			}
		} else {
			setAvailable('tele')
		}
	}, [eligibility, birthdate])

	const loadRegionals = async () => {
		setLoadingRegionals(true)
		try {
			const data = await GET_EVENT(key);
			setEvent(data?.event);
			setEligibility(data?.patient?.eligibility || false)
			setBirthdate(data?.patient?.birthdate || null)
			setManagerType(data.patient.manager_type);
			setProfessional(data.patient.professional_id);
			const regionals = data.patient.plan[0].regionals.filter(reg => !reg.is_admin).map(reg => { return { value: reg.id, label: `${reg.name} - ${reg.city_id} ${reg.address} ${reg.address_number}` } });
			setRegionalsList(regionals);
		} catch (e) {
			setRegionalsFailed(true);
		} finally {
			setLoadingRegionals(false)
		}
	}

	const loadDates = async () => {
		setLoadingDates(true)
		try {
			const data = await GET_AVAILABLE_DATES(key, regional, managerType, professional, type);
			const dates = [];
			data.map(dt => {
				dates.push({
					label: dateFormat(new Date(dt), "iiii',' d 'de' MMMM 'de' y"),
					value: new Date(dt),
					week: getWeek(new Date(dt))
				})
			})
			setAvailableDate(dates)
		} catch (e) {

		} finally {
			setLoadingDates(false)
		}
	}

	const loadSplitTime = async (week, date) => {
		setLoadingSplit(true)
		const payload = {
			input: {
				regional: regional,
				week: week,
				role_manager: managerType,
				type: type
			}
		}

		const data = await GET_SPLIT_TIME(key, payload);
		if (type == 'presencial') {
			const hours = data.filter(h => {
				return dateFormat(h.start, 'y-MM-dd') == dateFormat(date, 'y-MM-dd')
			});
			const hoursList = hours.sort((a, b) => { return a.title > b.title ? 1 : -1 }).map(h => {
				return { value: h.title, label: h.title }
			})

			setTimeList(hoursList);
		} else {
			const turns = data.filter(h => {
				return dateFormat(h.start, 'y-MM-dd') == dateFormat(date, 'y-MM-dd')
			});
			const turnList = turns.sort((a, b) => { return a.title > b.title ? 1 : -1 }).map(h => {
				return { value: h?.title == 'Manhã' ? 'manha' : 'tarde', label: h?.title }
			})
			setTurnList(turnList);

		}
		setLoadingSplit(false)
	}

	const methods = useForm({
		mode: "onChange",
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
	});

	const [regionalsList, setRegionalsList] = useState([]);
	const [timeList, setTimeList] = useState([]);
	const [turnList, setTurnList] = useState([]);
	const [regional, setRegional] = useState(null)
	const [date, setDate] = useState(null)
	const [type, setType] = useState(null)
	const [hour, setHour] = useState(null)
	const [turn, setTurn] = useState(null)

	const { register, handleSubmit, formState: { errors, isInvalid }, setError, getValues, setValue, } = methods;

	useEffect(() => {
		if (regional) {
			setValue('regional_id', regional)
			setError('regional_id', null)
		} else setValue('regional_id', null)
	}, [regional])

	useEffect(() => {
		if (type) {
			setValue('type', type)
			setError('type', null)
			setError('turn', null)
			loadDates();
		} else setValue('type', null)
	}, [type])

	useEffect(() => {
		if (date) {
			setValue('date', date)
			setError('date', null)
		} else setValue('date', null)
	}, [date])

	useEffect(() => {
		if (hour) {
			setValue('hour', hour)
			setError('hour', null)
		} else setValue('hour', null)
	}, [hour])

	useEffect(() => {
		if (turn) {
			setValue('turn', turn)
			setValue('hour', turn == 'manha' ? '08:00' : '13:00')
			setError('turn', null)
		} else setValue('turn', null)
	}, [turn])

	const bgColor = useColorModeValue("white", "gray.700");

	const onSubmit = async () => {
		setLoadingSubmit(true)
		const payload = {
			regional_id: regional,
			type: type,
			turn: type == 'tele' ? turn : null,
			scheduled_time: `${dateFormat(date, 'Y-MM-dd')} ${getValues('hour')}:00`,
			role_manager: managerType
		}

		try {
			const data = await POST_EVENT(key, payload);
			setFinished(true);

		} catch (e) {

		} finally {
			setLoadingSubmit(false)
		}
	}

	useEffect(() => {
		loadRegionals()
	}, [])

	useEffect(() => {
		if (date) {
			const week = getWeek(date)
			loadSplitTime(week, date)
		}
	}, [date])

	console.log(event);

	return (
		<Flex
			direction="column"
			alignSelf="center"
			justifySelf="center"
			overflow="hidden"
		>
			<Box
				position="absolute"
				minH={{ base: "70vh", md: "50vh" }}
				w={{ md: "calc(100vw - 50px)" }}
				left="0"
				right="0"
				bgRepeat="no-repeat"
				overflow="hidden"
				zIndex="-1"
				top="0"
				bgImage={greenBg}
				bgSize="cover"
				mx={{ md: "auto" }}
				mt={{ md: "5px" }}
			></Box>
			<Flex
				direction="column"
				textAlign="center"
				justifyContent="center"
				align="center"
				mt="3rem"
				mb="30px"
			>
				<Text fontSize="4xl" color="white" fontWeight="bold">
					Notrelife
				</Text>
			</Flex>
			<Flex alignItems="center" justifyContent="center" mb="200px" mt="20px"
				mr={{ sm: "20px" }}
				ml={{ sm: "20px" }}>
				<FormProvider {...methods}>
					<form onSubmit={(handleSubmit(onSubmit))}>
						<Flex
							direction="column"
							w={{ md: "565px", sm: "100%" }}
							minH="450px"
							background="transparent"
							borderRadius="15px"
							p="40px"
							bg={bgColor}
							boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
						>
							{
								regionalsFailed ?
									<Flex
										width="100%"
										height="100%"
										justifyContent="center"
										alignItems="center"
										direction="column"
									>
										<Text
											fontSize="lg"
											color="#000"
											mb="40px">O link que você está utilizando é inválido ou já expirou.</Text>
										<Text
											fontSize="lg"
											color="#000">Solicite um novo link para realizar seu agendamento.</Text>
									</Flex>
									:
									finished ?
										<Flex
											width="100%"
											height="100%"
											justifyContent="center"
											alignItems="center"
											direction="column"
											mt="auto"
											mb="auto"
										>
											<Alert
												status='success'
												variant='subtle'
												flexDirection='column'
												alignItems='center'
												justifyContent='center'
												textAlign='center'
												height='200px'
											>
												<AlertIcon boxSize='40px' mr={0} />
												<AlertTitle mt={4} mb={1} fontSize='lg'>
													Seu agendamento foi realizado com sucesso.
												</AlertTitle>
											</Alert>
										</Flex>
										:
										event?.attended_at ? 
											<Flex
												width="100%"
												height="100%"
												justifyContent="center"
												alignItems="center"
												direction="column"
												mt="auto"
												mb="auto"
											>
												<Alert
													status='info'
													variant='subtle'
													flexDirection='column'
													alignItems='center'
													justifyContent='center'
													textAlign='center'
													height='200px'
												>
													<AlertIcon boxSize='40px' mr={0} />
													<AlertTitle mt={4} mb={1} fontSize='lg'>
														O evento já foi finalizado.
													</AlertTitle>
												</Alert>
											</Flex>
										:
										event?.scheduled_to ?
											<Flex
											width="100%"
											height="100%"
											justifyContent="center"
											alignItems="center"
											direction="column"
											mt="auto"
											mb="auto"
										>
											<Alert
												status='info'
												variant='subtle'
												flexDirection='column'
												alignItems='center'
												justifyContent='center'
												textAlign='center'
												height='200px'
											>
												<AlertIcon boxSize='40px' mr={0} />
												<AlertTitle mt={4} mb={1} fontSize='lg'>
													Seu compromisso já foi agendado para o dia {dateFormat(event.scheduled_to, 'dd/MM/yyyy')}
													{' '} às {dateFormat(event.scheduled_to, 'hh:mm')}
												</AlertTitle>
											</Alert>
										</Flex>
										:
										loadingRegionals ?
											<Flex
												width="100%"
												height="100%"
												justifyContent="center"
												alignItems="center"
												direction="column"
												minH="450px"
											>
												<Spinner
													size="lg"
													mt="auto"
													mb="auto"
												/>
											</Flex>
											:
											<>
												<FormControl isInvalid={errors.regional_id} mb="24px">
													<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
														Selecione a regional para o agendamento
													</FormLabel>
													<Skeleton height="40px" isLoaded={!loadingRegionals}>
														<Select
															isClearable
															isDisabled={loadingSubmit}
															backspaceRemovesValue={true}
															options={regionalsList}
															placeholder="Selecione uma opção..."
															styles={msLightTheme}
															onChange={(data) => setRegional(data?.value || null)}
														/>
													</Skeleton>
													{errors.regional_id && <FormErrorMessage>{errors.regional_id.message}</FormErrorMessage>}
												</FormControl>
												<Grid
													templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
													gap="24px"
												>
													<FormControl isInvalid={errors.type} mb="24px">
														<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
															Selecione o tipo de atendimento
														</FormLabel>
														<Skeleton height="40px" isLoaded={!loadingRegionals}>
															<Select
																isClearable
																backspaceRemovesValue={true}
																options={available == 'both' ? scheduleTypes : available == 'tele' ? scheduleTypes.filter(tp => tp.value == 'tele') : scheduleTypes}
																isDisabled={!regional || loadingSubmit}
																placeholder={!regional ? "Selecione uma regional" : "Selecione uma opção..."}
																noOptionsMessage={({ inputValue }) => "Sem horários para o dia selecionado"}
																styles={msLightTheme}
																onChange={(data) => { setType(data?.value || null) }}
															/>
														</Skeleton>
														{errors.type && <FormErrorMessage>{errors.type.message}</FormErrorMessage>}
													</FormControl>
													<FormControl isInvalid={errors.date}>
														<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
															Selecione a data
														</FormLabel>
														<Skeleton height="40px" isLoaded={!loadingDates}>
															<Select
																isClearable
																options={availableDates}
																isDisabled={!regional || !type || loadingSubmit}
																placeholder={!type ? "Selecione um tipo de atendimento" : "Selecione uma opção..."}
																styles={msLightTheme}
																onChange={(date) => { setDate(date.value) }}
															/>
														</Skeleton>
														{/* <ReactDatePicker 
                      value={date}
                      selected={date}
                      disabled={!regional || loadingSubmit}
                      onChange={(date) => setDate(date)}
                      placeholderText="dd/mm/aaaa"
                      borderRadius="8px"
                      fontSize="md"
                      locale="ptBR"
                      width="100%"
                      timeIntervals={20}
                      filterDate={filterDate}
                      customInput={<DateInput />}
                    /> */}
														{errors.date && <FormErrorMessage>{errors.date.message}</FormErrorMessage>}
													</FormControl>
													{
														type == 'presencial' &&
														<FormControl isInvalid={errors.hour} mb="24px">
															<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
																Selecione o horário
															</FormLabel>
															<Skeleton height="40px" isLoaded={!loadingSplit}>
																<Select
																	isClearable
																	backspaceRemovesValue={true}
																	options={timeList}
																	isDisabled={!date || loadingSubmit}
																	placeholder={!date ? "Selecione uma data" : "Selecione uma opção..."}
																	noOptionsMessage={({ inputValue }) => "Sem horários para o dia selecionado"}
																	styles={msLightTheme}
																	onChange={(data) => { setHour(data?.value || null) }}
																/>
															</Skeleton>
															{errors.hour && <FormErrorMessage>{errors.hour.message}</FormErrorMessage>}
														</FormControl>
													}
													{
														type == 'tele' &&
														<FormControl isInvalid={errors.turn} mb="24px">
															<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
																Selecione o turno
															</FormLabel>
															<Skeleton height="40px" isLoaded={!loadingSplit}>
																<Select
																	isClearable
																	backspaceRemovesValue={true}
																	options={turnList}
																	isDisabled={!date || loadingSubmit}
																	placeholder={!date ? "Selecione uma data" : "Selecione uma opção..."}
																	noOptionsMessage={({ inputValue }) => "Sem turnos para o dia selecionado"}
																	styles={msLightTheme}
																	onChange={(data) => { setTurn(data?.value || null) }}
																/>
															</Skeleton>
															{errors.turn && <FormErrorMessage>{errors.turn.message}</FormErrorMessage>}
														</FormControl>
													}
												</Grid>
												<Button
													isLoading={loadingSubmit}
													disabled={isInvalid || loadingSubmit}
													type="submit"
													bg="notrelife.900"
													fontSize="sm"
													color="white"
													fontWeight="bold"
													w="100%"
													h="45"
													mt="auto"
													_hover={{
														bg: "notrelife.800",
													}}
													_active={{
														bg: "notrelife.800",
													}}
												>
													Agendar
												</Button>
											</>
							}
						</Flex>
					</form>
				</FormProvider>
			</Flex>
		</Flex>
	);
}

export default CustomerEvent;
