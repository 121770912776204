import { UsersProvider as Provider } from 'hooks/users/index'
import { ManagerQueryProvider } from 'services/manager'

const UsersProvider = ({ children }) => {
	return (
		<ManagerQueryProvider>
			<Provider>{children}</Provider>
		</ManagerQueryProvider>
	)
}

export default UsersProvider
