import { Box, Flex, SlideFade, Spinner, Stack, Table, TableContainer, Tbody, Td, Thead, Tr, VStack } from '@chakra-ui/react'
import { MinusCircle, PlusCircle } from '@phosphor-icons/react';

import dateFormat from 'functions/dateFormat';
import { weekDay } from 'functions/getWeekDay';
import { getWeekDay } from 'functions/getWeekDay';
import React, { useState } from 'react'

const translateValues = {
  // FREE HOUR
  'type': 'Tipo',
  'week_day': 'Dia Semana',
  'start_time': 'Horário Inicio',
  'end_time': 'Horário Término',
  'turn': 'Turno',
  'events_limit': 'Limite',
  // CUSTOM FREE HOUR
  'event_type': 'Type',
  'start': 'Início',
  'end': 'Término',
  // CPT
  'status': 'Status',
  'reason_disable': 'Motivo',
}

const status = {
  'awaiting_cid': 'Aguardando análise',
  'awaiting_documents': 'Aguardando envio',
  'awaiting_assign_at': 'Aguardando assinatura',
  'not_signed': 'Não assinada',
  'disabled': 'Não elegível',
  'signed': 'Assinada/Aplicada',

}

const AuditTable = ({data}) => {
  const [expand, setExpand] = useState(0);

  const handleExpand = (x) => {
    setExpand(x == expand ? 0 : x)
  }

  return (
    <Flex w="100%">
      <TableContainer width="100%">
        <Table variant='striped' colorScheme='gray'>
          <Tbody>
            {
              data.filter(el => (el.event == 'created' || el.updated.length > 0)).map((el, x) => 
                <Tr display="flex" py="8px">
                  <Flex w="100%" direction="column">
                    <Flex>
                      { `${dateFormat(el.date, 'dd/MM/yyy HH:mm:ss')} - ${el.user} - ${el.event == 'updated' ? 'Atualização' : 'Criação'}` } 
                      {
                        el.updated && el.updated.length > 0 &&
                        <Box 
                          display="flex" 
                          justifyContent="center" 
                          alignItems="center"
                          cursor="pointer" 
                          direction="row"
                          ml="6px"
                          onClick={() => {handleExpand(x + 1)}}
                          >
                            {
                              expand == x + 1 ? <MinusCircle size={22} /> : <PlusCircle size={22} />
                            }
                        </Box>
                      }
                    </Flex>
                    {
                      expand == x + 1 &&
                      <SlideFade in={expand == x + 1} offsetY='-5px'>
                        <Flex justifyContent="flex-start" width="100%">
                          <VStack align='stretch' spacing="2" mr="12px">
                            <Flex>Campo</Flex>
                            {
                              el.updated.map(up => <Flex>{translateValues[up.field] || up.field}</Flex>)
                            }
                          </VStack>
                          <VStack align='stretch' spacing="2" mr="12px">
                            <Flex>Valor Anterior</Flex>
                            {
                              el.updated.map(up => <Flex minH="24px">{up.field == 'week_day' ? weekDay[up.old] : up.field == 'status' ? status[up.old] : up.old}</Flex>)
                            }
                          </VStack>
                          <VStack align='stretch' spacing="2">
                            <Flex>Novo Valor</Flex>
                            {
                              el.updated.map(up => <Flex>{up.field == 'week_day' ? weekDay[up.new] : up.field == 'status' ? status[up.new] : up.new}</Flex>)
                            }
                          </VStack>
                        </Flex>
                      </SlideFade>
                    }
                  </Flex>
                </Tr>  
              )
            }
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}

export default AuditTable