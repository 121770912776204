import React from "react";
import {ResponsivePie} from "@nivo/pie";
import {useColorMode} from "@chakra-ui/react";
import {border, themeDark, themeLight, tooltip} from "variables/nivo/default";
import {
    arc,
    arcLabelsTextColor,
    arcLinkLabelsColor,
    legendsDark,
    legendsLight,
    margin,
    pieProps
} from "variables/nivo/halfPie";

import {risksBooleanColors} from "variables/colors";

const emptyData = [
    {id: "hs", label: "Declaração de Saúde", value: 0},
    {id: "fr", label: "Primeiro Acolhimento", value: 0}
]

const total = (data) => {
    let total = 0;
    data.map(item => {
        total += item.value;
    });

    return total;
};

const StatmentAndAssestmentChart = ({data}) => {
    if (!data) data = emptyData;

    const {colorMode} = useColorMode();

    return (
        <ResponsivePie
            data={data}
            margin={margin}
            colors={risksBooleanColors}
            theme={colorMode === "light" ? themeLight : themeDark}
            {...border}
            {...arc}
            arcLinkLabelsColor={arcLinkLabelsColor}
            arcLabelsTextColor={arcLabelsTextColor}
            legends={colorMode === "light" ? legendsLight : legendsDark}
            tooltip={({datum}) => tooltip(datum.label, datum.value, total(data), datum.color)}
            {...pieProps}
            innerRadius={0.7}
        />
    )
};

export default StatmentAndAssestmentChart
