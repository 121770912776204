import {withAuthenticator} from "@aws-amplify/ui-react";
import AppProvider from "./appProvider";
import "../assets/css/amplify-styles-copy.css";
import { RegionalsProvider } from "hooks/globalRegionals";

const AppAuthProvider = (props) => {
    const { signOut, user, children } = props;

    return (
        <AppProvider user={user} signOut={signOut}>
            <RegionalsProvider>
                {children}
            </RegionalsProvider>
        </AppProvider>
    )
}

export default withAuthenticator(AppAuthProvider, {
    hideSignUp: true
})
