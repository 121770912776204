import {createContext, useContext, useReducer} from "react";
import {reducers, ACTION_TYPES} from "./reducers";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {CREATE_REGIONAL, GET_REGIONAL_ID, GET_REGIONALS, UPDATE_REGIONAL} from "../../services/regionals";

const RegionalsStateContext = createContext()
const RegionalsDispatchContext = createContext()

const RegionalsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, {rows: [], first: 15, page: 1})

    const [pagingTo, { loading }] = useLazyQuery(GET_REGIONALS, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => dispatch({type: ACTION_TYPES.LIST, data: data.regionalsPaginate})
    })
    return (
        <RegionalsStateContext.Provider value={{...state, pagingTo, loading}}>
            <RegionalsDispatchContext.Provider value={dispatch}>
                {children}
            </RegionalsDispatchContext.Provider>
        </RegionalsStateContext.Provider>
    )
}

const useRegionals = () => {
    const context = useContext(RegionalsStateContext)

    if (!context) throw new Error("Falta o provider de regionais")

    return context;
}

const usePaginateRegionals = () => {
    const dispatch = useContext(RegionalsDispatchContext);

	return useLazyQuery(GET_REGIONALS, {
			onCompleted: (data) => {
				dispatch({type: ACTION_TYPES.LIST, data: data.regionalsPaginate})
			}
	})
}

const useRegional = (id) => {
    const dispatch = useContext(RegionalsDispatchContext);

    return useQuery(GET_REGIONAL_ID, {
        fetchPolicy: "no-cache",
        variables: {
            id: id
        }	
    },{
        onCompleted: (data) => dispatch({type: ACTION_TYPES.SELECT, data: data.regional})
    })
}

const useGetAllDataRegional = () => {
    const dispatch = useContext(RegionalsDispatchContext);
    return useLazyQuery(GET_REGIONAL_ID, {
        onCompleted: (data) => dispatch({type: ACTION_TYPES.SELECT, data: data.regional})
    })
}

const useCreateRegional = ({ onCompleted }) => {
    const dispatch = useContext(RegionalsDispatchContext);

    return useMutation(CREATE_REGIONAL, {
        onCompleted: (data) => {
            if (onCompleted) onCompleted(data)

            dispatch({type: ACTION_TYPES.ADD, data: data.createRegional})
        }
    });
}

const useUpdateRegional = (options = {}) => {
    const dispatch = useContext(RegionalsDispatchContext);
	const { onSuccess, onError } = options

    return useMutation(UPDATE_REGIONAL, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data)
            dispatch({type: ACTION_TYPES.UPDATE, data: data.updateRegional})
        }
    });
}

export {
    RegionalsProvider,
    useRegionals,
    usePaginateRegionals,
    useRegional,
    useGetAllDataRegional,
    useCreateRegional,
    useUpdateRegional
}
