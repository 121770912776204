import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_COMMITMENTS,
  GET_COMMITMENTS_PAGINATE,
  UPDATE_COMMITMENT,
} from "graphql/commitments";
import { ACTION_TYPES, reducers } from "./reducers";
import { useFilter } from "hooks/useFilter";
import { CREATE_NOTE } from "../../graphql/notes";

const CommitmentsStateContext = createContext();
const CommitmentsDispatchContext = createContext();

const CommitmentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });
  const { filters } = useFilter();

  const { data, loading, error } = useQuery(GET_COMMITMENTS_PAGINATE, {
    variables: {
      filters,
    },
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.commitmentsPaginate });
    },
  });

  return (
    <CommitmentsStateContext.Provider value={{ loading, error, ...state }}>
      <CommitmentsDispatchContext.Provider value={dispatch}>
        {children}
      </CommitmentsDispatchContext.Provider>
    </CommitmentsStateContext.Provider>
  );
};

const useCommitments = () => {
  const context = useContext(CommitmentsStateContext);

  if (!context)
    throw new Error(
      "useCommitments must be used within an CommitmentsProvider"
    );

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(CommitmentsDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a CommitmentsProvider");

  return dispatch;
};

const usePaginateCommitments = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_COMMITMENTS_PAGINATE, {
    onCompleted: (data) => {
      window.history.replaceState(null, '', `./compromissos?page=${data.commitmentsPaginate.paginatorInfo.currentPage}&size=${data.commitmentsPaginate.paginatorInfo.perPage}`);
      dispatch({ type: ACTION_TYPES.LIST, data: data.commitmentsPaginate });
    },
  });
};

const useUpdateCommitment = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_COMMITMENT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.updateCommitment);
      dispatch({
        type: ACTION_TYPES.UPDATE_COMMITMENT,
        data: data.updateCommitment,
      });
    },
    onError: (error) => {
      if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation);
    },
    refetchQueries: [
      {
        query: GET_COMMITMENTS,
      },
    ],
  });
};

const useCreateNote = (options = {}) => {
  const dispatch = useDispatch()
  const { onSuccess, onError } = options

  return useMutation(CREATE_NOTE, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess()
      dispatch({ type: ACTION_TYPES.ADD_NOTE, data: data.createNote })
    },
    onError: (error) => {
      if (onError) onError(error)
    },
  })
}

export {
  CommitmentsProvider,
  useCommitments,
  usePaginateCommitments,
  useCreateNote,
  useUpdateCommitment,
};
