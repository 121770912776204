import { gql } from '@apollo/client';

export const GET_REGIONALS = gql`
    query GET_REGIONALS($first: Int!, $page: Int!, $search: String) {
        regionalsPaginate(first: $first, page: $page, search: $search) {
            paginatorInfo {
                count
                currentPage
                firstItem
                perPage
                lastItem
                total
            }
            data {
                id
                name
                beneficiary_limit
                plansCount
                professionalsCount
                plans {
                    id
                    name
                }

            }
        }
    }
`
export const GET_REGIONAL_ID = gql`
    query GET_REGIONAL_ID($id: String!) {
        regional(id: $id) {
            id
            name
            beneficiary_limit
            cep
            state_id
            city_id
            district
            address
            address_number
            complement
            is_admin
            send_whatsapp
            autom_0_2
            autom_48
            autom_49_low_risk
            autom_49_med_low_risk
            autom_49_med_high_risk
            autom_49_high_risk
            plans {
                id
                name
                ans
                secad
            }
            professionals {
                id
                cpf
                user {
                    name
                }
            }
        }      
    }
`
export const GET_PLANS = gql`
    query GET_ALL_PLANS($search: String) {
        plans(search: $search) {
            id
            name
            ans
            secad
        }
    }
`
export const CREATE_REGIONAL = gql`
    mutation CREATE_REGIONAL($input: CreateRegionalInput) {
        createRegional(input: $input) {
            id
            name
            beneficiary_limit
            cep
            state_id
            city_id
            district
            address
            address_number
            complement
            plansCount
            professionalsCount
            send_whatsapp
            autom_0_2
            autom_48
            autom_49_low_risk
            autom_49_med_low_risk
            autom_49_med_high_risk
            autom_49_high_risk
            plans {
                id
                name
            }
            professionals {
                id
            }
        }
    }
`
export const UPDATE_REGIONAL = gql`
    mutation UPDATE_REGIONAL($input: UpdateRegionalInput){
        updateRegional(input: $input) {
            id
            name
            beneficiary_limit
            cep
            state_id
            city_id
            district
            address
            address_number
            complement
            plansCount
            professionalsCount
            send_whatsapp
            autom_0_2
            autom_48
            autom_49_low_risk
            autom_49_med_low_risk
            autom_49_med_high_risk
            autom_49_high_risk
            plans {
                id
                name
                ans
                secad
            }
            professionals {
                id
                cpf
                user {
                    name
                }
            }
        }
    }
`
