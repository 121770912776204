import React from "react";
import { NavLink, useParams } from "react-router-dom";

import { useCustomHour } from "hooks/customHours";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { getWeekDay } from "functions/getWeekDay";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import dateFormat from "functions/dateFormat";

const ViewCustomHour = () => {
  const { id } = useParams();
  const { data, loading } = useCustomHour(id);

  const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
  const textColor = useColorModeValue("gray.700", "white");
  const editButtonColor = useColorModeValue("white", "white");

  return (
    <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w={{ md: "100%", lg: "50%" }}>
          <CardHeader mb="40px">
            <Flex direction="row" w="100%" justify="space-between">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Agenda por dia
              </Text>
              <Flex>
                <NavLink to={`/sistema/administracao/agenda-ausencia/${id}/editar`}>
                  <Button 
                    color={editButtonColor}
                    backgroundColor={buttonBg}
                    variant="no-hover"
                    mr="5"
                  >

                    Editar
                  </Button>
                </NavLink>
                <NavLink to="/sistema/administracao/agenda">
                  <Button >
                    Voltar
                  </Button>
                </NavLink>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                gap="24px"
              >
              <FormControl>
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Regional
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    { data?.customHour?.regional?.name || null }
                  </Text>
                </Skeleton>
              </FormControl>
            </Grid>
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
              gap="24px"
              mt="24px"
            >
              <FormControl>
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Profissional
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    { data?.customHour?.professional?.user.name || null }
                  </Text>
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Tipo
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    { data?.customHour?.type == 'included' ? "Data liberada" : "Data bloqueada" }
                  </Text>
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Tipo de Agenda
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    { data?.customHour?.event_type == 'presencial' ? "Presencial" : "Tele Atendimento" }
                  </Text>
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Data de Início
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    { data?.customHour?.start ? dateFormat(data.customHour.start) : null }
                  </Text>
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Data de Término
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                  { data?.customHour?.end ? dateFormat(data.customHour.end) : null }
                  </Text>
                </Skeleton>
              </FormControl>
              {
                data?.customHour?.event_type == 'tele' ?
                  <FormControl>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Turno
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                      { data?.customHour?.turn == 'manha' ? 'Manhã' : 'Tarde' }
                      </Text>
                    </Skeleton>
                  </FormControl>
                  : 
                  <>
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Horário de Início
                      </FormLabel>
                      <Skeleton height={"25px"} isLoaded={!loading}>
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mb="3px"
                        >
                          { data?.customHour?.start_time ? dateFormat(data.customHour.start_time, 'HH:mm') : null }
                        </Text>
                      </Skeleton>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Horário de Término
                      </FormLabel>
                      <Skeleton height={"25px"} isLoaded={!loading}>
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mb="3px"
                        >
                        { data?.customHour?.end_time ? dateFormat(data.customHour.end_time, 'HH:mm') : null }
                        </Text>
                      </Skeleton>
                    </FormControl>
                  </>
                  }
            </Grid>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
  )
}

export default ViewCustomHour