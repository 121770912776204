import React from "react";

import {
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
} from "@chakra-ui/react";

import { useUpdateCampaignReceiver } from "hooks/whatsappDisclosures";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import dateFormat from "functions/dateFormat";

const schema = yup.object().shape({
  status: yup
    .string()
    .oneOf(["confirmed", "interested", "canceled"], "Valor inválido"),
});

const EditCampaignReceiverModal = ({ receiver, open, setEditModal }) => {
  const methods = useForm();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [
    updateCampaignReceiver,
    { loading, error },
  ] = useUpdateCampaignReceiver({
    onSuccess: (data) => {
      toast({
        title: "Convidado atualizado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Ocorreu um erro.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleDataBeforeSubmit = (data) => {
      if (data.status === "confirmed") {
        data.accept = true;
        data.accepted_in = dateFormat(dateFormat(new Date(), "Y-MM-dd HH:mm"));
      } else {
        data.accept = false;
      }

      return data;
  }

  const onSubmit = (data) => {
    data = handleDataBeforeSubmit(data);

    const variables = {
      input: {
        id: receiver?.id,
        ...data
      },
    };

    updateCampaignReceiver({ variables: variables });
  };

  const closeModal = () => {
    setEditModal(false);
  };

  return (
    <Modal
      isCentered
      onClose={closeModal}
      isOpen={open}
      motionPreset="slideInBottom"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Alterar status de {receiver?.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={5} isInvalid={errors?.status}>
                <FormLabel fontWeight="bold" fontSize="sm">
                  Status
                </FormLabel>
                <Select
                  {...register("status")}
                  borderRadius="8px"
                  fontSize="md"
                  defaultValue={receiver?.status}
                >
                  <option value="confirmed">Confirmado</option>
                  <option value="interested">Suplente</option>
                  <option value="canceled">Cancelado</option>
                </Select>
                {errors?.status && (
                  <FormErrorMessage>{errors.status.message}</FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                type="submit"
                bg="notrelife.900"
                color="white"
                onClick={closeModal}
                variant="nohover"
                disabled={!isValid}
              >
                Atualizar
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default EditCampaignReceiverModal;
