import React, {createContext, useContext, useReducer} from "react";
import {useQuery} from "@apollo/client";
import {GET_FIRST_RECEPTION} from "graphql/assessments";
const ACTION_TYPES = {
    GET: 'GET_FIRST_RECEPTION',
}
const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET: {
            return {
                ...state,
                firstreception: action.data
            }
        }
        default: {
            return state
        }
    }
}

const FirstReceptionStateContext = createContext()
const FirstReceptionDispatchContext = createContext()

const FirstReceptionProvider = ({ children, beneficiary_id }) => {
    const [state, dispatch] = useReducer(reducers, { firstreception: {} })
    const { data, loading, error } = useQuery(GET_FIRST_RECEPTION, {
        variables : {
            beneficiary_id
        },
        onCompleted: (data) => dispatch({type: ACTION_TYPES.GET, data})
    })

    return (
        <FirstReceptionStateContext.Provider value={{ data, loading, error, ...state }}>
            <FirstReceptionDispatchContext.Provider value={dispatch}>{children}</FirstReceptionDispatchContext.Provider>
        </FirstReceptionStateContext.Provider>
    )
}

const useFirstReception = () => {
    const context = useContext(FirstReceptionStateContext)

    if (!context)
        throw new Error('useFirstReception must be used within an FirstReceptionProvider')

    return context
}


export {
    FirstReceptionProvider,
    useFirstReception
}