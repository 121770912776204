import React from "react";

import { useUsers, useDeleteUser } from "hooks/users";

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useRefusePA } from "hooks/commitmentsManagement";

const PaRefusalModal = ({ beneficiaryId, open, setRefusalModal }) => {
  const toast = useToast()

  const { register, formState: { errors }, getValues } = useForm({
    mode: "onBlur",
  });

  const [refusePA, { loading }] = useRefusePA({
    onSuccess: () => {
        toast({
            title: "Agendamento de PA recusada.",
            status: "success",
            duration: 5000,
            isClosable: true,
        })
        setRefusalModal(false)
    },
    onError: (error) => {
      toast({
          title: "Ocorreu um erro",
          status: "error",
          duration: 5000,
          isClosable: true,
      })
    }
  })

  const onSubmit = () => {
    const variables = {
      input: {
        id: beneficiaryId,
        type_ineligibility: getValues('refusal_motive'),
        strike_fr_scheduled: 3
      }
    }
    refusePA({variables: variables})
  }


  return (
    <Modal
      isCentered
      onClose={() => setRefusalModal(false)}
      isOpen={open}
      motionPreset="slideInBottom"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent padding="12px">
        <ModalHeader>Motivo da recusa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select {...register("refusal_motive")}>
            <option value="not_necessary">Não acha necessário</option>
            <option value="no_compromise">Não quer comprometimento</option>
            <option value="no_directions">Não compartilha com os direcionamentos da equipe</option>
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button mr={5} variant="ghost" onClick={() => setRefusalModal(false)}>
            Fechar
          </Button>
          <Button
            bgColor={"notrelife.800"}
            color="#FFF"
            variant="no-hover"
            onClick={() => onSubmit()}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaRefusalModal;
