import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import {
	Box,
	Button,
	Collapse,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Select,
	Skeleton,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";

import Icon from "components/FontAwesomeIcons/Icon";
import { useUpdateFilters } from "hooks/useFilter";
import { useRegionals } from "hooks/globalRegionals";
import { getMonth, getYear } from "date-fns";
import { months as vMonths } from "variables/months";
import { useLazyQuery } from "@apollo/client";
import { REGIONAL } from "graphql/dashboards";
import { roleConsu, roleEnfer } from "../../../variables/roles";
import getCurrentYear from "../../../functions/getCurrentYear";
import { useFilter } from "hooks/useFilter";
import dateFormat from "functions/dateFormat";
import { useUpdateCommitmentsFilters } from "hooks/useFilter";

const renderYearsOptions = (i, value) => {
	return i == value ? (
		<option selected value={i}>
			{i}
		</option>
	) : (
		<option value={i}>{i}</option>
	)
}

const renderMonthOptions = (i, value) => {
	return i.value == value ? (
		<option selected value={i.value}>
			{i.label}
		</option>
	) : (
		<option value={i.value}>{i.label}</option>
	)
}

const FiltersBox = ({
	planNameFilter = false,
	professionalIdFilter = false,
	isTeleFilter = false,
	byBeneFilter = false,
	startDateFilter = false,
	endDateFilter = false,
	yearMonthFilter = false,
	statusFilter = false,
	assessmentFilter = false,
	roleFilter = "",
	isCommitment = false,
	source = "",
    ageFilter= false
}) => {
	const currentDate = new Date();
	const currentYear = getYear(currentDate);
	const currentAlmostYear = getCurrentYear(currentDate);
	const currentMonth = getMonth(currentDate) + 1;

	const [yearsList, setYearsList] = useState([]);
	const [monthsList, setMonthsList] = useState([]);

	const { filters } = useFilter();

	const defaultStart = filters?.start_date ? dateFormat(filters.start_date) : null
	const defaultEnd = filters?.end_date ? dateFormat(filters.end_date) : null

	const { mutateAsync: updateFilters } = useUpdateFilters();
	const { mutateAsync: updateCommitmentsFilters } = useUpdateCommitmentsFilters();
	const { register, getValues, setValue, watch } = useForm();
	const { regional: regionalId } = useRegionals();

	const [
		loadPlans,
		{ data: regional, loading: loadingRegional },
	] = useLazyQuery(REGIONAL);

	React.useEffect(() => {
		if (regionalId)
			loadPlans({
				variables: {
					id: regionalId,
				},
			});
	}, [regionalId]);

	const toast = useToast();
	const [expand, setExpand] = React.useState(window.screen.width > 766);

	const wYear = watch("year", null);
	const wMonth = watch("month", null);

	React.useEffect(() => {
		const years = [];
		for (let y = 2019; y <= currentAlmostYear; y++) {
			years.push(y);
		}
		setYearsList(years);
	}, [currentAlmostYear]);

	React.useEffect(() => {
		let months = [];
		if (wYear == currentYear) {
			vMonths
				.filter((x) => x.value < currentMonth + 1)
				.map((x) => {
					months.push(x);
				});
		} else {
			vMonths.map((x) => {
				months.push(x);
			});
		}
		setMonthsList(months);
	}, [wYear]);

	React.useEffect(() => {
		setValue("year", currentYear);
		setValue("month", currentMonth);
	}, []);

	const formatDate = (date, h = '00:00:00') => {
		const splitDate = date.split('/')
		return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]} ${h}`
	}

	const applyFilters = () => {
		const filters = {};
		if (planNameFilter && getValues("plan_name"))
			filters.plan_name = getValues("plan_name");
		if (professionalIdFilter && getValues("professional_id"))
			filters.professional_id = getValues("professional_id");
		if (isTeleFilter && getValues("type")) filters.type = getValues("type");
		if (byBeneFilter && getValues("by_bene"))
			filters.by_bene = getValues("by_bene");
		if (statusFilter && getValues("status"))
			filters.status = getValues("status");
		if (assessmentFilter && getValues("form_type"))
			filters.form_type = getValues("form_type");
		if (
			startDateFilter &&
			getValues("start_date") !== "__/__/____" &&
			getValues("start_date") !== ""
		)
			filters.start_date = formatDate(getValues("start_date"));
		if (
			endDateFilter &&
			getValues("end_date") !== "__/__/____" &&
			getValues("end_date") !== ""
		)
			filters.end_date = formatDate(getValues("end_date"), "00:00:00");
		if (yearMonthFilter && getValues("year"))
			filters.year = String(getValues("year"));
		if (yearMonthFilter && getValues("month"))
			filters.month = String(getValues("month"));
        if (ageFilter && getValues("age"))
            filters.age = getValues("age");

		isCommitment ? updateCommitmentsFilters(filters) : updateFilters(filters);
	};

	const clearFilters = () => {
		setValue("plan_name", null);
		setValue("type", null);
		setValue("by_bene", null);
		setValue("status", null);
		setValue("professional_id", null);
		setValue("start_date", "__/__/____");
		setValue("end_date", "__/__/____");
		setValue("year", null);
		setValue("month", null);
		setValue("assessment", null);
		setValue("age", null);
		applyFilters();
	};

	const handleProfessionals = () => {
		let pro = regional?.regional?.professionals;
		if (!!roleFilter) pro = pro?.filter((p) => p?.role === roleFilter);
		if (source === "dashboard_operacional")
			pro = pro?.filter(
				(p) => p?.role === "Enfermeiro" || p?.role === "Consultor_de_Saúde"
			);
		return pro?.map((p) => (
			<option id={p?.id} value={p?.id}>
				{p?.name}
			</option>
		));
	};

	const inputFocus = useColorModeValue("notrelife.800", "notrelife.800");
	const buttonBg = useColorModeValue("green.600", "green.600");
	const secButtonBg = useColorModeValue("blue.600", "blue.600");

	return (
		<Box w={"100%"} pl={22} pr={22} pb={22}>
			{!expand && (
				<Box position="absolute" top="0" right="0" pt="22px">
					<Button
						colorScheme={"gray"}
						size={"sm"}
						mr={6}
						onClick={() => setExpand(!expand)}
					>
						{expand ? (
							<Icon icon="shrink" color="white" />
						) : (
							<Icon icon="expand" color="white" />
						)}
					</Button>
				</Box>
			)}
			<Collapse in={expand} animateOpacity>
				<Grid
					templateColumns={{
						sm: "1fr",
						md: "repeat(2, 1fr)",
						lg: "repeat(4, 1fr)",
					}}
					templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
					gap="20px"
				>
					<FormControl hidden={!planNameFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por plano
						</FormLabel>
						<Skeleton isLoaded={!loadingRegional} h="40px">
							{
								regional?.regional &&
								<Select
									{...register("plan_name")}
									defaultValue={filters?.plan_name}
									borderRadius="8px"
									fontSize="md"
									placeholder="Selecione uma opção..."
									color="gray.400"
									focusBorderColor={inputFocus}
								>
									{regional?.regional?.plans?.map((p) => {
										return (
											<option id={p?.plan_name} value={p?.plan_name}>
												{p?.plan_name}
											</option>
										);
									})}
								</Select>
							}
						</Skeleton>
					</FormControl>
					<FormControl hidden={!professionalIdFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por profissional
						</FormLabel>
						<Skeleton isLoaded={!loadingRegional} h="40px">
							{
								regional?.regional &&
								<Select
									{...register("professional_id")}
									defaultValue={filters?.professional_id}
									borderRadius="8px"
									fontSize="md"
									placeholder="Selecione uma opção..."
									color="gray.400"
									focusBorderColor={inputFocus}
								>
									{handleProfessionals()}
								</Select>
							}
						</Skeleton>
					</FormControl>
					<FormControl hidden={!assessmentFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por tipo
						</FormLabel>
						<Skeleton isLoaded={!loadingRegional}>
							<Select
								{...register("form_type")}
								defaultValue={filters?.form_type}
								borderRadius="8px"
								fontSize="md"
								placeholder="Selecione uma opção..."
								color="gray.400"
								focusBorderColor={inputFocus}
							>
								<option value={"Declaração de Saúde"}>Declaração de Saúde</option>
								<option value={"Primeiro Acompanhamento"}>Primeiro Acompanhamento</option>
							</Select>
						</Skeleton>
					</FormControl>
					<FormControl hidden={!isTeleFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por tipo de atendimento
						</FormLabel>
						<Select
							{...register("type")}
							defaultValue={filters?.type || "pending"}
							borderRadius="8px"
							fontSize="md"
							placeholder="Selecione uma opção..."
							color="gray.400"
							focusBorderColor={inputFocus}
						>
							<option value={true}>Tele-Atendimento</option>
							<option value={false}>Presencial</option>
						</Select>
					</FormControl>
					<FormControl hidden={!byBeneFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por tipo de agendamento
						</FormLabel>
						<Select
							{...register("by_bene")}
							defaultValue={filters?.by_bene || "pending"}
							borderRadius="8px"
							fontSize="md"
							placeholder="Selecione uma opção..."
							color="gray.400"
							focusBorderColor={inputFocus}
						>
							<option value={true}>Realizado pelo beneficiário</option>
							<option value={false}>Realizado por um profissional</option>
						</Select>
					</FormControl>
					<FormControl hidden={!statusFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por status
						</FormLabel>
						<Select
							{...register("status")}
							defaultValue={filters?.status}
							borderRadius="8px"
							fontSize="md"
							placeholder="Selecione uma opção..."
							color="gray.400"
							focusBorderColor={inputFocus}
						>
							<option value="pending">Pendente</option>
							<option value="contact_attempt">Tentativa de contato</option>
							<option value="scheduled">Agendado</option>
							<option value="refused">Recusado</option>
						</Select>
					</FormControl>
					<FormControl hidden={!startDateFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Data de início
						</FormLabel>
						<Input
							{...register("start_date")}
							defaultValue={defaultStart}
							as={InputMask}
							mask="99/99/9999"
							placeholder="dd/mm/aaaa"
							borderRadius="8px"
							fontSize="md"
							focusBorderColor={inputFocus}
						/>
					</FormControl>
					<FormControl hidden={!endDateFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Data de fim
						</FormLabel>
						<Input
							{...register("end_date")}
							defaultValue={defaultEnd}
							as={InputMask}
							mask="99/99/9999"
							placeholder="dd/mm/aaaa"
							borderRadius="8px"
							fontSize="md"
							focusBorderColor={inputFocus}
						/>
					</FormControl>
					<FormControl hidden={!yearMonthFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por ano
						</FormLabel>
						<Select
							{...register("year")}
							defaultValue={filters?.year || currentYear}
							borderRadius="8px"
							fontSize="md"
							placeholder="Selecione uma opção..."
							color="gray.400"
							focusBorderColor={inputFocus}
						>
							{yearsList.map((i) => renderYearsOptions(i, filters?.year || currentYear))}
						</Select>
					</FormControl>
					<FormControl hidden={!yearMonthFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por mês
						</FormLabel>
						<Select
							{...register("month")}
							defaultValue={filters?.month || null}
							borderRadius="8px"
							fontSize="md"
							placeholder="Selecione uma opção..."
							color="gray.400"
							focusBorderColor={inputFocus}
							disabled={wYear == null || wYear == ""}
						>
							{monthsList.map((i) => renderMonthOptions(i, filters?.month || currentMonth))}
						</Select>
					</FormControl>
					<FormControl hidden={!ageFilter}>
						<FormLabel fontSize="xs" fontWeight="bold" mb="10px">
							Filtrar por Idade
						</FormLabel>
						<Select
							{...register("age")}
							defaultValue={filters?.age || null}
							borderRadius="8px"
							fontSize="md"
							placeholder="Selecione uma opção..."
							color="gray.400"
							focusBorderColor={inputFocus}
						>
                            <option value="48minus">48 anos ou menos</option>
                            <option value="49plus">49 anos ou mais</option>
						</Select>
					</FormControl>
				</Grid>
			</Collapse>
			{expand && (
				<Box>
					<Flex w={"100%"} justify={"flex-end"} mt="20px">
						<Button
							colorScheme={"gray"}
							size={"sm"}
							mr={6}
							onClick={() => setExpand(!expand)}
						>
							{expand ? (
								<Icon icon="shrink" color="white" />
							) : (
								<Icon icon="expand" color="white" />
							)}
						</Button>
						<Button
							color="#FFF"
							bg={secButtonBg}
							variant="no-hover"
							size={"sm"}
							mr={6}
							onClick={() => clearFilters()}
						>
							Limpar filtros
						</Button>
						<Button
							color="#FFF"
							bg={buttonBg}
							variant="no-hover"
							size={"sm"}
							onClick={() => applyFilters()}
						>
							Aplicar filtros
						</Button>
					</Flex>
				</Box>
			)}
		</Box>
	);
};

export default FiltersBox;
