import React, { useState } from "react";

import { Box, Flex, Stack, Text, Tooltip, useColorMode, } from "@chakra-ui/react";

import { FaHeart, } from "react-icons/fa";

import TimelineRow from "components/Tables/TimelineRow";
import EvaluationModal from "./EvaluationModal";
import Icon from "components/FontAwesomeIcons/Icon";
import { useBeneficiaries } from "hooks/beneficiaries";

const BeneficiaryTimeline = () => {
    const { colorMode } = useColorMode();
    const { beneficiary } = useBeneficiaries();

    const healthStatement = beneficiary?.healthStatement || {};
    const timeline = beneficiary?.timeline || [];

    const [evId, setEvId] = useState(null)
    const [evTitle, setEvTitle] = useState(null)
    const [open, setOpen] = useState(false)

    const textColor = (colorMode == 'light') ? 'black' : 'white';

    const downloadFile = () => {
        const fileUrl = healthStatement.url_document;
        window.open(fileUrl, '_blank')
    }

    const timelineClone = timeline ? [...timeline] : []

    return (
        <>
            <Flex width="100%" px="14px">
                {
                    timeline && timeline.length == 0 ?
                        <Text fontSize="lg" fontWeight="bold">
                            Não existem eventos para serem exibidos
                        </Text>
                        :
                        <Stack direction="column" spacing="4px">
                            {timelineClone?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).map((row, index) => {
                                return (
                                    <>
                                        <Flex>
                                            <Flex
                                                cursor="pointer"
                                                transition={"0.4s"}
                                                _hover={{ opacity: 0.75 }}
                                                onClick={
                                                    () => {
                                                        setEvId(row.taggable.id)
                                                        setEvTitle(row?.taggable?.type?.name || null)
                                                        setOpen(true)
                                                    }
                                                }
                                            >
                                                <TimelineRow
                                                    logo={row.logo ? FaHeart : ""}
                                                    title={row.title}
                                                    titleColor={textColor}
                                                    date={row.date}
                                                    isTele={row.isTele}
                                                    color={row.color}
                                                    description={row.description || "Apuração dos Riscos"}
                                                    tags={row.tags}
                                                    key={index}
                                                />
                                                <Flex ml="20px">
                                                    <Icon icon="plus" size="lg" />
                                                </Flex>
                                            </Flex>
                                            {
                                                row?.taggable?.type?.name == 'Declaração de Saúde' && healthStatement.url_document &&
                                                <Flex ml="8px" justifyContent="center" align="center">
                                                    <Tooltip placement="right" label="Recepção da proposta" ml="8px">
                                                        <Box
                                                            cursor="pointer"
                                                            transition={"0.4s"}
                                                            _hover={{ opacity: 0.75 }}
                                                            onClick={() => downloadFile()}>
                                                            <Icon icon="download" size="lg" />
                                                        </Box>
                                                    </Tooltip>
                                                </Flex>
                                            }
                                        </Flex>
                                    </>
                                );
                            })}
                        </Stack>
                }
            </Flex>
            {
                open &&
                <EvaluationModal
                    id={evId}
                    title={evTitle}
                    isOpen={open}
                    onClose={() => setOpen(false)}
                />
            }
        </>
    )
}

export default BeneficiaryTimeline
