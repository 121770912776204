const ACTION_TYPES = {
    LIST: "LIST_DOCUMENTS",
    VIEW: "VIEW_DOCUMENT",
    ADD: "ADD_DOCUMENT",
    UPDATE: "UPDATE_DOCUMENT",
    REMOVE: "REMOVE_DOCUMENT",
}

const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.LIST: {
            return {
                ...state, documents: action.data.documents
            }
        }
        case ACTION_TYPES.VIEW: {
            return {
                ...state, document: action.data.document
            }
        }
        default: {
            return state
        }
    }
}

export {
    reducers, ACTION_TYPES
}