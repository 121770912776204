import AsyncTable from "components/Tables/AsyncTable";
import dateFormat from "functions/dateFormat";
import { useAssignCpt, useAssocCid, useCpts, usePagination } from "hooks/cpt";
import { useFilter } from "hooks/useFilter";
import { useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import {
  COLORS_CPT_STATUS,
  CPT_DISABLED_REASON,
  FORM_TYPE_LIST,
  LABEL_CPT_STATUS,
  STATUS_CPT_AWAITING_CID_DISEASES,
  STATUS_CPT_AWAITING_ASSIGN_AT,
  STATUS_CPT_AWAITING_DOCUMENTS,
  STATUS_CPT_DISABLED,
  STATUS_CPT_NOT_SIGNED,
  STATUS_CPT_MEDICAL_ANALYSIS,
} from "functions/enums";
import { Flex, Icon, Text } from "@chakra-ui/react";
import CPTAudit from "./CPTAudit";
import { useAuth } from "hooks/useAuth";
import { roleSuper } from "../../../variables/roles";
import { cptFilter } from "functions/storageFilters";
import queryParamsPage from "functions/queryParamsPage";
import { useLocation } from "react-router-dom";
import { STATUS_CPT_LIST } from "functions/enums";
import { storageFilter } from "functions/storageFilters";
import { STATUS_CPT_MEDICAL_ANALYSIS_DOCUMENTS } from "functions/enums";

const columns = [
  {
    Header: "Nome",
    accessor: "name",
    isLink: true,
  },
  {
    Header: "CPF",
    accessor: "cpf",
  },
  {
    Header: "Plano",
    accessor: "plan_name",
  },
  {
    Header: "Proposta",
    accessor: "proposal_number",
  },
  {
    Header: "Estabelecida em",
    accessor: "established_in",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (props) => (
      <Flex align="center">
        <Icon
          as={BsCircleFill}
          w="8px"
          h="8px"
          color={COLORS_CPT_STATUS[props.value]}
          me="6px"
        />
        <Text color={"grey.400"} fontSize="md">
          {LABEL_CPT_STATUS[props.value]}
          {props.value === STATUS_CPT_DISABLED &&
            ` - ${CPT_DISABLED_REASON[props.row.original.reason_disable]}`}
        </Text>
      </Flex>
    ),
  },
  {
    Header: "Data de Adesão",
    accessor: "assign_plan_at",
    Cell: (props) => <div>{dateFormat(props.value)}</div>,
  },
  {
    Header: "Cadastrado em",
    accessor: "inserted_at",
    Cell: (props) => <div>{dateFormat(props.value)}</div>,
  },
];

const CptTable = () => {
  const location = useLocation();
  const { page: queryPage, size: querySize } = queryParamsPage(location);
  const {
    status: defaultStatus,
    established_in: defaultEstablished,
  } = storageFilter("@cpt_filters");

  const { user } = useAuth();
  const { cpt_filters } = useFilter();
  const [searchAux, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState(defaultStatus || null);
  const [establishedInFilter, setEstablishedInFilter] = useState(
    defaultEstablished || null
  );
  const { loading, page } = useCpts();
  const [paginateCptPage, { loading: reloading }] = usePagination();
  const [setSigned, { loading: loadingSigned }] = useAssignCpt();
  const [updateCpt] = useAssocCid();

  const [auditId, setAuditId] = useState(null);
  const [open, setOpen] = useState(false);

  const getPage = ({
    pageSize,
    pageIndex,
    search,
    status,
    established_in = "",
  }) => {
    const where = {
      column: "STATUS",
      operator: typeof status != "string" ? "IN" : "EQ",
      value: status,
    };

    if (!!established_in && FORM_TYPE_LIST.includes(established_in)) {
      where.AND = [
        {
          column: "ESTABLISHED_IN",
          operator: "EQ",
          value: established_in ?? "",
        },
      ];
    }

    paginateCptPage({
      variables: {
        perPage: pageSize,
        page: pageIndex,
        where,
        search: search ? `%${search}%` : null,
      },
    });
  };

  const fetchData = ({ pageSize, pageIndex, search }) => {
    setSearch(search);
    setStatusFilter(defaultStatus);
    setEstablishedInFilter(establishedInFilter ?? "");
    setEstablishedInFilter(establishedInFilter ?? "");
    getPage({
      pageSize: pageSize,
      pageIndex: pageIndex,
      search: search,
      status: statusFilter,
      established_in: establishedInFilter,
    });
  };

  useEffect(() => {
    if (
      !!cpt_filters &&
      !!cpt_filters?.status &&
      STATUS_CPT_LIST.includes(cpt_filters?.status)
    ) {
      setStatusFilter(cpt_filters?.status);
    }

    if (
      !!cpt_filters &&
      !!cpt_filters?.established_in &&
      FORM_TYPE_LIST.includes(cpt_filters?.established_in)
    ) {
      setEstablishedInFilter(cpt_filters?.established_in);
    }
    getPage({
      pageSize: querySize || 15,
      pageIndex: queryPage || 1,
      search: searchAux,
      status:
        cpt_filters?.status == "medical_analysis" ||
        statusFilter == "medical_analysis"
          ? ["medical_analysis", "medical_analysis_documents"]
          : cpt_filters?.status || statusFilter,
      established_in: establishedInFilter,
    });
  }, [cpt_filters, statusFilter, searchAux, establishedInFilter]);

  const handleAudit = (id) => {
    setAuditId(id);
    setOpen(true);
  };

  return (
    <>
      <AsyncTable
        columns={columns}
        isLoaded={!loading && !reloading && !loadingSigned}
        fetchData={fetchData}
        data={page?.data ?? [{}, {}, {}, {}]}
        total={page?.paginatorInfo?.total ?? 0}
        asyncPage={page?.paginatorInfo?.currentPage ?? 1}
        size={page?.paginatorInfo?.perPage}
        linkRow
        searchByName
        baseRoute={"cpt"}
        noDelete={true}
        noEdit={true}
        noView={true}
        customActions={[
          {
            label: "Registro de Atividades",
            icon: "documents",
            visible: () => {
              return user?.role === roleSuper;
            },
            callback: (id) => {
              handleAudit(id);
            },
          },
          {
            icon: "documentsReceived",
            label: "Termo Enviado",
            visible: (row) => {
              return (
                !row.assign_at &&
                (row?.status === STATUS_CPT_AWAITING_DOCUMENTS ||
                  row?.status === STATUS_CPT_MEDICAL_ANALYSIS_DOCUMENTS)
              );
            },
            callback: async (id) => {
              await updateCpt({
                variables: {
                  input: {
                    id: id,
                    status: STATUS_CPT_AWAITING_ASSIGN_AT,
                  },
                },
              });
              getPage({
                pageIndex: page?.paginatorInfo?.currentPage,
                pageSize: page?.paginatorInfo?.perPage || 15,
                status: statusFilter,
              });
            },
          },
          {
            icon: "signature",
            label: "Assinatura coletada",
            visible: (row) => {
              return (
                !row.assign_at &&
                (row?.status === STATUS_CPT_AWAITING_ASSIGN_AT ||
                  row?.status === STATUS_CPT_NOT_SIGNED)
              );
            },
            callback: async (id) => {
              await setSigned({
                variables: {
                  ids: [id],
                },
              });
            },
          },
          {
            icon: "awaitingSignature",
            label: "Retornar para aguardando assinatura",
            visible: (row) => {
              return row?.status === STATUS_CPT_NOT_SIGNED;
            },
            callback: async (id) => {
              await updateCpt({
                variables: {
                  input: {
                    id: id,
                    status: STATUS_CPT_AWAITING_ASSIGN_AT,
                  },
                },
              });
              getPage({
                pageIndex: page?.paginatorInfo?.currentPage,
                pageSize: page?.paginatorInfo.perPage,
                status: statusFilter,
              });
            },
          },
          {
            icon: "signatureDeclined",
            label: "Assinatura Recusada",
            visible: (row) => {
              return (
                !row.assign_at && row?.status === STATUS_CPT_AWAITING_ASSIGN_AT
              );
            },
            callback: async (id) => {
              await updateCpt({
                variables: {
                  input: {
                    id: id,
                    status: STATUS_CPT_NOT_SIGNED,
                  },
                },
              });
              getPage({
                pageIndex: page?.paginatorInfo?.currentPage,
                pageSize: page.paginatorInfo.perPage,
                status: statusFilter,
              });
            },
          },
          {
            icon: "medical_analysis",
            label: "Enviar para Análise Médica",
            visible: (row) => {
              return (
                row?.status === STATUS_CPT_AWAITING_CID_DISEASES ||
                row?.status === STATUS_CPT_AWAITING_DOCUMENTS
              );
            },
            callback: async (id, row) => {
              await updateCpt({
                variables: {
                  input: {
                    id: id,
                    status:
                      row?.status === STATUS_CPT_AWAITING_CID_DISEASES
                        ? STATUS_CPT_MEDICAL_ANALYSIS
                        : STATUS_CPT_MEDICAL_ANALYSIS_DOCUMENTS,
                  },
                },
              });
              getPage({
                pageIndex: page?.paginatorInfo?.currentPage,
                pageSize: page.paginatorInfo.perPage,
                status: statusFilter,
              });
            },
          },
        ]}
      />
      {open && (
        <CPTAudit
          id={auditId}
          open={open}
          close={() => setOpen(false)}
          type="patient"
        />
      )}
    </>
  );
};

export default CptTable;
