import { STATUS_CPT_AWAITING_CID_DISEASES } from "functions/enums";
import { storageFilter } from "functions/storageFilters";
import { cptFilter } from "functions/storageFilters";
import { createContext, useContext, useReducer } from "react";
import { useMutation } from "react-query";

const ACTION_TYPES = {
  UPDATE_FILTER: "UPDATE_FILTER",
  UPDATE_CPT_FILTER: "UPDATE_CPT_FILTER",
  UPDATE_COMMITMENTS_FILTER: "UPDATE_COMMITMENTS_FILTER",
};

const reducers = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_FILTER: {
      window.localStorage.setItem(
        "@dashboard_filters",
        JSON.stringify(action.data)
      );
      return {
        filters: action.data,
      };
    }
    case ACTION_TYPES.UPDATE_CPT_FILTER: {
      window.localStorage.setItem("@cpt_filters", JSON.stringify(action.data));
      return {
        cpt_filters: action.data,
      };
    }
    case ACTION_TYPES.UPDATE_COMMITMENTS_FILTER: {
      window.localStorage.setItem(
        "@commitments_filters",
        JSON.stringify(action.data)
      );
      return {
        filters: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

const FilterStateContext = createContext();
const FilterDispatchContext = createContext();

const FilterProvider = ({ target = null, children }) => {
  const currentDate = new Date();

  let defaultFilters = {};
  if (target == "dashboard") {
    const storageFilters = storageFilter("@dashboard_filters");
    defaultFilters = {
      filters: storageFilters || {
        year: currentDate.getFullYear().toString(),
        month: (currentDate.getMonth() + 1).toString(),
      },
    };
  } else if (target == "cpt") {
    const defaultStatus = cptFilter();
    defaultFilters = {
      cpt_filters: {
        status: defaultStatus || STATUS_CPT_AWAITING_CID_DISEASES,
        established_in: "",
      },
      regional_id: null,
    };
  } else if (target == "commitment") {
    const storageFilters = storageFilter("@commitments_filter");
    defaultFilters = { filters: storageFilters || null };
  }

  const [state, dispatch] = useReducer(reducers, defaultFilters);

  return (
    <FilterStateContext.Provider value={state}>
      <FilterDispatchContext.Provider value={dispatch}>
        {children}
      </FilterDispatchContext.Provider>
    </FilterStateContext.Provider>
  );
};

const useFilter = () => {
  const context = useContext(FilterStateContext);

  if (!context)
    throw new Error("useFilter must be used within an FilterProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(FilterDispatchContext);

  if (!dispatch)
    throw new Error("useFilter must be used within an FilterProvider");

  return dispatch;
};

const useUpdateFilters = () => {
  const dispatch = useDispatch();

  return useMutation((filters) =>
    dispatch({ type: ACTION_TYPES.UPDATE_FILTER, data: filters })
  );
};

const useUpdateCPTFilters = () => {
  const dispatch = useDispatch();

  return useMutation((filters) =>
    dispatch({ type: ACTION_TYPES.UPDATE_CPT_FILTER, data: filters })
  );
};

const useUpdateCommitmentsFilters = () => {
  const dispatch = useDispatch();

  return useMutation((filters) =>
    dispatch({ type: ACTION_TYPES.UPDATE_COMMITMENTS_FILTER, data: filters })
  );
};

export {
  FilterProvider,
  useFilter,
  useUpdateFilters,
  useUpdateCPTFilters,
  useUpdateCommitmentsFilters,
};
