export const motives = [
  { id: 1, label: "Demanda administrativa" },
  { id: 2, label: "Queixa/dúvida clínica" },
  { id: 3, label: "Dúvida de CPT" },
  { id: 4, label: "Acolhimento NIP" },
  { id: 5, label: "Dúvida do plano de cuidados" },
  { id: 6, label: "Dúvida sobre rede assistencial" },
  { id: 7, label: "Reclamação de atendimento" },
  { id: 8, label: "Pedido de agendamento com especialidade sem encaminhamento"},
  { id: 9, label: "Renovação/pedido de receita ou guia de exame" },
  { id: 10, label: "Formulário de alto custo SUS" },
  { id: 11, label: "Outro" },
  { id: 12, label: "Visita hospitalar" },
  { id: 13, label: "Telemonitoramento durante internação" },
  { id: 14, label: "Telemonitoramento após alta hospitalar" },
];
