import React from "react";

import { useCancelBeneficiary } from "hooks/beneficiaries";

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tooltip,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Icon from "components/FontAwesomeIcons/Icon";

const CancelBeneficiary = ({
  beneficiary,
  loadingBenef,
  open,
  onOpen,
  onClose,
}) => {
  const toast = useToast();

  const [cancelBeneficiary, { loading }] = useCancelBeneficiary({
    onSuccess: () => {
      toast({
        title: `Beneficiário ${
          beneficiary.active ? "reativado" : "cancelado"
        } com sucesso`,
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        onClose();
    },
    onError: (errors) => {
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleCancel = () => {
    const variables = {
      input: {
        id: beneficiary.id,
        active: !beneficiary.active,
      },
    };
    cancelBeneficiary({ variables: variables });
  };

  const cancelColor = useColorModeValue("red.600", "red.500");
  const cancelHover = useColorModeValue("red.500", "red.400");

  const reactiveColor = useColorModeValue("green.600", "green.500");
  const reactiveHover = useColorModeValue("green.500", "green.400");

  return (
    <>
      <Box>
        <Tooltip
          label={
            beneficiary?.active ? "Cancelar proposta" : "Reativer proposta"
          }
          placement="top"
          closeOnClick={true}
        >
          <Button
            background={beneficiary?.active ? cancelColor : reactiveColor}
            _hover={{
              backgroundColor: beneficiary?.active
                ? cancelHover
                : reactiveHover,
            }}
            color="white"
            disabled={loadingBenef}
            onClick={onOpen}
          >
            {loadingBenef ? <Spinner /> : <Icon icon="power" />}
          </Button>
        </Tooltip>
      </Box>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={open}
        motionPreset="slideInBottom"
        size="xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {!beneficiary.active ? "Reativar" : "Cancelar"} beneficiário{" "}
            {beneficiary.name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Por favor, confirme a ação de{" "}
            {!beneficiary.active ? "reativar" : "cancelamento"}.
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={loading}
              backgroundColor="notrelife.800"
              color="white"
              variant="no-hover"
              type="submit"
              onClick={() => handleCancel()}
            >
              {!beneficiary.active ? "Reativar" : "Cancelar"} Beneficiário
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelBeneficiary;
