import React from "react";
import { Box, Grid, Flex, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CptTable from "./components/CptTable";
import Filters from "./components/Filters";

export default () => {
    return (
        <React.Fragment>
            <Grid
                templateColumns={{ sm: "1fr", md: "1fr" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="12px"
                pt={{ sm: "125px", lg: "160px" }}
            >
                <Filters />
                <Card px="0px" pb="24px" minHeight="320px">
                    <CardHeader mb="34px" px="22px">
                        <Box w="100%">
                            <Flex
                                justify="space-between"
                                align="center"
                                fontSize={{ sm: "10px", lg: "12px" }}
                                color="gray.400"
                            >
                                <Text fontSize="lg" fontWeight="bold">
                                    CPT
                                </Text>

                            </Flex>
                        </Box>
                    </CardHeader>
                    <CardBody h="100%">
                        <CptTable />
                    </CardBody>
                </Card>
            </Grid>
        </React.Fragment>
    )
}