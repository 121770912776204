
const storageFilter = (key) => {
    const storageFilters = window.localStorage.getItem(key)
    if (storageFilters) {
        const filters = JSON.parse(storageFilters)

        return filters
    }

    return {}

}

const cptFilter = () => {
    const storageStatus = window.localStorage.getItem('@cpt_filters')
    if (storageStatus) {
        const { status } = JSON.parse(storageStatus)
        return status
    }

    return null
}

export {
    storageFilter,
    cptFilter,
}