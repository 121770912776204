import { Box, Text } from "@chakra-ui/react";
import Icon from "components/FontAwesomeIcons/Icon";
import AsyncTable from "components/Tables/AsyncTable";
import dateFormat from "functions/dateFormat";
import { usePaginateReports, useReports } from "hooks/reports";
import { useEffect } from "react";
import { REPORTS } from "../../../functions/enums";

const columns = [
	{
		Header: "Link",
		accessor: "url",
		Cell: (props) =>
			props.value ? (
				<Text
					textAlign="left"
					display="flex"
					justifyContent="start"
					_hover={{ color: "notrelife.800" }}
				>
					<a href={props.value} target="_blank">
						<Icon icon="download" />
					</a>
				</Text>
			) : null
	},
	{
		Header: "Nome",
		accessor: "label",
		isLink: true,
	},
	{
		Header: "Status",
		accessor: "status",
		Cell: (props) => (
			<div>{props.value === "generated" ? "Gerado" : "Processando"}</div>
		),
	},
	{
		Header: "Tipo",
		accessor: "report_tag",
		Cell: (props) => <div>{REPORTS.find((i) => i.tag === props.value)?.label || props.value}</div>,
	},
	{
		Header: "Criado em",
		accessor: "created_at",
		Cell: (props) => <div>{dateFormat(props.value)}</div>,
	},
];

const ReportTable = () => {
	const { rows, perPage, page, total, currentPage } = useReports();
	const [paginateCptPage, { loading }] = usePaginateReports();

	const fetchData = ({ pageSize, pageIndex }) => {
		paginateCptPage({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
			},
		});
	};

	document.addEventListener("report-notification", function (e) {
		fetchData({})
	});

	return (
		<AsyncTable
			columns={columns}
			isLoaded={!loading}
			fetchData={fetchData}
			size={perPage}
			data={!loading ? rows : [{}, {}, {}, {}]}
			total={total || 0}
			asyncPage={currentPage || 1}
			withoutActions={true}
			baseRoute={"reports"}
		/>
	);
};

export default ReportTable;
