import { CommitmentsProvider as Provider } from "hooks/commitments"
import { FilterProvider } from "hooks/useFilter"
import { ManagerQueryProvider } from "services/manager"

const CommitmentsProvider = ({ children }) => {
    return (
        <FilterProvider target="commitment">
            <ManagerQueryProvider>
                <Provider>
                    {children}
                </Provider>
            </ManagerQueryProvider>
        </FilterProvider>
    )
}

export default CommitmentsProvider
