import React from "react";
import { Box, Button, Flex, Grid, Skeleton, Text, } from "@chakra-ui/react";

import BeneficiaryAssestmentTable from "./BeneficiaryAssessmentTable";
import Icon from "components/FontAwesomeIcons/Icon";
import { useBeneficiaries } from "hooks/beneficiaries";

const BeneficiaryHealthStatment = () => {
    const { beneficiary } = useBeneficiaries();
    const healthStatement = beneficiary?.healthStatement;

    const downloadFile = () => {
        const fileUrl = healthStatement.url_document;
        window.open(fileUrl, '_blank')
    }

    return (
        <Flex width="100%" height="100%" direction="column">
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="20px"
                width="100%"
            >
                {
                    !beneficiary ?
                        <Box width="100%">
                            <Flex width="100%" fontSize="15px">
                                <Icon icon="note" margin="25" size="xl" />
                                <Grid
                                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                    templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                                    gap="6px"
                                    fontSize="20px"
                                    width="100%"
                                >
                                    <Skeleton isLoaded={beneficiary} height="27px" />
                                    <Skeleton isLoaded={beneficiary} height="30px" />
                                </Grid>
                            </Flex>
                        </Box>
                        :
                        !healthStatement ?
                            <Text fontSize="lg" fontWeight="bold">
                                Formulário ainda não foi respondido
                            </Text>
                            :
                            <Box position="relative" pt="16px">
                                {
                                    healthStatement.url_document &&
                                    <Button
                                        background="notrelife.800"
                                        _hover={{ background: "notrelife.700" }}
                                        color="white"
                                        size="sm"
                                        position="absolute"
                                        top="0"
                                        right="0" leftIcon={<Icon icon="download" />}
                                        onClick={() => downloadFile()}
                                    >Baixar PDF</Button>
                                }
                                <BeneficiaryAssestmentTable assessment={healthStatement} />
                            </Box>
                }
            </Grid>
        </Flex>
    )
}

export default BeneficiaryHealthStatment
