import { ApolloProvider } from '@apollo/client';
import { getToken } from 'functions/token';
import client from "./client"

const DashboardsQueryProvider = ({ children }) => {
    const token = getToken();
    return (
        <ApolloProvider client={client(token)}>
            {children}
        </ApolloProvider>
    )
}

export {
    DashboardsQueryProvider
}