import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {padding} from "variables/nivo/bar";
import {useColorMode} from "@chakra-ui/react";
import {monochromeMainColors} from "variables/colors";
import {axisLeft, axisTop, labelTextColor, themeDark, themeLight, tooltip} from "variables/nivo/default";

function genInfo(data) {
    let ret = [];
    if (!!data) {
        data = data[0];
        ret.push({name: "Abaixo do Peso", total: data.underweight});
        ret.push({name: "Peso Normal", total: data.normal_weight});
        ret.push({name: "Excesso de Peso", total: data.overweight});
        ret.push({name: "Obesidade Grau 1", total: data.obesity_grade_one});
        ret.push({name: "Obesidade Grau 2", total: data.obesity_grade_two});
        ret.push({name: "Obesidade Grau 3", total: data.obesity_grade_three});
    }
    return ret;
}

const emptyData = [{name: "", total: ""},]

const IMC = ({data}) => {
    if (!data) data = emptyData; else data = genInfo(data);
    const {colorMode} = useColorMode();

    return (<ResponsiveBar
            data={data}
            keys={["total"]}
            indexBy="name"
            layout="vertical"
            margin={{top: 20, right: 20, bottom: 10, left: 50}}
            padding={padding}
            colors={monochromeMainColors}
            labelSkipWidth={11}
            theme={colorMode === "light" ? themeLight : themeDark}
            axisBottom={null}
            axisTop={axisTop}
            axisLeft={axisLeft}
            labelTextColor="#FFF"
            tooltip={({indexValue, value}) => tooltip(indexValue, value)}
        />)
};

export default IMC
