import { Button, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import { useEvents } from 'hooks/beneficiaryEvents';
import { useEffect, useState } from 'react'

const AssessmentModal = ({ isOpen, onClose, assessment, setAssessment }) => {
    const [confirmation, setConfirmation] = useState(false);
    const { currentForm, pendingAssessments, dispatch } = useEvents();
    const toast = useToast()

    const assessmentId = assessment?.id || null

    const handleClose = () => {
        setConfirmation(false);
        onClose()
        setAssessment({});
    }

    const handleConfirmation = () => {
        if (!currentForm.url) handleClose();
        else setConfirmation(true);
    }

    const handleFinish = () => {
        if (!toast.isActive("finishForm")) {
            toast({
                id: "finishForm",
                status: "success",
                title: "Formulário finalizado com sucesso!"
            })
        }
        dispatch({ type: "REMOVE_PENDING", data: currentForm.id })
        dispatch({ type: "SET_CURRENT_FORM", data: {} })
        handleClose();
    }

    useEffect(() => {
        window.addEventListener("message", function (message) {
            if (message.origin === process.env.REACT_APP_FORM_BUILDER) {
                const { data: messageData } = message;
                if (messageData.success) handleFinish();
            }
        });
        return () => {
            window.removeEventListener("message", () => { });
        };
    }, [currentForm]);

    return (
        <>
            <Modal
                isOpen={isOpen}
                isCentered
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{currentForm.name}</ModalHeader>
                    <ModalBody>
                        {
                            !currentForm.url ? "Aguarde..." :
                                <iframe
                                    style={{ visibility: "initial" }}
                                    height="500"
                                    width="100%"
                                    src={currentForm.url}
                                    title={currentForm.name}
                                />
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="gray" onClick={handleConfirmation}>Fechar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {
                <Modal
                    isOpen={confirmation}
                    isCentered
                    size="md"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody>
                            <Text fontSize="lg">
                                Deseja fechar o formulário? Se ainda não tiver finalizado o preenchimento, clique em "Salvar" no formulário.
                            </Text>
                        </ModalBody>
                        <ModalFooter>
                            <HStack spacing={4}>
                                <Button colorScheme="gray" onClick={handleClose}>Fechar</Button>
                                <Button colorScheme="orange" onClick={() => setConfirmation(false)}>Voltar</Button>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
        </>
    )
}

export default AssessmentModal