const ACTION_TYPES = {
    SET_AWAITING: "SET_AWAITING",
    SET_PENDING: "SET_PENDING",
    SET_CURRENT_FORM: "SET_CURRENT_FORM",
    UPDATE_PENDING: "UPDATE_PENDING",
    REMOVE_AWAITING: "REMOVE_AWAITING",
    REMOVE_PENDING: "REMOVE_PENDING",
}

const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_AWAITING: {
            return {
                ...state,
                awaitingAssessments: action.data
            }
        }
        case ACTION_TYPES.SET_PENDING: {
            return {
                ...state,
                pendingAssessments: action.data
            }
        }
        case ACTION_TYPES.SET_CURRENT_FORM: {
            return {
                ...state,
                currentForm: action.data
            }
        }
        case ACTION_TYPES.UPDATE_PENDING: {
            return {
                ...state,
                pendingAssessments: state.pendingAssessments.map(st => {
                    if (st.event.id == action.data.id) {
                        st.event.id = action.data.newId
                        st.event.scheduled_to = action.data.scheduled_to;
                        st.event.type = action.data.type
                    }
                    return st
                })
            }
        }
        case ACTION_TYPES.REMOVE_AWAITING: {
            return {
                ...state,
                awaitingAssessments: state.awaitingAssessments.filter(st => st.id !== action.data)
            }
        }
        case ACTION_TYPES.REMOVE_PENDING: {
            return {
                ...state,
                pendingAssessments: state.pendingAssessments.filter(st => st.id !== action.data)
            }
        }
        default: {
            return state;
        }
    }
}


export {
    reducers,
    ACTION_TYPES
}
