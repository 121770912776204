
import { ManagerQueryProvider } from "services/manager";
import { RegionalsProvider } from "../hooks/regionals";

export const RegionalsHookQueryProvider = ({ children }) => {
    return (
        <ManagerQueryProvider>
            <RegionalsProvider>
                {children}
            </RegionalsProvider>
        </ManagerQueryProvider>
    )
}
