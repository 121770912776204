import React from 'react'

import { 
  Button,
  Flex,
  Modal, 
  ModalBody, 
  ModalCloseButton, 
  ModalContent, 
  ModalFooter, 
  ModalHeader, 
  ModalOverlay, 
  Spinner, 
  Textarea, 
  toast, 
  useToast 
} from '@chakra-ui/react'

import AuditTable from './AuditTable'


const AuditModal = ({open, close, data, isLoading = false}) => {

  return (
    <Modal
        isCentered
        onClose={() => close()}
        isOpen={open}
        motionPreset='slideInBottom'
        size="lg"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registro de Atividades</ModalHeader>
          <ModalCloseButton />
          <ModalBody
              maxH="600px"
              overflowY="auto">
            <Flex 
              width="100%" 
              height="100%" 
              justifyContent="center"
              align="center"
            >
              {
                isLoading ?
                  <Spinner size="lg"/>
                :
                data &&
                <AuditTable
                  data={data}
                />
              }
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button 
              bgColor={"notrelife.800"}
              color="#FFF"
              variant="no-hover"
              onClick={() => close()}
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default AuditModal