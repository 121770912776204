const ACTION_TYPES = {
  LIST: 'LIST_NOTES',
  VIEW: 'VIEW_NOTE',
  ADD: 'ADD_NOTE',
  UPDATE: 'UPDATE_NOTE',
  REMOVE: 'REMOVE_NOTE',
}

const reducers = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return { 
        ...state, 
        notes: action.data.notes
      }
    }
    case ACTION_TYPES.ADD: {
      return { 
        ...state, 
        notes: [ ...state.notes, action.data ] 
      }
    }
    default: {
      return state
    }
  }
}

export {
  reducers,
  ACTION_TYPES
}