import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Switch,
	Text,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import RegionalFormCreate from "./RegionalFormCreate";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import AsyncPlansMultiselect from "./AsyncPlansMultiselect";
import AsyncProfessionalsMultiselect from "./AsyncProfessionalsMultiselect";
import states from "variables/states";
import cities from "variables/cities";
import { getAddress } from "functions/getAddress";
import { useEffect } from "react";

const schema = yup.object().shape({
	name: yup.string().required("O campo Nome é obrigatório"),
	beneficiary_limit: yup
		.string()
		.required("O campo Total de vidas é obrigatório"),
	cep: yup.string().required("O campo CEP é obrigatório"),
	state_id: yup.string().required("O campo Estado é obrigatório"),
	city_id: yup.string().required("O campo Cidade é obrigatório"),
	district: yup.string().required("O campo Bairro é obrigatório"),
	address: yup.string().required("O campo Endereço é obrigatório"),
	address_number: yup.string().required("O campo Número é obrigatório"),
});

const ModalCreateRegional = ({ onClose, isOpen, onSubmit, loading }) => {
	const methods = useForm({
		mode: "onBlur",
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	const {
		control,
		register,
		reset,
		handleSubmit,
		formState: { errors },
		watch,
		getValues,
		setValue,
	} = methods;

	const toast = useToast();

	const wState = watch("state_id", null);
	const wIsAdmin = watch("is_admin", false)
	const wSendWhatsapp = watch("send_whatsapp", false)

	useEffect(() => {
		if(wIsAdmin) setValue('send_whatsapp', false)
	}, [wIsAdmin])

	const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
	const textColor = useColorModeValue("gray.700", "white");

	const findAddress = async (zipcode) => {
		const address = await getAddress(zipcode);
		if (!address) {
			toast({
				title: "CEP não encontrado.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} else {
			setValue(
				"state_id",
				String(states.find((state) => state.initials == address.uf).id)
			);
			setValue(
				"city_id",
				String(cities.find((city) => city.name == address.localidade).id)
			);
			setValue("district", address.bairro);
			setValue("address", address.logradouro);
		}
	};

	return (
		<Modal
			isCentered
			onClose={() => {
				reset(), onClose(false);
			}}
			isOpen={isOpen}
			motionPreset="slideInBottom"
			size={"xl"}
			autoFocus={false}
		>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>
							<Flex direction="column">
								<Text
									color={textColor}
									fontSize="lg"
									fontWeight="bold"
									mb="3px"
								>
									Criar nova regional
								</Text>
								<Text color="gray.400" fontWeight="normal" fontSize="sm">
									Preencha os campos para realizar a criação
								</Text>
							</Flex>
						</ModalHeader>
						<ModalBody>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel fontWeight="bold" fontSize="xs">
											Nome
										</FormLabel>
										<Input
											focusBorderColor={inputHover}
											{...register("name")}
										/>
										{errors.name && (
											<FormErrorMessage>{errors.name.message}</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.beneficiary_limit}>
										<FormLabel fontWeight="bold" fontSize="xs">
											Total de vidas Permitidas
										</FormLabel>
										<NumberInput focusBorderColor={inputHover}>
											<NumberInputField
												{...register("beneficiary_limit")}
												name={"beneficiary_limit"}
												id={"beneficiary_limit"}
											/>
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
										{errors.beneficiary_limit && (
											<FormErrorMessage>
												{errors.beneficiary_limit.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl>
										<FormLabel fontWeight="bold" fontSize="xs">
											Associar Planos
										</FormLabel>
										<AsyncPlansMultiselect />
									</FormControl>
									<FormControl>
										<FormLabel fontWeight="bold" fontSize="xs">
											Associar Profissionais
										</FormLabel>
										<AsyncProfessionalsMultiselect />
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
									gap="24px"
									mt="24px"
								>
									<FormControl isInvalid={errors.cep}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											CEP
										</FormLabel>
										<Input
											{...register("cep")}
											as={InputMask}
											mask="99999-999"
											onChange={({ target }) =>
												target.value.replace("_", "").length == 9 &&
												findAddress(target.value)
											}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.cep && (
											<FormErrorMessage>{errors.cep.message}</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.state_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Estado
										</FormLabel>
										<Select
											{...register("state_id")}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
											placeholder="Selecione uma opção..."
											color="gray.400"
										>
											{states.map((state) => (
												<option value={state.id}>{state.name}</option>
											))}
										</Select>
										{errors.state_id && (
											<FormErrorMessage>
												{errors.state_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.city_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Cidade
										</FormLabel>
										<Select
											{...register("city_id")}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
											placeholder={
												!wState || wState == ""
													? "Selecione um estado..."
													: "Selecione uma opção..."
											}
											color="gray.400"
											disabled={!wState || wState == ""}
										>
											{cities
												.filter((city) => city.state_id == wState)
												.map((city) => (
													<option value={city.id}>{city.name}</option>
												))}
										</Select>
										{errors.city_id && (
											<FormErrorMessage>
												{errors.city_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.district}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Bairro
										</FormLabel>
										<Input
											{...register("district")}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.district && (
											<FormErrorMessage>
												{errors.district.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.address}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Endereço
										</FormLabel>
										<Input
											{...register("address")}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.address && (
											<FormErrorMessage>
												{errors.address.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.address_number}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Número
										</FormLabel>
										<Input
											{...register("address_number")}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.address_number && (
											<FormErrorMessage>
												{errors.address_number.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.complement}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Complemento
										</FormLabel>
										<Input
											{...register("complement")}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
									</FormControl>
								</Grid>
								<Grid
									templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
									gap="24px"
									mt="24px"
								>
									<FormControl isInvalid={errors.complement}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Regional Administrativa
										</FormLabel>
										<Switch {...register("is_admin")} colorScheme='green' />
									</FormControl>
									<FormControl isInvalid={errors.complement}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Automação de Whatsapp
										</FormLabel>
										<Switch isChecked={(!wIsAdmin && wSendWhatsapp)} {...register("send_whatsapp")} colorScheme='green' isDisabled={wIsAdmin} />
									</FormControl>
								</Grid>
							</Flex>
						</ModalBody>
						<ModalFooter>
							<Button
								backgroundColor="notrelife.800"
								isLoading={loading}
								color="white"
								variant="no-hover"
								type="submit"
							>
								Salvar
							</Button>
						</ModalFooter>
					</ModalContent>
				</form>
			</FormProvider>
		</Modal>
	);
};

export default ModalCreateRegional;
