import { SocketProvider } from 'hooks/socket'
import { AuthProvider as Provider } from 'hooks/useAuth'
import NotificationProvider from './notificationProvider'

const AuthProvider = ({ children, user }) => {
	return (
		<Provider user={user}>
			<SocketProvider>
				<NotificationProvider>
					{children}
				</NotificationProvider>
			</SocketProvider>
		</Provider>
	)
}

export default AuthProvider
