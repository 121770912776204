import { usePaginateRegionals, useRegionals } from "../../../hooks/regionals";
import AsyncTable from "../../../components/Tables/AsyncTable";
import { Badge, Stack } from '@chakra-ui/react'
import { useAuth } from "hooks/useAuth";

const columns = [
    { Header: "Nome", accessor: "name" },
    { Header: "Total de planos", accessor: "plansCount" },
    { Header: "Profissionais na regional", accessor: "professionalsCount" },
    { Header: "Limite de Beneficiarios", accessor: "beneficiary_limit" },
];

const RegionalsTable = ({ onItemClick }) => {
    const { loading, rows, total, perPage, currentPage } = useRegionals()
    const [paginateUsers, { loading: reloading }] = usePaginateRegionals()

    const fetchData = ({ pageSize, pageIndex, search }) => {
        if (pageSize != perPage)
            pageIndex = 1
        paginateUsers({
            variables: {
                first: pageSize || 30,
                page: pageIndex || 1,
                search: search || ''
            }
        })
    }

    return (
        <AsyncTable
            isLoaded={!loading && !reloading}
            fetchData={fetchData}
            clickItem={onItemClick}
            data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
            columns={columns}
            total={total}
            size={perPage}
            asyncPage={currentPage}
            searchByName
            baseRoute="regionais"
            noView
            noDelete
        />
    );
}


export default RegionalsTable;
