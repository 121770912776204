import React from "react";
import { createContext, useContext, useReducer } from "react";
import { GET_COMPANIES_PAGINATE, GET_COMPANY } from "graphql/companies";
import { useLazyQuery, useQuery } from "@apollo/client";

import { ACTION_TYPES, reducers } from "./reducers";

const CompaniesStateContext = createContext();
const CompaniesDispatchContext = createContext();

const CompaniesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
    orderBy: "created_at",
  });

  return (
    <CompaniesStateContext.Provider value={{ ...state }}>
      <CompaniesDispatchContext.Provider value={dispatch}>
        {children}
      </CompaniesDispatchContext.Provider>
    </CompaniesStateContext.Provider>
  );
};

const useCompanies = () => {
  const context = useContext(CompaniesStateContext);

  if (!context)
    throw new Error("useCompanies must be used within an CompaniesProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(CompaniesDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a CompaniesProvider");

  return dispatch;
};

const usePaginateCompanies = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_COMPANIES_PAGINATE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.companiesPaginate });
    },
  });
};

const useCompany = (id) => {
  const dispatch = useDispatch();

  return useQuery(
    GET_COMPANY,
    {
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.SELECT, data: data.company });
      },
    }
  );
};

export { CompaniesProvider, useCompanies, usePaginateCompanies, useCompany };
