export const commitmentRefusalMotives = [
  {
    value: "not_interested",
    label: "Não deseja agendar",
  },
  {
    value: "private_doctor",
    label: "Possui médico particular",
  },
  {
    value: "specialist_pass",
    label: "Passa com especialista",
  },
];
