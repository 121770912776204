import { DashboardsProvider } from "hooks/dashboards/useDashboards";
import { FilterProvider } from "hooks/useFilter";
import { DashboardsQueryProvider } from "services/dashboard";

const FilteredLifesDashboardProvider = ({ children }) => {
	return (
		<FilterProvider target="dashboard">
			<DashboardsQueryProvider>
				<DashboardsProvider>
					{children}
				</DashboardsProvider>
			</DashboardsQueryProvider>
		</FilterProvider>
	)
}

export default FilteredLifesDashboardProvider;
