const ACTION_TYPES = {
  LIST: "LIST",
  UPDATE: 'UPDATE',
}

const reducers = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return {
          ...state,
          rows: action.data.data,
          page: action.data.paginatorInfo.currentPage,
          perPage: action.data.paginatorInfo.perPage,
          total: action.data.paginatorInfo.total,
          currentPage: action.data.paginatorInfo.currentPage,
      }
    }
    case ACTION_TYPES.UPDATE: {
      return {
        ...state,
        rows: state?.rows?.length > 0 ? state.rows.map((item) => (item.id === action.data.plan.id ? action.data.plan : item)) : [],
      }
    }
    default: {
      return state
    }
  }
}

export {
  reducers,
  ACTION_TYPES
}