import { API } from '../api'

export async function GET_EVENT(token) {
	const { data } = await API.get(`api/calendar/init?key=${token}`);

	return data;
}

export async function GET_AVAILABLE_DATES(token, regional_id, role, professional, type) {
	const { data } = await API.get(`api/calendar/available-dates?key=${token}&regional_id=${regional_id}&role=${role}&professional=${professional}&type=${type}`);

	return data;
}

export async function GET_SPLIT_TIME(token, payload) {
	const { data } = await API.post(`api/calendar/split-time?key=${token}`, payload);

	return data;
}

export async function POST_EVENT(token, payload) {
	const { data } = await API.post(`api/calendar/save?key=${token}`, payload);

	return data;
}