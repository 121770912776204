import { useEffect, useState } from "react";
import { roleSuper } from "variables/roles";
import AsyncTable from "components/Tables/AsyncTable";
import {
  useCommitments,
  useRefusalCommitments,
} from "hooks/commitmentsManagement";
import { Link } from "react-router-dom";
import CommitmentsAudit from "./CommitmentsAudit";
import { useAuth } from "hooks/useAuth";
import { differenceInYears } from "date-fns";

import { findRiskDS } from "functions/getStratification";
import { findRiskPA } from "functions/getStratification";

const columns = [
  {
    Header: "Motivo",
    accessor: "type_ineligibility",
    Cell: (props) => {
      if (props.value === "not_necessary") return "Não acha necessário";
      if (props.value === "no_compromise") return "Não quer comprometimento";
      if (props.value === "no_directions")
        return "Não compartilha com os direcionamentos da equipe";
      return "Reiterada Ausência";
    },
  },
  {
    Header: "Beneficiario",
    accessor: "name",
    Cell: (props) => (
      <Link to={`../administracao/gestao-vidas/${props?.row?.original?.id}`}>
        {props.value}
      </Link>
    ),
  },
  {
    Header: "Celular",
    accessor: "phone",
  },
  {
    Header: "Plano",
    accessor: "plan[0].name",
  },
  {
    Header: "Idade",
    accessor: "birthdate",
    Cell: (props) => (
      <div>
        {props?.value
          ? differenceInYears(new Date(), new Date(props.value))
          : null}
      </div>
    ),
  },
  {
    Header: "Estratificação DS",
    accessor: "stratification_ds",
    Cell: (props) => <div>{findRiskDS(props?.row?.original?.assessments)}</div>,
  },
  {
    Header: "Estratificação PA",
    accessor: "stratification_pa",
    Cell: (props) => <div>{findRiskPA(props?.row?.original?.assessments)}</div>,
  },
];

const RefusalTable = ({
  plan = null,
  year = null,
  month = null,
  professional = null,
  assessment = null,
  riskDS = null,
  riskPA = null,
  age = null,
  handleNoteModal,
  handleRegisterNoteModal,
  handleEventModal,
}) => {
  const { user } = useAuth();

  const { commitments, total, perPage, currentPage } = useCommitments();
  const [loadComittments, { loading }] = useRefusalCommitments();

  const [auditId, setAuditId] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchData = ({ pageSize, pageIndex, search }) => {
    const variables = {
      first: pageSize,
      page: pageIndex,
      input: {
        plan: plan,
        year: year,
        month: month,
        professional: professional,
        assessmentType: assessment,
        name: search || "",
        riskDS: riskDS,
        riskPA: riskPA,
        age: age,
      },
    };
    loadComittments({ variables: variables });
  };

  useEffect(() => {
    const variables = {
      input: {
        plan: plan,
        year: year,
        month: month,
        professional: professional,
        assessmentType: assessment,
        riskDS: riskDS,
        riskPA: riskPA,
        age: age,
      },
    };
    loadComittments({ variables: variables });
  }, [plan, month, professional, assessment, riskDS, riskPA, age]);

  const handleAudit = (id) => {
    setAuditId(id);
    setOpen(true);
  };

  const customActions = [
    {
      label: "Registro de Atividades",
      icon: "documents",
      visible: () => {
        return user?.role === roleSuper;
      },
      callback: (id) => {
        handleAudit(id);
      },
    },
    {
      label: "Agendar",
      icon: "calendar",
      visible: (row) => {
        return row?.pending_awaiting_schedules?.length > 0;
      },
      callback: (id, row) => {
        const values = {
          id: id,
          name: row.name,
          type: "schedule",
          events: row?.pending_awaiting_schedules,
        };
        handleEventModal(values);
      },
    },
  ];

  return (
    <>
      <AsyncTable
        searchByName
        noView
        noEdit
        customActions={!loading ? customActions : null}
        isLoaded={!loading}
        fetchData={fetchData}
        data={
          !loading
            ? commitments.sort((a, b) => {
                return new Date(a.scheduled_to) - new Date(b.scheduled_to);
              })
            : [{}, {}, {}, {}]
        }
        columns={columns}
        size={perPage}
        total={total || 0}
        asyncPage={currentPage}
        baseRoute=""
      />
      {open && (
        <CommitmentsAudit
          id={auditId}
          open={open}
          close={() => setOpen(false)}
          type="patient"
        />
      )}
    </>
  );
};

export default RefusalTable;
