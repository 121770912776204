import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_TICKETS_PAGINATE, GET_TICKETS, GET_TICKET, CREATE_TICKET, UPDATE_TICKET } from 'graphql/tickets'
import { ACTION_TYPES, reducers } from './reducers'

const TicketsStateContext = createContext()
const TicketsDispatchContext = createContext()

const TicketsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, {rows: [], first: 15, page: 1})
  
    const { data, loading, error } = useQuery(GET_TICKETS_PAGINATE, {
			fetchPolicy: "no-cache",
      onCompleted: (data) => {
          dispatch({type: ACTION_TYPES.LIST, data: data.ticketsPaginate})
      }
  })
	
    return (
			<TicketsStateContext.Provider value={{ data, loading, error, ...state }}>
					<TicketsDispatchContext.Provider value={dispatch}>{children}</TicketsDispatchContext.Provider>
			</TicketsStateContext.Provider>
    )
}

const useTickets = () => {
	const context = useContext(TicketsStateContext)
	
	if (!context)
		throw new Error('useTickets must be used within an TicketsProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(TicketsDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a TicketsProvider')

	return dispatch
}

const usePaginateTickets = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_TICKETS_PAGINATE, {
			onCompleted: (data) => {
				dispatch({type: ACTION_TYPES.LIST, data: data.ticketsPaginate})
			}
	})
}

const useCreateTicket = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(CREATE_TICKET, {
			onCompleted: (data) => {
				if(onSuccess) onSuccess(data.createTicket)
				dispatch({ type: ACTION_TYPES.ADD, data: data.createTicket })
			},
			onError: (error) => {
				if(onError) onError(error)
			},
	})
}

const useTicket = (id) => {
	const dispatch = useDispatch()

	return useQuery(GET_TICKET , {
		fetchPolicy: "no-cache",
			variables: {
				id: id
			}	
		},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.SELECT, data: data.ticket })
			},
		}
	)
}

const useUpdateTicket = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(UPDATE_TICKET, {
			onCompleted: (data) => {
				if(onSuccess) onSuccess(data.updateTicket)
				dispatch({ type: ACTION_TYPES.UPDATE_TICKET, data: data.updateTicket })
			},
			onError: (error) => {
					if(onError) onError(error?.graphQLErrors[0]?.extensions?.validation)
			},
			refetchQueries: [
					{
							query: GET_TICKETS
					}
			]
	})
}

export {
	TicketsProvider,
	useTickets,
	usePaginateTickets,
	useCreateTicket,
	useTicket,
	useUpdateTicket
}
