import { Box, useStyleConfig } from "@chakra-ui/react";
import { useLayout } from "hooks/layout";
function MainPanel(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("MainPanel", { variant });
  const { sidebarWidth } = useLayout()
  return (
    <Box __css={styles} {...rest} w={{
      base: "100%",
      xl: `calc(100% - ${sidebarWidth}px)`,
    }}>
      {children}
    </Box>
  );
}

export default MainPanel;
