import { gql } from '@apollo/client';

export const GET_TICKETS = gql`
    query GetTickets {
        tickets {
            id
            user_id
            status
            priority
            source
            causes
            created_at
            updated_at
            finished_at
            notified_at
        }
    }
`

export const GET_TICKETS_PAGINATE = gql`
    query GET_TICKETS($first: Int! = 15, $page: Int! = 1, $search: String) {
        ticketsPaginate(first: $first, page: $page, search: $search) {
            paginatorInfo {
                count
                currentPage
                firstItem
                perPage
                lastItem
                total
            }
            data {
                id
                user_id
                status
                priority
                source
                causes
                created_at
                updated_at
                finished_at
                notified_at
            }
        }
    }
`

export const GET_TICKET = gql`
    query GetTicket($id: String!) {
        ticket(id: $id) {
            id
            user_id
            user {
                name
            }
            status
            priority
            source
            causes
            abstract
            documents {
                name
                url
            }
            answer
            created_at
            updated_at
            finished_at
            notified_at
        }
    }
`

export const CREATE_TICKET = gql`
    mutation CreateTicket($input: CreateTicketInput) {
        createTicket(input: $input) {
            id
    }
}
`

export const UPDATE_TICKET = gql`
    mutation UpdateTicket($input: UpdateTicketInput) {
        updateTicket(input: $input) {
            id
        }
    }
`
