import {
	useBeneficiaries,
	usePaginateBeneficiaries,
} from "hooks/beneficiaries";

import dateFormat from "functions/dateFormat";
import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryParamsPage from "functions/queryParamsPage";
import AsyncTable from "components/Tables/AsyncTable";
import { CaretDown } from "@phosphor-icons/react";

const BeneficiariesTable = () => {
	const location = useLocation();
	const {
		page: queryPage,
		size: querySize,
		proposalId: queryProposal,
	} = queryParamsPage(location);
	const [orderBy, setOrderBy] = useState("created_at");

	const handleOrder = (toOrder) => {
		if (orderBy !== toOrder) return setOrderBy(toOrder);
	};

	const { rows, total, perPage, currentPage } = useBeneficiaries();
	const [paginateBeneficiaries, { loading }] = usePaginateBeneficiaries();

	useEffect(() => {
		fetchData({ pageSize: total, pageIndex: currentPage, search: null })
	}, [orderBy])

	const fetchData = ({ pageSize, pageIndex, search }) => {
		// if (pageSize !== perPage) pageIndex = 1;
		paginateBeneficiaries({
			variables: {
				first: pageSize || querySize || 30,
				page: pageIndex || queryPage || 1,
				proposalId: queryProposal || "",
				search: search || "",
				orderBy: orderBy || "created_at",
			},
		});
	};

	const columns = [
		{ Header: "Nome", accessor: "name", isLink: true, disableSortBy: true },
		{ Header: "CPF", accessor: "cpf", disableSortBy: true },
		{ Header: "Plano", accessor: "proposal.plan.name", disableSortBy: true },
		{
			Header: "Status",
			accessor: "active",
			Cell: (props) => (
				<Flex alignItems="center">
					<Box
						backgroundColor={props.value ? "green.500" : "red.500"}
						width={3}
						height={3}
						borderRadius={100}
						mr={2}
					/>{" "}
					{props.value ? "Ativo" : "Cancelado"}
				</Flex>
			),
		},
		{
			Header: "Proposta",
			accessor: "proposal.proposal_number",
			disableSortBy: true,
		},
		{
			Header: (
				<Flex>
					<Text
						_hover={{ cursor: "pointer" }}
						onClick={() => handleOrder("created_at")}
					>
						Cadastrado em
					</Text>
					{orderBy === "created_at" && <CaretDown size={12} weight="fill" />}
				</Flex>
			),
			accessor: "created_at",
			disableSortBy: true,
			Cell: (props) => <div>{dateFormat(props.value)}</div>,
		},
		{
			Header: (
				<Flex>
					<Text
						_hover={{ cursor: "pointer" }}
						onClick={() => handleOrder("proposal.assign_at")}
					>
						Data de Adesão
					</Text>
					{orderBy === "proposal.assign_at" && <CaretDown size={12} weight="fill" />}
				</Flex>
			),
			accessor: "proposal.assign_at",
			disableSortBy: true,
			Cell: (props) => <div>{dateFormat(props.value)}</div>,
		},
	];

	return (
		<AsyncTable
			withoutActions={true}
			isLoaded={!loading}
			fetchData={fetchData}
			size={perPage}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			asyncPage={currentPage}
			searchByName
			baseRoute="gestao-vidas"
			noView
			linkRow
		/>
	);
};

export default BeneficiariesTable;
