const roles = [
    {id: 1, value: 'Administrador', label: 'Administrador'},
    {id: 2, value: 'Consultor_de_Saúde', label: 'Consultor de Saúde'},
    {id: 3, value: 'Enfermeiro', label: 'Enfermeiro'},
    {id: 4, value: 'Recepcionista', label: 'Recepcionista'},
    {id: 5, value: 'Médico_CPT', label: 'Médico CPT'},
    {id: 6, value: 'Super_Admin', label: 'Super Admin'}
];

const roleAdmin = roles[0].value;
const roleConsu = roles[1].value;
const roleEnfer = roles[2].value;
const roleRecep = roles[3].value;
const roleMedic = roles[4].value;
const roleSuper = roles[5].value;

export {
    roles, roleAdmin, roleConsu, roleEnfer, roleRecep, roleMedic, roleSuper
}