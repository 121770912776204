import React from 'react'

import { useUsers, useDeleteUser } from 'hooks/users';

import { 
  Button,
  Modal, 
  ModalBody, 
  ModalCloseButton, 
  ModalContent, 
  ModalFooter, 
  ModalHeader, 
  ModalOverlay, 
  useToast 
} from '@chakra-ui/react'

const NoteModal = ({beneficiary, open, setNoteModal}) => {

  return (
    <Modal
        isCentered
        onClose={() => setNoteModal(false)}
        isOpen={open}
        motionPreset='slideInBottom'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent padding="12px">
          <ModalHeader>Nota de {beneficiary?.name || null}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {beneficiary?.notes || null}
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' onClick={() => setNoteModal(false)}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default NoteModal