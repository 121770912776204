import { Box, Grid, Text } from '@chakra-ui/react'

import { useFilter } from 'hooks/useFilter'
import { useAccumulatedDashboard } from 'hooks/dashboards/useDashboards'

import AverageAccumulated from './AverageAccumulated'
import RegionalsGoal from './RegionalsGoal'
import RisksHealthDeclaration from './RisksHealthDeclaration'
import HealthDeclarationPA from './HealthDeclarationPA'
import SchedulesPA from './SchedulesPA'

import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import FiltersBox from '../components/FiltersBox'
import ChartContainer from 'components/Charts/ChartContainer'

const Accumulated = () => {
    const { filters } = useFilter();
    const { data, loading } = useAccumulatedDashboard(filters)

    return (
        <>
            <Grid
                templateColumns={{ sm: "1fr", md: "1fr" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="12px"
                pt={{ sm: "125px", lg: "160px" }}
            >
                <Card px="0px" pb="0px" minHeight="100px">
                    <CardHeader mb="34px" px="22px">
                        <Text fontSize="lg" fontWeight="bold">
                            Filtros
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <FiltersBox yearMonthFilter />
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            {
                loading ? <Text>Carregando...</Text> :
                    <>
                        <Grid
                            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                            gap="12px"
                            pt={{ sm: "12px", lg: "12px" }}
                        >
                            <ChartContainer minHeight="550px" title="Taxa de Primeiro acolhimento Realizado +49">
                                <AverageAccumulated data={data.dashboard_accumulated.regionalsGoals_49} goal={95} />
                            </ChartContainer>
                            <ChartContainer minHeight="550px" title="Taxa de Primeiro acolhimento Realizado -48">
                                <AverageAccumulated data={data.dashboard_accumulated.regionalsGoals_48} goal={70} />
                            </ChartContainer>
                        </Grid>
                        <Grid
                            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                            gap="12px"
                            pt={{ sm: "12px", lg: "12px" }}
                        >
                            <ChartContainer minHeight="550px" overflowY="auto" title="Regionais X Metas de Primeiro Acolhimento +49">
                                <RegionalsGoal data={data.dashboard_accumulated.regionalsGoals_49} goal={95} />
                            </ChartContainer>
                            <ChartContainer minHeight="550px" overflowY="auto" title="Regionais X Metas de Primeiro Acolhimento -48">
                                <RegionalsGoal data={data.dashboard_accumulated.regionalsGoals_48} goal={70} />
                            </ChartContainer>
                        </Grid>
                        <Grid
                            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                            gap="12px"
                            pt={{ sm: "12px", lg: "12px" }}
                        >
                            <ChartContainer minHeight="400px" title="Risco DS X Primeiro Acolhimento">
                                <RisksHealthDeclaration data={data.dashboard_accumulated.risks} />
                            </ChartContainer>
                        </Grid>
                        <Grid
                            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                            gap="12px"
                            pt={{ sm: "12px", lg: "12px" }}
                        >
                            <ChartContainer minHeight="500px" overflowY="auto" title="Declaração de Saúde x Primeiro Acolhimento +49">
                                <HealthDeclarationPA data={data.dashboard_accumulated.regionalsGoals_49} />
                            </ChartContainer>
                            <ChartContainer minHeight="500px" overflowY="auto" title="Declaração de Saúde x Primeiro Acolhimento -48">
                                <HealthDeclarationPA data={data.dashboard_accumulated.regionalsGoals_48} />
                            </ChartContainer>
                        </Grid>
                        <Grid
                            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                            gap="12px"
                            pt={{ sm: "12px", lg: "12px" }}
                        >
                            <ChartContainer minHeight="550px" title="PAs Agendados X Aguardando agendamento +49">
                                <SchedulesPA data={data.dashboard_accumulated.schedules_49} />
                            </ChartContainer >
                            <ChartContainer minHeight="550px" title="PAs Agendados X Aguardando agendamento -48">
                                <SchedulesPA data={data.dashboard_accumulated.schedules_48} />
                            </ChartContainer>
                        </Grid>
                    </>
            }
        </>
    )
}

export default Accumulated