import React from 'react'

import { useRemoveLifePlan } from 'hooks/beneficiaries';

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast
} from '@chakra-ui/react'

const BeneficiaryLifePlanDeleteModal = ({ open, setModal, id }) => {
	const toast = useToast()

	const [removeLifePlan, { loading }] = useRemoveLifePlan({
		onSuccess: () => {
			toast({
				title: 'Plano removido com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			}),
				setModal(false)
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			})
		},
	})

	const onRemove = () => {
		removeLifePlan({ variables: { id: id } });
	}

	return (
		<Modal
			isCentered
			onClose={() => setModal(false)}
			isOpen={open}
			motionPreset='slideInBottom'
			autoFocus={false}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Deletar Plano de Vida</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Por favor, confirme a ação de exclusão.
				</ModalBody>
				<ModalFooter>
					<Button mr={3} variant='ghost' onClick={() => setModal(false)}>Cancelar</Button>
					<Button
						isLoading={loading}
						colorScheme='red'
						onClick={() => onRemove()}
					>
						Deletar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default BeneficiaryLifePlanDeleteModal