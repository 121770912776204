import {
    Flex, Grid, Icon, Select, Skeleton, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import bgCardReports from "assets/img/background-card-reports.png";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { useGetProfessionalsByRegional } from "hooks/calendar";
import { useGetProfessionals } from "hooks/calendar";
import { useStatistics } from "hooks/calendar/statistics";
import { useEffect, useState } from "react";
import { FaUser, FaCalendar, FaPhone } from "react-icons/fa";
import { useParams } from "react-router-dom";
import ReportsCard from "./components/ReportsCard";
import ScheduleTable from "./components/ScheduleTable";
import Statistcs from "./components/Statistcs";
import {roleConsu, roleEnfer} from "../../variables/roles";

export default () => {
    const { day } = useParams()
    const {loadStatistics, statistics, loading, error } = useStatistics()
    const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
    const [getProfessionals, { data: professionals, loading: loadingProfessionals }] = useGetProfessionals()

    const [regional, setRegional] = useState(null);
    const [role, setRole] = useState(null);
    const [professional, setProfessional] = useState(null);

    useEffect(() => {
        const variables = {
            date: day,
        }

        loadStatistics({
            variables: variables
        })
        getProfessionals()
    }, []);

    useEffect(() => {
        const variables = {
            date: day,
            professional: professional || null,
            role: role || null,
        }

        loadStatistics({
            variables: variables
        })
    }, [professional, role])

    if (error) return `Submission error! ${error.message}`;

    const handleProfessionals = () => {
        let pro = professionals?.professionals;
        if (!!role)  {
            pro = pro?.filter(p => p?.user?.role === role);
        } else {
            pro = pro?.filter(p => p?.user?.role === roleConsu || p?.user?.role === roleEnfer);
        }
        return pro?.map(p => (<option value={p?.id}>{p?.user?.name}</option>))
    }

    return (
        <Flex direction="column" pt={{ base: "150px", lg: "75px" }}>
            <Card mb="24px">
                <CardHeader>
                    <Flex>
                        <Stack
                            direction="column"
                            spacing="12px"
                            mr="12px"
                        >
                            <Text fontSize="sm" color="gray.400" fontWeight="normal">
                            Filtrar por tipo de profissional
                            </Text>
                            <Select
                            value={role}
                            focusBorderColor={inputHover}
                            placeholder="Selecione uma opção..."
                            onChange={({target}) => setRole(target.value)}
                            color="gray.500"
                            size="sm"
                            borderRadius="12px"
                            cursor="pointer"
                            >
                                <option value={roleConsu}>Consultor de Saúde</option>
                                <option value={roleEnfer}>Enfermeiro</option>
                            </Select>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing="12px"
                        >
                            <Text fontSize="sm" color="gray.400" fontWeight="normal">
                            Filtrar por profissional
                            </Text>
                            <Skeleton isLoaded={!loadingProfessionals}>
                            <Select
                                value={professional}
                                focusBorderColor={inputHover}
                                placeholder={"Selecione uma opção..."}
                                onChange={({target}) => setProfessional(target.value)}
                                color="gray.500"
                                size="sm"
                                borderRadius="12px"
                                cursor="pointer"
                                >
                                {handleProfessionals()}
                                </Select>
                            </Skeleton>
                        </Stack>
                    </Flex>
                </CardHeader>
            </Card>
            {
                loading ?
                `Carregando...` 
                :
                <>
                    <Grid templateColumns={{ md: "repeat(2, 1fr)" }} gap="24px" mb="24px">
                        <Grid
                            templateColumns={{ md: "repeat(2, 1fr)" }}
                            templateRows={{ md: "repeat(2, 1fr)" }}
                            gap="24px">
                            <ReportsCard
                                backgroundImage={bgCardReports}
                                title={"Total de compromissos"}
                                number={statistics.commitments || 0}
                                icon={<Icon as={FaCalendar} w="25px" h="25px" color="blue.900" />}
                            />
        
                            <ReportsCard
                                backgroundImage={bgCardReports}
                                title={"Presenciais"}
                                number={statistics.facetoface || 0}
                                icon={<Icon as={FaUser} w="25px" h="25px" color="blue.900" />}
                            />
        
                            <ReportsCard
                                backgroundImage={bgCardReports}
                                title={"Tele Atendimento"}
                                number={statistics.teleservice || 0}
                                icon={<Icon as={FaPhone} w="25px" h="25px" color="blue.900" />}
                            />
                        </Grid>
                        <Statistcs statistics={statistics}/>
                    </Grid>
                    <ScheduleTable 
                        regional={regional}
                        role={role}
                        professional={professional}
                    />
                </>
            }
        </Flex>
    )
}
