export const HEALTH_STATEMENT = "Declaração de Saúde";
export const FIRST_RECEPTION = "Primeiro Acolhimento";
export const ACCOMPANIMENT = "Acompanhamento";

export const FORM_TYPE_LIST = [
  HEALTH_STATEMENT,
  FIRST_RECEPTION,
  ACCOMPANIMENT,
];

/**
 * CPT ENUMS
 */

export const STATUS_CPT_AWAITING_CID_DISEASES = "awaiting_cid";
export const STATUS_CPT_AWAITING_ASSIGN_AT = "awaiting_assign_at";
export const STATUS_CPT_MEDICAL_ANALYSIS = "medical_analysis";
export const STATUS_CPT_MEDICAL_ANALYSIS_DOCUMENTS =
  "medical_analysis_documents";
export const STATUS_CPT_NOT_SIGNED = "not_signed";
export const STATUS_CPT_SIGNED = "signed";
export const STATUS_CPT_DISABLED = "disabled";
export const STATUS_CPT_AWAITING_DOCUMENTS = "awaiting_documents";

export const STATUS_CPT_LIST = [
  STATUS_CPT_AWAITING_CID_DISEASES,
  STATUS_CPT_AWAITING_ASSIGN_AT,
  STATUS_CPT_NOT_SIGNED,
  STATUS_CPT_SIGNED,
  STATUS_CPT_DISABLED,
  STATUS_CPT_AWAITING_DOCUMENTS,
];

export const COLORS_CPT_STATUS = {
  awaiting_assign_at: "orange.400",
  awaiting_cid: "gray.400",
  not_signed: "red.400",
  signed: "green.400",
};

export const LABEL_CPT_STATUS = {
  awaiting_assign_at: "Aguardando assinatura",
  awaiting_cid: "Aguardando análise",
  not_signed: "Não assinado",
  signed: "Assinado/Aplicado",
  awaiting_documents: "Aguardando Envio do Termo",
  disabled: "CPT - Não Elegível",
};

export const CPT_DISABLED_REASON = {
  red5: "RED5",
  isento: "Isento",
  anothers: "Outros",
};

export const CPT_DISABLED_REASON_RED5 = { label: "RED5", value: "red5" };

export const CPT_DISABLED_REASON_ISENTO = { label: "Isento", value: "isento" };

export const CPT_DISABLED_REASON_ANOTHERS = {
  label: "Outros",
  value: "anothers",
};

export const CPT_DISABLED_REASON_LIST = {
  [CPT_DISABLED_REASON_RED5.value]: CPT_DISABLED_REASON_RED5.label,
  [CPT_DISABLED_REASON_ISENTO.value]: CPT_DISABLED_REASON_ISENTO.label,
  [CPT_DISABLED_REASON_ANOTHERS.value]: CPT_DISABLED_REASON_ANOTHERS.label,
};

export const REPORTS = [
  {
    label: "Gestão de Vidas",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_lives_management`,
    filters: {},
  },
  {
    label: "Declaração de Saúde",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_health_statement`,
    filters: {},
  },
  {
    label: "Primeiro Acolhimento 49",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_first_reception_49_plus`,
    filters: {},
  },
  {
    label: "Primeiro Acolhimento 48",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_first_reception_48_minus`,
    filters: {},
  },
  {
    label: "Agenda",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_schedule`,
    filters: {},
  },
  {
    label: "Metas do Plano de Vida",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_goal`,
    filters: {},
  },
  {
    label: "CPT - Rastreio de divergências",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_cpt_divergence_tracking`,
    filters: {},
  },
  {
    label: "CPT",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `cpt`,
    filters: {},
  },
  {
    label: "WhatsApp",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_whats_app`,
    filters: {},
  },
  {
    label: "Divulgação",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_disclosure`,
    filters: {},
  },
  {
    label: "Lista de consultas",
    tag:
      (process.env.REACT_APP_REPORT_PREFIX
        ? process.env.REACT_APP_REPORT_PREFIX
        : "") + `notrelife_commitments`,
    filters: {},
  },
];
