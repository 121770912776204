import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	from,
	InMemoryCache,
} from "@apollo/client";
import {
	JWT_TOKEN_COGNITO,
	JWT_TOKEN_COGNITO_ID,
} from "../../variables/enumLabels";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

const createClient = (token = null) => {
	if (!token) LOGOUT();

	const ERROR_LINK = onError(({ networkError }) => {
		if (networkError?.statusCode === 401) LOGOUT();
	});

	const UPLOAD_LINK = createUploadLink({
		uri: process.env.REACT_APP_API_URL + "/api/manager/graphql",
		headers: {
			authorization:
				"Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
			api: "Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
		},
	});

	const HTTP_LINK = new HttpLink({
		uri: process.env.REACT_APP_API_URL + "/api/manager/graphql",
		headers: {
			authorization:
				"Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
			api: "Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
		},
	});

	const APP_LINK = from([ERROR_LINK, UPLOAD_LINK, HTTP_LINK]);

	return new ApolloClient({
		link: APP_LINK,
		cache: new InMemoryCache(),
	});
};
export default createClient;
