import { useQuery } from '@apollo/client';
import AuditModal from 'components/AuditTrack/AuditModal'
import { AUDIT } from 'graphql/audit';
import React from 'react'

const CPTAudit = ({id, open, close, type}) => {
  const {data, loading} = useQuery(AUDIT, {
    variables: {
      id: id,
      type: type
    }
  })

  return (
    <AuditModal 
      data={data?.audit || null}
      open={open}
      close={() => close()}
      isLoading={loading}
    />
  )
}

export default CPTAudit