const ACTION_TYPES = {
    LIST: 'LIST_UNSCHEDULED_EVENTS',
    VIEW: 'VIEW_UNSCHEDULED_EVENT',
    ADD: 'ADD_UNSCHEDULED_EVENT',
    UPDATE: 'UPDATE_UNSCHEDULED_EVENT',
    REMOVE: 'REMOVE_UNSCHEDULED_EVENT',
}

const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.LIST: {
            return {
                ...state,
                unscheduledEvents: action.data.unscheduledEvents
            }
        }
        case ACTION_TYPES.ADD: {
            return {
                ...state,
                unscheduledEvents: [ ...state.unscheduledEvents, action.data ]
            }
        }
        default: {
            return state
        }
    }
}

export {
    reducers,
    ACTION_TYPES
}