import CardHeader from "components/Card/CardHeader";
import {
  Button,
  Flex,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ACCOMPANIMENT,
  FIRST_RECEPTION,
  HEALTH_STATEMENT,
  STATUS_CPT_AWAITING_ASSIGN_AT,
  STATUS_CPT_AWAITING_CID_DISEASES,
  STATUS_CPT_AWAITING_DOCUMENTS,
  STATUS_CPT_DISABLED,
  STATUS_CPT_NOT_SIGNED,
  STATUS_CPT_SIGNED,
  STATUS_CPT_MEDICAL_ANALYSIS,
} from "functions/enums";
import Card from "components/Card/Card";
import React from "react";
import { useForm } from "react-hook-form";
import CardFooter from "components/Card/CardFooter";
import { useUpdateCPTFilters } from "hooks/useFilter";
import { storageFilter } from "functions/storageFilters";

export default () => {
  const {
    status: defaultStatus,
    established_in: defaultEstablished,
  } = storageFilter("@cpt_filters");
  const inputHover = useColorModeValue("notrelife.800", "notrelife.800");

  const { register, handleSubmit } = useForm({
    defaultValues: {
      status: defaultStatus || STATUS_CPT_AWAITING_CID_DISEASES,
      established_in: defaultEstablished || "",
    },
  });

  const { mutate } = useUpdateCPTFilters();

  return (
    <form onSubmit={handleSubmit(mutate)}>
      <Card mb="24px">
        <CardHeader>
          <Flex>
            <Stack direction="column" spacing="12px" mr="12px">
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                Status
              </Text>
              <Select
                focusBorderColor={inputHover}
                placeholder="Selecione uma opção..."
                color="gray.500"
                size="sm"
                borderRadius="12px"
                cursor="pointer"
                {...register("status")}
              >
                <option value={STATUS_CPT_AWAITING_CID_DISEASES}>
                  Aguardando análise de doenças
                </option>
                <option value={STATUS_CPT_AWAITING_DOCUMENTS}>
                  Aguardando Envio do Termo
                </option>
                <option value={STATUS_CPT_MEDICAL_ANALYSIS}>
                  Análise Médica
                </option>
                <option value={STATUS_CPT_AWAITING_ASSIGN_AT}>
                  Aguardando Assinatura
                </option>
                <option value={STATUS_CPT_SIGNED}>
                  Cobertura Parcial Assinada/Aplicada
                </option>
                <option value={STATUS_CPT_NOT_SIGNED}>
                  Cobertura Parcial Não Assinada
                </option>
                <option value={STATUS_CPT_DISABLED}>CPT - Não Elegível</option>
              </Select>
            </Stack>
            <Stack direction="column" spacing="12px" mr="12px">
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                Estabelecida em
              </Text>
              <Select
                focusBorderColor={inputHover}
                placeholder="Selecione uma opção..."
                color="gray.500"
                size="sm"
                borderRadius="12px"
                cursor="pointer"
                {...register("established_in")}
              >
                <option value={HEALTH_STATEMENT}>{HEALTH_STATEMENT}</option>
                <option value={FIRST_RECEPTION}>{FIRST_RECEPTION}</option>
                <option value={ACCOMPANIMENT}>{ACCOMPANIMENT}</option>
              </Select>
            </Stack>
          </Flex>
        </CardHeader>
        <CardFooter p={"18px 0"}>
          <Flex direction={"row"}>
            <Button type="submit" mr={"10px"}>
              Filtrar
            </Button>
          </Flex>
        </CardFooter>
      </Card>
    </form>
  );
};
