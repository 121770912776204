import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Select,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";

import dateFormat from "functions/dateFormat";
import { useBeneficiaries, useCreateContactsPhoneMade } from "hooks/beneficiaries";

const phoneOptions = [
    {
        id: 1,
        value: "Sem Tentativas"
    },
    {
        id: 2,
        value: "Beneficiário Ciente"
    },
    {
        id: 3,
        value: "Telefone Ocupado"
    },
    {
        id: 4,
        value: "Beneficiário Ausente"
    },
    {
        id: 5,
        value: "Recado com terceiro"
    },
]

const tries = [
    {
        id: 1,
    },
    {
        id: 2,
    },
    {
        id: 3,
    },
    {
        id: 4,
    },
    {
        id: 5,
    },
]
const pending_activities = [
    {
        id: "Primeiro Acolhimento",
    },
    {
        id: "Acompanhamento",
    },
    {
        id: "Declaração de Saúde",
    }
]

const schema = yup.object().shape({
    contact_type: yup
        .number()
        .required("Selecione a opção de contato"),
    was_scheduled: yup
        .boolean()
        .required("Agendamento realizado?"),
    tries: yup
        .number()
        .required("Selecione o número de tentativas"),
    pending_activity: yup
        .string()
        .required("Selecione a atividade pendente")
})
const BeneficiaryPhoneRegister = () => {
    const methods = useForm();
    const toast = useToast();
    const { beneficiary } = useBeneficiaries()
    const contactsPhoneMade = beneficiary?.contactsPhoneMade;

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
    });

    const [createPhoneRegister, { loading: loadingCreate }] = useCreateContactsPhoneMade({
        onSuccess: () => {
            reset()
            toast({
                title: 'Histórico adicionado com sucesso.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        },
        onError: (err) => {
            toast({
                title: "Ocorreu um erro",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        },
    })

    const onSubmit = (data) => {
        createPhoneRegister({
            variables: {
                input: {
                    id: beneficiary.id,
                    contactsPhoneMade: {
                        create: [{
                            ...data
                        }]
                    }
                }
            }
        })
    }

    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Flex w="100%" justifyContent="center" alignItems="flex-start" direction="column" mb="15px">
            <Flex mb="24px" width="100%">
                <FormProvider {...methods} >
                    <form style={{ width: '100%' }} onSubmit={(handleSubmit(onSubmit))}>
                        <Flex direction="column" w="100%">
                            <Grid
                                templateColumns={{ sm: "1fr", md: "3fr 2fr 2fr 1fr" }}
                                gap="24px"
                                width="100%"
                            >
                                <FormControl isInvalid={errors.contact_type}>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Contato Telefônico
                                    </FormLabel>
                                    <Select
                                        {...register('contact_type')}
                                        focusBorderColor={inputHover}
                                        borderRadius="8px"
                                        fontSize="md"
                                        placeholder="Selecione uma opção..."
                                        width="auto"
                                    >
                                        {
                                            phoneOptions.map(el => (
                                                <option value={el.id}>{el.value}</option>
                                            ))
                                        }
                                    </Select>
                                    {errors.contact_type &&
                                        <FormErrorMessage>{errors.contact_type.message}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={errors.tries}>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Número de Tentativas
                                    </FormLabel>
                                    <Select
                                        {...register('tries')}
                                        focusBorderColor={inputHover}
                                        borderRadius="8px"
                                        fontSize="md"
                                        placeholder="Selecione uma opção..."
                                        width="auto"
                                    >
                                        {
                                            tries.map(el => (
                                                <option value={el.id}>{el.id}</option>
                                            ))
                                        }
                                    </Select>
                                    {errors.tries && <FormErrorMessage>{errors.tries.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={errors.pending_activity}>
                                    <FormLabel
                                        color={textColor}
                                        fontWeight="bold"
                                        fontSize="xs"
                                    >
                                        Atividade Pendente
                                    </FormLabel>
                                    <Select
                                        {...register('pending_activity')}
                                        focusBorderColor={inputHover}
                                        borderRadius="8px"
                                        fontSize="md"
                                        placeholder="Selecione uma opção..."
                                        width="auto"
                                    >
                                        {
                                            pending_activities.map(el => (
                                                <option value={el.id}>{el.id}</option>
                                            ))
                                        }
                                    </Select>
                                    {errors.pending_activity &&
                                        <FormErrorMessage>{errors.pending_activity.message}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={errors.was_scheduled}>
                                    <Flex align="center" direction="column">
                                        <Text>Agendamento Realizado?</Text>
                                        <Checkbox
                                            {...register('was_scheduled')}
                                            size="lg" colorScheme="healthlab" me="8px" />

                                    </Flex>
                                </FormControl>
                            </Grid>
                        </Flex>
                        <Flex mt="24px" align={"center"} justify={"flex-end"}>
                            <Button
                                type='submit'
                                isLoading={loadingCreate}
                                isDisabled={loadingCreate}
                                variant="no-hover"
                                bg={buttonBg}
                                alignSelf="flex-end"
                                w="100px"
                                h="35px"
                            >
                                <Text fontSize="xs" color="#fff" fontWeight="bold">
                                    Adicionar
                                </Text>
                            </Button>
                        </Flex>
                    </form>
                </FormProvider>
            </Flex>
            <Flex direction="column" width="100%">
                {
                    !contactsPhoneMade || contactsPhoneMade.length == 0 ?
                        <Text fontSize="lg" fontWeight="bold">
                            Nenhum registro encontrado
                        </Text>
                        :
                        <>
                            <Text fontSize="lg" fontWeight="bold" mb="24px">
                                Histórico de Contato Telefônico
                            </Text>
                            <TableContainer width="100%">
                                <Table variant='simple' size='md'>
                                    <Thead>
                                        <Tr>
                                            <Th>Data Hora</Th>
                                            <Th>Atividade Pendente</Th>
                                            <Th>Status do Agendamento</Th>
                                            <Th>Contato Telefônico</Th>
                                            <Th>Nº Tentativas</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            contactsPhoneMade.map((el, x) => (
                                                <Tr>
                                                    <Td>{dateFormat(el.created_at)}</Td>
                                                    <Td>{el.pending_activity || ""}</Td>
                                                    <Td>{el.was_scheduled ? "Agendada" : "Não Agendada"}</Td>
                                                    <Td>{phoneOptions.find(o => (el.contact_type === o.id)).value || ""}</Td>
                                                    <Td>{el.tries || ""}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </>
                }
            </Flex>
        </Flex>
    )
}

export default BeneficiaryPhoneRegister


//
// const elements = [
//   {
//     id: '1',
//     contact_type: 3,
//     activity: 'Acompanhamento',
//     schedule_status: 'Agendada',
//     tries: 1,
//     created_at: new Date()
//   },
//   {
//     id: '1',
//     contact_type: 2,
//     activity: 'Primeiro Acolhimento',
//     schedule_status: 'Agendada',
//     tries: 4,
//     created_at: new Date()
//   },
//   {
//     id: '1',
//     contact_type: 4,
//     activity: 'Acolhimento',
//     schedule_status: 'Agendada',
//     tries: 3,
//     created_at: new Date()
//   },
// ]

