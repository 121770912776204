import CardHeader from "components/Card/CardHeader";
import {Flex, Text, useColorModeValue} from "@chakra-ui/react";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import React from "react";

export default ({title, content, ...props}) => {
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Flex  {...props} ml={'10px'} mb={'10px'} direction={'column'}>
            <Card>
                <CardHeader>
                    <Text fontSize="lg" text={textColor} fontWeight="bold">
                        {title}
                    </Text>
                </CardHeader>
                <CardBody w="100%" pt="28px">
                    {content}
                </CardBody>
            </Card>
        </Flex>
    );
}