import React, {createContext, useContext, useReducer} from "react";
import {useQuery} from "@apollo/client";
import {GET_HEALTH_STATEMENT} from "graphql/assessments";

const ACTION_TYPES = {
    GET: 'GET_HEALTH_STATEMENT',
}
const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET: {
            return {
                ...state,
                healthstatement: action.data
            }
        }
        default: {
            return state
        }
    }
}

const HealthStatementStateContext = createContext()
const HealthStatementDispatchContext = createContext()

const HealthStatementProvider = ({ children, beneficiary_id }) => {
    const [state, dispatch] = useReducer(reducers, { healthstatement: {} })
    const { data, loading, error } = useQuery(GET_HEALTH_STATEMENT, {
        variables : {
            beneficiary_id
        },
        onCompleted: (data) => dispatch({type: ACTION_TYPES.GET, data})
    })

    return (
        <HealthStatementStateContext.Provider value={{ data, loading, error, ...state }}>
            <HealthStatementDispatchContext.Provider value={dispatch}>{children}</HealthStatementDispatchContext.Provider>
        </HealthStatementStateContext.Provider>
    )
}


const useHealthStatement = () => {
    const context = useContext(HealthStatementStateContext)

    if (!context)
        throw new Error('useHealthStatement must be used within an HealthStatementProvider')

    return context
}

export {
    HealthStatementProvider,
    useHealthStatement
}