import React from 'react'
import { useState, createContext, useContext, useReducer } from 'react'
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_FREEHOURS_PAGINATE, GET_FREEHOUR, CREATE_FREEHOUR, UPDATE_FREEHOUR } from 'graphql/freeHours'
import { ACTION_TYPES, reducers } from './reducers'
import { DELETE_FREEHOUR } from 'graphql/freeHours';

const FreeHoursStateContext = createContext()
const FreeHoursDispatchContext = createContext()

const FreeHoursProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, { rows: [], first: 15, page: 1 })

	const [toDelete, setToDelete] = useState(null)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)

	const { data, loading, error } = useQuery(GET_FREEHOURS_PAGINATE, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.freeHoursPaginate })
		}
	})

	return (
		<FreeHoursStateContext.Provider value={{ ...state, toDelete, setToDelete, deleteModalOpen, setDeleteModalOpen, data, loading }}>
			<FreeHoursDispatchContext.Provider value={dispatch}>{children}</FreeHoursDispatchContext.Provider>
		</FreeHoursStateContext.Provider>
	)
}

const useFreeHours = () => {
	const context = useContext(FreeHoursStateContext)

	if (!context)
		throw new Error('useFreeHours must be used within an FreeHoursProvider')

	return context
}

const useDispatch = () => {
	const dispatch = useContext(FreeHoursDispatchContext)

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a UsersProvider')

	return dispatch
}

const usePaginateFreeHours = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_FREEHOURS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(null, '', `./gestao-vidas?page=${data.freeHoursPaginate.paginatorInfo.currentPage}&size=${data.freeHoursPaginate.paginatorInfo.perPage}`);
			dispatch({ type: ACTION_TYPES.LIST, data: data.freeHoursPaginate })
		},
	})
}

const useCreateFreeHour = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(CREATE_FREEHOUR, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data)
			dispatch({ type: ACTION_TYPES.ADD, data: data.createFreeHour })
		},
		onError: (error) => {
			if (onError) onError(error)
		},
	})
}

const useFreeHour = (id) => {
	const dispatch = useContext(FreeHoursDispatchContext);

	return useQuery(GET_FREEHOUR, {
		fetchPolicy: "no-cache",
		variables: {
			id: id
		}
	},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.SELECT, data: data.freeHour })
			},
		}
	)
}

const useUpdateFreeHour = (options = {}) => {
	const dispatch = useContext(FreeHoursDispatchContext);
	const { onSuccess, onError } = options

	return useMutation(UPDATE_FREEHOUR, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data)
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateFreeHour })
		},
		onError: (error) => {
			if (onError) onError(error)
		},
		refetchQueries: [
			{
				query: GET_FREEHOURS_PAGINATE
			}
		]
	})
}

const useDeleteFreeHour = (options = {}) => {
	const dispatch = useContext(FreeHoursDispatchContext);
	const { onSuccess } = options
	return useMutation(DELETE_FREEHOUR, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess()
			dispatch({ type: ACTION_TYPES.REMOVE, data: data.deleteFreeHour })
		},
	},
	)
}

export {
	FreeHoursProvider,
	useFreeHours,
	usePaginateFreeHours,
	useCreateFreeHour,
	useFreeHour,
	useUpdateFreeHour,
	useDeleteFreeHour
}
