import React from "react";
import { NavLink, useParams } from "react-router-dom";

import { useTicket } from "hooks/tickets";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  Text,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import status from "variables/status";
import priorities from "variables/priorities";
import { useAuth } from "hooks/useAuth";
import { useRegionals } from "hooks/globalRegionals";
import { roleAdmin, roleSuper } from "../../../variables/roles";
import { Download } from "@phosphor-icons/react";

const ViewTicket = () => {
  const { id } = useParams();
  const { loading, data } = useTicket(id);
  const { user } = useAuth();

  const textColor = useColorModeValue("gray.700", "white");
  const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");

  console.log(user);

  const userRoles = user?.roles;

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card maxW={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="row" w="100%" justify="space-between">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Ticket
            </Text>
            <Flex>
              {!!userRoles && 
                (userRoles[0].name === roleAdmin ||
                  userRoles[0].name === roleSuper) && (
                  <NavLink to={`/sistema/administracao/suporte/${id}/editar`}>
                    <Button
                      backgroundColor={buttonBg}
                      color="white"
                      variant="no-hover"
                      mr="5"
                    >
                      Editar
                    </Button>
                  </NavLink>
                )}
              <NavLink to="/sistema/administracao/suporte">
                <Button>Voltar</Button>
              </NavLink>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <Flex>
              <FormControl>
                <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                  Status
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    {data?.ticket?.status
                      ? status.find((stat) => stat.value == data.ticket.status)
                          ?.name
                      : null}
                  </Text>
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                  Prioridade
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    {data?.ticket?.priority
                      ? priorities.find(
                          (prior) => prior.value == data.ticket.priority
                        )?.name
                      : null}
                  </Text>
                </Skeleton>
              </FormControl>
            </Flex>
            <FormControl>
              <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                Onde encontrou o erro?
              </FormLabel>
              {loading ? (
                <Skeleton height={"25px"} />
              ) : (
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  mb="3px"
                >
                  {data?.ticket?.source || null}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                O que o erro causa?
              </FormLabel>
              {loading ? (
                <Skeleton height={"25px"} />
              ) : (
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  mb="3px"
                >
                  {data?.ticket?.causes || null}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                Resuma o erro encontrado
              </FormLabel>
              {loading ? (
                <Skeleton height={"25px"} />
              ) : (
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  mb="3px"
                >
                  {data?.ticket?.abstract || null}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                Aberto pelo usuário
              </FormLabel>
              <Skeleton height={"25px"} isLoaded={!loading}>
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  mb="3px"
                >
                  {data?.ticket?.user?.name || null}
                </Text>
              </Skeleton>
            </FormControl>
            <FormControl>
              <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                Resposta ao ticket
              </FormLabel>
              {loading ? (
                <Skeleton height={"25px"} />
              ) : (
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  mb="3px"
                >
                  {data?.ticket?.answer || null}
                </Text>
              )}
            </FormControl>
            {data?.ticket?.documents?.length > 0 && (
              <FormControl>
                <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                  Anexos
                </FormLabel>
                <Flex gap="12px" direction="column">
                  {data?.ticket?.documents?.map((doc) => (
                    <Flex>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="15px"
                      >
                        {doc?.name}
                      </Text>

                      <IconButton
                        onClick={() => {
                          window.open(doc?.url);
                        }}
                        icon={<Download size="22" />}
                      />
                    </Flex>
                  ))}
                </Flex>
              </FormControl>
            )}
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default ViewTicket;
