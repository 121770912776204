import { createContext, useContext, useReducer } from "react";
import { reducers } from "./reducers";

const BeneficiaryStateContext = createContext()
const BeneficiaryDispatchContext = createContext()

const initialState = {
    awaitingAssessments: [],
    pendingAssessments: [],
    currentForm: {},
}

const BeneficiaryEventsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, initialState)

    return (
        <BeneficiaryStateContext.Provider value={{ ...state, dispatch }}>
            <BeneficiaryDispatchContext.Provider value={dispatch}>{children}</BeneficiaryDispatchContext.Provider>
        </BeneficiaryStateContext.Provider>
    )
}

const useEvents = () => {
    const context = useContext(BeneficiaryStateContext)

    if (!context) throw new Error('useBeneficiaries must be used within an BeneficiaryProvider')

    return context
}

const useEventsDispatch = () => {
    const dispatch = useContext(BeneficiaryDispatchContext)

    if (!dispatch) throw new Error("Falta o provider")

    return dispatch
}

export {
    BeneficiaryEventsProvider,
    useEvents,
    useEventsDispatch
}
