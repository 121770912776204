import {msDarkTheme, msLightTheme} from "variables/multiselectStyle";
import AsyncSelect from "react-select/async";
import {useLazyQuery} from "@apollo/client";
import {GET_PLANS} from "services/regionals";
import {useColorMode} from "@chakra-ui/react";
import {useFormContext} from "react-hook-form";

const AsyncPlansMultiselect = ({ whereCondition, defaultValues = [], isMulti = true }) => {
    const [loadPlans, {loading, data}] = useLazyQuery(GET_PLANS);
    const { colorMode } = useColorMode();
    const { register, setValue, unregister } = useFormContext()

    const asyncValues = async (item) => {
        const {data} = await loadPlans({
            variables: {search: item}
        })
        return data.plans;
    }
    const handleAction = (actualData) => {
       setValue("plan_id", actualData.id)
    }
    return (
        <AsyncSelect
            isMulti={isMulti}
            loadOptions={asyncValues}
            isLoading={loading}
            cacheOptions
            defaultValue={defaultValues || []}
            getOptionLabel={e => (`${e.name} secad: ${e.secad} ANS: ${e.ans}`) }
            getOptionValue={e => e.id}
            color="#FFF"

            onChange={(i) => handleAction(i)}
            placeholder="Selecione uma opção..."
            loadingMessage={() => "Procurando..."}
            noOptionsMessage={({inputValue}) => !inputValue ? "Digite para pesquisar" : "Não foram encontrados resultados"}
            styles={
                colorMode === "light" ? msLightTheme : msDarkTheme
            }
        />
    )
}

export default AsyncPlansMultiselect;
