import {useParams} from 'react-router-dom'
import {FormProvider} from "react-hook-form";

import {Box, Button, Flex, Spinner, Text, useColorModeValue,} from "@chakra-ui/react";
import greenBg from "assets/img/backgroundcard.png";
import React from "react";
import {GET_CAMPAIGN, POST_CAMPAIGN} from 'services/customers/campaigns';
import {useMutation, useQuery} from 'react-query';
import dateFormat from 'functions/dateFormat';

const getText = {
	'virtual': (data) => (
		<Text
			fontSize="lg"
			color="#000"
			mb="40px">
			Que bom que você está aqui, para garantir o seu lugar, basta clicar no botão "Confirmar".
			Se trata de um evento online e após a sua confirmação você receberá o link para acessar no dia e hora
			indicado.
		</Text>
	),
	'presencial': (data) => (
		<Text
			fontSize="lg"
			color="#000"
			mb="40px">
			Que bom que você está aqui, para garantir o seu lugar, basta clicar no botão "Confirmar".
			O local para você participar é:
			{data?.campaign?.address || null}, {data?.campaign?.district || null}, {data?.campaign?.complement || null}, {data?.campaign?.cep || null}, {data?.campaign?.city || null}/{data?.campaign?.state || null}.
			Apenas para lembrá-lo, nosso evento, {data?.campaign?.title || null} {" "}
			sobre {data?.campaign?.about || null}, ocorrerá no
			dia {data?.campaign ? `${dateFormat(data.campaign.scheduled_to, 'dd/MM')} às ${dateFormat(data.campaign.scheduled_to, 'HH:mm')} ` : null}.
		</Text>
	),
};
export default () => {
	const {key} = useParams()
	const bgColor = useColorModeValue("white", "gray.700");

	const {isLoading, data, error} = useQuery(['campaigns'], () => GET_CAMPAIGN(key))

	const {mutate: postCampaign, isLoading: isLoadingSubmit, data: response, error: errorForm} = useMutation({
		mutationFn: (data) => POST_CAMPAIGN(data, key),
	})

	const handleClick = (value) => {
		postCampaign({accept: value})
	}

	/**
	 * fetch GET /api/communication/campaign
	 * fetch POST /api/communication/campaign
	 */

	return (
		<Flex
			direction="column"
			alignSelf="center"
			justifySelf="center"
			overflow="hidden"
		>
			<Box
				position="absolute"
				minH={{base: "70vh", md: "50vh"}}
				w={{md: "calc(100vw - 50px)"}}
				left="0"
				right="0"
				bgRepeat="no-repeat"
				overflow="hidden"
				zIndex="-1"
				top="0"
				bgImage={greenBg}
				bgSize="cover"
				mx={{md: "auto"}}
				mt={{md: "5px"}}
			></Box>
			<Flex
				direction="column"
				textAlign="center"
				justifyContent="center"
				align="center"
				mt="3rem"
				mb="30px"
			>
				<Text fontSize="4xl" color="white" fontWeight="bold">
					Notrelife
				</Text>
			</Flex>
			<Flex alignItems="center" justifyContent="center" mb="200px" mt="20px"
			      mr={{sm: "20px"}}
			      ml={{sm: "20px"}}>
				<FormProvider>
					<Flex
						direction="column"
						justifyContent="center"
						alignItems="center"
						w={{md: "565px", sm: "100%"}}
						minH="450px"
						background="transparent"
						borderRadius="15px"
						p="40px"
						bg={bgColor}
						boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
					>
						{
							isLoading ?
								<Spinner
									size="lg"
									mt="auto"
									mb="auto"
								/>
								:
								error ?
									<Text
										fontSize="lg"
										color="#000"
										mb="40px">O link que você está utilizando é inválido ou já expirou.</Text>
									:
									errorForm ?
										<Text
											fontSize="lg"
											color="#000"
											mb="40px">Infelizmente nossas vagas para este evento foram preenchidas
											rapidamente. Mas fique tranquilo, pois iremos manter você informado, caso
											ocorra alguma desistência.
											Muito obrigado!</Text>
										:
										response ?
											<Text
												fontSize="lg"
												color="#000"
												mb="40px">
												Agradecemos a sua resposta
											</Text>
											:
											<Flex
												direction="column"
											>
												<Flex
													direction="column"
												>
													<Text
														fontSize="lg"
														color="#000"
														mb="40px">
														{data?.receiver?.beneficiary?.name}
													</Text>
													{getText[data?.campaign.ambience](data)}
												</Flex>
												<Flex
													width="100%"
													justifyContent="space-between"
												>
													{
														isLoadingSubmit ?
															<Flex justifyContent="center" w="100%">
																<Spinner
																	size="lg"
																	mt="auto"
																	mb="auto"
																/>
															</Flex>
															:
															<>
																<Button
																	bg="notrelife.900"
																	fontSize="sm"
																	color="white"
																	fontWeight="bold"
																	h="45"
																	mt="auto"
																	mr="5"
																	_hover={{
																		bg: "notrelife.800",
																	}}
																	_active={{
																		bg: "notrelife.800",
																	}}
																	flexGrow={2}
																	onClick={() => handleClick(true)}
																>Confirmar</Button>
																<Button
																	fontSize="sm"
																	fontWeight="bold"
																	h="45"
																	mt="auto"
																	ml="5"
																	flexGrow={1}
																	onClick={() => handleClick(false)}
																>Cancelar</Button>
															</>
													}
												</Flex>
											</Flex>
						}
					</Flex>
				</FormProvider>
			</Flex>
		</Flex>
	);
}
