/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import dateFormat from "functions/dateFormat";
import CancelEvent from "./CancelEvent";
import { useEventsDay, useEventsDayLazyLoading } from "hooks/calendar/statistics";
import AsyncTable from "components/Tables/AsyncTable";
import { motives } from "variables/unscheduled_motives";
import { fromAnyFormatToBrWithHours } from "../../../functions/momentFormat";
import teleDateFormat from "functions/teleDateFormat";

const getAssessmentType = ({ row: { original: event } } = {}) => {
    if (!!event?.assessment) return event?.assessment?.numbered_title;

    if (!!event?.awaiting_schedule) return event?.awaiting_schedule?.awaiting_schedule;

    if (!!event?.assessment_type) return event?.assessment_type?.name;

    if (!!event?.unscheduled_event) return event?.unscheduled_event?.type === 1 ? 'Demanda Espontânea' : 'Monitoramento de Internados'

    return 'Declaração de Saúde'
}

const getAttendanceType = ({ row: { original: event } } = {}) => {
    const getAttendanceMotive = (event) => {
        let motive = motives.find((m) => m.id === event.motive)?.label

        if (motive === 'Outro' && !!event.info) return `${motive}, ${event?.info || null}`;

        return motive;
    }

    if (!!event.assessment) return event.type === 'tele' ? 'Tele-atendimento' : 'Presencial';

    if (!!event.unscheduled_event) return getAttendanceMotive(event.unscheduled_event);

    return ''
}

export default ({ regional, role, professional }) => {
    const { day } = useParams();
    const textColor = useColorModeValue("gray.700", "white");
    const [paginateDayEvents, { error, refetch }] = useEventsDayLazyLoading();
    const history = useHistory();

    const { rows, total, currentPage, perPage, loading } = useEventsDay();
    const [open, setOpen] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(null);

    const columns = [
        {
            Header: "Paciente",
            accessor: "patient.name",
            Cell: (props) => (
                <Text
                    onClick={() =>
                        history.push(
                            `/sistema/administracao/gestao-vidas/${props.row.original.patient.id}`
                        )
                    }
                    fontWeight="bold"
                    _hover={{ cursor: "pointer", color: "notrelife.800" }}
                >
                    {props.value}
                </Text>
            ),
        },
        {
            Header: "Agendado para",
            accessor: "scheduled_to",
            Cell: (props) => props?.row?.original?.type == 'presencial'
                ? <div>{dateFormat(props?.value, "dd/MM/y kk:mm")}</div>
                : <div>{dateFormat(props?.value, "dd/MM/y")} - {teleDateFormat(props?.value)}</div>,
        },
        {
            Header: "Tipo de compromisso",
            accessor: "assessment.assessmentType.name",
            Cell: (props) => <div>{getAssessmentType(props)}</div>,
        },
        {
            Header: "Tipo de atendimento",
            accessor: "type",
            Cell: (props) => <div>{getAttendanceType(props)}</div>,
        },
        {
            Header: "Profissional",
            accessor: "professional.user.name",
        },
        {
            Header: "Tipo de profissional",
            accessor: "professional.user.role",
        },
        {
            Header: "Status",
            Cell: (props) => (
                <div>
                    {handleStatus(props?.row?.original)}
                </div>
            ),
        },
        {
            Header: "Reagendado para",
            Cell: (props) => (
                <div>
                    {fromAnyFormatToBrWithHours(props?.row?.original?.parent?.scheduled_to)}
                </div>
            ),
        }
    ];

    useEffect(() => {
        const roleFilter = !!role
            ? {
                professional: {
                    column: "ROLE",
                    operator: "EQ",
                    value: role,
                },
            }
            : null;

        const professionalFilter = !!professional
            ? {
                column: "PROFESSIONAL_ID",
                operator: "EQ",
                value: professional,
            }
            : null;

        const variables = {
            where: {
                column: "SCHEDULED_TO",
                operator: "GTE",
                value: `${day} 00:00:00`,
                AND: [
                    {
                        column: "SCHEDULED_TO",
                        operator: "LTE",
                        value: `${day} 23:59:00`,
                    },
                    { ...professionalFilter },
                ],
            },
            ...roleFilter,
            perPage: perPage,
        };

        paginateDayEvents({ variables: variables });
    }, [regional, role, professional]);

    if (error) {
        console.log(error);
        return "Ocorreu um erro";
    }

    const fetchData = ({ pageSize, pageIndex }) => {
        paginateDayEvents({
            variables: {
                where: {
                    column: "SCHEDULED_TO",
                    operator: "GTE",
                    value: `${day} 00:00:00`,
                    AND: [
                        {
                            column: "SCHEDULED_TO",
                            operator: "LTE",
                            value: `${day} 23:59:00`,
                        },
                    ],
                },
                perPage: pageSize,
                page: pageIndex,
            },
        });
    };

    const customActions = [
        {
            label: "Cancelar Agendamento",
            visible: (row) => {
                return !row?.canceled_at && !row?.attended && !row?.expired_at;
            },
            callback: (id) => {
                setCurrentEventId(id);
                setOpen(true);
            },
        },
    ];

    const handleStatus = (event) => {
        if (!!event) {
            if (!!event.attended) return "Realizado";
            if (!!event.rescheduled_at) return "Reagendado";
            if (!!event.canceled_at) return "Cancelado";
            if ((!!event.expired_at) || (!!event.scheduled_to && new Date(event.scheduled_to) < new Date())) return "Ausente";
        }

        return "Aguardando";
    }

    return (
        <Card overflowX={{ sm: "scroll", lg: "hidden" }}>
            <CardBody>
                <AsyncTable
                    isLoaded={!loading}
                    data={!loading && rows ? rows : [{}, {}, {}, {}]}
                    fetchData={fetchData}
                    columns={columns}
                    total={total}
                    size={perPage}
                    asyncPage={currentPage}
                    customActions={!loading ? customActions : []}
                    baseRoute="agenda"
                    noView
                    noEdit
                    noDelete
                />
                {open && currentEventId && (
                    <CancelEvent
                        id={currentEventId}
                        canceledEvent={() => refetch()}
                        open={open}
                        close={() => setOpen(false)}
                    />
                )}
            </CardBody>
        </Card>
    );
};
