import React from 'react'
import { useState, createContext, useContext, useReducer } from 'react'
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_SCHEDULED_COMMITMENTS, GET_MISSED_COMMITMENTS, CREATE_NOTE, PA_REFUSAL } from 'graphql/commitmentsManagement';
import { ACTION_TYPES, reducers } from './reducers'
import { GET_AUTO_COMMITMENTS } from 'graphql/commitmentsManagement';
import { GET_REFUSAL_COMMITMENTS } from 'graphql/commitmentsManagement';
import { RESCHEDULE_EVENT } from 'graphql/commitmentsManagement';
import { GET_AWAITING_COMMITMENTS } from 'graphql/commitmentsManagement';
import { GET_COMMITMENTS_STATISTICS } from 'graphql/commitmentsManagement';
import { CREATE_EVENT } from 'services/calendar/sql/events';

const CommitmentsStateContext = createContext()
const CommitmentsDispatchContext = createContext()

const CommitmentsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, { commitments: [], first: 30, page: 1 })

	return (
		<CommitmentsStateContext.Provider value={{ ...state }}>
			<CommitmentsDispatchContext.Provider value={dispatch}>{children}</CommitmentsDispatchContext.Provider>
		</CommitmentsStateContext.Provider>
	)
}

const useCommitments = () => {
	const context = useContext(CommitmentsStateContext)

	if (!context)
		throw new Error('useCommitments must be used within an CommitmentsProvider')

	return context
}

const useDispatch = () => {
	const dispatch = useContext(CommitmentsDispatchContext)

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a CommitmentsProvider')

	return dispatch
}

const usePaginateCommitments = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_COMMITMENTS_PAGINATE, {
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.commitmentsPaginate })
		},
	})
}

const useAwaitingCommitments = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_AWAITING_COMMITMENTS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.awaitingCommitments })
		},
	})
}

const useScheduledCommitments = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_SCHEDULED_COMMITMENTS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.scheduledCommitments })
		},
	})
}

const useMissedCommitments = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_MISSED_COMMITMENTS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.missedCommitments })
		},
	})
}

const useAutoCommitments = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_AUTO_COMMITMENTS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.autoCommitments })
		},
	})
}

const useRefusalCommitments = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_REFUSAL_COMMITMENTS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.LIST, data: data.refusalCommitments })
		},
	})
}

const useCommitmentsStatistics = () => {
	const dispatch = useDispatch()

	return useLazyQuery(GET_COMMITMENTS_STATISTICS)
}

const useScheduleEvent = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(CREATE_EVENT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess()
			dispatch({ type: ACTION_TYPES.SCHEDULE_EVENT, data: data.createEvent })
		},
		onError: (error) => {
			if (onError) onError(error)
		},
	})
}

const useRescheduleEvent = (event, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(RESCHEDULE_EVENT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data)
			if (event == 'scheduled') dispatch({ type: ACTION_TYPES.RESCHEDULE_EVENT, data: data.rescheduleEvent })
			if (event == 'missing') dispatch({ type: ACTION_TYPES.RESCHEDULE_EVENT_MISSING, data: data.rescheduleEvent })
		},
		onError: (error) => {
			if (onError) onError(error)
		},
	})
}

const useCreateNote = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(CREATE_NOTE, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess()
			dispatch({ type: ACTION_TYPES.ADD_NOTE, data: data.createNote })
		},
		onError: (error) => {
			if (onError) onError(error)
		},
	})
}

const useRefusePA = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess, onError } = options

	return useMutation(PA_REFUSAL, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess()
			dispatch({ type: ACTION_TYPES.REFUSE_PA, data: data.paRefusal })
		},
		onError: (error) => {
			onError(error?.graphQLErrors[0]?.extensions?.validation)
		},
	})
}

export {
	CommitmentsProvider,
	useDispatch,
	useCommitments,
	usePaginateCommitments,
	useAwaitingCommitments,
	useScheduledCommitments,
	useMissedCommitments,
	useAutoCommitments,
	useRefusalCommitments,
	useCommitmentsStatistics,
	useScheduleEvent,
	useRescheduleEvent,
	useCreateNote,
	useRefusePA
}
