import React, {useEffect} from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useQuery, useMutation } from 'react-query'

import { ACTION_TYPES, reducers } from './reducers'
import { GET_ALL_REGIONALS } from 'services/regional';
import { isEmpty } from 'lodash';
import { SELECT_REGIONAL } from 'services/regional';
import {useAuth} from "../useAuth";

const RegionalsStateContext = createContext()
const RegionalsDispatchContext = createContext()

const RegionalsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, {regionals: [], regional: null})
	const { user } = useAuth()
    const { isLoading, isFetching } = useQuery(['regionals'], () => GET_ALL_REGIONALS(), {
			onSuccess: (data) => {
				if (isEmpty(data)) {
					return dispatch({ type: ACTION_TYPES.LIST, data })
				}
				dispatch({ type: ACTION_TYPES.LIST, data })
			},
    })
	useEffect(() => {
		if (user?.regional_id) dispatch({ type: ACTION_TYPES.SELECT, data: user })
	}, [user]);

    return (
			<RegionalsStateContext.Provider value={{ ...state, isLoading, isFetching }}>
					<RegionalsDispatchContext.Provider value={dispatch}>{children}</RegionalsDispatchContext.Provider>
			</RegionalsStateContext.Provider>
    )
}

const useRegionals = () => {
	const context = useContext(RegionalsStateContext)
	
	if (!context)
		throw new Error('useRegionals must be used within an RegionalsProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(RegionalsDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a RegionalsProvider')

	return dispatch
}

const useSelectRegional = () => {
  const dispatch = useDispatch()

	return useMutation((data) => SELECT_REGIONAL(data), {
			onSuccess: (data) => {
					dispatch({ type: ACTION_TYPES.SELECT, data })
          location.reload()
			},
	})
}

export {
	RegionalsProvider,
	useRegionals,
  useSelectRegional
}
