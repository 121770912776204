import React from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import {
  Button,
  useColorModeValue,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useToast,
  Input,
  Text,
  ModalBody,
} from "@chakra-ui/react";
import { useUpdatePlan } from "hooks/plans";

const EditPlan = ({ plan, onClose }) => {
  const methods = useForm();
  const toast = useToast();
  const { register, handleSubmit, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const [updatePlan, { loading: loadingUpdate, error }] = useUpdatePlan({
    onSuccess: (data) => {
      toast({
        title: "Plano atualizado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Ocorreu um erro.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onSubmit = () => {
    const variables = {
      input: {
        id: plan?.id,
        name: getValues("name"),
      },
    };

    updatePlan({ variables: variables });
  };

  const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
  const buttonColor = useColorModeValue("white", "white");

  return (
    <>
      <ModalOverlay />
      <ModalContent padding="12px 24px">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalCloseButton />
            <ModalHeader textAlign="left">
              Editando Plano: {plan?.name}
            </ModalHeader>

            <ModalBody>
              <FormControl mb=".75em">
                <FormLabel fontWeight="bold" fontSize="xs">
                  Nome do Plano
                </FormLabel>
                <Input defaultValue={plan?.name} {...register("name")} />
              </FormControl>

              <FormControl mb=".75em">
                <FormLabel color="gray.600" fontWeight="bold" fontSize="xs">
                  ANS
                </FormLabel>
                <Text color="gray.500">{plan?.ans}</Text>
              </FormControl>
              <FormControl mb=".75em">
                <FormLabel color="gray.600" fontWeight="bold" fontSize="xs">
                  SECAD
                </FormLabel>
                <Text color="gray.500">{plan?.secad}</Text>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                bg={buttonBg}
                color={buttonColor}
                onClick={onClose}
                variant="nohover"
              >
                Atualizar
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </>
  );
};

export default EditPlan;
