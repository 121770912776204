import {
	Button,
	Flex,
	Grid,
	GridItem,
	Select,
	Skeleton,
	Stack,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useGetProfessionals } from "hooks/calendar";
import React, { useEffect, useState } from "react";
import Statistics from "./components/Statistics";

import ScheduleTable from "./components/ScheduleTable";
import AwaitingScheduleTable from "./components/AwaitingScheduleTable";
import MissingScheduleTable from "./components/MissingScheduleTable";
import AutoScheduleTable from "./components/AutoScheduleTable";
import RefusalTable from "./components/RefusalTable";
import NoteModal from "./components/NoteModal";
import RegisterNoteModal from "./components/RegisterNoteModal";
import EventModal from "./components/EventModal";
import MessageModal from "./components/MessageModal";
import { useCommitmentsStatistics } from "hooks/commitmentsManagement";
import { months } from "variables/months";
import { getMonth, getYear } from "date-fns";
import CardsGroup from "./components/CardsGroup";
import { GET_PLANS } from "graphql/plans";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useRegionals } from "hooks/globalRegionals";
import { SIMPLE_REGIONAL } from "graphql/calendar";
import { roleConsu, roleEnfer } from "../../variables/roles";
import getCurrentYear from "../../functions/getCurrentYear";
import { storageFilter } from "functions/storageFilters";
import { clearRows } from "hooks/commitmentsManagement/actionCreators";
import { useDispatch } from "hooks/commitmentsManagement";

const CommitmentsManagement = () => {
	const defaultFilters = storageFilter("@commitments_management_filters");
	const currentDate = new Date();
	const currentYear = String(getYear(currentDate));
	const currentAlmostYear = String(getCurrentYear(currentDate));
	const currentMonth = String(getMonth(currentDate) + 1);
	const [pannel, setPannel] = useState(1);
	const [
		getProfessionals,
		{ data: professionals, loading: loadingProfessionals },
	] = useGetProfessionals();
	const { data: plans, loading: loadingPlans } = useQuery(GET_PLANS);
	const { regional: regionalId } = useRegionals();

	const dispatch = useDispatch();

	const [plan, setPlan] = useState(defaultFilters?.plan || null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [professional, setProfessional] = useState(
		defaultFilters?.professional || null
	);
	const [assessment, setAssessment] = useState(
		defaultFilters?.assessmentType || null
	);
	const [riskDS, setRiskDS] = useState(defaultFilters?.riskDS || null);
	const [riskPA, setRiskPA] = useState(defaultFilters?.riskPA || null);
	const [age, setAge] = useState(defaultFilters?.age || null);

	const [
		loadStatistics,
		{ data: statistics, loading: loadingStatistics },
	] = useCommitmentsStatistics();

	const [
		loadPlans,
		{ data: regional, loading: loadingRegional },
	] = useLazyQuery(SIMPLE_REGIONAL);

	const [beneficiary, setBeneficiary] = useState({});
	const [noteModal, setNoteModal] = useState(false);
	const [registerNoteModal, setRegisterModal] = useState(false);
	const [eventModal, setEventModal] = useState(false);
	const [messageModal, setMessageModal] = useState(false);

	const onPannelChange = (id) => {
		clearRows(dispatch);
		setPannel(id)
	}

	useEffect(() => { getProfessionals(); }, []);

	useEffect(() => {
		if (regionalId) {
			loadPlans({ variables: { id: regionalId } });
		}
	}, [regionalId]);

	useEffect(() => {
		const variables = {
			input: {
				plan: plan,
				month: month,
				year: year,
				professional: professional,
				assessmentType: assessment,
				riskDS: riskDS,
				riskPA: riskPA,
				age: age,
			},
		};
		loadStatistics({ variables: variables });

		const stringFilter = JSON.stringify({
			plan: plan,
			professional: professional,
			assessmentType: assessment,
			riskDS: riskDS,
			riskPA: riskPA,
			age: age,
		});
		window.localStorage.setItem(
			"@commitments_management_filters",
			stringFilter
		);
	}, [plan, month, year, professional, assessment, riskDS, riskPA, age]);

	const handleNoteModal = (name, notes) => {
		const values = {
			name: name,
			notes: notes,
		};
		setBeneficiary(values);
		setNoteModal(true);
	};

	const handleRegisterNoteModal = (id, name, title, notes) => {
		const values = {
			id: id,
			name: name,
			title: title,
			notes: notes,
		};

		setBeneficiary(values);
		setRegisterModal(true);
	};

	const handleEventModal = (beneficiary) => {
		setBeneficiary(beneficiary);
		setEventModal(true);
	};

	const handleMessageModal = (beneficiary) => {
		setBeneficiary(beneficiary);
		setMessageModal(true);
	};

	const years = [];
	for (let y = 2019; y <= currentAlmostYear; y++) {
		years.push(y);
	}

	const handleProfessionals = () => {
		const pro = regional?.simpleRegional?.professionals?.filter(
			(p) => p?.user?.role === roleConsu || p?.user?.role === roleEnfer
		);
		return pro?.map((p) => <option value={p?.id}>{p?.user?.name}</option>);
	};

	const textColor = useColorModeValue("black", "white");
	const hoverBg = useColorModeValue("gray.200", "gray.500");
	const inputHover = useColorModeValue("notrelife.800", "notrelife.800");

	return (
		<Flex direction="column" pt={{ base: "150px", lg: "75px" }}>
			<Card mb="36px">
				<CardHeader>
					<Flex>
						<Stack direction="column" spacing="18px" mr="18px" hidden={true}>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por ano
							</Text>
							<Skeleton isLoaded={!loadingRegional}>
								<Select
									value={year}
									focusBorderColor={inputHover}
									placeholder="Selecione uma opção..."
									onChange={({ target }) => setYear(target.value)}
									color="gray.500"
									size="sm"
									borderRadius="18px"
									cursor="pointer"
								>
									{years.map((i) => (
										<option value={i}>{i}</option>
									))}
								</Select>
							</Skeleton>
						</Stack>
						<Stack direction="column" spacing="18px" mr="18px" hidden={true}>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por mês
							</Text>
							<Skeleton isLoaded={!loadingRegional}>
								<Select
									value={month}
									focusBorderColor={inputHover}
									placeholder="Selecione uma opção..."
									onChange={({ target }) => setMonth(target.value)}
									color="gray.500"
									size="sm"
									borderRadius="18px"
									cursor="pointer"
								>
									{months.map((i) => (
										<option value={i.value}>{i.label}</option>
									))}
								</Select>
							</Skeleton>
						</Stack>
						<Stack direction="column" spacing="18px" mr="18px">
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por plano
							</Text>
							<Skeleton isLoaded={!loadingRegional}>
								<Select
									value={plan}
									focusBorderColor={inputHover}
									placeholder="Selecione uma opção..."
									onChange={({ target }) => setPlan(target?.value)}
									color="gray.500"
									size="sm"
									borderRadius="18px"
									cursor="pointer"
								>
									{regional?.simpleRegional?.plans?.map((p) => (
										<option value={p?.plan_name}>{p?.plan_name}</option>
									))}
								</Select>
							</Skeleton>
						</Stack>
						<Stack direction="column" spacing="18px" mr="18px">
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por profissional
							</Text>
							<Skeleton isLoaded={!loadingRegional}>
								<Select
									value={professional}
									focusBorderColor={inputHover}
									placeholder={"Selecione uma opção..."}
									onChange={({ target }) => setProfessional(target.value)}
									color="gray.500"
									size="sm"
									borderRadius="18px"
									cursor="pointer"
								>
									{handleProfessionals()}
								</Select>
							</Skeleton>
						</Stack>
						<Stack direction="column" spacing="18px" mr="18px">
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por compromisso
							</Text>
							<Skeleton isLoaded={!loadingRegional}>
								<Select
									value={assessment}
									focusBorderColor={inputHover}
									placeholder={"Selecione uma opção..."}
									onChange={({ target }) => setAssessment(target.value)}
									color="gray.500"
									size="sm"
									borderRadius="18px"
									cursor="pointer"
								>
									<option value="Declaração de Saúde">
										Declaração de Saúde
									</option>
									<option value="Primeiro Acolhimento">
										Primeiro Acolhimento
									</option>
									<option value="Pós Alta">Pós Alta</option>
									<option value="Saúde dos Ossos">Saúde dos Ossos</option>
									<option value="Rastreio Cognitivo">Rastreio Cognitivo</option>
									<option value="Auriculoterapia">Auriculoterapia</option>
									<option value="Acompanhamento">Acompanhamento</option>
								</Select>
							</Skeleton>
						</Stack>
						<Stack direction="column" spacing="18px" mr="18px">
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por estratificação DS
							</Text>
							<Skeleton isLoaded={!loadingRegional}>
								<Select
									value={riskDS}
									focusBorderColor={inputHover}
									placeholder={"Selecione uma opção..."}
									onChange={({ target }) => setRiskDS(target.value)}
									color="gray.500"
									size="sm"
									borderRadius="18px"
									cursor="pointer"
								>
									<option value="Risco Baixo">Risco Baixo</option>
									<option value="Risco Médio Baixo">Risco Médio Baixo</option>
									<option value="Risco Médio Alto">Risco Médio Alto</option>
									<option value="Risco Alto">Risco Alto</option>
								</Select>
							</Skeleton>
						</Stack>
						<Stack direction="column" spacing="18px" mr="18px">
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por estratificação PA
							</Text>
							<Skeleton isLoaded={!loadingRegional}>
								<Select
									value={riskPA}
									focusBorderColor={inputHover}
									placeholder={"Selecione uma opção..."}
									onChange={({ target }) => setRiskPA(target.value)}
									color="gray.500"
									size="sm"
									borderRadius="18px"
									cursor="pointer"
								>
									<option value="Risco Baixo">Risco Baixo</option>
									<option value="Risco Médio Baixo">Risco Médio Baixo</option>
									<option value="Risco Médio Alto">Risco Médio Alto</option>
									<option value="Risco Alto">Risco Alto</option>
								</Select>
							</Skeleton>
						</Stack>
						<Stack direction="column" spacing="18px" mr="18px">
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por faixa de idade
							</Text>
							<Skeleton isLoaded={!loadingRegional}>
								<Select
									value={age}
									focusBorderColor={inputHover}
									placeholder={"Selecione uma opção..."}
									onChange={({ target }) => setAge(target.value)}
									color="gray.500"
									size="sm"
									borderRadius="18px"
									cursor="pointer"
								>
									<option value="48">48 anos ou menos</option>
									<option value="49">49 anos ou mais</option>
								</Select>
							</Skeleton>
						</Stack>
					</Flex>
				</CardHeader>
			</Card>
			<Grid templateColumns={{ md: "repeat(2, 1fr)" }} gap="24px" mb="24px">
				<CardsGroup
					loading={loadingStatistics}
					statistics={statistics?.commitmentsStatistics}
				/>
				<Statistics
					loading={loadingStatistics}
					statistics={statistics?.commitmentsStatistics}
				/>
			</Grid>
			<Card mb="24px">
				<CardHeader>
					<Grid
						templateColumns={{
							sm: "1fr",
							sd: "repeat(2, 1fr)",
							lg: "repeat(5, 1fr)",
						}}
						alignItems="center"
						justifyItems="center"
						overflowX="scroll"
					>
						<GridItem mr="12px" mb="12px">
							<Button
								bgColor={1 === pannel ? "notrelife.800" : "transparent"}
								_hover={
									1 === pannel ? { bg: "notrelife.800" } : { bg: hoverBg }
								}
								color={1 === pannel ? "white" : textColor}
								onClick={() => onPannelChange(1)}
							>
								Aguardando agendamento
							</Button>
						</GridItem>
						<GridItem mr="12px" mb="12px">
							<Button
								bgColor={2 === pannel ? "notrelife.800" : "transparent"}
								_hover={
									2 === pannel ? { bg: "notrelife.800" } : { bg: hoverBg }
								}
								color={2 === pannel ? "white" : textColor}
								onClick={() => onPannelChange(2)}
							>
								Agendamentos
							</Button>
						</GridItem>
						<GridItem mr="12px" mb="12px">
							<Button
								bgColor={3 === pannel ? "notrelife.800" : "transparent"}
								_hover={
									3 === pannel ? { bg: "notrelife.800" } : { bg: hoverBg }
								}
								color={3 === pannel ? "white" : textColor}
								onClick={() => onPannelChange(3)}
							>
								Não comparecimento
							</Button>
						</GridItem>
						<GridItem mr="12px" mb="12px">
							<Button
								bgColor={4 === pannel ? "notrelife.800" : "transparent"}
								_hover={
									4 === pannel ? { bg: "notrelife.800" } : { bg: hoverBg }
								}
								color={4 === pannel ? "white" : textColor}
								onClick={() => onPannelChange(4)}
								justifyContent="center"
								alignItems="center"
							>
								Público 48-
							</Button>
						</GridItem>
						<GridItem mr="12px" mb="12px">
							<Button
								bgColor={5 === pannel ? "notrelife.800" : "transparent"}
								_hover={
									5 === pannel ? { bg: "notrelife.800" } : { bg: hoverBg }
								}
								color={5 === pannel ? "white" : textColor}
								onClick={() => onPannelChange(5)}
							>
								Recusa/Reiterada Ausência/Desinteresse
							</Button>
						</GridItem>
					</Grid>
				</CardHeader>
				<CardBody>
					{pannel === 1 ? (
						<AwaitingScheduleTable
							year={year}
							plan={plan}
							month={month}
							professional={professional}
							assessment={assessment}
							riskDS={riskDS}
							riskPA={riskPA}
							age={age}
							handleNoteModal={handleNoteModal}
							handleRegisterNoteModal={handleRegisterNoteModal}
							handleEventModal={handleEventModal}
						/>
					) : pannel === 2 ? (
						<ScheduleTable
							plan={plan}
							year={year}
							month={month}
							professional={professional}
							assessment={assessment}
							riskDS={riskDS}
							riskPA={riskPA}
							age={age}
							handleNoteModal={handleNoteModal}
							handleRegisterNoteModal={handleRegisterNoteModal}
							handleEventModal={handleEventModal}
						/>
					) : pannel === 3 ? (
						<MissingScheduleTable
							plan={plan}
							year={year}
							month={month}
							professional={professional}
							assessment={assessment}
							riskDS={riskDS}
							riskPA={riskPA}
							age={age}
							handleNoteModal={handleNoteModal}
							handleRegisterNoteModal={handleRegisterNoteModal}
							handleEventModal={handleEventModal}
						/>
					) : pannel === 4 ? (
						<AutoScheduleTable
							plan={plan}
							year={year}
							month={month}
							professional={professional}
							assessment={assessment}
							riskDS={riskDS}
							riskPA={riskPA}
							age={age}
							handleMessageModal={handleMessageModal}
						/>
					) : pannel === 5 ? (
						<RefusalTable
							plan={plan}
							year={year}
							month={month}
							professional={professional}
							assessment={assessment}
							riskDS={riskDS}
							riskPA={riskPA}
							age={age}
							handleEventModal={handleEventModal}
						/>
					) : (
						<></>
					)}
				</CardBody>
			</Card>
			<NoteModal
				beneficiary={beneficiary}
				open={noteModal}
				setNoteModal={setNoteModal}
			/>
			{registerNoteModal && (
				<RegisterNoteModal
					beneficiary={beneficiary}
					open={registerNoteModal}
					setRegisterModal={setRegisterModal}
				/>
			)}
			{eventModal && (
				<EventModal
					beneficiary={beneficiary}
					open={eventModal}
					setEventModal={setEventModal}
				/>
			)}
			<MessageModal
				beneficiary={beneficiary}
				open={messageModal}
				setMessageModal={setMessageModal}
			/>
		</Flex>
	);
};

export default CommitmentsManagement;
