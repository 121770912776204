import React, {useState} from "react"
import {useHistory, useLocation} from "react-router-dom"

import {Box, Button, Flex, Grid, Text, useColorModeValue} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ReportTable from "./components/ReportTable";
import ReportCreateModal from "./components/ReportCreateModal";
import { useAuth } from "hooks/useAuth";

const Reports = () => {
    const { user } = useAuth()
    const [modal, setModal] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const textColor = useColorModeValue("gray.700", "white");
    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const buttonColor = useColorModeValue("white", "white");

    const handleClick = () => {
        setModal(true);
    }

    return (
        <>
            <Grid
                templateColumns={{ sm: "1fr", md: "1fr" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="12px"
                pt={{ sm: "125px", lg: "160px" }}
            >
                <Card px="0px" pb="24px" minHeight="320px">
                    <CardHeader mb="34px" px="22px">
                        <Box w="100%">
                            <Flex
                                justify="space-between"
                                align="center"
                                fontSize={{sm: "10px", lg: "12px"}}
                                color="gray.400"
                            >
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Relatórios
                                </Text>
                                {
                                    !['Recepcionista', 'Assistende ADM'].includes(user?.role) &&
                                    <Button
                                        bg={buttonBg}
                                        variant="no-hover"
                                        color={buttonColor}
                                        onClick={() => handleClick()}>
                                        Criar Relatório
                                    </Button>
                                }
                            </Flex>
                        </Box>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <ReportTable/>
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            <ReportCreateModal
                open={modal}
                setModal={setModal}
            />

        </>
    )
}
export default Reports;