import React from "react";
import {useHealthStatement} from "hooks/healthStatement";
import {useFirstReception} from "../../../hooks/firstReception";
import {useAccompaniment} from "../../../hooks/accompaniment";
import BeneficiaryAssestmentTable from "views/Beneficiaries/ViewBeneficiary/components/BeneficiaryAssessmentTable"
import Flexbox from "./Flexbox";

export default ({title, identifierId, state, ...props}) => {
    const {loading: isLoadingHs, healthstatement} = useHealthStatement();
    const {loading: isLoadingFr, firstreception} = useFirstReception();
    const {loading: isLoadingAc, accompaniment} = useAccompaniment();

    const loading = isLoadingHs || isLoadingFr || isLoadingAc;

    let form = accompaniment;
    if (!!state.ds) form = healthstatement;
    if (!!state.fr) form = firstreception;

    return (
        loading
            ? (<Flexbox title={title} content={"Carregando..."} {...props}> </Flexbox>)
            : !!state?.ac
                ? (form?.assessmentstwo?.map((f, index) => <Flexbox title={title + " " + (index + 1)} content={<BeneficiaryAssestmentTable assessment={f}/>}  {...props}> </Flexbox>))
                : (<Flexbox title={title} content={<BeneficiaryAssestmentTable assessment={form?.assessment}/>} {...props}> </Flexbox>)
    );
}