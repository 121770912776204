import { gql } from '@apollo/client';

export const GET_CPT_LIST = gql`
    query GetCptList($perPage: Int, $page: Int, $where: QueryCptsWhereWhereConditions, $search: String) {
        cpts(first: $perPage, page: $page, where: $where, search: $search) {
            paginatorInfo {
                count
                currentPage
                perPage
                total
            }
            data {
                id
                name
                email
                kinship
                cpf
                gender
                phone
                birthdate
                plan_name
                plan_ans
                regional
                proposal_number
                established_in
                status
                assign_at
                assign_plan_at
                inserted_at
                reason_disable
                reason_disable_extended
            }
        }
    }`
export const GET_CPT = gql`
    query GetCpt($id: ID!) {
        cpt(id: $id) {
            id
            identifier_id
            name
            email
            kinship
            cpf
            gender
            phone
            birthdate
            plan_name
            plan_ans
            regional
            proposal_number
            established_in
            status
            assign_at
            assign_plan_at
            inserted_at
            awaiting_diseases
            reason_disable
            user_id
            reason_disable_extended
            diseases {
                id
                code
                name
            }
        }
    }
`
export const UPLOAD_CONFIG = gql`
    mutation UPLOAD_CONFIG($file: Upload!) {
        uploadConfig(file: $file) {
            status
        }
    }
`
export const GET_CID_COUNT = gql`
    query GET_COUNT_CID($search: Mixed!) {
        cid(where: {
            column: "name", operator: LIKE, value: $search
        })
    }
`
export const GET_CID_CODES = gql`
    query GET_COUNT_CODES($search: Mixed!) {
        getCidCodes(
            where: {
                column: "name", operator: IS_NOT_NULL,
                OR: [
                    {column: "name", operator: LIKE, value: $search},
                    {column: "code", operator: LIKE, value: $search}
                ]
            },
            limit: 60
        ) {
            id
            name
            code
        }
    }
`
export const GET_CID_FOUND_ANOTHER_MEASUREMENTS = gql`
    query GET_CID_FOUND_ANOTHER_MEASUREMENTS($cpt: QueryCidFoundAnotherMeasurementsCptWhereHasConditions) {
        cidFoundAnotherMeasurements(cpt: $cpt) {
            id
            name
            code
        }
    }
`
export const SET_CID_CPT = gql`
    mutation SET_CID_CPT($input: UpdateCPTInput!) {
        partialCoverage(input: $input) {
            id
            identifier_id
            name
            email
            kinship
            cpf
            gender
            phone
            birthdate
            plan_name
            plan_ans
            regional
            proposal_number
            established_in
            status
            assign_at
            assign_plan_at
            inserted_at
            awaiting_diseases
            reason_disable
            user_id
            reason_disable_extended
            diseases {
                code
                name
            }
        }
    }
`

export const UPDATE_CPT = gql`
    mutation UPDATE_CPT($input: UpdateCPTInput!) {
        partialCoverage(input: $input) {
            id
            identifier_id
            name
            email
            kinship
            cpf
            gender
            phone
            birthdate
            plan_name
            plan_ans
            regional
            proposal_number
            established_in
            status
            assign_at
            assign_plan_at
            inserted_at
            awaiting_diseases
            reason_disable
            user_id
            reason_disable_extended
            diseases {
                code
                name
            }
        }
    }
`
export const DELETE_CPT = gql`
    mutation DELETE_CPT($id: ID!) {
        deletePartialCoverage(id: $id) {
            id
            name
        }
    }
`

export const SET_ASSIGN_CPT = gql`
    mutation SET_ASSIGN_CPT($ids: [ID!]!) {
        assignPartialCoverage(id: $ids) {
            id
            name
            email
            kinship
            cpf
            gender
            phone
            birthdate
            plan_name
            plan_ans
            regional
            proposal_number
            established_in
            status
            assign_at
            assign_plan_at
            inserted_at
            reason_disable
            reason_disable_extended
        }
    }
`