import React, { useState } from "react";

import {
	Box,
	Grid,
	Flex,
	Text,
	useColorModeValue,
	Button,
	Select,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useHistory, useLocation } from "react-router-dom";

import WhatsappDisclosuresTable from "components/Tables/Whatsapp/WhatsappDisclosuresTable";

import exemplo from "assets/csv/exemplo_whatsapp.csv";
import { storageFilter } from "functions/storageFilters";

export default () => {
	const defaultFilters = storageFilter('@whatsapp_filters')
	const location = useLocation();
	const history = useHistory();

	const textColor = useColorModeValue("gray.700", "white");
	const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
	const buttonColor = useColorModeValue("white", "white");

	const handleClick = () => {
		history.push(`${location.pathname}/criar`);
	};

	const handleDownload = () => {
		window.open(exemplo, '_blank');
	};

	const [invitation_type, setInvitationType] = useState(defaultFilters?.invitation_type || null);

	const handleInvitationType = (e) => {
		setInvitationType(e.target.value);
		const stringFilter = JSON.stringify({ invitation_type: e.target.value })
		window.localStorage.setItem('@whatsapp_filters', stringFilter)
	};

	return (
		<Grid
			templateColumns={{ sm: "1fr", md: "1fr" }}
			templateRows={{ sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)" }}
			gap="12px"
			pt={{ sm: "125px", lg: "160px" }}
		>
			<Card px="0px" pb="24px" minHeight="320px">
				<CardHeader mb="34px" px="22px">
					<Box w="100%">
						<Flex
							justify="space-between"
							align="center"
							fontSize={{ sm: "10px", lg: "12px" }}
							color="gray.400"
						>
							<Text color={textColor} fontSize="lg" fontWeight="bold">
								Whatsapp
							</Text>
							<Box>
								<Button
									bg={buttonBg}
									variant="no-hover"
									style={{ marginRight: "15px" }}
									color={buttonColor}
									onClick={() => handleDownload()}
								>
									Baixar Planilha Modelo
								</Button>
								<Button
									bg={buttonBg}
									variant="no-hover"
									style={{ marginRight: "15px" }}
									color={buttonColor}
									onClick={() => handleClick()}
								>
									Criar Divulgação
								</Button>
							</Box>
						</Flex>
					</Box>
				</CardHeader>
				<CardBody h="100%">
					<Box w="100%" h="100%">
						<Box ml="14px" maxW="20%">
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Filtrar por tipo de divulgação
							</Text>

							<Select
								color="gray.500"
								size="sm"
								borderRadius="12px"
								cursor="pointer"
								placeholder="Selecione..."
								onChange={handleInvitationType}
								value={invitation_type}
							>
								<option value="campaign">Campanha</option>
								<option value="activity">Atividade</option>
								<option value="lecture">Palestra</option>
							</Select>
						</Box>
						<WhatsappDisclosuresTable type_filter={invitation_type} />
					</Box>
				</CardBody>
			</Card>
		</Grid>
	);
};
