import { createElement, useEffect, useState } from "react";
import { Box, Button, Divider, Flex } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useAuth } from "hooks/useAuth";
import { useParams } from "react-router-dom";
import { usePaginateCampaignReceivers } from "hooks/whatsappDisclosures";
import DisclosureCampaignReceiversTable from "../../../../components/Tables/Whatsapp/DisclosureCampaignReceiversTable";

const menu = [
  { title: "Aceitos", value: "confirmed" },
  { title: "Suplentes", value: "interested" },
  { title: "Cancelados", value: "canceled" },
  { title: "Aguardando resposta", value: null },
];

const PannelHandler = ({ colorMode }) => {
  const { user } = useAuth();
  const { id } = useParams();
  const [
    paginateReceivers,
    { loading, error },
  ] = usePaginateCampaignReceivers();
  const [pannel, setPannel] = useState(0);
  const textColor = colorMode === "light" ? "black" : "gray.500";
  const hoverBg = colorMode === "light" ? "gray.200" : "gray.200";

  useEffect(() => {
    paginateReceivers({
      variables: { whatsapp_disclosure_id: id, status: menu[pannel]?.value },
    });
  }, [pannel]);

  return (
    <Card
      px="0px"
      pb="0px"
      minHeight="480px"
      overflowX={{ sm: "auto", lg: "unset" }}
    >
      <CardHeader px="22px" mb="0px">
        <Flex flexDirection="column" width="100%">
          <Flex justifyContent="space-around" alignItems="top" flexWrap="wrap">
            {menu.map((m, index) => (
              <Button
                key={index}
                bgColor={index === pannel ? "notrelife.800" : "transparent"}
                _hover={
                  index === pannel ? { bg: "notrelife.800" } : { bg: hoverBg }
                }
                color={index === pannel ? "white" : textColor}
                mr="12px"
                mb="12px"
                onClick={() => setPannel(index)}
              >
                <Flex justifyContent="center" alignItems="center">
                  {m.title}
                </Flex>
              </Button>
            ))}
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody h="100%" width="100%">
        <Box w="100%" px="22px" pb="22px">
          <DisclosureCampaignReceiversTable
            id={id}
            status={menu[pannel]?.value}
          />
        </Box>
      </CardBody>
    </Card>
  );
};

export default PannelHandler;
