import {BeneficiaryProvider as Provider} from "hooks/beneficiary"
import {ManagerQueryProvider} from "../services/manager";

const BeneficiaryProvider = ({children}) => {
    return (
        <ManagerQueryProvider>
            <Provider>
                {children}
            </Provider>
        </ManagerQueryProvider>
    )
}

export default BeneficiaryProvider
