export const assessmentsTypes = {
  "declaracao_saude": "Declaração de Saúde",
  "primeiro_acolhimento_49_mais|primeiro_acolhimento_0_a_48": "Primeiro Acolhimento",
  "primeiro_acolhimento_49_mais": "Primeiro Acolhimento",
  "primeiro_acolhimento_0_a_48": "Primeiro Acolhimento",
  "pos_alta": "Pós alta",
  "saude_dos_ossos": "Saúde dos Ossos",
  "rastreio_cognitivo": "Rastreio Cognitivo",
  "auriculoterapia": "Auriculoterapia",
}
