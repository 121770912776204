import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";
import states from "variables/states";
import cities from "variables/cities";
import getCEP from "../../../functions/getCEP";
import dateFormat from "functions/dateFormat";

export default ({ defaultValues } = {}) => {
  const {
    register,
    watch,
    setValue,
    unregister,
    setError,
    formState: { errors },
  } = useFormContext();

  const textColor = useColorModeValue("gray.700", "white");
  const inputHover = useColorModeValue("notrelife.800", "notrelife.800");
  const [cep, setCep] = useState(watch("cep", ""));
  const toast = useToast();
  const wAmbience = watch("ambience", "virtual");
  const wState = watch("state_id");

  useEffect(() => {
    if (cep) {
      const cleanCEP = cep.replace(/[^0-9]/g, "");
      if (cleanCEP && cleanCEP.length === 8) {
        getCEP(cleanCEP)
          .then((data) => {
            if (data) {
              if (data?.erro) throw new Error("Cep não encontrado");

              if (data.uf) {
                const state = states.find(
                  (state) => state.initials === data.uf
                );
                if (state) setValue("state_id", String(state.id));
              }
              if (data.logradouro) setValue("address", data.logradouro);
              if (data.complemento) setValue("complement", data.complemento);
              if (data.bairro) setValue("district", data.bairro);
              if (data.localidade) {
                const city = cities.find(
                  (city) => city.name === data.localidade
                );
                if (city) setValue("city_id", String(city.id));
              }
            }
          })
          .catch(() => {
            toast({
              title: "CEP não encontrado.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });

            setError("cep", { type: "custom", message: "CEP inválido" });
          });
      }
    }
  }, [cep]);

  const unregisterPresencialFields = () => {
    unregister("cep");
    unregister("address");
    unregister("district");
    unregister("complement");
    unregister("city_id");
    unregister("state_id");
    unregister("capacity");
  };

  useEffect(() => {
    wAmbience === "presencial"
      ? unregister("url")
      : unregisterPresencialFields();
  }, [wAmbience]);

  return (
    <>
      <FormControl isInvalid={errors.about}>
        <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
          Sobre
        </FormLabel>
        <Input
          {...register("about")}
          focusBorderColor={inputHover}
          borderRadius="8px"
          fontSize="md"
          color="gray.400"
          defaultValue={defaultValues?.about}
        />
        {errors.about && (
          <FormErrorMessage>{errors.about.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={errors.scheduled_to}>
        <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
          Dia e hora
        </FormLabel>
        <Input
          {...register("scheduled_to")}
          as={InputMask}
          mask="99/99/9999 99:99"
          placeholder="04/10/2021 12:00"
          focusBorderColor={inputHover}
          borderRadius="8px"
          fontSize="md"
          defaultValue={
            defaultValues?.scheduled_to
              ? dateFormat(defaultValues?.scheduled_to, "dd/MM/Y HH:mm")
              : null
          }
        />
        {errors.scheduled_to && (
          <FormErrorMessage>{errors.scheduled_to.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={errors.ambience}>
        <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
          Ambiente
        </FormLabel>
        <Select
          {...register("ambience")}
          focusBorderColor={inputHover}
          borderRadius="8px"
          fontSize="md"
          defaultValue={defaultValues?.ambience || "virtual"}
        >
          <option value="presencial">Presencial</option>
          <option value="virtual">Virtual</option>
        </Select>

        {errors.ambience && (
          <FormErrorMessage>{errors.ambience.message}</FormErrorMessage>
        )}
      </FormControl>

      {wAmbience === "virtual" ? (
        <FormControl isInvalid={errors.url}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Endereço Virtual
          </FormLabel>
          <Input
            {...register("url")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.url}
          />
          {errors.url && (
            <FormErrorMessage>{errors.url.message}</FormErrorMessage>
          )}
        </FormControl>
      ) : (
        <Grid templateColumns="repeat(2, 1fr)" gap="12px">
          <FormControl isInvalid={errors.cep}>
            <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
              CEP
            </FormLabel>
            <Input
              {...register("cep")}
              as={InputMask}
              mask="99999-999"
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              onChange={({ target }) => setCep(target.value)}
              defaultValue={defaultValues?.cep}
            />
            {errors.cep && (
              <FormErrorMessage>{errors.cep.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors.state_id}>
            <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
              Estado
            </FormLabel>
            <Select
              {...register("state_id")}
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              placeholder="Selecione uma opção..."
              color="gray.400"
              defaultValue={defaultValues?.state_id}
            >
              {states.map((state) => (
                <option value={state.id}>{state.name}</option>
              ))}
            </Select>
            {errors.state_id && (
              <FormErrorMessage>{errors.state_id.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors.city_id}>
            <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
              Cidade
            </FormLabel>
            <Select
              {...register("city_id")}
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              placeholder={
                !wState ? "Selecione um estado..." : "Selecione uma opção..."
              }
              color="gray.400"
              disabled={!wState}
              defaultValue={defaultValues?.city_id}
            >
              {cities
                .filter((city) => city.state_id == wState)
                .map((city) => (
                  <option value={city.id}>{city.name}</option>
                ))}
            </Select>
            {errors.city_id && (
              <FormErrorMessage>{errors.city_id.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors.address}>
            <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
              Endereço Completo
            </FormLabel>
            <Input
              {...register("address")}
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              defaultValue={defaultValues?.address}
            />
            {errors.address && (
              <FormErrorMessage>{errors.address.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors.district}>
            <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
              Bairro
            </FormLabel>
            <Input
              {...register("district")}
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              defaultValue={defaultValues?.district}
            />
            {errors.district && (
              <FormErrorMessage>{errors.district.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors.complement}>
            <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
              Complemento
            </FormLabel>
            <Input
              {...register("complement")}
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              defaultValue={defaultValues?.complement}
            />
            {errors.complement && (
              <FormErrorMessage>{errors.complement.message}</FormErrorMessage>
            )}
          </FormControl>
        </Grid>
      )}
    </>
  );
};
