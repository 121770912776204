import { ACTION_TYPES } from "./reducers";

const paginateBeneficiaries = (paginate, dispatch) => {
	dispatch({
		type: ACTION_TYPES.PAGINATE,
		data: paginate
	})
}

const createBeneficiary = (beneficiary, dispatch) => {
	dispatch({
		type: ACTION_TYPES.CREATE,
		data: beneficiary
	})
}

const viewBeneficiary = (beneficiary, dispatch) => {
	dispatch({
		type: ACTION_TYPES.VIEW,
		data: beneficiary
	})
}

const updateBeneficiary = (beneficiary, dispatch) => {
	dispatch({
		type: ACTION_TYPES.UPDATE,
		data: beneficiary
	})
}

const cancelBeneficiary = (beneficiary, dispatch) => {
	dispatch({
		type: ACTION_TYPES.CANCEL_ACTIVE,
		data: beneficiary
	})
}

const createLifePlan = (lifePlan, dispatch) => {
	dispatch({
		type: ACTION_TYPES.CREATE_LIFE_PLAN,
		data: lifePlan
	})
}

const removeLifePlan = (lifePlanId, dispatch) => {
	dispatch({
		type: ACTION_TYPES.REMOVE_LIFE_PLAN,
		data: lifePlanId
	})
}

const createPhoneContacts = (phoneContact, dispatch) => {
	dispatch({
		type: ACTION_TYPES.CREATE_PHONE_CONTACT,
		data: phoneContact
	})
}

export {
	paginateBeneficiaries,
	createBeneficiary,
	viewBeneficiary,
	updateBeneficiary,
	cancelBeneficiary,
	createLifePlan,
	removeLifePlan,
	createPhoneContacts
}