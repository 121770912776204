import { createContext, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import {GET_STATISTICS_EVENTS_DATE, GET_EVENTS_DAY_PAGINATE, CANCEL_EVENT} from "services/calendar/sql/events";
import { ACTION_TYPES, reducers } from "./reducers";
import {useQuery, useLazyQuery, useMutation} from "@apollo/client";

const StatisticHookState = createContext()
const StatisticHookDispatch = createContext()


const StatisticHookProvider = ({ children }) => {
    const { day } = useParams()
    const [state, dispatch] = useReducer(reducers, {statistics: {}, day_events_page: {data: []}});

    const [loadStatistics, { data, loading, error}] = useLazyQuery(GET_STATISTICS_EVENTS_DATE, {
        onCompleted: (data) => {
            dispatch({type: ACTION_TYPES.STATISTICS, data})
        }
    })

    return (
        <StatisticHookState.Provider value={{ ...state, ...data, loadStatistics, loading, error}}>
            <StatisticHookDispatch.Provider value={dispatch}>
                {children}
            </StatisticHookDispatch.Provider>
        </StatisticHookState.Provider>
    )
}

const useDispatch = () => {
    const dispatch = useContext(StatisticHookDispatch)

    if (!dispatch) throw new Error("Falta o provider de estatisticas do calendario")

    return dispatch
}
const useStatistics = () => {
    const state = useContext(StatisticHookState)
    if (!state) throw new Error("Falta o provider de estatisticas do calendario")

    return state
}

const useEventsDay = () => {
    const state = useContext(StatisticHookState)
    if (!state) throw new Error("Falta o provider de estatisticas do calendario")

    return state
}

const useEventsDayLazyLoading = (options) => {
    const dispatch = useDispatch();
    return useLazyQuery(GET_EVENTS_DAY_PAGINATE, {
        ...options,
        onCompleted: (data) => {
            dispatch({type: ACTION_TYPES.PAGINATE_DAY_EVENTS, data: data})
        }
    })
}
const useCancelEvent = (id, options) => {
    const dispatch = useDispatch();
    const {onCompleted} = options
    const { day } = useParams()

    return useMutation(CANCEL_EVENT, {
        variables: {id},
        onCompleted: (data) => {
            if (onCompleted) onCompleted(data)
            dispatch({ type: ACTION_TYPES.EVENT_CANCELED, data: data.cancelEvent })
        }, refetchQueries: [
            {
                query: GET_STATISTICS_EVENTS_DATE,
                variables: {date: day}
            },
        ]
    })
}
export {
    StatisticHookProvider,
    useStatistics,
    useEventsDayLazyLoading,
    useCancelEvent,
    useEventsDay
}
