import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_PLANS,
  GET_PLANS_PAGINATE,
  UPDATE_PLAN,
} from "graphql/plans";
import { ACTION_TYPES, reducers } from "./reducers";
import { useFilter } from "hooks/useFilter";

const PlansStateContext = createContext();
const PlansDispatchContext = createContext();

const PlansProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });
  const { filters } = useFilter();

  const { data, loading, error } = useQuery(GET_PLANS_PAGINATE, {
    variables: {
      filters,
    },
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.plansPaginate });
    },
  });

  return (
    <PlansStateContext.Provider value={{ loading, error, ...state }}>
      <PlansDispatchContext.Provider value={dispatch}>
        {children}
      </PlansDispatchContext.Provider>
    </PlansStateContext.Provider>
  );
};

const usePlans = () => {
  const context = useContext(PlansStateContext);

  if (!context)
    throw new Error(
      "usePlans must be used within an PlansProvider"
    );

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(PlansDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a PlansProvider");

  return dispatch;
};

const usePaginatePlans= () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_PLANS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.plansPaginate });
    },
  });
};

const useUpdatePlan = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_PLAN, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.updatePlan);
      dispatch({
        type: ACTION_TYPES.UPDATE_PLAN,
        data: data.updatePlan,
      });
    },
    onError: (error) => {
      if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation);
    },
    refetchQueries: [
      {
        query: GET_PLANS,
      },
    ],
  });
};

export {
  PlansProvider,
  usePlans,
  usePaginatePlans,
  useUpdatePlan,
};
