import { gql } from '@apollo/client';

export const GET_USERS =  gql`
    query GetUsers {
        users {
            id
            name
            email
            role
            status
            created_at
            professional {
                id
            }
        }
    }
`

export const GET_USERS_PAGINATE = gql`
    query GET_USERS($first: Int! = 30, $page: Int! = 1, $search: String) {
        usersPaginate(first: $first, page: $page, search: $search) {
            paginatorInfo {
                count
                currentPage
                firstItem
                perPage
                lastItem
                total
            }
            data {
                id
                name
                email
                role
                status
                created_at
                professional {
                    id
                    assistedProfessionals {
                        id
                        user {
                            name
                        }
                    }
                }
            }
        }
    }
`

export const GET_USER = gql`
    query GetUser($id: ID!) {
        user(id: $id) {
            id
            name
            email
            role
            status
            professional {
                id
                cpf
                phone
                birthdate
                regionals {
                    id
                    name
                }
                assistedProfessionals {
                    id
                    user {
                        name
                    }
                }
            }
        }
    }
`

export const CREATE_USER = gql`
    mutation CreateUser($input: CreateUserInput) {
        createUser(input: $input) {
            id
            name
            email
            role
            status
            created_at
            professional {
                id
            }
        }
    }
`

export const UPDATE_USER = gql`
    mutation UpdateUser($input: UpdateUserInput) {
        updateUser(input: $input) {
            id
        }
    }
`
