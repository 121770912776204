import React from "react"

import { Box, Button, Divider, Flex, Grid, Skeleton, Text, } from "@chakra-ui/react";
import Icon from "components/FontAwesomeIcons/Icon";
import { fromAnyFormatToBr } from "functions/momentFormat";
import { useDocuments, useRequestAuthorizedLink } from "hooks/documents";

const BeneficiaryFiles = () => {
    const { documents, data, loading } = useDocuments();
    const [requestAuthorizedLink, { data: doc, loading: preparingLink }] = useRequestAuthorizedLink();
    const onRequestAuthorizedLink = async (docId) => {
        const ret = await requestAuthorizedLink({
            variables: {
                docId: docId
            }
        })
        window.open(ret.data.renewDocumentLink.url);
    }
    return (
        <Flex width="100%" height="100%" direction="column">
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="20px"
                width="100%"
            >
                {
                    loading ?
                        <Box width="100%">
                            <Flex width="100%" fontSize="15px">
                                <Icon icon="note" margin="25" size="xl" />
                                <Grid
                                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                    templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                                    gap="6px"
                                    fontSize="20px"
                                    width="100%"
                                >
                                    <Skeleton isLoaded={!loading} height="27px" />
                                    <Skeleton isLoaded={!loading} height="30px" />
                                </Grid>
                            </Flex>
                        </Box>
                        :
                        !documents || documents.length === 0 ?
                            <Text fontSize="lg" fontWeight="bold">
                                Não há registro de arquivos para o beneficiário
                            </Text>
                            :
                            documents.map((doc, x) => (
                                <Box width="100%">
                                    <Flex width="100%" fontSize="15px">
                                        <Icon icon="document" margin="25" size="xl" />
                                        <Grid
                                            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                                            gap="15px"
                                            fontSize="20px"
                                        >
                                            <Text fontSize="20px">
                                                {doc.label}
                                            </Text>
                                            <Text fontSize="16px">
                                                Adicionado em: {fromAnyFormatToBr(doc.created_at)}
                                            </Text>
                                            <Button
                                                isLoading={preparingLink}
                                                variant="solid"
                                                bg={"notrelife.800"}
                                                onClick={() => onRequestAuthorizedLink(doc.id)}
                                                _hover={{ backgroundColor: "notrelife.600" }}
                                                _active={{ backgroundColor: "notrelife.900" }}
                                                color="white"
                                                minW="200px"
                                            >
                                                <Icon icon="download" margin="15" /> Baixar
                                            </Button>
                                        </Grid>
                                    </Flex>
                                    {x + 1 < documents.length &&
                                        <Divider orientation="horizontal" mt="20px" height="2px"
                                            backgroundColor="gray.300" />}
                                </Box>
                            ))
                }
            </Grid>
        </Flex>
    )
}

export default BeneficiaryFiles