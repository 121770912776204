const ACTION_TYPES = {
  LIST: 'LIST_LIFE_PLANS',
  VIEW: 'VIEW_LIFE_PLAN',
  ADD: 'ADD_LIFE_PLAN',
  UPDATE: 'UPDATE_LIFE_PLAN',
  REMOVE: 'REMOVE_LIFE_PLAN',
}

const reducers = (state, action) => {
  if(!action.data || !action.type) return state
  switch (action.type) {
    case ACTION_TYPES.LIST: {
      return { 
        ...state, 
        plans: action.data
      }
    }
    case ACTION_TYPES.ADD: {
      return { 
        ...state, 
        plans: [ ...state.plans, action.data ] 
      }
    }
    default: {
      return state
    }
  }
}

export {
  reducers,
  ACTION_TYPES
}