import { useQuery } from "@apollo/client";
import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner
} from "@chakra-ui/react";
import { GET_BENEFICIARY_EVALUATION } from "graphql/beneficiaries";
import BeneficiaryAssessmentTable from "./BeneficiaryAssessmentTable";


const EvaluationModal = ({ id, title, onClose, isOpen }) => {
    const { data, loading } = useQuery(GET_BENEFICIARY_EVALUATION, {
        variables: {
            where: {
                "column": "ASSESSMENT_ID",
                "operator": "EQ",
                "value": id,
            }
        }
    })

    return (
        <Modal
            isCentered
            onClose={() => { onClose(false) }}
            isOpen={isOpen}
            motionPreset='slideInBottom'
            size={"xl"}
            autoFocus={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody
                    maxH="600px"
                    overflowY="auto"
                >
                    {
                        loading ?
                            <Flex width="100%" h="100%" minH="60px" justify="center" align="center">
                                <Spinner />
                            </Flex>
                            : data && <BeneficiaryAssessmentTable assessment={data} />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        backgroundColor='notrelife.800'
                        color="white"
                        variant="no-hover"
                        type="submit"
                        onClick={() => onClose()}
                    >
                        Fechar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}


export default EvaluationModal;
