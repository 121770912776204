import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Box, Button, Flex, Grid, Text, useColorModeValue, useDisclosure, } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import BeneficiariesTable from "./BeneficiariesTable";
import MassBeneficiaryCancellation from "./components/MassBeneficiaryCancellation";
import { useAuth } from "../../hooks/useAuth";
import { roleSuper } from 'variables/roles';


const Beneficiaries = () => {
    const location = useLocation();
    const history = useHistory();
    const { user } = useAuth();

    const { isOpen, onOpen, onClose } = useDisclosure()

    const textColor = useColorModeValue("gray.700", "white");
    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const buttonColor = useColorModeValue("white", "white");

    const handleClick = () => {
        history.push(`${location.pathname}/criar`);
    };

    return (
        <Flex
            direction="column"
            minH="100vh"
        >
            <Grid
                templateColumns={{ sm: "1fr", md: "1fr" }}
                templateRows={{ sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)" }}
                gap="12px"
                pt={{ sm: "125px", lg: "160px" }}
            >
                <Card px="0px" pb="24px" minHeight="320px">
                    <CardHeader mb="34px" px="22px">
                        <Box w="100%">
                            <Flex
                                justify="space-between"
                                align="center"
                                fontSize={{ sm: "10px", lg: "12px" }}
                                color="gray.400"
                            >
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Beneficiários
                                </Text>
                                <Flex direction={"row"}>
                                    <Button
                                        bg={buttonBg}
                                        variant="no-hover"
                                        style={{ marginRight: "15px" }}
                                        color={buttonColor}
                                        onClick={() => handleClick()}
                                    >
                                        Cadastro de Proposta
                                    </Button>
                                    <Button
                                        bg={buttonBg}
                                        variant="no-hover"
                                        color={buttonColor}
                                        hidden={user?.role !== roleSuper}
                                        onClick={onOpen}>
                                        Cancelar Planos em Lote
                                    </Button>
                                </Flex>
                            </Flex>
                        </Box>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <BeneficiariesTable />
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            {
                isOpen &&
                <MassBeneficiaryCancellation
                    open={isOpen}
                    close={onClose}
                />
            }
        </Flex>
    );
};

export default Beneficiaries;
