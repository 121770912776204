import React from "react"

import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
} from "@chakra-ui/react"
import {useForm} from "react-hook-form";

export default ({data, title, message, onConfirm, btnMessage, isOpen, closeModal, isLoading = false, select = []}) => {
    const {handleSubmit, register, getValues, watch} = useForm();
    const wReason = watch("reason");

    const onSubmit = () => {

        const reason = getValues("reason");
        let extended = null;

        if (reason === "anothers") {
            extended = getValues("extended");
        }

        onConfirm(data, reason, extended);
    };
    return (
        <Modal
            isCentered
            onClose={() => closeModal()}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            autoFocus={false}
        >
            <ModalOverlay/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <p>{message}</p>
                        <br/>
                        <Select
                            hidden={!(select && select.length > 0)}
                            {...register("reason")}
                            borderRadius="8px"
                            fontSize="md"
                            placeholder="Selecione um motivo..."
                            color="gray.400"
                        >
                            {select.map(s => {return (<option id={s.value} value={s.value}>{s.label}</option>)})}
                        </Select>
                        <br/>
                        <Input
                            hidden={!(!!wReason && wReason === "anothers")}
                            {...register("extended")}
                            type="text"
                            borderRadius="8px"
                            fontSize="md"
                            placeholder="Descreva a justificativa."
                            color="gray.400"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} variant="ghost" onClick={() => closeModal()}>Cancelar</Button>
                        <Button
                            isLoading={isLoading}
                            colorScheme="red"
                            type={"submit"}
                        >
                            {btnMessage}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}
