import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { Auth } from "aws-amplify";
import { JWT_TOKEN_COGNITO, JWT_TOKEN_COGNITO_ID } from "../../variables/enumLabels";
import { createUploadLink } from "apollo-upload-client";

const CptQueryProvider = ({ children }) => {

    const client = new ApolloClient({
        link: createUploadLink({
            uri: process.env.REACT_APP_API_URL + '/api/cpt/graphql',
            headers: {
                authorization: "Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
                api: "Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
            }
        }),
        cache: new InMemoryCache(),
    });
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}

export {
    CptQueryProvider
}
