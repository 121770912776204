import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import Pusher from "pusher-js";

import { GET_REPORTS } from 'services/reports';
import { ACTION_TYPES, reducers } from './reducers'
import { useQuery } from 'react-query';

import { JWT_TOKEN_COGNITO_ID } from "variables/enumLabels";
import { useAuth } from 'hooks/useAuth';
import { useSocket } from 'hooks/socket';

const NotificationsStateContext = createContext()
const NotificationsDispatchContext = createContext()

const NotificationsProvider = ({ children }) => {
	const { user } = useAuth()
	const { pusher } = useSocket();
	const [state, dispatch] = useReducer(reducers, { notifications: [], newForm: {}, forms: [], reports: [], newReport: {} })

	const { isLoading: isLoadingReports } = useQuery(['reports'], () => GET_REPORTS(), {
		onSuccess: (data) => {
			dispatch({ type: ACTION_TYPES.LIST_REPORTS, data })
		}
	})

	const newReportNotification = new CustomEvent("report-notification", { "status": null });
	const formFinishedNotification = new CustomEvent("form-finished", { "status": null });

	if (pusher && user.id)
		subscribeChannel(pusher, dispatch, user, newReportNotification, formFinishedNotification);

	return (
		<NotificationsStateContext.Provider value={{ isLoadingReports, ...state }}>
			<NotificationsDispatchContext.Provider value={dispatch}>{children}</NotificationsDispatchContext.Provider>
		</NotificationsStateContext.Provider>
	)
}

const subscribeChannel = (pusher, dispatch, user, reportEvent, formEvent) => {
	console.log('channel', pusher.channel(`private-receiver.${user.id}`))
	if (pusher.channel(`private-receiver.${user.id}`)) return;
	pusher.subscribe(`private-receiver.${user.id}`)
		.bind("Notrelife\\CommunicationChannel\\Triggers\\BroadcastDispatcher",
			(data) => {
				if (data.status == 'generated') {
					dispatch({ type: ACTION_TYPES.UPDATE_REPORT, data: data })
					document.dispatchEvent(reportEvent);
				}
				if (data.status == 'form') {
					dispatch({ type: ACTION_TYPES.UPDATE_FORM, data: data })
					document.dispatchEvent(formEvent)
				}
			})
	window.listenNotifications = true;
}

const useNotifications = () => {
	const context = useContext(NotificationsStateContext)

	if (!context)
		throw new Error('useNotifications must be used within an NotificationsProvider')

	return context
}

const useDispatch = () => {
	const dispatch = useContext(NotificationsDispatchContext)

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a NotificationsProvider')

	return dispatch
}

const useNewReport = (data) => {
	const dispatch = useContext(NotificationsDispatchContext)

	dispatch({ type: ACTION_TYPES.ADD_REPORT, data: data })
}

const useReadNotification = () => {

}

export {
	NotificationsProvider,
	useNotifications,
	useNewReport,
	useReadNotification,
	useDispatch,
}
