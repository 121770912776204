import FilteredLifesDashboardProvider from "providers/filteredLifesDashboardProvider";
import UsersProvider from "providers/usersProvider";
import BeneficiariesProvider2 from "providers/oldBeneficiariesProvider";
import { RegionalsHookQueryProvider } from "./providers/RegionalsHookQueryProvider";

import Lives from "views/Dashboards/Lives";
import Health from "views/Dashboards/Health";
import Operational from "views/Dashboards/Operational";
import WhatsApp from "views/Dashboards/WhatsApp";
import Accumulated from "views/Dashboards/accumulated";

import Beneficiaries2 from "views/OldBeneficiaries/Beneficiaries";
import CreateBeneficiary2 from "views/OldBeneficiaries/CreateBeneficiary/CreateBeneficiary";
import ViewBeneficiary2 from "views/OldBeneficiaries/ViewBeneficiary/ViewBeneficiary";

import BeneficiariesProvider from "./providers/beneficiariesProvider";
import Beneficiaries from "views/Beneficiaries";
import CreateBeneficiary from "views/Beneficiaries/CreateBeneficiary";
import ViewBeneficiary from "views/Beneficiaries/ViewBeneficiary";

import Users from "views/Users/Users";
import CreateUser from "views/Users/CreateUser/CreateUser";
import ViewUser from "views/Users/ViewUser/ViewUser";
import EditUser from "views/Users/EditUser/EditUser";

import ScheduleProvider from "providers/scheduleProvider";
import ProfessionalSchedule from "views/ProfessionalSchedule/ProfessionalSchedule";
import CreateFreeHour from "views/ProfessionalSchedule/CreateFreeHour/CreateFreeHour";
import ViewFreeHour from "views/ProfessionalSchedule/ViewFreeHour/ViewFreeHour";
import EditFreeHour from "views/ProfessionalSchedule/EditFreeHour/EditFreeHour";
import CreateCustomHour from "views/ProfessionalSchedule/CreateCustomHour/CreateCustomHour";
import ViewCustomHour from "views/ProfessionalSchedule/ViewCustomHour/ViewCustomHour";
import EditCustomHour from "views/ProfessionalSchedule/EditCustomHour/EditCustomHour";

import Calendar from "views/Calendar/Calendar";
import { CalendarHookQueryProvider } from "providers/calendarHookQueryProvider";
import { CalendarStatisticProvider } from "providers/calendarStatisticsProvider";

import DailySchedule from "views/DailySchedule";
import Regionals from "./views/Regionals";
import EditRegional from "views/Regionals/components/EditRegional";
import BeneficiaryProvider2 from "./providers/beneficiaryProvider";

import Tickets from "views/Tickets";
import CreateTicket from "views/Tickets/CreateTicket/CreateTicket";
import EditTicket from "views/Tickets/EditTicket/EditTicket";
import ViewTicket from "views/Tickets/ViewTicket/ViewTicket";
import TicketsProvider from "providers/ticketsProvider";
import Cpt from "./views/Cpt";
import EditCpt from "./views/Cpt/View";
import { CptHookQueryProvider } from "./providers/cptHookQueryProvider";

import Commitments from "views/Commitments";
import CommitmentsProvider from "providers/commitmentsProvider";
import Reports from "./views/Reports/Reports";

import CommitmentsManagement from "views/CommitmentsManagement";
import CommitmentsManagementProvider from "providers/commitmentsManagementProvider";
import { CptFilterHookQueryProvider } from "./providers/cptFilterHookQueryProvider";
import { ReportsHookQueryProvider } from "./providers/reportsHookQueryProvider";
import CPT from "views/Dashboards/CPT";
import Plans from "views/Plans";
import PlansProvider from "providers/plansProvider";
import {
  roleAdmin,
  roleConsu,
  roleEnfer,
  roleMedic,
  roleRecep,
  roleSuper,
} from "./variables/roles";

import WhatsappDisclosures from "views/WhatsappDisclosures";
import CreateWhatsappDisclosure from "views/WhatsappDisclosures/CreateWhatsappDisclosure";
import EditWhatsappDisclosure from "views/WhatsappDisclosures/EditWhatsappDisclosure";
import WhatsappDisclosureProvider from "providers/whatsappDisclosureProvider";
import ViewWhatsappDisclosure from "views/WhatsappDisclosures/ViewWhatsappDisclosure";
import Companies from "views/Companies/Companies";
import CompaniesProvider from "providers/CompaniesProvider";

const routes = [
  // DASHBOARDS
  {
    name: "dashboards",
    path: "/dashboards/lives",
    component: Lives,
    provider: FilteredLifesDashboardProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleMedic],
  },
  {
    name: "dashboards",
    path: "/dashboards/health",
    component: Health,
    provider: FilteredLifesDashboardProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleMedic],
  },
  {
    name: "dashboards",
    path: "/dashboards/operational",
    component: Operational,
    provider: FilteredLifesDashboardProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleMedic],
  },
  {
    name: "dashboards",
    path: "/dashboards/whatsapp",
    component: WhatsApp,
    provider: FilteredLifesDashboardProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleMedic],
  },
  {
    name: "dashboards",
    path: "/dashboards/cpt",
    component: CPT,
    provider: FilteredLifesDashboardProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "dashboards",
    path: "/dashboards/acumulado",
    component: Accumulated,
    provider: FilteredLifesDashboardProvider,
    layout: "/sistema",
    roles: [roleSuper],
  },
  // VIDAS
  {
    name: "vidas",
    path: "/administracao/gestao-vidas",
    component: Beneficiaries,
    provider: BeneficiariesProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "vidas",
    path: "/administracao/gestao-vidas/criar",
    component: CreateBeneficiary,
    provider: BeneficiariesProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "vidas",
    path: "/administracao/gestao-vidas/:id",
    component: ViewBeneficiary,
    provider: BeneficiariesProvider,
    layout: "/sistema",
    roles: [],
  },
  // VIDAS 2
  // {
  //   name: "vidas",
  //   path: "/administracao/gestao-vidas-2",
  //   component: Beneficiaries2,
  //   provider: BeneficiaryProvider2,
  //   layout: "/sistema",
  //   roles: [],
  // },
  // {
  //   name: "vidas",
  //   path: "/administracao/gestao-vidas-2/criar",
  //   component: CreateBeneficiary2,
  //   provider: BeneficiaryProvider2,
  //   layout: "/sistema",
  //   roles: [],
  // },
  // {
  //   name: "vidas",
  //   path: "/administracao/gestao-vidas-2/:id",
  //   component: ViewBeneficiary2,
  //   provider: BeneficiaryProvider2,
  //   layout: "/sistema",
  //   roles: [],
  // },
  // EMPRESAS
  {
    name: "empresas",
    path: "/administracao/empresas",
    component: Companies,
    provider: CompaniesProvider,
    layout: "/sistema",
    roles: [roleSuper],
  },
  // RELATORIOS
  {
    name: "relatorio",
    path: "/relatorios/relatorio",
    component: Reports,
    provider: ReportsHookQueryProvider,
    layout: "/sistema",
    roles: [],
  },
  // USUARIOS
  {
    name: "usuarios",
    path: "/administracao/usuarios",
    component: Users,
    provider: UsersProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper],
  },
  {
    name: "usuarios",
    path: "/administracao/usuarios/criar",
    component: CreateUser,
    provider: UsersProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper],
  },
  {
    name: "usuarios",
    path: "/administracao/usuarios/:id",
    component: ViewUser,
    provider: UsersProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper],
  },
  {
    name: "usuarios",
    path: "/administracao/usuarios/:id/editar",
    component: EditUser,
    provider: UsersProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper],
  },
  // AGENDA PROFISSIONAL
  {
    name: "agenda",
    path: "/administracao/agenda",
    component: ProfessionalSchedule,
    provider: ScheduleProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  {
    name: "agenda",
    path: "/administracao/agenda/criar",
    component: CreateFreeHour,
    provider: ScheduleProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  {
    name: "agenda",
    path: "/administracao/agenda/:id",
    component: ViewFreeHour,
    provider: ScheduleProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  {
    name: "agenda",
    path: "/administracao/agenda/:id/editar",
    component: EditFreeHour,
    provider: ScheduleProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  {
    name: "agenda",
    path: "/administracao/agenda-ausencia/criar",
    component: CreateCustomHour,
    provider: ScheduleProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  {
    name: "agenda",
    path: "/administracao/agenda-ausencia/:id",
    component: ViewCustomHour,
    provider: ScheduleProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  {
    name: "agenda",
    path: "/administracao/agenda-ausencia/:id/editar",
    component: EditCustomHour,
    provider: ScheduleProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  // CALENDARIO
  {
    name: "calendario",
    path: "/agendamento/calendario",
    component: Calendar,
    provider: CalendarHookQueryProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "Agenda do dia",
    path: "/agenda/:day",
    component: DailySchedule,
    provider: CalendarStatisticProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "gestao",
    path: "/agendamento/gestao-compromissos",
    component: CommitmentsManagement,
    provider: CommitmentsManagementProvider,
    layout: "/sistema",
    roles: [],
  },
  // REGIONAIS
  {
    name: "regionais",
    path: "/administracao/regionais",
    component: Regionals,
    provider: RegionalsHookQueryProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  {
    name: "regionais",
    path: "/administracao/regionais/:id/editar",
    component: EditRegional,
    provider: RegionalsHookQueryProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper, roleConsu, roleEnfer, roleRecep],
  },
  // ATENDIMENTO
  {
    name: "suporte",
    path: "/administracao/suporte",
    component: Tickets,
    provider: TicketsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "suporte",
    path: "/administracao/suporte/criar",
    component: CreateTicket,
    provider: TicketsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "suporte",
    path: "/administracao/suporte/:id/",
    component: ViewTicket,
    provider: TicketsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "suporte",
    path: "/administracao/suporte/:id/editar",
    component: EditTicket,
    provider: TicketsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "cpt",
    path: "/administracao/cpt",
    component: Cpt,
    provider: CptFilterHookQueryProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "cpt",
    path: "/administracao/cpt/:id",
    component: EditCpt,
    provider: CptHookQueryProvider,
    layout: "/sistema",
    roles: [],
  },

  {
    name: "Lista de Consultas",
    path: "/administracao/compromissos",
    component: Commitments,
    provider: CommitmentsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "Whatsapp",
    path: "/administracao/whatsapp",
    component: WhatsappDisclosures,
    provider: WhatsappDisclosureProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "Criar Whatsapp",
    path: "/administracao/whatsapp/criar",
    component: CreateWhatsappDisclosure,
    provider: WhatsappDisclosureProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "Editar Whatsapp",
    path: "/administracao/whatsapp/:id/editar",
    component: EditWhatsappDisclosure,
    provider: WhatsappDisclosureProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "Visualizar Whatsapp",
    path: "/administracao/whatsapp/:id",
    component: ViewWhatsappDisclosure,
    provider: WhatsappDisclosureProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "Planos",
    path: "/administracao/planos",
    component: Plans,
    provider: PlansProvider,
    layout: "/sistema",
    roles: [roleAdmin, roleSuper],
  },
];

export default routes;
