const queryParamsPage = (location) => {
    const search = location.search

    if (!search.includes('?page=')) return { page: null, size: null }

    const query = search.replace('?page=', '').replace('&size=', ',').replace('&proposalId=', ',').split(',')

    const page = Number(query[0])
    const size = Number(query[1])
    const proposalId = (query[2])

    return { page: page, size: size, proposalId: proposalId }

}

export default queryParamsPage