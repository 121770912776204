import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { useColorMode } from "@chakra-ui/react";
import { themeDark, themeLight } from "variables/nivo/default";

const emptyData = [
  {
    date: "",
    initial: 0,
    confirmation: 0,
    reminder: 0,
    missing: 0,
    campaign: 0,
    lecture: 0,
    activity: 0,
  },
];

const colorScheme = [
  "#1E73DB",
  "#3E993C",
  "#E0A51B",
  "#CB4333",
  "#5389E0",
  "#954FE0",
  "#AEE05C",
];

const ThirtyDaysWhatsApp = ({ data }) => {
  if (!data) data = emptyData;

  const ret = [
    {
      id: "Inicial",
      data: data.map((item) => {
        return { x: item.date, y: item.initial };
      }),
    },
    {
      id: "Confirmação",
      data: data.map((item) => {
        return { x: item.date, y: item.confirmation };
      }),
    },
    {
      id: "Lembrete",
      data: data.map((item) => {
        return { x: item.date, y: item.reminder };
      }),
    },
    {
      id: "No Show",
      data: data.map((item) => {
        return { x: item.date, y: item.missing };
      }),
    },
    {
      id: "Campanha",
      data: data.map((item) => {
        return { x: item.date, y: item.campaign };
      }),
    },
    {
      id: "Palestra",
      data: data.map((item) => {
        return { x: item.date, y: item.lecture };
      }),
    },
    {
      id: "Atividade",
      data: data.map((item) => {
        return { x: item.date, y: item.activity };
      }),
    },
  ];

  const { colorMode } = useColorMode();

  return (
    <ResponsiveLine
      data={ret}
      margin={{ top: 50, right: 125, bottom: 50, left: 100 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={10}
      pointBorderWidth={2}
      enableSlices="x"
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      colors={colorScheme}
      theme={colorMode === "light" ? themeLight : themeDark}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default ThirtyDaysWhatsApp;
