import React, { useState } from "react";
import {
  useDisclosure,
  Modal,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { usePlans, usePaginatePlans } from "hooks/plans";
import AsyncTable from "../AsyncTable";
import EditPlan from "views/Plans/EditPlan/EditPlan";
import Icon from "../../FontAwesomeIcons/Icon";

const PlansTable = () => {
  const [activeRow, setActiveRow] = useState({});

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "ANS",
      accessor: "ans",
      Cell: (props) => <div>{props.value}</div>,
    },
    {
      Header: "SECAD",
      accessor: "secad",
      Cell: (props) => <div>{props.value}</div>,
    },
  ];

  const { loading, rows, total, perPage, currentPage } = usePlans();
  const [paginatePlans, { loading: reloading }] = usePaginatePlans();

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginatePlans({
      variables: {
        first: pageSize || 30,
        page: pageIndex || 1,
        search: search || "",
      },
    });
  };

  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const customActions = [
    {
      label: "Editar",
      icon: "edit",
      callback: (id, row) => {
        onOpen();
        setActiveRow(row);
      },
    },
  ];

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
        <EditPlan plan={activeRow} onClose={onClose} />
      </Modal>

      <AsyncTable
        isLoaded={!loading && !reloading}
        fetchData={fetchData}
        size={perPage}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        searchByName
        baseRoute="planos"
        noDelete
        noEdit
        noView
        customActions={!loading ? customActions : []}
      />
    </React.Fragment>
  );
};

export default PlansTable;
