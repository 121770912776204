import React from 'react'

import { useFreeHours, useDeleteFreeHour } from 'hooks/freeHours';

import { 
  Button,
  Modal, 
  ModalBody, 
  ModalCloseButton, 
  ModalContent, 
  ModalFooter, 
  ModalHeader, 
  ModalOverlay, 
  useToast 
} from '@chakra-ui/react'

const DeleteFreeHour = () => {
  const { toDelete , deleteModalOpen, setDeleteModalOpen } = useFreeHours();
  const toast = useToast()

  const [deleteFreeHour, { loading }] = useDeleteFreeHour({
    onSuccess: () => {
      toast({
        title: 'Agenda removida com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      setDeleteModalOpen(false)
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const onDelete = () => {
    deleteFreeHour({variables: {id: toDelete}});
  }

  return (
    <Modal
        isCentered
        onClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        motionPreset='slideInBottom'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deletar Agenda semanal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Por favor, confirme a ação de exclusão.
          </ModalBody>
          <ModalFooter>
            <Button mr={3} variant='ghost' onClick={() => setDeleteModalOpen(false)}>Cancelar</Button>
            <Button 
              isLoading={loading}
              colorScheme='red' 
              onClick={() => onDelete()}
            >
              Deletar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default DeleteFreeHour