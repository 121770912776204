const status = [
  {
    name: "Aberto",
    value: "aberto",
    color: "yellow.500",
  },
  {
    name: "Em Andamento",
    value: "em_andamento",
    color: "blue.600",
  },
  {
    name: "Finalizado",
    value: "finalizado",
    color: "green.600",
  },
  {
    name: "Cancelado",
    value: "finalizado",
    color: "red.600",
  },
];

export default status;