/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, ChakraProvider, Portal } from "@chakra-ui/react";
import "assets/css/pud-dashboard-styles.css";
import Footer from "components/Footer/Footer.js";
// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import theme from "theme/theme.js";
import CustomerEvent from "views/CustomerEvent";

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const navRef = React.useRef();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "auth";
  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%"  h="100%">
      <Box ref={navRef} w="100%" h="100%">
        <Box w="100%"  h="100%">
          <Box ref={wrapper} w="100%"  h="100%">
            <CustomerEvent />
          </Box>
        </Box>
        <Box px="24px" mx="auto" width="1044px" maxW="100%"  h="100%">
          <Footer />
        </Box>
      </Box>
    </ChakraProvider>
  );
}
