import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import {useForm, FormProvider} from "react-hook-form";
import AsyncPlansMultiselect from "./AsyncPlansMultiselect";



const ModalAssocPlan = ({ onClose, isOpen, onSubmit, loadingSave, defaultValues }) => {
    const methods = useForm()
    const {  handleSubmit } = methods

    return (
        <Modal
            isCentered
            onClose={() => onClose(false)}
            isOpen={isOpen}
            motionPreset='slideInBottom'
            size={"xl"}
            autoFocus={false}
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Associar Plano a Regional</ModalHeader>
                        <ModalBody>
                            <FormControl>
                                <FormLabel
                                    fontWeight="bold"
                                    fontSize="xs"
                                >
                                    Associar Planos
                                </FormLabel>
                                <AsyncPlansMultiselect
                                    defaultValues={defaultValues}
                                />

                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                isLoading={loadingSave}
                                backgroundColor='notrelife.800'
                                color="white"
                                variant="no-hover"
                                type="submit"
                            >
                                Salvar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default ModalAssocPlan;
