import { TicketsProvider as Provider } from "hooks/tickets"
import { ManagerQueryProvider } from "services/manager"

const TicketsProvider = ({ children }) => {
    return (
        <ManagerQueryProvider>
            <Provider>
                {children}
            </Provider>
        </ManagerQueryProvider>
    )
}

export default TicketsProvider
