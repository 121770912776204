import { useDisclosure, useToast } from '@chakra-ui/react';
import { useEvents, useEventsDispatch } from 'hooks/beneficiaryEvents';
import CommitmentsManagementProvider from 'providers/commitmentsManagementProvider';
import CommitmentsProvider from 'providers/commitmentsProvider';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import EventModal from 'views/CommitmentsManagement/components/EventModal';
import PaRefusalModal from 'views/CommitmentsManagement/components/PaRefusalModal';
import AppointmentsMade from './AppointmentsMade'
import AssessmentModal from './AssessmentModal';
import AwaitingAssessment from './AwaitingAssessment'
import { usePendingEventsBeneficiary } from 'hooks/beneficiary';

const BeneficiaryEvents = ({ awaitingSchedules = [] }) => {
	const { id } = useParams();
	const { awaitingAssessments } = useEvents()
	const dispatch = useEventsDispatch()
	const [open, setOpen] = useState(false);
	const [eventData, setEventData] = useState({})
	const toast = useToast();
	const [hideAwaiting, setHideAwaiting] = useState([])
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [assessment, setAssessment] = useState({});

	// const { loading: loadingEvents } = usePendingEventsBeneficiary(id);

	const [
		loadEvents,
		{ loading: loadingEvents },
	] = usePendingEventsBeneficiary(id);

	useEffect(() => {
		const onLoadEvents = async () => {
			const { data } = await loadEvents();
			dispatch({
				type: "SET_PENDING",
				data: data?.beneficiary?.assessmentsPending,
			});
		};
		onLoadEvents();
	}, []);

	useEffect(() => {
		if (awaitingSchedules) dispatch({ type: 'SET_AWAITING', data: awaitingSchedules })
	}, [awaitingSchedules])

	const handleOpenModal = (data) => {
		setEventData(data)
		setOpen(true)
	}

	const handleOpenForm = (url, name, id) => {
		try {
			if (!url) {
				toast({
					title: 'Estamos gerando o formulario para o beneficiario.',
					status: 'warning',
					duration: 5000,
					isClosable: true,
				})
			} else {
				dispatch({
					type: 'SET_CURRENT_FORM', data: {
						id,
						url,
						name
					}
				})
				setAssessment({
					id,
					url,
					name
				})
				onOpen();
			}
		} catch (error) {
			console.log(error);
		}
	}

	document.addEventListener("form-finished", function (e) {
		setHideAwaiting([...hideAwaiting])
	});

	const onSuccess = (action, value = null) => {
		if (action == 'remove') dispatch({ type: 'REMOVE_AWAITING', data: value !== null ? value : eventData.exclude })
		if (action == 'update') dispatch({ type: 'UPDATE_PENDING', data: value })
		setEventData({})
	}

	return (
		<>
			{
				!loadingEvents && <AwaitingAssessment awaitingSchedules={awaitingAssessments} handleModal={handleOpenModal} />
			}
			<AppointmentsMade
				handleModal={handleOpenModal}
				handleForm={handleOpenForm}
				loadEvents={loadEvents}
				loadingEvents={loadingEvents}
			/>
			<AssessmentModal isOpen={isOpen} onClose={onClose} assessment={assessment} setAssessment={setAssessment} />
			<CommitmentsManagementProvider>
				{
					open &&
					<EventModal
						beneficiary={eventData}
						open={open}
						setEventModal={setOpen}
						onSuccess={onSuccess}
					/>
				}

			</CommitmentsManagementProvider>

		</>
	)
}

export default BeneficiaryEvents