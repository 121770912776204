import { gql } from '@apollo/client';

export const GET_LIFE_PLANS =  gql`
    query GetLifePlan($id: String!) {
        lifePlans(beneficiary_id: $id) {
            id
            plans
            options
            created_at
        }
    }
`

export const CREATE_LIFE_PLAN = gql`
    mutation CreateLifePlan($input: CreateLifePlanInput) {
    createLifePlan(input: $input) {
        id
        plans
        options
    }
  }
`

export const DELETE_LIFE_PLAN = gql`
    mutation DeleteLifePlan($input: CreateLifePlanInput) {
    createLifePlan(input: $input) {
        id
        plans
        options
    }
  }
`