import React from 'react'

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useUsers, useDeleteUser } from 'hooks/users';

import { 
  Button,
  Modal, 
  ModalBody, 
  ModalCloseButton, 
  ModalContent, 
  ModalFooter, 
  ModalHeader, 
  ModalOverlay, 
  useToast 
} from '@chakra-ui/react'
import Icon from 'components/FontAwesomeIcons/Icon';
import { useMutation } from '@apollo/client';
import { SEND_WHATSAPP_MESSAGE } from 'graphql/commitmentsManagement';
import { JWT_TOKEN_COGNITO_ID } from 'variables/enumLabels';

const MessageModal = ({beneficiary, open, setMessageModal}) => {
  const toast = useToast()

  const [sendMessage , { loading }] = useMutation(SEND_WHATSAPP_MESSAGE, {
    client: new ApolloClient({
      uri: process.env.REACT_APP_API_URL + '/api/manager/graphql',
      cache: new InMemoryCache(),
      headers: {
          authorization: "Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
          api: "Bearer " + window.localStorage.getItem(JWT_TOKEN_COGNITO_ID),
      }
    }),
    onCompleted: () => {
      setMessageModal(false),
      toast({
        title: 'Mensagem enviada.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    onError: () => {
      setMessageModal(false),
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
    })
    },
  })

  const handleSubmit = () => {
    sendMessage({variables: {
      patient_id: beneficiary.id
    }})
  }

  return (
    <Modal
        isCentered
        onClose={() => setMessageModal(false)}
        isOpen={open}
        motionPreset='slideInBottom'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Envio de mensagem</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Confirme o envio de mensagem para {beneficiary?.name || null} via Whatsapp
          </ModalBody>
            <ModalFooter>
              <Button mr={3} variant='ghost' onClick={() => setMessageModal(false)}>Cancelar</Button>
              <Button 
                isLoading={loading}
                backgroundColor='notrelife.800'
                color="white"
                variant="no-hover"
                type="submit"
                leftIcon={<Icon icon="whatsapp"/>}
                onClick={() => handleSubmit()}
              >
                Enviar
              </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default MessageModal