
import { Box, Flex, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { Info } from "@phosphor-icons/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

const ChartContainer = ({
  children,
  title = null,
  minHeight = "400px",
  maxHeight = "450px",
  position = "static",
  overflowY = "unset",
  tooltip = null,
}) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card
      minHeight={minHeight}
      maxHeight={maxHeight}
      width="100%"
      height="100%"
      position={position}
      overflowY={overflowY}
      overflowX={{ sm: "auto", lg: "unset" }}
    >
      <CardHeader px="22px">
        <Flex alignItems="center">
          <Text
            color={textColor}
            fontSize="lg"
            fontWeight="bold"
            marginBottom={0}
          >
            {title}
          </Text>
          {tooltip && (
            <Tooltip label={tooltip}>
              <Info size={18} color="" />
            </Tooltip>
          )}
        </Flex>
      </CardHeader>
      <CardBody h="100%" py=".75em">
        <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
          {children}
        </Box>
      </CardBody>
    </Card>
  );
};

export default ChartContainer;
