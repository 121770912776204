import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Box, Button, Flex, Grid, Select, Skeleton, Stack, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FreeHourTable from 'components/Tables/ProfessionalSchedule/FreeHourTable';
import DeleteFreeHour from './DeleteFreeHour/DeleteFreeHour';
import CustomHourTable from 'components/Tables/ProfessionalSchedule/CustomHourTable';
import DeleteCustomHour from './DeleteCustomHour/DeleteCustomHour';
import { useGetProfessionals } from 'hooks/calendar';
import { useRegionals } from 'hooks/globalRegionals';
import { useLazyQuery } from '@apollo/client';
import { REGIONAL } from 'graphql/calendar';
import { useAuth } from 'hooks/useAuth';
import { roleAdmin, roleConsu, roleEnfer } from "variables/roles";
import { roleSuper } from 'variables/roles';
import { storageFilter } from 'functions/storageFilters';

const ProfessionalSchedule = () => {
    const defaultFilters = storageFilter('@professional_schedule_filters')
    const { user } = useAuth();
    const location = useLocation();
    const history = useHistory();

    const [getProfessionals, { data: professionals, loading: loadingProfessionals }] = useGetProfessionals();

    const { regional: regionalId } = useRegionals();
    const [role, setRole] = useState(defaultFilters?.role || null);
    const [professional, setProfessional] = useState(defaultFilters?.professional || null);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        getProfessionals();
    }, []);

    const [
        loadPlans,
        { data: qRegional, loading: loadingRegional },
    ] = useLazyQuery(REGIONAL);

    useEffect(() => {
        const filter = {
            role: role,
            professional: professional
        }
        setFilter(filter)
        const stringFilter = JSON.stringify(filter)
        window.localStorage.setItem('@professional_schedule_filters', stringFilter)
    }, [role, professional])

    useEffect(() => {
        if (!!regionalId) loadPlans({ variables: { id: regionalId } });
    }, [regionalId]);

    const handleClick = (to) => {
        (to === 1 || to === '1') ? history.push(`${location.pathname}/criar`) : history.push(`${location.pathname}-ausencia/criar`);
    }

    const textColor = useColorModeValue("gray.700", "white");
    const buttonBg = useColorModeValue("notrelife.900", "notrelife.900");
    const buttonColor = useColorModeValue("white", "white");
    const inputHover = useColorModeValue("notrelife.800", "notrelife.800");

    const handleProfessionals = () => {
        let pro = qRegional?.regional?.professionals;
        if (!!role) {
            pro = pro?.filter(p => p?.user?.role === role);
        } else {
            pro = pro?.filter(p => p?.user?.role === roleConsu || p?.user?.role === roleEnfer);
        }
        return pro?.map(p => (<option value={p?.id}>{p?.user?.name}</option>))
    }

    return (
        <Grid
            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
            templateRows={{ sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "125px", lg: "160px" }}
        >
            <Card mb="24px">
                <CardHeader>
                    <Flex>
                        <Stack
                            direction="column"
                            spacing="12px"
                            mr="12px"
                        >
                            <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                Filtrar por tipo de profissionais
                            </Text>
                            <Skeleton isLoaded={!loadingRegional && !loadingProfessionals}>
                                <Select
                                    value={role}
                                    focusBorderColor={inputHover}
                                    placeholder="Selecione uma opção..."
                                    onChange={({ target }) => setRole(target.value)}
                                    color="gray.500"
                                    size="sm"
                                    borderRadius="12px"
                                    cursor="pointer"
                                >
                                    <option value={roleConsu}>Consultor de Saúde</option>
                                    <option value={roleEnfer}>Enfermeiro</option>
                                </Select>
                            </Skeleton>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing="12px"
                        >
                            <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                Filtrar por profissional
                            </Text>
                            <Skeleton isLoaded={!loadingRegional && !loadingProfessionals}>
                                <Select
                                    value={professional}
                                    focusBorderColor={inputHover}
                                    placeholder={"Selecione uma opção..."}
                                    onChange={({ target }) => setProfessional(target.value)}
                                    color="gray.500"
                                    size="sm"
                                    borderRadius="12px"
                                    cursor="pointer"
                                >
                                    {handleProfessionals()}
                                </Select>
                            </Skeleton>
                        </Stack>
                    </Flex>
                </CardHeader>
            </Card>
            <Card px="0px" pb="24px" minHeight="320px">
                <CardHeader mb="34px" px="22px">
                    <Box w="100%">
                        <Flex
                            justify="space-between"
                            align="center"
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400"
                        >
                            <Text color={textColor} fontSize="lg" fontWeight="bold">
                                Agenda Semanal
                            </Text>
                            {
                                (user?.role === roleAdmin || user?.role === roleSuper) &&
                                <Button
                                    bg={buttonBg}
                                    variant="no-hover"
                                    color={buttonColor}
                                    onClick={() => handleClick(1)}>
                                    Cadastrar Agenda
                                </Button>
                            }
                        </Flex>
                    </Box>
                </CardHeader>
                <CardBody h="100%">
                    <Box w="100%" h="100%">
                        <FreeHourTable filter={filter} />
                        <DeleteFreeHour />
                    </Box>
                </CardBody>
            </Card>
            <Card px="0px" pb="24px" minHeight="320px">
                <CardHeader mb="34px" px="22px">
                    <Box w="100%">
                        <Flex
                            justify="space-between"
                            align="center"
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400"
                        >
                            <Text color={textColor} fontSize="lg" fontWeight="bold">
                                Agenda do dia
                            </Text>
                            {
                                (user?.role === roleAdmin || user?.role === roleSuper) &&
                                <Button
                                    bg={buttonBg}
                                    variant="no-hover"
                                    color={buttonColor}
                                    onClick={() => handleClick(2)}>
                                    Cadastrar Agenda
                                </Button>
                            }
                        </Flex>
                    </Box>
                </CardHeader>
                <CardBody h="100%">
                    <Box w="100%" h="100%">
                        <CustomHourTable filter={filter} />
                        <DeleteCustomHour />
                    </Box>
                </CardBody>
            </Card>
        </Grid>
    )
}

export default ProfessionalSchedule