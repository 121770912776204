import { gql } from '@apollo/client';

export const GET_NOTES =  gql`
    query GetNotes($id: String!) {
        notes(beneficiary_id: $id) {
            id
            title
            text
            created_at
            user {
                name
            }
        }
    }
`

export const CREATE_NOTE = gql`
    mutation CreateNote($input: CreateNoteInput) {
    createNote(input: $input) {
        id
        title
        text
        created_at
        beneficiary {
            id
            notes {
                title
                text
            }
        }
        user {
            name
        }
    }
  }
`