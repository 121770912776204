import {createContext, useContext, useReducer, useCallback} from 'react'
import PropTypes from 'prop-types'
import {useQuery, useQueryClient} from 'react-query'
import {isEmpty} from "lodash";
import {ME} from "../services/regional";

const ACTION_TYPES = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  ME: 'ME'
}

const authReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SIGN_IN: {
      return {
        ...state,
        user: action.user,
      }
    }
    case ACTION_TYPES.SIGN_OUT: {
      return {
        user: {},
      }
    }
    case ACTION_TYPES.ME: {
      return {
        user: {
          ...state.user, ...action.data
        },
      }
    }
    default: {
      return state
    }
  }
}

const AuthStateContext = createContext()
const AuthDispatchContext = createContext()

const AuthProvider = ({ children, user }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: user
  })

  useQuery(['me'], () => ME(), {
    onSuccess: (data) => {
      if(data?.roles?.find(rl => rl.name == 'Super_Admin'))
        data.role = 'Super_Admin';
      return dispatch({ type: ACTION_TYPES.ME, data })
    },
  })

  return (
    <AuthStateContext.Provider value={{ isLoading: isEmpty(state.user), user: state.user }}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useAuth = () => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(AuthDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a AuthProvider')
  }
  return dispatch
}

export { 
  AuthProvider, 
  useAuth
}
