import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { createContext, useContext, useReducer, useState } from "react";
import { GET_PROFESSIONALS, GET_PROFESSIONALS_BY_REGIONAL } from "../../services/calendar/sql/professionals";
import {
    CREATE_EVENT,
    GET_EVENTS_LIMIT,
    GET_EVENTS_MONTH,
    GET_BENEFICIARIES,
    GET_ASSESTMENTS_TYPES,
    GET_REGIONALS,
    GET_BENEFICIARIES_QUICK, FIND_PLAN
} from "../../services/calendar/sql/events";
import { reducers, ACTION_TYPES } from "./reducers"

import { GET_PROFESSIONAL_TIME } from "graphql/freeHours";

const CalendarHookState = createContext()
const CalendarHookDispatch = createContext()

const CalendarHookProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, {events: []});

    const [loadMonthEvents, {loading, refetch: refetchMonth }] = useLazyQuery(GET_EVENTS_MONTH, {
        onCompleted: (data) => dispatch({ type: ACTION_TYPES.LIST, data: data.eventsMonth })
    })

    return (
        <CalendarHookState.Provider value={{loadMonthEvents, refetchMonth, loading, ...state}}>
            <CalendarHookDispatch.Provider value={dispatch}>
                {children}
            </CalendarHookDispatch.Provider>
        </CalendarHookState.Provider>
    )
}

const useDispatch = () => {
    const dispatch = useContext(CalendarHookDispatch)

    if (!dispatch) throw new Error("Falta o provider do calendario")

    return dispatch
}

const useGetEventsLimit10 = (date) => {
    return useLazyQuery(GET_EVENTS_LIMIT, {
        fetchPolicy: "no-cache",
        variables: {
            now: date
        }
    })
}

const useEvents = () => {
    const events = useContext(CalendarHookState)

    if (!events) throw new Error("Falta o provider do calendario")

    return events
}

const useCreateEvent = ({ onCompleted = (data) => console.log , onError = (data) => console.log}) => {
    return useMutation(CREATE_EVENT, {
        onCompleted: (data) => {
            onCompleted(data)
        },
        onError: (error) => {
            if(onError) onError(error?.graphQLErrors[0]?.extensions?.validation)
        }
    })
}

const useGetMonthEvents = (options = {}) => {
    const dispatch = useDispatch();

    return useLazyQuery(GET_EVENTS_MONTH, {
        ...options,
        fetchPolicy: "no-cache",
        onCompleted: (data) => dispatch({ type: ACTION_TYPES.LIST, data: data.eventsMonth })
    });
}
const useLazyBeneficiaries = (options) => {
    return useLazyQuery(GET_BENEFICIARIES, options);
}

const useLazyBeneficiariesQuick = (options) => {
    return useLazyQuery(GET_BENEFICIARIES_QUICK, options);
}

const useGetRegionals = () => {
    return useQuery(GET_REGIONALS)
}

const useGetProfessionals = (options) => {
    return useLazyQuery(GET_PROFESSIONALS, options);
}

const useGetProfessionalsByRegional = (options) => {
    return useLazyQuery(GET_PROFESSIONALS_BY_REGIONAL, options);
}

const useGetProfessionalSplitTime = (options) => {
    return useLazyQuery(GET_PROFESSIONAL_TIME, {
        ...options,
        fetchPolicy: "no-cache"
    });
}

const useEventTypes = () => {
    return useQuery(GET_ASSESTMENTS_TYPES);
}

export {
    CalendarHookProvider,
    useEvents,
    useCreateEvent,
    useGetMonthEvents,
    useGetRegionals,
    useLazyBeneficiaries,
    useLazyBeneficiariesQuick,
    useGetProfessionals,
    useGetProfessionalsByRegional,
    useGetProfessionalSplitTime,
    useEventTypes,
    useGetEventsLimit10,
}
