import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Skeleton,
  SkeletonText,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useWhatsappDisclosure } from "hooks/whatsappDisclosures";
import states from "variables/states";
import cities from "variables/cities";
import PannelHandler from "./Components/PannelHandler";
import { invitationTypes } from "../variables/invitationTypes";
import Icon from "components/FontAwesomeIcons/Icon";

const ViewWhatsappDisclosure = () => {
  const { id } = useParams();
  const { data, loading } = useWhatsappDisclosure(id);
  const textColor = useColorModeValue("black", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr 2fr)" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        w={"100%"}
        pt={{ sm: "12px", lg: "12px" }}
      >
        <Card
          px="0px"
          pb="22px"
          minHeight="480px"
          overflowX={{ sm: "auto", lg: "unset" }}
        >
          <CardHeader px="22px">
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              marginBottom={0}
            ></Text>
          </CardHeader>
          <CardBody h="100%" px="22px">
            <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
              <VStack align="stretch" spacing="4">
                <VStack align="stretch" spacing="1">
                  {loading ? (
                    <SkeletonText
                      skeletonHeight="27px"
                      spacing="4"
                      noOfLines={4}
                    />
                  ) : (
                    <>
                      <Text textAlign="left" fontSize="14px">
                        Título
                      </Text>
                      <Text textAlign="left" fontSize="18px">
                        {data?.whatsappDisclosure?.title}
                      </Text>

                      <Text textAlign="left" fontSize="14px">
                        Tipo de campanha
                      </Text>
                      <Text textAlign="left" fontSize="18px">
                        {
                          invitationTypes.find(
                            (type) =>
                              type.value ===
                              data?.whatsappDisclosure?.invitation_type
                          )?.label
                        }
                      </Text>

                      {data?.whatsappDisclosure?.about && (
                        <>
                          <Text textAlign="left" fontSize="14px">
                            Sobre
                          </Text>
                          <Text textAlign="left" fontSize="18px">
                            {data?.whatsappDisclosure?.about}
                          </Text>
                        </>
                      )}

                      <Text textAlign="left" fontSize="14px">
                        Capacidade
                      </Text>
                      <Text textAlign="left" fontSize="18px">
                        {data?.whatsappDisclosure?.capacity}
                      </Text>

                      {data?.whatsappDisclosure?.ambience === "presencial" ? (
                        <>
                          <Flex gap="4">
                            <VStack align="stretch" spacing="1">
                              <Text textAlign="left" fontSize="14px">
                                CEP
                              </Text>
                              <Text textAlign="left" fontSize="18px">
                                {data?.whatsappDisclosure?.cep}
                              </Text>
                            </VStack>
                            <VStack align="stretch" spacing="1">
                              <Text textAlign="left" fontSize="14px">
                                Cidade
                              </Text>
                              <Text textAlign="left" fontSize="18px">
                                {String(
                                  cities.find(
                                    (city) =>
                                      city.id ==
                                      data?.whatsappDisclosure?.city_id
                                  )?.name || ""
                                )}
                                {data?.whatsappDisclosure?.state_id && "/"}
                                {String(
                                  states.find(
                                    (state) =>
                                      state.id ==
                                      data?.whatsappDisclosure?.state_id
                                  )?.initials || ""
                                )}
                              </Text>
                            </VStack>
                          </Flex>
                          <Flex gap="4">
                            <VStack align="stretch" spacing="1">
                              <Text textAlign="left" fontSize="14px">
                                Endereço completo
                              </Text>
                              <Text textAlign="left" fontSize="18px">
                                {data?.whatsappDisclosure?.address}
                              </Text>
                            </VStack>
                          </Flex>
                          <Flex gap="4">
                            <VStack align="stretch" spacing="1">
                              <Text textAlign="left" fontSize="14px">
                                Bairro
                              </Text>
                              <Text textAlign="left" fontSize="18px">
                                {data?.whatsappDisclosure?.district}
                              </Text>
                            </VStack>
                            <VStack align="stretch" spacing="1">
                              <Text textAlign="left" fontSize="14px">
                                Complemento
                              </Text>
                              <Text textAlign="left" fontSize="18px">
                                {data?.whatsappDisclosure?.complement ||
                                  "Sem complemento"}
                              </Text>
                            </VStack>
                          </Flex>
                        </>
                      ) : (
                        <>
                          <Text textAlign="left" fontSize="14px">
                            Endereço virtual
                          </Text>
                          <Text textAlign="left" fontSize="18px">
                            {data?.whatsappDisclosure?.url}
                          </Text>
                        </>
                      )}
                      <Flex w="full" direction="column" justifyContent="start">
						<Text fontSize="md" mb={2}>Arquivos:</Text>
                        <VStack spacing={2} w="full">
                          {data?.whatsappDisclosure?.documents.map((doc) => (
                            <Link href={doc.url} isExternal>
                              <Button>
                                <Icon icon="download" margin="15" />
                                <Text>{doc.name}</Text>
                              </Button>
                            </Link>
                          ))}
                        </VStack>
                      </Flex>
                    </>
                  )}
                </VStack>
              </VStack>
            </Box>
          </CardBody>
        </Card>
        {!loading && <PannelHandler />}
      </Grid>
    </Flex>
  );
};

export default ViewWhatsappDisclosure;
