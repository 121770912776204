import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import CustomerEvent from "layouts/Customer.js";
import Campaign from "layouts/Campaign.js";
import AdminLayout from "layouts/Admin.js";

import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import RedirectHome from "components/RedirectHome";
import AppAuthProvider from "./providers/appAuthProvider";
import { TokenProvider } from "hooks/token";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" exact component={RedirectHome} />
            <Route path={`/campanha/:key`} component={Campaign} />
            <Route path={`/agendamento/:key`} component={CustomerEvent} />
            <TokenProvider>
                <AppAuthProvider>
                    <PrivateRoute path={`/sistema`} component={AdminLayout} />
                </AppAuthProvider>
            </TokenProvider>
            <Redirect from={`/`} to="/sistema/dashboard" />
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
