import React from "react";
import { useEffect, useState } from 'react';
import { useFreeHours, usePaginateFreeHours } from 'hooks/freeHours';

import { getWeekDay } from 'functions/getWeekDay';
import dateFormat from 'functions/dateFormat';
import AsyncTable from '../AsyncTable';
import { useRegionals } from "../../../hooks/globalRegionals";
import { useAuth } from "../../../hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";
import ProfessionalScheduleAudit from './ProfessionalScheduleAudit';
import { roleAdmin, roleSuper } from "../../../variables/roles";
import queryParamsPage from "functions/queryParamsPage";

const columns = [
  {
    Header: "Regional",
    accessor: "regional.name",
  },
  {
    Header: "Profissional",
    accessor: "professional.user.name",
  },
  {
    Header: "Tipo",
    accessor: "type",
    Cell: props => <div>{props.value === 'tele' ? 'Tele Atendimento' : 'Presencial'}</div>
  },
  {
    Header: "Dia",
    accessor: "week_day",
    Cell: props => <div>{getWeekDay(props.value)}</div>
  },
  {
    Header: "Horário",
    accessor: "start_time",
    Cell: props =>
      <div>
        {
          props.row.original.type === 'presencial' ?
            <div>
              {dateFormat(props.row.original.start_time, 'HH:mm')} - {dateFormat(props.row.original.end_time, 'HH:mm')}
            </div>
            :
            <div>{props.row.original.turn === 'manha' ? 'Manhã' : 'Tarde'}</div>
        }
      </div>
  },
]

const FreeHourTable = ({ filter }) => {
  const location = useLocation()
  const { page: queryPage, size: querySize } = queryParamsPage(location)
  const { user } = useAuth();
  const { regional: regionalId } = useRegionals();

  const { loading, rows, total, perPage, currentPage, setToDelete, setDeleteModalOpen } = useFreeHours();
  const [paginateFreeHours, { loading: reloading }] = usePaginateFreeHours();

  const [auditId, setAuditId] = useState(null)
  const [open, setOpen] = useState(false);

  const deleteFreeHour = (id) => { setToDelete(id), setDeleteModalOpen(true) };

  const fetchData = ({ pageSize, pageIndex }) => {
    if (pageSize !== perPage) pageIndex = 1;

    const roleFilter = (!!filter.role) ? {
      professional: {
        "column": "ROLE",
        "operator": "EQ",
        "value": filter.role,
      }
    } : null;

    const professionalFilter = (!!filter.professional) ? {
      "column": "PROFESSIONAL_ID",
      "operator": "EQ",
      "value": filter.professional
    } : null

    const variables = {
      where: {
        "column": !!regionalId ? "REGIONAL_ID" : "ID",
        "operator": !!regionalId ? "EQ" : "NEQ",
        "value": !!regionalId ? regionalId : null,
        AND: [
          { ...professionalFilter }
        ],
      },
      ...roleFilter,
      first: pageSize || querySize || 20,
      page: pageIndex || queryPage || 1,
    }

    paginateFreeHours({ variables: variables })
  }

  useEffect(() => {
    if (!!regionalId) fetchData(1, 20);
  }, [filter, regionalId]);

  const handleAudit = (id) => {
    setAuditId(id)
    setOpen(true)
  }

  const customActions = [
    {
      label: 'Registro de Atividades',
      icon: 'documents',
      visible: () => {
        return user?.role === roleSuper;
      },
      callback: (id) => {
        handleAudit(id)
      }
    }
  ]

  return (
    <>
      <AsyncTable
        isLoaded={!loading && !reloading && !!regionalId}
        fetchData={fetchData}
        data={!loading && !reloading && !!regionalId ? rows : [{}, {}, {}, {}]}
        customActions={!loading ? customActions : null}
        columns={columns}
        size={perPage}
        total={total}
        asyncPage={currentPage}
        baseRoute="agenda"
        removeAction={deleteFreeHour}
        noView
        linkRow
      />
      {
        open &&
        <ProfessionalScheduleAudit
          id={auditId}
          open={open}
          close={() => setOpen(false)}
        />
      }
    </>
  );
}

export default FreeHourTable
