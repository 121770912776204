import { useEffect, useState } from 'react';
import { useUsers } from 'hooks/users'

import { Box, Flex, Text } from "@chakra-ui/react";

import BasicTable from '../BasicTable'

import dateFormat from 'functions/dateFormat';
import { useUsersFilter } from 'hooks/users';
import AsyncTable from '../AsyncTable';
import { usePaginateUsers } from 'hooks/users';
import { roles } from 'variables/roles';
import queryParamsPage from 'functions/queryParamsPage';
import { useLocation } from 'react-router-dom';

const columns = [
  {
    Header: "Nome",
    accessor: "name",
    isLink: true,
  },
  {
    Header: "E-mail",
    accessor: "email",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: props => <Flex alignItems="center"><Box backgroundColor={props.value ? "green.500" : "red.500"} width={3} height={3} borderRadius={100} mr={2} /> {props.value ? 'Ativo' : 'Inativo'}</Flex>
  },
  {
    Header: "Perfil",
    accessor: "role",
    Cell: props => <div>{props?.value?.replace('_', ' ').replace('_', ' ') || null}</div>
  },
  {
    Header: "Cadastrado em",
    accessor: "created_at",
    Cell: props => <div>{dateFormat(props.value)}</div>
  },
]

const filters = [
  {
    label: 'Perfil',
    field: 'role',
    options: roles
  },
  {
    label: 'Status',
    field: 'status',
    options: [
      {
        label: 'Ativo',
        value: true
      },
      {
        label: 'Inativo',
        value: false
      },
    ]
  }
]

const UsersTable = () => {
  const location = useLocation()
  const { page: queryPage, size: querySize } = queryParamsPage(location)
  const { loading, rows = [{}], total, perPage, currentPage } = useUsers()
  const [paginateUsers, { loading: reloading }] = usePaginateUsers()

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage)
      pageIndex = 1
    paginateUsers({
      variables: {
        first: pageSize || querySize || 30,
        page: pageIndex || queryPage || 1,
        search: search || ''
      }
    })
  }

  return (
    <AsyncTable
      withoutActions={true}
      isLoaded={!loading && !reloading}
      fetchData={fetchData}
      size={perPage}
      data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
      columns={columns}
      total={total}
      asyncPage={currentPage}
      searchByName
      baseRoute="usuarios"
      noView
      linkRow
    />
  );
}

export default UsersTable
