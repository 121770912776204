import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {padding} from "variables/nivo/bar";
import {useColorMode} from "@chakra-ui/react";
import {monochromeMainColors} from "variables/colors";
import {axisLeft, axisTop, labelTextColor, themeDark, themeLight, tooltip} from "variables/nivo/default";

function genInfo(data) {
    let ret = [];
    if (!!data) {
        data = data[0];
        ret.push({name: "0 até 4", total: data.zero_four});
        ret.push({name: "5 até 9", total: data.five_nine});
        ret.push({name: "10 até 14", total: data.ten_fourteen});
        ret.push({name: "15 até 19", total: data.fifteen_nineteen});
        ret.push({name: "20 até 24", total: data.twenty_twentyfour});
        ret.push({name: "25 até 29", total: data.twentyfive_twentynine});
        ret.push({name: "30 até 34", total: data.thirty_thirtyfour});
        ret.push({name: "35 até 39", total: data.thirtyfive_thirtynine});
        ret.push({name: "40 até 44", total: data.fourty_fourtyfour});
        ret.push({name: "45 até 49", total: data.fourtyfive_fourtynine});
        ret.push({name: "50 até 54", total: data.fifty_fiftyfour});
        ret.push({name: "55 até 59", total: data.fiftyfive_fiftynine});
        ret.push({name: "60+", total: data.sixty_plus});
    }
    return ret;
}

const emptyData = [{name: "", total: 0},]

const Ages = ({data}) => {
    if (!data) data = emptyData; else data = genInfo(data);
    const {colorMode} = useColorMode();

    return (<ResponsiveBar
            data={data}
            keys={["total"]}
            indexBy="name"
            layout="horizontal"
            margin={{top: 5, right: 10, bottom: 5, left: 70}}
            padding={padding}
            colors={monochromeMainColors}
            labelSkipWidth={11}
            theme={colorMode === "light" ? themeLight : themeDark}
            axisBottom={null}
            axisLeft={axisLeft}
            labelTextColor="#FFF"
            tooltip={({indexValue, value}) => tooltip(indexValue, value)}
        />)
};

export default Ages
