import React, { useState } from "react";

import exemple from "assets/csv/exemplo.csv";

import {
    Box,
    Button,
    Center,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useUploadCancellationExcel } from "hooks/beneficiaries";

const MassBeneficiaryCancellation = ({ open, close }) => {
    const toast = useToast();
    const [errors, setError] = useState(false);
    const cancelColor = useColorModeValue("red.600", "red.500");
    const cancelHover = useColorModeValue("red.500", "red.400");

    const [upload, { loading }] = useUploadCancellationExcel({
        onCompleted: (data) => {
            close();
            toast({
                title: "Planilha de cancelamento importada com sucesso.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        },
        onError: (errors) => {
            setError(true);
            toast({
                title: "Ocorreu um erro",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        fileRejections,
        isDragActive,
    } = useDropzone({
        accept: ["text/csv"],
    });

    const isPlural = () => {
        return acceptedFiles.length > 1 ? "s" : "";
    };

    const onSubmitFile = () => {
        console.log(acceptedFiles[0]);
        acceptedFiles.map((file) => {
            console.log(file);
            upload({
                variables: {
                    file: file,
                },
            });
        });
    };

    const downloadFile = () => {
        window.open(exemple, '_blank');
    }

    return (
        <>
            <Modal
                isCentered
                onClose={close}
                isOpen={open}
                motionPreset="slideInBottom"
                size="xl"
                autoFocus={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cancelar Beneficiários</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center
                            align="center"
                            border="1px dashed #F49E00"
                            borderRadius="15px"
                            minH="200px"
                            cursor="pointer"
                            onChange={() => setError(false)}
                            {...getRootProps({ className: "dropzone" })}
                        >
                            <Input {...getInputProps()} />
                            <Text>
                                {errors ? (
                                    <Text mt="1em" textAlign="center" color="red">
                                        Ocorreu um erro na importação
                                    </Text>
                                ) : fileRejections?.length > 0 ? (
                                    <Text mt="1em" textAlign="center" color="red">
                                        Formato inválido, por favor utilize arquivos do tipo CSV
                                    </Text>
                                ) : acceptedFiles?.length === 0 ? (
                                    <Text>
                                        {isDragActive
                                            ? "Solte os arquivos aqui"
                                            : "Arraste ou selecione uma planilha de cancelamento em lote"}
                                    </Text>
                                ) : (
                                    <Box>
                                        <Text mb="1em">
                                            {`${acceptedFiles.length
                                                } arquivo${isPlural()} selecionado${isPlural()}`}
                                        </Text>
                                        {acceptedFiles.map((file, index) => {
                                            return (
                                                <Text
                                                    maxW="75%"
                                                    whiteSpace="nowrap"
                                                    overflow="hidden"
                                                    textOverflow="ellipsis"
                                                >
                                                    {index === acceptedFiles.length - 1
                                                        ? file.path
                                                        : `${file.path};`}
                                                </Text>
                                            );
                                        })}
                                    </Box>
                                )}
                            </Text>
                        </Center>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            backgroundColor={cancelColor}
                            _hover={{ background: cancelHover }}
                            color="white"
                            variant="no-hover"
                            type="submit"
                            style={{ marginRight: "15px" }}
                            isLoading={loading}
                            onClick={() => onSubmitFile()}
                        >
                            Cancelar Planos em Lote
                        </Button>
                        <Button
                            backgroundColor={cancelColor}
                            _hover={{ background: cancelHover }}
                            color="white"
                            variant="no-hover"
                            type="button"
                            onClick={() => downloadFile()}
                        >
                            Baixar Planilha Modelo
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MassBeneficiaryCancellation;
