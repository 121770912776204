import React, {createContext, useContext, useReducer} from "react";
import {useQuery} from "@apollo/client";
import {GET_ACCOMPANIMENT} from "graphql/assessments";

const ACTION_TYPES = {
    GET: 'GET_ACCOMPANIMENT',
}
const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET: {
            return {
                ...state, accompaniment: action.data
            }
        }
        default: {
            return state
        }
    }
}

const AccompanimentStateContext = createContext()
const AccompanimentDispatchContext = createContext()

const AccompanimentProvider = ({children, beneficiary_id}) => {
    const [state, dispatch] = useReducer(reducers, {accompaniment: {}})
    const {data, loading, error} = useQuery(GET_ACCOMPANIMENT, {
        variables: {
            beneficiary_id
        }, onCompleted: (data) => dispatch({type: ACTION_TYPES.GET, data})
    })

    return (<AccompanimentStateContext.Provider value={{data, loading, error, ...state}}>
            <AccompanimentDispatchContext.Provider value={dispatch}>{children}</AccompanimentDispatchContext.Provider>
        </AccompanimentStateContext.Provider>)
}


const useAccompaniment = () => {
    const context = useContext(AccompanimentStateContext)

    if (!context) throw new Error('useAccompaniment must be used within an AccompanimentProvider')

    return context
}

export {
    AccompanimentProvider, useAccompaniment
}