import React, { useEffect, useState } from "react";

import { Box, Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import CompaniesTable from "components/Tables/Companies/CompaniesTable";
import BasicTable from "components/Tables/BasicTable";
import { fromAnyFormatToBrWithHours } from "functions/momentFormat";
import { useHistory, useLocation } from "react-router-dom";

const ProposalDetails = ({ selectedCompany }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const history = useHistory();

  if (!selectedCompany) return <></>;

  return (
    <Flex direction={"column"} w={"100%"} style={{ gridColumn: "5 / span 6" }}>
      <Card>
        <CardHeader>
          <Flex direction="column">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              {selectedCompany?.name}
            </Text>
            <Text>{selectedCompany?.document}</Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <BasicTable
            tableData={selectedCompany?.proposals || []}
            columnsData={[
              { Header: "Número da Proposta", accessor: "proposal_number" },
              {
                Header: "Assinado em",
                accessor: "assign_at",
                Cell: (props) => (
                  <div>{fromAnyFormatToBrWithHours(props?.value) || null}</div>
                ),
              },
            ]}
            noEdit={true}
            noView={true}
            noDelete={true}
            clickItem={(proposal) =>
              history.push(`/sistema/administracao/gestao-vidas?page=1&size=30&proposalId=${proposal.id}`)
            }
          />
        </CardBody>
      </Card>
    </Flex>
  );
};

const Companies = () => {
  const textColor = useColorModeValue("gray.700", "white");

  const [selectedCompany, setSelectedCompany] = useState(null);

  const selectCompany = (data) => {
    setSelectedCompany(data);
  };

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(6, 6fr)" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        w={"100%"}
      >
        <Card
          style={{ gridColumn: `1 / span ${selectedCompany ? "4" : "6"}` }}
          px="0px"
          pb="24px"
          minHeight="320px"
        >
          <CardHeader mb="34px" px="22px">
            <Box w="100%">
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                <Text color={textColor} fontSize="lg" fontWeight="bold">
                  Empresas
                </Text>
              </Flex>
            </Box>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <CompaniesTable onItemClick={selectCompany} />
            </Box>
          </CardBody>
        </Card>
        <ProposalDetails selectedCompany={selectedCompany}></ProposalDetails>
      </Grid>
    </Flex>
  );
};

export default Companies;
