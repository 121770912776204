import React from "react";
import {
	Box,
	Flex,
	SimpleGrid,
	Skeleton,
	Stat,
	StatLabel,
	StatNumber,
	Tooltip,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
// Custom icons
import MiniStatistics from "components/MiniStatistic";
import dateFormat from "functions/dateFormat";
import { usePendingEventsBeneficiary } from "hooks/beneficiary";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEvents } from "hooks/beneficiaryEvents";
import { useEventsDispatch } from "hooks/beneficiaryEvents";
import { useBeneficiaries } from "hooks/beneficiaries";
import { File, Clock } from "@phosphor-icons/react";

const AppointmentsMade = ({
	handleModal,
	awaitingSchedules = [],
	handleForm,
	loadingEvents,
	loadEvents
}) => {
	const { beneficiary } = useBeneficiaries();
	const { pendingAssessments } = useEvents();
	const dispatch = useEventsDispatch();
	const { id } = useParams();
	// const [
	// 	loadEvents,
	// 	{ data: events, loading: loadingEvents },
	// ] = usePendingEventsBeneficiary(id);
	const iconBoxInside = useColorModeValue("white", "white");
	const textColor = useColorModeValue("gray.700", "white");

	const handleClick = (id, event) => {
		handleModal({
			table: "scheduled",
			event_id: id,
			name: beneficiary?.name,
			type: "reschedule",
			event: event,
			exclude: id,
		});
	};

	document.addEventListener("form-finished", function (e) {
		loadEvents();
	});

	// useEffect(() => {
	// 	setTimeout(async () => {
	// 		const { data } = await loadEvents();
	// 		dispatch({
	// 			type: "SET_PENDING",
	// 			data: data?.beneficiary?.assessmentsPending,
	// 		});
	// 	}, 2000);
	// }, []);

	const getTeleTurn = (tele) => {
		try {
			const timestamp = new Date(tele);
			return timestamp.getHours() < 12 ? "Manhã" : "Tarde";
		} catch (error) {
			console.log(error);
		}
	};

	const handleAmount = (e) => {
		try {
			if (!!e && !!e.event && !!e.event.type && e.event.type === "tele") {
				return `${dateFormat(e.event.scheduled_to, "dd/MM/Y")} - ${getTeleTurn(
					e.event.scheduled_to
				)}`;
			}
			return dateFormat(e.event.scheduled_to, "dd/MM/Y HH:mm");
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<SimpleGrid spacing="24px">
			<Flex direction={"column"}>
				{loadingEvents ? (
					<Card minH="83px" w={"100%"} marginBottom={"10px"}>
						<CardBody>
							<Flex
								flexDirection="row"
								align="center"
								justify="center"
								w="100%"
							>
								<Stat me="auto">
									<Skeleton height="23px" isLoaded={false} mb="1" width="40%">
										<StatLabel
											fontSize="sm"
											color="gray.400"
											fontWeight="bold"
											pb=".1rem"
										></StatLabel>
										Carregando
									</Skeleton>
									<Flex>
										<Skeleton height="27px" isLoaded={false} width="40%">
											<StatNumber fontSize="lg" color={textColor}>
												Carregando
											</StatNumber>
										</Skeleton>
									</Flex>
								</Stat>
								<Box>
									<Skeleton height="45px" isLoaded={false} borderRadius="12px">
										<IconBox
											as="box"
											mr="8px"
											h={"45px"}
											w={"45px"}
											background="green.600"
											_hover={{ background: "green.500" }}
											cursor="pointer"
										></IconBox>
									</Skeleton>
								</Box>
							</Flex>
						</CardBody>
					</Card>
				) : (
					pendingAssessments?.map((e, index) => (
						<Card minH="83px" w={"100%"} marginBottom={"10px"}>
							<CardBody>
								<Flex
									flexDirection="row"
									align="center"
									justify="center"
									w="100%"
								>
									<Stat me="auto">
										<StatLabel
											fontSize="sm"
											color="gray.400"
											fontWeight="bold"
											pb=".1rem"
										>
											{e?.type?.name}
										</StatLabel>
										<Flex>
											<StatNumber fontSize="lg" color={textColor}>
												{handleAmount(e)}
											</StatNumber>
										</Flex>
									</Stat>
									<Tooltip
										label="Reagendar"
										placement="top"
										closeOnClick={true}
									>
										<Box
											onClick={() => handleClick(e?.event.id, e?.type?.name)}
										>
											<IconBox
												as="box"
												mr="8px"
												h={"45px"}
												w={"45px"}
												background="green.600"
												_hover={{ background: "green.500" }}
												cursor="pointer"
											>
												<Clock
													size="24"
													color={iconBoxInside}
												/>
											</IconBox>
										</Box>
									</Tooltip>
									<Tooltip
										label="Realizar consulta"
										placement="top"
										closeOnClick={true}
									>
										<Box onClick={() => handleForm(e?.url, e?.type?.name, e?.id)}>
											<IconBox
												as="box"
												h={"45px"}
												w={"45px"}
												background="notrelife.800"
												_hover={{ background: "notrelife.700" }}
												cursor="pointer"
											>
												<File size="24px" color="white" weight="fill" />
											</IconBox>
										</Box>
									</Tooltip>
								</Flex>
							</CardBody>
						</Card>
					))
				)}
			</Flex>
		</SimpleGrid>
	);
};

export default AppointmentsMade;
