import {createContext, useContext, useReducer} from "react";
import {ACTION_TYPES, reducers} from "./reducers";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";

import {
    CANCEL_BENEFICIARY,
    COMMITMENTS_BENEFICIARY,
    CREATE_LIFE_PLAN,
    DELETE_LIFE_PLAN,
    GET_BENEFICIARIES_PAGINATE,
    GET_BENEFICIARY,
    PENDING_EVENTS_BENEFICIARY,
    UPDATE_BENEFICIARY,
    UPDATE_CARE_PLAN,
    UPDATE_PROPOSAL,
    PA_REFUSAL,
    PENDING_AWAITING_SCHEDULES
} from "../../graphql/beneficiaries";
import {GET_PROFESSIONALS_BY_REGIONAL} from "graphql/professionals";

const BeneficiaryStateContext = createContext()
const BeneficiaryDispatchContext = createContext()


const BeneficiaryProvider = ({children}) => {
    const {id} = useParams()
    const [state, dispatch] = useReducer(reducers, {beneficiary: {}})
    const {data, loading, error} = useQuery(GET_BENEFICIARY, {
        variables: {
            id: id
        },
        onCompleted: (data) => dispatch({type: ACTION_TYPES.LOAD, data: data.beneficiary})
    })
    return (
        <BeneficiaryStateContext.Provider value={{...state, loading, error}}>
            <BeneficiaryDispatchContext.Provider value={dispatch}>{children}</BeneficiaryDispatchContext.Provider>
        </BeneficiaryStateContext.Provider>
    )
}

const useUpdateBeneficiary = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const {onSuccess, onError} = options

    return useMutation(UPDATE_PROPOSAL, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess()
            dispatch({type: ACTION_TYPES.UPDATE_PROPOSAL, data: data.updateProposal})
        },
        onError: (error) => {
            if (onError) onError(error?.graphQLErrors[0]?.extensions?.validation)
        },
        refetchQueries: [
            {
                query: GET_BENEFICIARIES_PAGINATE
            }
        ]
    })
}

const useBeneficiary = () => {
    const context = useContext(BeneficiaryStateContext)

    if (!context)
        throw new Error('useBeneficiaries must be used within an BeneficiaryProvider')

    return context
}

const useCreateContactsPhoneMade = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const {onSuccess, onError} = options
    return useMutation(UPDATE_BENEFICIARY, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess()
            dispatch({type: ACTION_TYPES.UPDATE, data: data.updateBeneficiary})
        },
    })
}

const useCreateBeneficiaryLifePlan = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const {onSuccess} = options

    return useMutation(CREATE_LIFE_PLAN, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data.createLifePlan)
            dispatch({type: ACTION_TYPES.CREATE_LIFE_PLAN, data: data.createLifePlan})
        },
    })
}

const useDeleteBeneficiaryLifePlan = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const {onSuccess} = options

    return useMutation(DELETE_LIFE_PLAN, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data.deleteLifePlan)
            dispatch({type: ACTION_TYPES.DELETE_LIFE_PLAN, data: data.deleteLifePlan})
        },
    })
}

const useUpdateBeneficiaryCarePlan = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const {onSuccess} = options

    return useMutation(UPDATE_CARE_PLAN, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data.updateCarePlan)
            dispatch({type: ACTION_TYPES.UPDATE_CARE_PLAN, data: data.updateCarePlan})
        },
    })
}

const usePendingEventsBeneficiary = (id) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    return useLazyQuery(PENDING_EVENTS_BENEFICIARY, {
        fetchPolicy: "no-cache",
        variables: {
            id
        },
        onCompleted: (data) => {
            //dispatch({type: ACTION_TYPES.PENDING_EVENTS_BENEFICIARY, data: data.beneficiary})
        },
    })
}
const useCommitmentsBeneficiary = (id) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    return useQuery(COMMITMENTS_BENEFICIARY, {
        variables: {
            id
        },
        onCompleted: (data) => {
            dispatch({type: ACTION_TYPES.COMMITMENTS_BENEFICIARY, data: data.beneficiary})
        },
    })
}

const useCancelBeneficiary = (options = {}) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    const {onSuccess} = options

    return useMutation(CANCEL_BENEFICIARY, {
        onCompleted: (data) => {
            if (onSuccess) onSuccess(data.cancelBeneficiary)
            dispatch({type: ACTION_TYPES.CANCEL_BENEFICIARY, data: data.cancelBeneficiary})
        },
    })
}

const useGetProfessionalsByRegional = (options) => {
    return useLazyQuery(GET_PROFESSIONALS_BY_REGIONAL, options);
}

const usePendingAwaitingSchedules = (id) => {
    const dispatch = useContext(BeneficiaryDispatchContext)
    return useLazyQuery(PENDING_AWAITING_SCHEDULES, {
        fetchPolicy: "no-cache",
        variables: {
            id
        },
        onCompleted: (data) => {
            //dispatch({type: ACTION_TYPES.PENDING_AWAITING_SCHEDULES, data: data.beneficiary})
        },
    })
}


export {
    BeneficiaryProvider,
    useBeneficiary,
    useUpdateBeneficiary,
    useCreateContactsPhoneMade,
    useCreateBeneficiaryLifePlan,
    useDeleteBeneficiaryLifePlan,
    useUpdateBeneficiaryCarePlan,
    usePendingEventsBeneficiary,
    useCommitmentsBeneficiary,
    useCancelBeneficiary,
    useGetProfessionalsByRegional,
    usePendingAwaitingSchedules
}
