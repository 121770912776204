import { useCompanies, usePaginateCompanies } from "hooks/companies";

import AsyncTable from "../AsyncTable";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryParamsPage from "functions/queryParamsPage";

const CompaniesTable = ({onItemClick}) => {
  const location = useLocation();
  const { page: queryPage, size: querySize } = queryParamsPage(location);
  const [orderBy, setOrderBy] = useState("created_at");

  const handleOrder = (toOrder) => {
    if (orderBy !== toOrder) return setOrderBy(toOrder);
  };

  const { loading, rows, total, perPage, currentPage } = useCompanies();
  const [paginateCompanies, { loading: reloading }] = usePaginateCompanies();

  React.useEffect(() => {
    fetchData({ pageSize: total, pageIndex: currentPage, search: null });
  }, [orderBy]);

  const fetchData = ({ pageSize, pageIndex, search }) => {
    // if (pageSize !== perPage) pageIndex = 1;
    paginateCompanies({
      variables: {
        first: pageSize || querySize || 30,
        page: pageIndex || queryPage || 1,
        search: search || "",
        orderBy: orderBy || "created_at",
      },
    });
  };

  const columns = [
    {
      Header: "Razão social",
      accessor: "name",
      disableSortBy: true,
    },
    {
      Header: "CNPJ",
      accessor: "document",
      disableSortBy: true,
    },
    {
      Header: "Vidas ativas",
      accessor: "beneficiaries",
      disableSortBy: true,
      Cell: (props) => <div>{props?.value?.length || 0}</div>,
    },
  ];

  return (
    <AsyncTable
      withoutActions={true}
      isLoaded={!loading && !reloading}
      fetchData={fetchData}
      clickItem={onItemClick}
      size={perPage}
      data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
      columns={columns}
      total={total}
      asyncPage={currentPage}
      searchByName
      baseRoute="empresas"
      noView
    />
  );
};

export default CompaniesTable;
