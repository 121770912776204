import React from "react";

import { Box, Grid, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "./components/FiltersBox";
import { useWhatsAppDashboard } from "hooks/dashboards/useDashboards";
import TotalLivesCount from "components/Charts/Nivo/Lifes/TotalLivesCount";
import ThirtyDaysWhatsApp from "components/Charts/Nivo/Lifes/ThirtyDaysWhatsApp";
import { useFilter } from "hooks/useFilter";

const WhatsApp = () => {
  const { filters } = useFilter();
  const { data, loading } = useWhatsAppDashboard(filters);
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt={{ sm: "125px", lg: "160px" }}
      >
        <Card px="0px" pb="0px" minHeight="100px">
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Filtros
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <FiltersBox yearMonthFilter />
            </Box>
          </CardBody>
        </Card>
      </Grid>
      {loading ? (
        "Carregando..."
      ) : (
        <>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(4, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem inicial
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.initialMessage}
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem de Boas Vindas
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.welcomeMessage}
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem de confirmação
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.confirmationMessage}
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem agendamento
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.appointmentMessage}
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem no show
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.noShowMessage}
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem de Lembrança do Agendamento
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={
                      data?.dashboard_whats_app?.appointmentReminderMessage
                    }
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem de Automação
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.automationMessage}
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem de finalização de Primeiro Acolimento
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={
                      data?.dashboard_whats_app?.firstAcknowledgementMessage
                    }
                  />
                </Box>
              </CardBody>
            </Card>
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem Convite para Evento
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.eventInvitationMessage}
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem Confirmação em Evento
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.eventConfirmationMessage}
                  />
                </Box>
              </CardBody>
            </Card>
            <Card
              px="0px"
              pb="0px"
              minHeight="180px"
              height="180px"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Mensagem de Campanha
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <TotalLivesCount
                    total={data?.dashboard_whats_app?.campaignMessage}
                  />
                </Box>
              </CardBody>
            </Card>
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="12px"
            pt={{ sm: "12px", lg: "12px" }}
          >
            <Card
              px="0px"
              pb="0px"
              minHeight="750px"
              height="auto"
              overflowX={{ sm: "auto", lg: "unset" }}
            >
              <CardHeader px="22px">
                <Text
                  color={textColor}
                  fontSize="lg"
                  fontWeight="bold"
                  marginBottom={0}
                >
                  Taxa de evolução e comparativo
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%" minW={{ lg: "auto", sm: 700 }}>
                  <ThirtyDaysWhatsApp
                    data={data?.dashboard_whats_app?.thirtyDays}
                  />
                </Box>
              </CardBody>
            </Card>
          </Grid>
        </>
      )}
    </>
  );
};

export default WhatsApp;
