import {gql} from "@apollo/client";


export const GET_PROFESSIONALS_SEARCH = gql`
query GET_PROFESSIONALS_SEARCH($search: Mixed!) {
    professionals(user: {column: NAME, operator: LIKE, value: $search}) {
        id
        cpf
        user {
            name
            email
        }
    }
}

`
