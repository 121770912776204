export const cpfFormat = (value) => {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const phoneFormat = (value) => {
  return value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
};

export const rgFormat = (value) => {
  return value.replace(/(\D{2})(\d{2})(\d{3})(\d{3})/, "$1-$2.$3.$4");
};
